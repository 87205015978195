'use strict';

angular.module('directive.user.tax-status', [
    'model.TaxStatus',
    'service.spouse-or-partner'
  ])
  .directive('taxStatus', [
    '$timeout',
    'ram',
    'config',
    'dropdownOptions',
    'TaxStatus',
    'spouseOrPartner',
    taxStatusDirective
  ]);

function taxStatusDirective($timeout, ram, config, dropdownOptions, TaxStatus, spouseOrPartner) {

  return {
    restrict: 'E',
    scope: {
      form: '=',
      person: '=',
      optional: '='
    },
    templateUrl: 'directives/user/tax-status.html',
    link: taxStatusLink,
    controller: ['$scope', taxStatusCtrl]
  };

  function taxStatusLink(scope, el, attrs) {
    if (attrs.isSpouse === 'true') {
      scope.isSpouse = true;
    }
  }

  function taxStatusCtrl($scope) {
    $scope.config = config;
    $scope.yesOrNo = dropdownOptions.yesOrNo();
    $scope.taxStatus = $scope.person.taxStatus();
    var usaCitizenSelectedCallBack = null;

    var _createAndOrResetTaxStatusRecords = null;

    $scope.jurisdictions = $scope.config.types.Country.filter(function(country) {
      return country.code !== 'CA';
    });

    $scope.$watch('person.citizenship()', function(newCitizenship, oldCitizenship) {
      if ((oldCitizenship !== 'US') && (newCitizenship === 'US')) {
        if ($scope.person.hasForeignTaxStatus() && $scope.taxStatus && $scope.taxStatus.jurisdiction() === 'US') {
          // Records already exist.
          return;
        }
        if ($scope.person.hasForeignTaxStatus()) {
          // Need to toggle it to keep the watchers happy
          $scope.person.hasForeignTaxStatus(false); // trigger the server to delete the record
          usaCitizenSelectedCallBack = function() {
            $scope.person.hasForeignTaxStatus(true); // trigger the watcher below to set things up for us
          };

        } else {
          $scope.person.hasForeignTaxStatus(true);
        }
      }
    });

    $scope.$watch('person.hasForeignTaxStatus()', function() {
      return _createAndOrResetTaxStatusRecords();
    });

    _createAndOrResetTaxStatusRecords = function() {
      return $scope.person.save()
        .then(function() {
          return $scope.person.taxStatuses.ready(true);
        })
        .then(function() {
          if ($scope.person.hasForeignTaxStatus() && !$scope.person.taxStatus()) {
            $scope.taxStatus = new TaxStatus({
              personId: $scope.person.id
            });
          }
          if ($scope.taxStatus && !$scope.taxStatus.jurisdiction() && $scope.person.citizenship() === 'US') {
            $scope.taxStatus.jurisdiction('US');
          }
        })
        .then(function() {
          var callBack = usaCitizenSelectedCallBack;
          if (callBack) {
            usaCitizenSelectedCallBack = null;
            callBack();
          }
        });
    };


    $scope.$watch('taxStatus.jurisdiction()', function(newTaxStatus, oldTaxStatus) {
      if ((oldTaxStatus === 'US') && (newTaxStatus !== 'US')) {
        $scope.taxStatus.taxIdentificationNumber(null);
        $scope.onTaxStatusChange();
      } else if ((oldTaxStatus !== 'US') && (newTaxStatus === 'US')) {
        $scope.taxStatus.taxIdentificationNumber(null);
        $scope.taxStatus.reasonText(null);
        $scope.taxStatus.reasonId(null);
      }
    });

    // Wait with save in case the hasTin radio button was clicked,
    // so that hasTin can update the fields accordingly.
    $scope.delayedSave = function() {
      $timeout(function() {
        $scope.onTaxStatusChange();
      }, 100);
    };

    $scope.reasonChange = function() {
      if (!$scope.taxStatus.reason.is.other()) {
        $scope.taxStatus.reasonText(null);
      }
      $scope.onTaxStatusChange();
    };

    $scope.onTaxStatusChange = function() {
      if ($scope.taxStatus.isValid()) {
        return $scope.taxStatus.save().then(function() {
          $scope.person.taxStatuses.ready(true);
        });
      }
    };

    $scope.spouseOrPartner = function() {
      return spouseOrPartner.relationshipName($scope.person);
    };

    // - - - - - - - - - - - - - - - - - - - - - - - - -
    // Preset the tax status for US citizens if it is missing from the database.
    var doSave = false;
    if ($scope.person.citizenship() === 'US') {
      if (!$scope.person.hasForeignTaxStatus()) {
        doSave = true;
        $scope.person.hasForeignTaxStatus(true);
      }
      if (doSave || _.size($scope.person.taxStatuses()) === 0) {
        _createAndOrResetTaxStatusRecords();
      }
    }
  }
}
