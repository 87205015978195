'use strict';

function updateRecordFactory(ram, config) {

  var updateRecord = new ram.Collection('UpdateRecord', {
    hasOne: {
      issue: 'Issue'
    },
    schema: config.schemas.UpdateRecord,
    resources: {
      default: new ram.resources.Http('/api/update_records/:id.json'),
      cookie: new ram.resources.Cookie('update_records')
    }
  });

  updateRecord.prototype.getParsedPayload = function() {
    return JSON.parse(this.payload());
  };

  return updateRecord;
}

angular.module('model.UpdateRecord', [
    'ram',
    'model.Issue'
  ])
  .factory('UpdateRecord', [
    'ram',
    'config',
    updateRecordFactory
  ]);
