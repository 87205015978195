'use strict';

function resolve($injector, $state) {
  return function(dependency) {
    return $injector.invoke($state.$current.resolve[dependency], null, $state.$current.locals.globals);
  };
}

angular.module('service.resolve', [])
  .factory('resolve', [
    '$injector',
    '$state',
    resolve
  ]);
