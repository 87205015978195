'use strict';

var insiderRoleFactory = function(ram, config) {

  /**
   * @class A model representing a generic person - could be an applicant,
   *        spouse, annuitant, etc.
   */
  var InsiderRole = new ram.Collection('InsiderRole', {
    belongsTo: {
      person: 'Person'
    },
    enums: {
      type: config.types.InsiderRole,
    },
    resources: {
      default: new ram.resources.Http('/api/insider_roles/:id.json'),
      cookie: new ram.resources.Cookie('insider_roles')
    },
    schema: config.schemas.InsiderRole
  });

  /**
   * Check whether the role is of a public company insider.
   *
   * @method isInsider
   * @return {Boolean}
   */
  InsiderRole.prototype.isInsider = function() {
    var typeId = this.typeId();
    return typeId === 0 || typeId === 1 || typeId === 2;
  };

  /**
   * Check whether the role is of a public company owner.
   *
   * @method isOwner
   * @return {Boolean}
   */
  InsiderRole.prototype.isOwner = function() {
    var typeId = this.typeId();
    return typeId === 3;
  };

  /**
   * Check whether the role is of a securities industry insider.
   *
   * @method isDealer
   * @return {Boolean}
   */
  InsiderRole.prototype.isDealer = function() {
    var typeId = this.typeId();
    return typeId === 4 || typeId === 5 || typeId === 6;
  };

  return InsiderRole;
};

angular.module('model.InsiderRole', [
    'ram'
  ])
  .factory('InsiderRole', [
    'ram',
    'config',
    insiderRoleFactory
  ]);
