'use strict';

angular.module('controller.dashboard.account', [
    'model.Question',
    'directive.dashboard-account-processing-messages',
    'directive.risklevelselector',
    'service.form-helper',
    'service.goal-service',
    'service.investment-charts.asset-allocation-data-generator',
    'service.investments-bar-prep-data',
    'service.user-notifications'
  ])
  .controller('DashboardAccountCtrl', [
    '$scope',
    '$http',
    'goal',
    'questions',
    'account',
    'identityVerificationStatus',
    'invBarPrepData',
    'investmentChartsAssetAllocationDataGenerator',
    'modelPortfolio',
    'formHelper',
    'goalService',
    'userNotifications',
    accountController
  ]);

function accountController($scope, $http, goal, questions, account, identityVerificationStatus, invBarPrepData, investmentChartsAssetAllocationDataGenerator, modelPortfolio, formHelper, goalService, userNotifications) {

  if (account.isCombinedAccount()) {
    return;
  }

  $scope.goal = goal;
  $scope.portfolio = modelPortfolio;
  $scope.identityVerificationStatus = identityVerificationStatus;

  $scope.preventEditing = (goal.advisorRiskOffset() !== 0 || account.skipRebalancing());

  // handle the questions
  $scope.questions = _.indexBy(questions, function(question) {
    return question.accessor();
  });

  function doSave(formController) {
    $scope.isSaving = true;
    if ($scope.goal.notimeframe() && ($scope.goal.timeframe() !== 25)) {
      $scope.goal.notimeframe(false);
    }
    return $scope.goal.save()
      .then(function() {
        return $http.post('/api/update_records/audit', {
          reason: 'Account Modify'
        });
      }).then(function() {
        formController.$setPristine();
        userNotifications.showTransientSuccess(
          'Thank you. Changes will be applied to your account within 2-3 business days.'
        );
      }).catch(function() {
        userNotifications.showError('Something went wrong while saving your changes. Please try again later.');
      }).finally(function() {
        $scope.isSaving = false;
      });
  }

  $scope.submitForm = function(formController) {
    if (formController.$invalid) {
      formHelper.notDone($scope, formController);
    } else {
      $scope.accountModifyReviewModal(
          $scope.account,
          $scope.account.balance(),
          $scope.goal,
          $scope.portfolio,
          $scope.currentUser,
          $scope.questions,
          $scope.toBeStacked,
          $scope.investmentsChartData
        ).result
        .then(function() {
          doSave(formController);
        });
    }
  };

  function setChartData() {
    var chartData = investmentChartsAssetAllocationDataGenerator.getData($scope.portfolio.nonZeroAllocations());

    $scope.allocationsForBarChart = _.map(chartData, function(chartDataRow) {
      return chartDataRow.allocation;
    });

    $scope.allocationDataForLegend = _.map(chartData, function(chartDataRow) {
      return {
        color: chartDataRow.color,
        label: chartDataRow.allocation.fund().label(),
        weight: chartDataRow.allocation.weight()
      };
    });

    var invChartDataObject = invBarPrepData.getChartDataForAllocations($scope.allocationsForBarChart, $scope.account);
    if (invChartDataObject) {
      $scope.toBeStacked = invChartDataObject.toBeStacked;
      $scope.investmentsChartData = invChartDataObject.investmentsChartData;
    }
  }

  function updateModelPortfolio(current, old) {
    if (current === old) {
      return;
    }

    goalService.fetchModelPortfolio(goal, $scope.account)
      .then(function(modelPortfolio) {
        $scope.portfolio = modelPortfolio;

        setChartData();
      });
  }

  var updateRiskRecommendation = _.throttle(function() {
    goalService.calculateRiskRecommendation($scope.currentUser, goal)
      .then(function(riskRecommendation) {
        $scope.goal.riskRecommendation.set(riskRecommendation, true);
      });
  }, 1000);

  function riskFactorChange(current, old) {
    if (current === old) {
      return;
    }
    updateRiskRecommendation();
  }

  function updatePreset(current, old) {
    if (current === old) {
      return;
    }
  }
  // When this controller goes out of scope, reset the goal if the advisor has set an offset.
  $scope.$on('$destroy', function() {
    if (goal && goal.advisorRiskOffset() !== 0 && goal.changed()) {
      goal.reset();
    }
  });

  $scope.onCancel = function(formController) {
    goal.reset();
    formController.$setPristine();
  };

  $scope.$watch('goal.preset()', updatePreset);
  $scope.$watch('goal.risk()', updateModelPortfolio);
  $scope.$watch('goal.timeframe()', function(timeframe, oldTimeframe) {
    $scope.maturity = moment().add(timeframe, 'y')._d;
    riskFactorChange(timeframe, oldTimeframe);
  });
  $scope.$watch('goal.withdrawals()', function(newValue, oldValue) {
    goal.unpredictable(false);
    riskFactorChange(newValue, oldValue);
  });

  setChartData();
}
