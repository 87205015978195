'use strict';

import filteredClientDocumentsComponent from './filtered-client-documents.component';

const FilteredClientDocumentsModule = angular.module('component.filtered-client-documents', [
    'ram',
    'directive.input.selector',
    'service.filtered-document-holder',
    'service.securityService'
  ])
  .component('filteredClientDocuments', filteredClientDocumentsComponent);

export default FilteredClientDocumentsModule;
