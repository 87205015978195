import template from './id-verification-section.html';
import controller from './id-verification-section.controller';

const idVerificationSectionComponent = {
  template,
  bindings: {
    identityVerificationStatus: '<',
    personId: '<',
    afterIdentityCheck: '&',
    documents: '<'
  },
  controller: ['$http', '$rootScope', '$state', controller],
  controllerAs: '$ctrl'
};

export default idVerificationSectionComponent;
