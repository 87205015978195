'use strict';

function directive($timeout) {
  return {
    restrict: 'E',
    scope: {
      message: '&'
    },
    templateUrl: 'directives/input/manual-help-block.html',
    link: function(scope, element, attrs) {

      var display = function(message) {
        if (!message || message === '') {
          return;
        }
        scope.errorMessage = message;
        scope.messageClass = attrs.messageClass || '';

        scope.$applyAsync();

        var div = element;
        $timeout(function() {
          div.css('display', 'block');
          div.find('.popover.bottom').css('margin-bottom', 0);
        });
      };

      display(scope.message());
    }
  };
}

angular.module('directive.input.manual-help-block', [])
  .directive('helpBlockManual', [
    '$timeout',
    directive
  ]);
