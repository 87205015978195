'use strict';

angular.module('service.promotion', [])
  .factory('promotion', [
    '$http',
    '$timeout',
    'ram',
    promotionFactory
  ]);

function promotionFactory($http, $timeout, ram) {

  var promoCode = new ram.Accessor(''),
    promoCodeState = new ram.Accessor('begin'),
    promoCodeMessage = new ram.Accessor(''),
    removalMessage = new ram.Accessor();

  var _promoCodeMessages = {
    '204': 'Promo code has already been redeemed. Please enter a new code.',
    '201': 'Thank you, your promo code has been applied.',
    '422': 'Please enter a valid promo code.',
    'error': 'Something went wrong. Please try again later'
  };

  return {
    apply: apply,
    code: promoCode,
    codeMessage: promoCodeMessage,
    codeState: promoCodeState,
    remove: removePromo,
    removalMessage: removalMessage
  };

  function promoCodeGetMessage(responseOrError) {
    var status = responseOrError.status.toString();
    var messageKey = _.has(_promoCodeMessages, status) ? status : 'error';
    promoCodeMessage(_promoCodeMessages[messageKey]);
    return status;
  }

  function apply(user, effectiveDate) {
    if (!promoCode() || !promoCode().length) {
      promoCodeMessage(_promoCodeMessages['422']);
      promoCodeState('invalid');
      return;
    }
    return $http.put('api/promotion_redemptions/redeem_promo_code', {
        userId: user.id,
        params: {
          code: promoCode(),
          effectiveDate: effectiveDate
        }
      })
      .then(function(response) {
          var status = promoCodeGetMessage(response);
          if (status === '201') {
            promoCodeState('valid');
          } else {
            promoCodeState('invalid');
          }
        },
        function(error) {
          promoCodeGetMessage(error);
          promoCodeState('invalid');
        });
  }

  function removePromo(promotionRedemption) {
    return $http.delete('api/promotion_redemptions/' + promotionRedemption.id)
      .then(
        function() {
          displayRemovePromoCodeMessage('Promotion removed.');
        },
        function() {
          displayRemovePromoCodeMessage('Something went wrong. Please try again later.');
        });
  }

  function displayRemovePromoCodeMessage(message) {
    removalMessage(message);
    $timeout(function() {
      removalMessage('');
    }, 1000);
  }
}
