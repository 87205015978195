'use strict';

function modalTwoFactorAuthenticationController($rootScope, $scope, $state, ram, twoFactorAuthenticator, authenticationFactor, User, isMobile, adaptiveViews) {
  $scope.dismissOrGoToProfile = function() {
    if ($state.is('profile-activate-two-factor-authentication') ||
      $state.is('profile-deactivate-two-factor-authentication')) {
      return $state.go('profile.security');
    } else {
      $scope.$dismiss();
    }
  };
  $scope.code = new ram.Accessor();
  $scope.phoneNumber = authenticationFactor.phoneNumber().substring(authenticationFactor.phoneNumber().length - 4);
  $scope.contactMethod = authenticationFactor.contactMethod();
  $scope.isMobile = isMobile;
  $scope.isSafari = adaptiveViews.isSafari;
  $scope.renderedInDesktopModal = true && !$scope.isMobile;
  $scope.isSaving = false;

  function reloadUser() {
    return User.find({
      id: $rootScope.currentUser.id
    }).then(function(user) {
      $rootScope.currentUser = user;
    });
  }

  $scope.send2faCodeVia = function(method) {
    $scope.feedbackMessage = {};
    twoFactorAuthenticator.sendCodeForLoggedInUser(method)
      .then(function(result) {
        $scope.feedbackMessage = result;
      });
  };
  $scope.validateCode = function() {
    $scope.isSaving = true;
    $scope.feedbackMessage = {};
    twoFactorAuthenticator.deactivate($scope.code())
      .then(function(result) {
        if (result.error) {
          $scope.feedbackMessage = result;
          $scope.isSaving = false;
        } else {
          reloadUser()
            .then(function() {
              $scope.isSaving = false;
              $scope.dismissOrGoToProfile();
            });
        }
      });
  };

  twoFactorAuthenticator.sendCodeForLoggedInUser();
}

angular.module('controller.modal.two-factor-authentication', [
    'ram',
    'model.User',
    'service.adaptive-views',
    'service.two-factor-authenticator'
  ])
  .controller('ModalTwoFactorAuthenticationCtrl', [
    '$rootScope',
    '$scope',
    '$state',
    'ram',
    'twoFactorAuthenticator',
    'authenticationFactor',
    'User',
    'isMobile',
    'adaptiveViews',
    modalTwoFactorAuthenticationController
  ]);
