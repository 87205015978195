'use strict';

angular.module('model.AccountApproval', [
    'model.Account',
    'model.User'
  ])
  .factory('AccountApproval', [
    'ram',
    'config',
    accountApprovalFactory
  ]);

function accountApprovalFactory(ram, config) {
  var AccountApproval = new ram.Collection('AccountApproval', {
    hasOne: {
      account: 'Account',
      approver: 'User',
      previous: 'AccountApproval',
    },
    enums: {
      status: config.types.AccountApproval
    },
    schema: config.schemas.AccountApproval,
    resources: {
      default: new ram.resources.Http('/api/account_approvals/:id.json'),
      cookie: new ram.resources.Cookie('account_approvals')
    }
  });

  return AccountApproval;
}
