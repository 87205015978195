'use strict';

angular.module('controller.advise.trade-reconciliation.report', [
  'model.Custodian'
]).controller('AdviseTradeReconciliationReportCtrl', [
  '$scope',
  'Custodian',
  'tradeReconciliationReport',
  adviseTradeReconciliationReportController
]);

function adviseTradeReconciliationReportController($scope, Custodian, tradeReconciliationReport) {
  $scope.tradeReconciliationReport = tradeReconciliationReport;
}
