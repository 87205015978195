'use strict';

/**
 * Initialize angular UI router for academy states.
 *
 * @param  {Object} $stateProvider API used to declare application states.
 */
function configRoutes($stateProvider) {

  function resolveUserName($stateParams) {
    return $stateParams.user;
  }

  function resolvePassword($stateParams) {
    return $stateParams.password;
  }

  function resolveUploadDetails($http, userName, password) {
    var url = '/api/users/get_mobile_upload_link_details.json';
    var headers = {
      'Authorization': 'Basic ' + btoa(userName + ':' + password)
    };

    return $http.get(url, {
        headers: headers
      })
      .then(function(data) {
        return data.data;
      })
      .catch(function(data) {
        if (data.data) {
          return {
            error: data.data
          };
        }
        return null;
      });
  }

  $stateProvider
    .state('mobile-upload', {
      url: '/mobile-upload?user&password',
      parent: 'site',
      controller: 'MobileUploadCtrl',
      resolve: {
        userName: ['$stateParams', resolveUserName],
        password: ['$stateParams', resolvePassword],
        uploadDetails: ['$http', 'userName', 'password', resolveUploadDetails]
      },
      views: {
        main: {
          controller: 'MobileUploadCtrl'
        },
        navbar: {
          templateUrl: 'navbar/mobile-upload-navbar.html'
        },
        footer: {
          templateUrl: 'mobile/partials/footer.html'
        }
      }
    });
}

angular.module('routes.mobile-upload', [
    'controller.mobile-upload',
    'templates',
    'ui.router'
  ])
  .config([
    '$stateProvider',
    configRoutes
  ]);
