'use strict';

function allocationFactory(ram, config) {
  return new ram.Collection('Allocation', {
    belongsTo: {
      fund: 'Fund',
      modelPortfolio: 'ModelPortfolio'
    },
    schema: config.schemas.Allocation,
    resources: {
      default: new ram.resources.Http('/api/allocations/:id.json'),
      cookie: new ram.resources.Cookie('allocations')
    },
  });
}

angular.module('model.Allocation', [
    'ram',
    'model.Fund'
  ])
  .factory('Allocation', [
    'ram',
    'config',
    allocationFactory
  ]);
