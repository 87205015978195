'use strict';

angular.module('controller.onboarding.communication-and-consent', [])
  .controller('OnboardingCommunicationAndConsentController', [
    '$scope',
    '$rootScope',
    'config',
    'account',
    onboardingCommunicationAndConsentCtrl
  ]);

function onboardingCommunicationAndConsentCtrl($scope, $rootScope, config, account) {

  $scope.account = account;

  $scope.$parent.done = function() {
    return $rootScope.validateForm($scope.form) && $scope.form.$valid;
  };

  $scope.$parent.notDone = function() {
    return $scope.formNotDone();
  };

  $scope.$parent.save = function() {
    $scope.account.save();
  };
}
