'use strict';

function paginatedTransferInstructionFactory(ram, config) {

  /**
   * Constructor for PaginatedTransferInstruction model.
   * @class
   */
  var PaginatedTransferInstruction = new ram.Collection('PaginatedTransferInstruction', {
    schema: config.schemas.PaginatedTransferInstruction,
    hasOne: {
      paginationMeta: 'PaginationMeta'
    },
    hasMany: {
      transferInstructions: 'TransferInstruction',
    },
    resources: {
      default: new ram.resources.Http('/api/paginated_transfer_instructions/:id.json'),
      cookie: new ram.resources.Cookie('paginated_transfer_instructions')
    }
  });

  return PaginatedTransferInstruction;
}

angular.module('model.PaginatedTransferInstruction', [
    'ram',
    'config',
    'model.PaginationMeta',
    'model.TransferInstruction'
  ])
  .factory('PaginatedTransferInstruction', [
    'ram',
    'config',
    paginatedTransferInstructionFactory
  ]);
