'use strict';

angular.module('form-helper-account-beneficiary', [
    'model.AccountBeneficiary',
    'model.PersonalRelation',
    'model.Person'
  ])
  .factory('formHelperAccountBeneficiary', [
    '$q',
    '$http',
    'AccountBeneficiary',
    'PersonalRelation',
    'Person',
    factory
  ]);

function factory($q, $http, AccountBeneficiary, PersonalRelation, Person) {
  function save(vars) {
    var relativeParams = vars.scope.beneficiary.personalRelation().relative().toJSON();
    var personalRelationParams = vars.scope.beneficiary.personalRelation().toJSON();
    var beneficiaryParams = vars.scope.beneficiary.toJSON();

    return $http.post('/api/account_beneficiaries/advisor_update', {
      personId: relativeParams.id,
      firstName: relativeParams.firstName,
      middleName: relativeParams.middleName,
      lastName: relativeParams.lastName,
      sin: relativeParams.sin,
      personalRelationId: personalRelationParams.id,
      typeId: personalRelationParams.typeId,
      otherRelationName: personalRelationParams.otherRelationName,
      beneficiaryId: beneficiaryParams.id,
      accountId: beneficiaryParams.accountId,
      userId: beneficiaryParams.userId
    });
  }

  function createBeneficiary(vars, mode, personalRelationId) {
    var params = _getAccountBeneficiaryParams(vars, personalRelationId);
    var localBeneficiary = null;

    if (personalRelationId) {
      localBeneficiary = new AccountBeneficiary(params);
      localBeneficiary.personalRelation.update(vars.scope.person.spouseRelation().toJSON());

    } else {
      var localPerson = new Person();
      var personalRelationParams = {
        typeId: null,
        personId: null,
        relativeId: null,
        otherRelationName: null,
        id: undefined,
        relative: localPerson
      };
      params.personalRelation = new PersonalRelation(params);
      localBeneficiary = new AccountBeneficiary(params);
      localBeneficiary.personalRelation.update(personalRelationParams);
      localBeneficiary.personalRelation().relative.update({
        firstName: null,
        middleName: null,
        lastName: null,
        sin: null
      });
    }
    vars.baseScope.beneficiary = localBeneficiary; // for the save code
    vars.baseScope.beneficiaries = [localBeneficiary]; // for the save code
    vars.baseScope.successorHolder = null; // for the save code
    vars.scope.beneficiary = localBeneficiary; // for the code here
  }

  function create(vars, personalRelationId) {
    var params = _getAccountBeneficiaryParams(vars, personalRelationId);
    return AccountBeneficiary.create(params);
  }

  function _getAccountBeneficiaryParams(vars, personalRelationId) {
    var params = {
      accountId: vars.account.id,
      userId: vars.scope.currentUser.id
    };
    if (personalRelationId) {
      params.personalRelationId = personalRelationId;
    } else {
      // create new person
      params.person = {};
    }
    return params;
  }

  return {
    save: save,
    create: create,
    createBeneficiary: createBeneficiary
  };
}
