'use strict';

angular.module('model.HouseholdInvitation', [
    'model.Household',
    'model.Person',
    'ram',
    'config'
  ])
  .factory('HouseholdInvitation', [
    '$http',
    'ram',
    'config',
    householdInvitationFactory
  ]);

function householdInvitationFactory($http, ram, config) {

  var HouseholdInvitation = new ram.Collection('HouseholdInvitation', {
    enums: {
      relationship: config.types.HouseholdInvitationRelationship
    },
    belongsTo: {
      household: 'Household',
      inviter: 'Person'
    },
    schema: config.schemas.HouseholdInvitation,
    resources: {
      default: new ram.resources.Http('/api/household_invitations/:id.json'),
      cookie: new ram.resources.Cookie('household_invitations')
    }
  });

  HouseholdInvitation.prototype.accept = function() {
    return $http.put('/api/household_invitations/' + this.id + '/accept.json');
  };

  HouseholdInvitation.prototype.decline = function() {
    return $http.put('/api/household_invitations/' + this.id + '/decline.json');
  };

  return HouseholdInvitation;
}
