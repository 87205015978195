'use strict';

function balanceFactory(ram, CashFlow, DateAccessor) {

  /**
   * Constructor for resource-aware Balance model.
   * @class
   */
  var Balance = new ram.Collection('Balance', {
    belongsTo: {
      account: 'Account'
    },
    resources: {
      default: new ram.resources.Http('/api/balances/:id.json'),
      cookie: new ram.resources.Cookie('balances')
    },
  });

  /**
   * Convert the date into a Date object, which is easier to work with.
   * @method initialize
   *
   * @param  {Object} attrs Balance object attributes
   */
  Balance.prototype.initialize = function(attrs) {
    this.date = new DateAccessor(attrs.date);
    this.amount(parseFloat(attrs.amount));
  };

  Balance.prototype.toCashFlow = function() {
    return new CashFlow(this.date(), this.amount() || 0);
  };

  /**
   * Converts the balance to something that can be displayed in a graph.
   * @return {Object} The same data as an object
   */
  Balance.prototype.toDisplayFormat = function() {
    return {
      date: this.date(),
      amount: this.amount() || 0
    };
  };

  return Balance;
}

angular.module('model.Balance', [
    'model.Account',
    'model.CashFlow',
    'ram',
    'ram.DateAccessor'
  ])
  .factory('Balance', [
    'ram',
    'CashFlow',
    'DateAccessor',
    balanceFactory
  ]);
