import template from './beneficiary-form-regular.html';
import controller from './beneficiary-form-regular.controller';

const beneficiaryFormRegularComponent = {
  template,
  bindings: {
    accountFlow: '=',
    client: '=',
    subforms: '='
  },
  controller: [
    '$q',
    'SuccessorHolder',
    'AccountBeneficiary',
    'Beneficiary',
    'AdvisorBeneficiaryForm',
    'spouseOrPartner',
    'beneficiaryParamsGenerator',
    controller
  ],
  controllerAs: '$ctrl'
};

export default beneficiaryFormRegularComponent;
