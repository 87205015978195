'use strict';

angular.module('directive.portfolio-performance', [
    'model.ModelPortfolio',
    'ram',
    'service.investment-charts.asset-allocation-data-generator',
    'service.investments-bar-prep-data',
    'service.loading-indicator',
    'service.portfolio-line-chart-data-service'
  ])
  .directive('portfolioPerformance', [
    '$q',
    'ModelPortfolio',
    'config',
    'invBarPrepData',
    'investmentChartsAssetAllocationDataGenerator',
    'loadingIndicator',
    'portfolioLineChartDataService',
    'ram',
    portfolioPerformanceDirective
  ]);

function portfolioPerformanceDirective($q, ModelPortfolio, config, invBarPrepData, investmentChartsAssetAllocationDataGenerator, loadingIndicator, portfolioLineChartDataService, ram) {
  return {
    restrict: 'E',
    scope: {
      portfolioSelections: '='
    },
    templateUrl: 'directives/portfolio-performance.html',
    link: function($scope) {
      var defaultSelection = {
        portfolioType: 'core',
        riskLevel: 5
      };

      var indexOf = {
        '1m': 0,
        '6m': 1,
        '1yr': 2,
        'inception': 3,
        'annualizedInception': 4
      };

      angular.extend($scope, {
        cumulativePerformance: {},
        end: new ram.Accessor(),
        formatCurrency: formatCurrency,
        formatDate: formatDate,
        point: {},
        portfolioType: {
          options: [{
            label: 'Core',
            value: 'core'
          }, {
            label: 'Socially Responsible',
            value: 'resp'
          }],
          selection: new ram.Accessor(defaultSelection.portfolioType)
        },
        riskLevel: new ram.Accessor(defaultSelection.riskLevel),
        start: new ram.Accessor()
      });

      function formatCurrency(d) {
        return d3.format('$')(d);
      }

      function formatDate(d) {
        var formatString = $scope.$root.isMobile ? '%b \'%y' : '%b %Y';
        return d3.time.format(formatString)(d);
      }

      $scope.currentSelection = function() {
        return $scope.portfolioType.selection() + $scope.riskLevel();
      };

      $scope.$watchGroup(['portfolioType.selection()', 'riskLevel()'], handleSelectionUpdate);

      function fetchModelPortfolio(riskLevel, portfolioType) {
        var corePortfolioOption = _.findWhere(config.types.AccountPortfolioOption, {
          name: 'core_portfolio'
        });

        var sociallyResponsiblePortfolioOption = _.findWhere(config.types.AccountPortfolioOption, {
          name: 'socially_responsible_portfolio'
        });

        return ModelPortfolio.where({
            portfolioOptionId: portfolioType === 'core' ? corePortfolioOption.id : sociallyResponsiblePortfolioOption.id,
            risk: riskLevel,
            taxable: false
          })
          .then(_.first);
      }

      function handleSelectionUpdate(values) {
        var portfolioType = values[0],
          riskLevel = values[1],
          selection = portfolioType + riskLevel;

        if (!$scope.portfolioSelections[selection]) {
          loadingIndicator.show();
          var promises = [];
          $scope.portfolioSelections[selection] = {};

          promises.push(portfolioLineChartDataService.fetch(riskLevel, portfolioType)
            .then(addToLineChartDataObject));

          promises.push(fetchModelPortfolio(riskLevel, portfolioType)
            .then(addSelectionToSelectionsObject));

          $q.all(promises).then(function() {
            updateView(selection);
            loadingIndicator.hide();
          });
        } else {
          updateView(selection);
        }

        function addToLineChartDataObject(data) {
          $scope.portfolioSelections[selection].lineChartData = data;
        }

        function addSelectionToSelectionsObject(modelPortfolio) {
          // pseudo account to allow for reuse of invBarPrepData service
          var pseudoaccount = {
            isCombinedAccount: function() {
              return false;
            },
            type: new ram.Accessor({
              label: 'pseudo label'
            })
          };

          var chartData = investmentChartsAssetAllocationDataGenerator.getData(modelPortfolio.allocations());

          var allocationDataForLegend = _.map(chartData, function(chartDataRow) {
            return {
              color: chartDataRow.color,
              symbol: chartDataRow.allocation.fund().symbol(),
              label: chartDataRow.allocation.fund().label(),
              assetClass: chartDataRow.allocation.fund().assetClass().label,
              weight: chartDataRow.allocation.weight()
            };
          });

          var allocationsForBarChart = _.map(chartData, function(chartDataRow) {
            return chartDataRow.allocation;
          });

          $scope.portfolioSelections[selection].allocationLegendData = allocationDataForLegend;
          $scope.portfolioSelections[selection].barChartData = invBarPrepData.getChartDataForAllocations(allocationsForBarChart, pseudoaccount);
          $scope.portfolioSelections[selection].mer = modelPortfolio.fee();
        }
      }

      function updateView(selection) {
        var portfolioSelection = $scope.portfolioSelections[selection];
        updatePerformanceAsOfDate(portfolioSelection);
        updateStartEnd(portfolioSelection);
        updateOverlayStatus(portfolioSelection);
        calcCumulativePerf(selection);
      }

      function updatePerformanceAsOfDate(portfolioSelection) {
        $scope.performanceAsOfDate = performanceAsOfDate(portfolioSelection).format('MMMM Do, YYYY');
      }

      function updateStartEnd(portfolioSelection) {
        var len = portfolioSelection.lineChartData.data.length;
        $scope.start(portfolioSelection.lineChartData.data[len - 1].date);
        $scope.end(portfolioSelection.lineChartData.data[0].date);
      }

      function updateOverlayStatus(portfolioSelection) {
        $scope.overlayEndDate = overlayEndDate(portfolioSelection);
        $scope.overlayNeeded = moment($scope.overlayEndDate, 'D-MMM-YY')
          .isAfter(firstBusinessDay());
      }

      function overlayEndDate(selection) {
        return moment(selection.lineChartData.firstDataPointDate, 'D-MMM-YY')
          .subtract(1, 'days')
          .format('D-MMM-YY');
      }

      function firstBusinessDay() {
        return $scope.portfolioType.selection() === 'core' ? moment('2016-01-04') : moment('2017-01-03');
      }

      function calcCumulativePerf(selection) {
        // No need to recalculate
        if ($scope.cumulativePerformance[selection]) {
          return;
        } else {
          $scope.cumulativePerformance[selection] = {};
        }

        var portfolioSelection = $scope.portfolioSelections[selection];
        var fromDates = [
          performanceAsOfDate(portfolioSelection).startOf('month'),
          performanceAsOfDate(portfolioSelection).startOf('month').subtract(5, 'months'),
          performanceAsOfDate(portfolioSelection).startOf('month').subtract(11, 'months'),
          moment($scope.start())
        ];
        fromDates.forEach(calcPerfAndSave);

        $scope.cumulativePerformance[selection][indexOf.annualizedInception] = calcAnnualizedInception($scope.cumulativePerformance[selection][indexOf.inception]);

        function calcPerfAndSave(date, index) {
          var data = portfolioSelection.lineChartData.data,
            len = data.length,
            lastDataPointDate = moment(data[len - 1].date),
            tempResult;

          if (date.isBefore(lastDataPointDate, 'day')) {
            return;
          }

          for (var i = 0; i < len; i++) {
            var currentDataPointDate = moment(data[i].date);
            if (date.isAfter(currentDataPointDate, 'day')) {
              break;
            } else if (currentDataPointDate.isAfter(performanceAsOfDate(portfolioSelection), 'day')) {
              continue;
            } else {
              if (!tempResult) {
                tempResult = data[i].pctOfPrevious;
              } else {
                tempResult = tempResult * data[i].pctOfPrevious;
              }
            }
          }

          $scope.cumulativePerformance[selection][index] = Math.floor((tempResult - 1) * 10000) / 100;
        }

        function calcAnnualizedInception(sinceInceptionPerf) {
          // Assuming sinceInceptionPerf is in percentage. ie) 13.4
          sinceInceptionPerf = sinceInceptionPerf / 100;
          // Annualize formula: (1 + sinceInception) ^ (365 / totalDates) - 1
          return (Math.pow(1 + sinceInceptionPerf, 365 / totalDates()) - 1) * 100;
        }

        function totalDates() {
          var data = portfolioSelection.lineChartData.data,
            lastDataPointDate = performanceAsOfDate(portfolioSelection),
            startDate;

          for (var i = data.length - 1; i >= 0; i--) {
            if (data[i].pctOfPrevious !== 1) {
              startDate = moment(data[i].date).startOf('month');
              break;
            }
          }

          return moment.duration(lastDataPointDate.diff(startDate)).asDays();
        }
      }

      // For performance calculations, we take the most recent last day of month
      function performanceAsOfDate(selection) {
        var mostRecentDate = moment(selection.lineChartData.data[0].date);
        var lastDayOfCurrentMonth = mostRecentDate.endOf('month');

        if (mostRecentDate.isSame(lastDayOfCurrentMonth, 'day')) {
          return mostRecentDate;
        } else {
          return mostRecentDate.startOf('month').subtract(1, 'days');
        }
      }

      $scope.displayPerformance = function(period) {
        if (!$scope.cumulativePerformance[$scope.currentSelection()]) {
          return;
        }
        var performance = $scope.cumulativePerformance[$scope.currentSelection()][indexOf[period]];
        performance = performance ? performance.toFixed(1) + '%' : 'n/a';
        performance = performance === '-0.0%' ? '0.0%' : performance;
        return performance;
      };
    }
  };
}
