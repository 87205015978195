'use strict';

angular.module('controller.dashboard.goal-summary', [
    'directive.goal-summary'
  ])
  .controller('DashboardGoalSummaryController', [
    '$state',
    '$scope',
    'goals',
    'transferInstructions',
    goalSummaryController
  ]);

function goalSummaryController($state, $scope, goals, transferInstructions) {
  $scope.goals = _.filter(goals, function(goal) {
    return goal.accountGoals()[0].account().isVisible();
  });
  $scope.transferInstructions = transferInstructions;

  $scope.accountId = function(id) {
    if (arguments.length) {
      $state.go($state.current, {
        accountId: id
      });
    }
    return $scope.account.id;
  };
}
