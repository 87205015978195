'use strict';

angular.module('routes.advise.csv-reports-view', [
    'controller.advise.csv-reports-view',
    'templates',
    'ui.router'
  ])
  .config([
    '$stateProvider',
    configRoutes
  ]);

function configRoutes($stateProvider) {
  $stateProvider
    .state('advise.csv-reports-view', {
      url: '/reports-view',
      abstract: false,
      controller: 'AdviseCsvReportsViewCtrl',
      templateUrl: 'views/advise/reports/csv-reports-view.html'
    })
    .state('advise.csv-reports-view.idna', {
      url: '/investorDNA-Report',
      controller: ['$scope', function($scope) {
        $scope.endDate = moment().startOf('month').subtract(1, 'day').toDate();
        $scope.dateRangePicked = $scope.$parent.dateRangePicked;
      }],
      template: '<date-range end-date="endDate" interval="1" date-range-picked="dateRangePicked(startDate, endDate)"></date-range>'
    })
    .state('advise.csv-reports-view.aum', {
      url: '/aum-report',
      controller: ['$scope', function($scope) {
        $scope.endDate = moment().startOf('month').subtract(1, 'day').toDate();
        $scope.dateRangePicked = $scope.$parent.dateRangePicked;
      }],
      template: '<date-range end-date="endDate" interval="0" date-range-picked="dateRangePicked(startDate, endDate)"></date-range>'
    })
    .state('advise.csv-reports-view.aum-advisor', {
      url: '/aum-advisor-report',
      controller: ['$scope', function($scope) {
        $scope.endDate = moment().startOf('month').subtract(1, 'day').toDate();
        $scope.dateRangePicked = $scope.$parent.dateRangePicked;
      }],
      template: '<date-range end-date="endDate" interval="3" date-range-picked="dateRangePicked(startDate, endDate)"></date-range>'
    })
    .state('advise.csv-reports-view.beneficiary', {
      url: '/beneficiary-report',
      template: '<div></div>'
    })
    .state('advise.csv-reports-view.advisor-group', {
      url: '/advisor-group-report',
      template: '<div></div>'
    })
    .state('advise.csv-reports-view.fund-flows', {
      url: '/fund-flows',
      controller: ['$scope', function($scope) {
        $scope.endDate = moment().startOf('month').subtract(1, 'day').toDate();
        $scope.dateRangePicked = $scope.$parent.dateRangePicked;
      }],
      template: '<date-range end-date="endDate" interval="3" date-range-picked="dateRangePicked(startDate, endDate)"></date-range>'
    })
    .state('advise.csv-reports-view.fund-flows-by-advisor', {
      url: '/fund-flows-by-advisor',
      controller: ['$scope', function($scope) {
        $scope.endDate = moment().startOf('month').subtract(1, 'day').toDate();
        $scope.dateRangePicked = $scope.$parent.dateRangePicked;
      }],
      template: '<date-range end-date="endDate" interval="3" date-range-picked="dateRangePicked(startDate, endDate)"></date-range>'
    })
    .state('advise.csv-reports-view.account-balances', {
      url: '/account-balances',
      controller: ['$scope', function($scope) {
        $scope.endDate = moment().startOf('month').subtract(1, 'day').toDate();
        $scope.dateRangePicked = $scope.$parent.dateRangePicked;
      }],
      template: '<date-range end-date="endDate" interval="3" date-range-picked="dateRangePicked(startDate, endDate)"></date-range>'
    })
    .state('advise.csv-reports-view.quarterly-aum', {
      url: '/quarterly-aum',
      controller: ['$scope', function($scope) {
        $scope.endDate = moment().startOf('month').subtract(1, 'day').toDate();
        $scope.dateRangePicked = $scope.$parent.dateRangePicked;
      }],
      template: '<date-range end-date="endDate" interval="0" date-range-picked="dateRangePicked(startDate, endDate)"></date-range>'
    })
    .state('advise.csv-reports-view.advisors-added', {
      url: '/advisors-added',
      controller: ['$scope', function($scope) {
        $scope.endDate = moment().startOf('month').subtract(1, 'day').toDate();
        $scope.dateRangePicked = $scope.$parent.dateRangePicked;
      }],
      template: '<date-range end-date="endDate" interval="3" date-range-picked="dateRangePicked(startDate, endDate)"></date-range>'
    });
}
