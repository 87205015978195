'use strict';

function jointApplicantFactory(ram, config) {

  return new ram.Collection('JointApplicant', {
    belongsTo: {
      account: 'Account'
    },
    resources: {
      default: new ram.resources.Http('/api/joint_applicants/:id.json'),
      cookie: new ram.resources.Cookie('joint_applicants')
    },
    schema: config.schemas.JointApplicant
  });
}

angular.module('model.JointApplicant', [
    'model.Account',
    'ram',
    'config'
  ])
  .factory('JointApplicant', [
    'ram',
    'config',
    jointApplicantFactory
  ]);
