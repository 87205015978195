'use strict';

angular.module('controller.advise.account', [
    'service.activityDisplayHelper',
    'service.download-csv',
    'service.timeframeDisplayHelper',
    'service.user-notifications',
    'model.Activity',
    'model.Note',
    'model.Account',
    'ram',
    'directive.advise.beneficiary-modify-card',
    'directive.editableContext',
    'directive.profile-form',
    'service.init-unsaved-changes-tracking',
    'service.investment-charts.asset-allocation-data-generator',
    'service.investment-charts.holdings-data-generator',
    'service.beneficiary-service',
    'service.form-modification-tracker',
    'service.account-service',
    'service.securityService',
    'component.advisor-dashboard.account-rif-detail',
    'component.chart-set',
    'component.chart-set-period',
    'component.investment-charts.holdings-pie-chart',
    'component.investment-charts.model-portfolio-pie-chart'
  ])
  .directive('elemReady', ['$parse', '$timeout', function($parse, $timeout) {
    return {
      restrict: 'A',
      link: function($scope, elem, attrs) {
        $timeout(function() {
          elem.ready(function() {
            $scope.$apply(function() {
              var func = $parse(attrs.elemReady);
              func($scope);
            });
          });
        });
      }
    };
  }])
  .directive('noTyping', [function() {
    return {
      restrict: 'A',
      link: function($scope, elem) {
        elem.bind('keydown', function(e) {
          var ARROWS = [37, 38, 39, 40];
          if (((e.metaKey || e.ctrlKey) && e.keyCode === 67) || // copy key
            _.contains(ARROWS, e.keyCode)) // arrows
          {
            return;
          }
          e.preventDefault();
        });
      }
    };
  }])
  .controller('AdviseAccountCtrl', [
    '$scope',
    '$filter',
    '$stateParams',
    '$window',
    '$state',
    '$timeout',
    '$analytics',
    'ram',
    'config',
    'Account',
    'Activity',
    'Note',
    'activityDisplayHelper',
    'client',
    'account',
    'accGoalAudits',
    'downloadCsv',
    'investmentChartsAssetAllocationDataGenerator',
    'investmentChartsHoldingsDataGenerator',
    'statements',
    'beneficiaries',
    'successorHolder',
    'accountHolders',
    'taxableAccounts',
    'bankAccounts',
    'unsavedChangesTracking',
    'formModificationTracker',
    'accountService',
    'timeframeDisplayHelper',
    'securityServiceFactory',
    'accountApprovals',
    'userNotifications',
    adviseAccountController
  ])
  .controller('AdviseBeneficiaryChangeController', [
    '$scope',
    '$q',
    'beneficiaryService',
    'formModificationTracker',
    adviseBeneficiaryChangeController
  ]);

function adviseAccountController($scope,
  $filter,
  $stateParams,
  $window,
  $state,
  $timeout,
  $analytics,
  ram,
  config,
  Account,
  Activity,
  Note,
  activityDisplayHelper,
  client,
  account,
  accGoalAudits,
  downloadCsv,
  investmentChartsAssetAllocationDataGenerator,
  investmentChartsHoldingsDataGenerator,
  statements,
  beneficiaries,
  successorHolder,
  accountHolders,
  taxableAccounts,
  bankAccounts,
  unsavedChangesTracking,
  formModificationTracker,
  accountService,
  timeframeDisplayHelper,
  securityServiceFactory,
  accountApprovals,
  userNotifications
) {
  const platform = config.env.platform;
  $scope.isWsi = platform && platform.name === 'WSI';
  $scope.currentUserSecurity = securityServiceFactory.create($scope.$root.currentUser);

  $scope.client = client;
  $scope.account = account;
  $scope.taxableAccounts = taxableAccounts;
  $scope.bankAccounts = bankAccounts;
  $scope.accountApprovals = accountApprovals;
  $scope.canConvertToCorporate = $scope.account.canConvertToCorporate();

  var accountGoals = $scope.account.accountGoals();
  var modelPortfolio = accountGoals[0].modelPortfolio();
  var hasTactical = (typeof modelPortfolio === 'undefined') ? false : (modelPortfolio.allocations()[0].tacticalWeight() !== null);
  const riskLevelLabels = ['Low', 'Low-Med','Medium', 'Med-High', 'High'];

  var defaultPortfolioTypeSelection;
  if (hasTactical) {
    defaultPortfolioTypeSelection = 'tactical';
  } else {
    defaultPortfolioTypeSelection = 'strategic';
  }

  angular.extend($scope, {
    portfolioType: {
      options: [{
        label: 'Tactical',
        value: 'tactical'
      }, {
        label: 'Strategic',
        value: 'strategic'
      }],
      selection: new ram.Accessor(defaultPortfolioTypeSelection)
    },
  });

  function createStrategicChartDataToDisp(chartDataList) {
    return _.map(chartDataList, function(chartDataRow) {
      return {
        color: chartDataRow.color,
        label: chartDataRow.allocation.fund().label(),
        value: chartDataRow.allocation.weight()
      };
    });
  }

  function createStrategicLegendDataToDisp(chartDataList) {
    return _.map(chartDataList, function(chartDataRow) {
      return {
        color: chartDataRow.color,
        label: chartDataRow.allocation.fund().label(),
        weight: chartDataRow.allocation.weight()
      };
    });
  }

  function createTacticalChartDataToDisp(chartDataList) {
    return _.map(chartDataList, function(chartDataRow) {
      return {
        color: chartDataRow.color,
        label: chartDataRow.allocation.fund().label(),
        value: chartDataRow.allocation.tacticalWeight()
      };
    });
  }

  function createTacticalLegendDataToDisp(chartDataList) {
    return _.map(chartDataList, function(chartDataRow) {
      return {
        color: chartDataRow.color,
        label: chartDataRow.allocation.fund().label(),
        weight: chartDataRow.allocation.tacticalWeight()
      };
    });
  }

  var strategicPieChartData;
  var strategicLegendData;
  var tacticalPieChartData;
  var tacticalLegendData;
  var chartData ;

  if (typeof modelPortfolio !== 'undefined') {
    chartData = investmentChartsAssetAllocationDataGenerator.getData(modelPortfolio.nonZeroAllocations());
  } else {
    chartData = null;
  }

  strategicPieChartData = createStrategicChartDataToDisp(chartData);
  strategicLegendData = createStrategicLegendDataToDisp(chartData);

  $scope.portfolioType.options[0].isGrayLabel = !hasTactical;
  $scope.canSelectPortfolioType = hasTactical;
  if (hasTactical) {
    var tacticalChartData = investmentChartsAssetAllocationDataGenerator.getData(modelPortfolio.nonZeroTacticalAllocations());
    tacticalPieChartData = createTacticalChartDataToDisp(tacticalChartData);
    tacticalLegendData = createTacticalLegendDataToDisp(tacticalChartData);
  }

  $scope.$watchGroup(['portfolioType.selection()'], handlePortfolioTypeSelect);

  function handlePortfolioTypeSelect(values) {
    if (values[0] === 'strategic') {
      $scope.allocationDataForPieChart = strategicPieChartData;
      $scope.allocationDataForLegend = strategicLegendData;
    } else {
      $scope.allocationDataForPieChart = tacticalPieChartData;
      $scope.allocationDataForLegend = tacticalLegendData;
    }
  }

  $scope.onTabChange = (newTab, $event) => {
    if (!$event || !$event.target) return;

    try {
      $analytics.tabTrack([{
        id: `advise-account-${newTab}`,
        name: $event.target.innerText,
        type: 'tab'
      }], {
        lists: [{
          id: newTab,
          name: $event.target.innerText,
          type: newTab
        }],
        forms: newTab === 'notes' && [{
          id: 'add-review-notes',
          name: 'Add a review note',
          type: newTab
        }]
      })
    } catch(error) {
      console.error(error)
      window.Sentry && window.Sentry.captureException(error);
    }
  }

  $scope.positions = {
    positions: investmentChartsHoldingsDataGenerator.getAggregateData($scope.account.nonZeroPositions())
  };

  $scope.isResp = accountService.isResp(account);
  $scope.canHaveBeneficiaries = accountService.canHaveBeneficiaries(account);
  $scope.isFamilyResp = accountService.isFamilyResp($scope.account);
  $scope.statements = statements;
  $scope.beneficiaries = beneficiaries;
  $scope.beneficiary = _.first(beneficiaries);
  $scope.successorHolder = successorHolder;
  $scope.canAddBeneficiary = $scope.isFamilyResp || $scope.isResp && !_.first($scope.beneficiaries);
  $scope.accountHolders = accountHolders;
  $scope.lastGoal = accountGoals[0].goal();
  $scope.lastAudit = $scope.lastGoal.audits()[$scope.lastGoal.audits().length - 1];

  $scope.loadRiskModal = function() {
    $scope.advisorModifyAccountRiskModal($scope.account);
  };

  $scope.loadPortfolioOptionsModal = function() {
    $scope.advisorModifyPortfolioOptionsModal($scope.account);
  };

  $scope.loadActivityNotesModal = function(activityId) {
    Activity.find({
        id: activityId
      })
      .then(function(activity) {
        $scope.genericMessageModal('Advisor Notes - ' + $filter('date')(activity.createdAt(), 'medium'),
          activity.notes());
      });
  };

  $scope.loadRebalancingModal = function() {
    $scope.advisorModifyRebalancingOptionModal($scope.account.skipRebalancing()).result
      .then(function() {
        $scope.account.changeRebalancing();
      });
  };

  $scope.loadClientNameOverrideModal = function() {
    $scope.advisorModifyClientNameOverrideModal($scope.account).result
      .then(function() {
        $scope.account.save();
      });
  };

  $scope.prettyPrintRiskLevel = function(riskLevel) {
    return riskLevelLabels[riskLevel];
  };

  $scope.goalChangers = function() {
    var changers = {};

    accGoalAudits.forEach(function(audit) {
      var goalChangerId = audit.userId();
      if (!goalChangerId || changers[goalChangerId]) {
        return;
      }

      //Assuming an advisor(who is not super advisor) cannot change client's risk level.
      if (account.userId() === goalChangerId) {
        changers[goalChangerId] = 'Client';
      } else if ($scope.currentUserSecurity.isSuperAdvisor()) {
        changers[goalChangerId] = audit.userFullName() + ' (advisor)';
      } else {
        changers[goalChangerId] = 'ModernAdvisor';
      }
    });

    return changers;
  };

  $scope.balancePeriods = [{
    label: 'From inception'
  }, {
    label: '1 Year',
    start: moment().subtract(1, 'years').toDate()
  }, {
    label: 'YTD',
    start: moment().startOf('years').toDate()
  },{
    label: 'Quarterly',
    start: moment().subtract(3, 'months').toDate()
  }];

  $scope.balancePeriod = new ram.Accessor($scope.balancePeriods[0]);
  var data = activityDisplayHelper.getActivityTypes();
  data = data.filter(function(item) {
    return !item.hidden;
  });
  $scope.activityTypes = data;
  $scope.activityPeriods = timeframeDisplayHelper.getPeriods();

  $scope.activityType = new ram.Accessor($scope.activityTypes[0]);
  $scope.activityPeriod = new ram.Accessor($scope.activityPeriods[0]);

  $scope.activities = activityDisplayHelper.activities;
  $scope.accountById = activityDisplayHelper.accountById;

  $scope.$watchGroup(['activityType()', 'activityPeriod()'], function() {
    $scope.allActivities = activityDisplayHelper.activities($scope.account, $scope.activityType(), $scope.activityPeriod());
  });

  /**
   * This is simple now, but in the future will obtain the advisor's name
   * @param  {integer} userId The user ID who made the change for Modify activity
   * @return {string }        For now, 'client' or 'advisor'
   */
  $scope.lookupUserName = function(userId) {
    if (userId === account.userId) {
      return 'Client';
    } else {
      return 'Advisor';
    }
  };

  $scope.downloadBalances = downloadCsv.balances;

  $scope.convertToCorporate = function() {
    var modalTitle = 'Convert Account To Corporate';
    var modalBody = 'Are you sure you want to convert this account to Corporate?';

    userNotifications.clearNotification();
    $scope.$root.genericConfirmationModal(modalTitle, modalBody, 'btn-danger', 'Convert')
      .result
      .then(() => $scope.account.convertToCorporate())
      .then(() => {
        return $scope.account.reload({
          force: true
        });
      })
      .then(() => {
        $scope.canConvertToCorporate = $scope.account.canConvertToCorporate();
        userNotifications.showTransientSuccess('Success.  Account converted to Corporate.');
      })
      .catch((error) => {
        if (error) {
          userNotifications.showError('There was an error.  Please contact support.');
        }
      });
  };

  /****************************************************
   * Advisor Notes Code
   */
  var MIN_TEXTAREA_HEIGHT = 95;

  $scope.newNote = new ram.Accessor('');
  $scope.existingNotes = account.notes();

  function setTextAreaHeight(height, e) {
    var element = typeof e === 'object' ? e.target : document.getElementById('advisor-new-note');
    element.style.height = height + 'px';
  }

  $scope.autoExpand = function(e) {
    var heights = [MIN_TEXTAREA_HEIGHT];
    var element = typeof e === 'object' ? e.target : document.getElementById('advisor-new-note');
    heights.push(element.scrollHeight);
    setTextAreaHeight(_.max(heights), e);
  };

  $scope.resetNoteForm = function() {
    $scope.newNote('');
    setTextAreaHeight(MIN_TEXTAREA_HEIGHT);
  };

  $scope.saveNote = function() {
    Note.create({
        accountId: $scope.account.id,
        text: $scope.newNote()
      })
      .then(function(note) {
        $scope.existingNotes.unshift(note);
        $scope.resetNoteForm();
        $timeout(setHeightBelowForAll, 50);
      }, function() {});
  };

  $scope.setHeightBelow = function(index) {
    var box = document.getElementById('textarea-' + index);
    var height = box.scrollHeight;
    box.style.height = height + 'px';
  };

  function setHeightBelowForAll() {
    _.each($scope.existingNotes, function(_note, index) {
      $scope.setHeightBelow(index);
    });
  }

  /**************************************************
   * Beneficiary
   */
  $scope.beneficiaryTabLabel = beneficiaries.length <= 1 ? 'Beneficiary' : 'Beneficiaries';

  /**************************************************
   * Switching tabs
   */
  var BALANCE_HISTORY = 0,
    BENEFICIARY = 6,
    NOTES = 7,
    RIFPAYMENT = 8;
  if ($stateParams.initialTab === 'payment') {
    $scope.active = RIFPAYMENT;
  } else if ($stateParams.initialTab === 'notes') {
    $scope.active = NOTES;
  } else {
    $scope.active = BALANCE_HISTORY;
  }

  var unsavedMessageForPage = {
    mandatory: true,
    messageTitle: 'Inconsistent Data',
    messageBody: '<p>Your data on the Account page is incomplete.</p><p>Please Cancel or enter all data and press Save.</p>'
  };
  unsavedChangesTracking.init($scope.$root, $scope, $window, $state, unsavedMessageForPage);

  function createUnsavedMessageForTab(tabName) {
    return {
      mandatory: true,
      messageTitle: 'Inconsistent Data',
      messageBody: '<p>Your data on the ' + tabName + ' tab is incomplete.</p><p>Please Cancel or enter all data and press Save.</p>'
    };
  }

  function showUnsavedModal(tabName, oldTabNum) {
    var unsavedMessage = createUnsavedMessageForTab(tabName);
    $scope.genericOkModal(unsavedMessage.messageTitle, unsavedMessage.messageBody);
    $scope.active = oldTabNum;
  }

  $scope.$watch('active', function(newVal, oldVal) {
    if (formModificationTracker.hasUnsavedChanged() && newVal) {
      var formName = formModificationTracker.getCurrentForm().formName;

      if (newVal !== BENEFICIARY && oldVal === BENEFICIARY && formName === 'form') {
        showUnsavedModal('Beneficiary', BENEFICIARY);
      } else if (newVal !== RIFPAYMENT && oldVal === RIFPAYMENT && formName === 'rifDetailForm') {
        showUnsavedModal('Payment Details', RIFPAYMENT);
      }
    }

    if (newVal && newVal === NOTES) { // no else
      $timeout(setHeightBelowForAll, 50); // 1 works, 50 is generous. The usual visibility tricks don't work here because the notes are always in the DOM, even when not shown.
    }
  });
}

function adviseBeneficiaryChangeController(
  $scope,
  $q,
  beneficiaryService,
  formModificationTracker
) {
  /* jshint validthis: true */
  function reload() {
    var _beneficiaries;
    var _account;
    return $scope.account.fetch(true)
      .then(function(account) {
        _account = account;
        return account.beneficiaries.ready(true)
          .then(function(beneficiaries) {
            _beneficiaries = beneficiaries;
            return $q.all(_.map(beneficiaries, function(beneficiary) {
              return beneficiary.loadDependantObjects();
            }));
          })
          .then(function() {
            return _account.successorHolder.ready();
          })
          .then(function() {
            return $scope.client.person().personalRelations.ready(true);
          })
          .then(function() {
            var _successorHolder = _account.successorHolder() && _account.successorHolder().id ? _account.successorHolder() : null;
            beneficiaryService.updateScopeBeneficiaries(_beneficiaries, _successorHolder);
          });
      });
  }

  this.save = function(id) {
    var beneficiary = _.find($scope.beneficiaries, function(_beneficiary) {
      return _beneficiary.id === id;
    });
    beneficiary = beneficiary || $scope.successorHolder;
    if (beneficiary) {
      beneficiary.justCreated(false);
      $scope.preventEdit = false;
      return beneficiaryService.save(beneficiary)
        .then(function() {
          return reload();
        });
    } else {
      return reload();
    }
  };

  this.reset = function(id) {
    if (id) {
      var beneficiary = _.find($scope.beneficiaries, function(_beneficiary) {
        return _beneficiary.id === id;
      });
      beneficiary = beneficiary || $scope.successorHolder;
      if (beneficiary && beneficiary.justCreated()) {
        beneficiaryService.removeRespBeneficiary(beneficiary);
      }
    }
    $scope.preventEdit = false;
    return reload();
  };

  /**
   * The caller may wish to control the isSaving flag.
   */
  $scope.getForeignScope = function(foreignScope) {
    $scope.foreignScope = foreignScope;
  };

  $scope.addBeneficiary = function() {
    beneficiaryService.setAccount($scope.account);
    beneficiaryService.addRespBeneficiary();
  };

  $scope.$watch('beneficiaries.length', function(newVal, oldVal) {
    // The following gets picked up by getPreventEdit
    $scope.preventEdit = !$scope.preventEdit && (newVal !== undefined) && (newVal > oldVal) && _.find($scope.beneficiaries, function(beneficiary) {
      return beneficiary.justCreated();
    });
    $scope.canAddBeneficiary = $scope.isFamilyResp || $scope.isResp && !_.first($scope.beneficiaries);
  });

  $scope.disableCreateNewBeneficiary = function() {
    var editingAnother = formModificationTracker.hasUnsavedChanged(),
      justCreatedNew = _.find($scope.beneficiaries, function(beneficiary) {
        return beneficiary.justCreated();
      });
    return editingAnother || justCreatedNew;
  };

  beneficiaryService.setBaseScope($scope);
}
