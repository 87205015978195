import React, {useState, useRef} from 'react';

function UploadMutualFundTrades({csrfToken, userNotifications}) {
  const filePicker = useRef();
  const [fileName, setFileName] = useState(undefined);
  const [fileStatus, setFileStatus] = useState('No File');
  const [isProcessing, setIsProcessing] = useState(false);
  const [validLines, setValidLines] = useState([]);
  const [invalidLines, setInvalidLines] = useState([]);

  const filePicked = () => {
    setFileStatus(undefined);
    setValidLines([]);
    setInvalidLines([]);
    setFileName(undefined);

    const newFile = filePicker.current?.files[0];
    setFileName(newFile?.name);
    if (newFile?.name && validateFile(newFile)) {
      setFileStatus(`Successfully loaded file! (${newFile.size} bytes)`)
      process();
    }
  }

  const process = () => {
    setIsProcessing(true);
    const formData = generateFormData();
    const request = new XMLHttpRequest();
    request.open('POST', '/api/super_advisor/allotments/upload_mutual_fund_trades.json');
    request.setRequestHeader('X-CSRF-Token', csrfToken());
    request.onload = function() {
      const result = JSON.parse(request.response);
      if (this.status === 200) {
        userNotifications.showTransientSuccess(`File processed ${result.invalid_lines.length > 0 ? 'with some unmatched lines' : 'successfully'}.`)
        setValidLines(result.valid_lines);
        setInvalidLines(result.invalid_lines);
        setIsProcessing(false);
        resetUx(true, 'Processed');

      } else {
        const errorMessage = result.error || 'There was an unexpected result. Please try again later.';
        userNotifications.showError(errorMessage);
        setIsProcessing(false);
        resetUx(false, 'Error');
      }
    };
    request.onerror = function() {
      userNotifications.showError('Something went wrong while uploading the data. Please try again later.');
      setIsProcessing(false);
      resetUx(false, 'Error');
    };
    request.send(formData);
  };

  return <div className="container">
    <h3>Upload Mutual Fund Trades</h3>
    <div className="panel panel-default">
      <div className="panel-body">
        <div id="user-notification" className="m-b-0-alert-override"/>
        <dl className="dl-horizontal">
          <dt className="p-tb-5 dt-center-align w-150px">File:</dt>
          <dd className="p-tb-5">
            <button type="button"
                    className="btn btn-white h-30px w-100px border-grey m-t-neg5 p-t-4"
                    onClick={() => filePicker.current.click()}>
              Pick
            </button>
            <input type="file"
                   ref={filePicker}
                   className="disp-n-imp"
                   name="image"
                   id="select-file"
                   accept=".csv,.xlsx"
                   onChange={filePicked}/>
          </dd>
          <div className="clearfix"/>
          <dl>
            <dt className="p-tb-5 dt-center-align w-150px">File Name:</dt>
            <dd className="p-tb-5">{fileName}</dd>
            <dt className="p-tb-5 dt-center-align w-150px">File Status:</dt>
            <dd className="p-tb-5">
              {!isProcessing && <span>{fileStatus}</span>}
              {isProcessing &&
                <span>
                <img className="btn-icon" src="assets/images/loading-spinner.svg"/> Processing
              </span>
              }
            </dd>
          </dl>
        </dl>

        {renderLines('Successful Lines', validLines)}
        {renderLines('Unmatched Lines', invalidLines)}
      </div>
    </div>
  </div>;

  function renderLines(title, data) {
    if (data.length === 0) {
      return null;
    }

    return <div className="panel panel-default m-t-10">
      <div className="panel-body">
        <h4>{title}</h4>
        <table className="table">
          <thead>
          <tr>
            <th>Account</th>
            <th>Side</th>
            <th>Fund</th>
            <th>Type</th>
            <th>Value</th>
            <th>Message</th>
          </tr>
          </thead>
          <tbody>
          {data.map((item, index) => (<tr key={index}>
            <td>{item.account}</td>
            <td>{item.side}</td>
            <td>{item.fund}</td>
            <td>{item.type}</td>
            <td>{item.value}</td>
            <td>{item.message}</td>
          </tr>))}
          </tbody>
        </table>
      </div>
    </div>;
  }

  function resetUx(success, status) {
    if (!success) {
      setFileName(null);
    }
    setFileStatus(status)
    filePicker.current.value = '';
  }

  function validateFile(file) {
    if (!file) {
      setFileStatus('No file selected.');
      setFileName('Please select your file.');
      return false;
    }
    if (file.size / 1024 / 1024 > 2) {
      setFileStatus('file is too big.');
      return false;
    }
    return true;
  }

  function generateFormData() {
    const formData = new FormData();
    const newFile = filePicker.current?.files[0];

    formData.append('file', newFile);
    return formData;
  }
}

export default UploadMutualFundTrades;
