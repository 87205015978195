'use strict';

function controller($scope, marketing, options) {

  options = options || {};
  $scope.email = options.email;
  $scope.firstName = options.firstName;
  $scope.modalBody = options.modalBody;
  $scope.modalTitle = options.modalTitle;

  $scope.subscribe = function() {
    marketing.subscribeMailingList($scope.firstName, $scope.email);
    $scope.form.$setPristine();
    $scope.email = '';
    $scope.firstName = '';
  };

  $scope.onMailingList = marketing.onMailingList;
}

angular.module('controller.modal.mailingList', [
    'directive.input',
    'service.marketing'
  ])
  .controller('ModalMailingListController', [
    '$scope',
    'marketing',
    'options',
    controller
  ]);
