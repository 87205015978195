'use strict';

angular.module('service.promotion-redemption-cookie-creator', [])
  .factory('promotionRedemptionCookieCreator', [
    '$cookies',
    promotionRedemptionCookieCreatorFactory
  ]);

function promotionRedemptionCookieCreatorFactory($cookies) {
  var PROMOTION_COOKIE_NAMES = [
    'auto_apply_promo_code',
    'group_savings_plan',
    'group_url_id',
    'leadDynoTracking',
    'referral_code'
  ];

  function existingCookie() {
    let existingCookieName = PROMOTION_COOKIE_NAMES.find((promotionCookieName) => {
      return $cookies.get(promotionCookieName);
    });

    if (existingCookieName) {
      return $cookies.get(existingCookieName);
    }
  }

  function removeOtherPromotionCookies() {
    _.each(PROMOTION_COOKIE_NAMES, function(promotionCookieName) {
      $cookies.remove(promotionCookieName);
    });
  }

  function createReferAFriend(referralCode) {
    removeOtherPromotionCookies();

    $cookies.putObject('referral_code', {
      code: referralCode,
      promotionRedeemedAt: moment().toDate()
    }, {
      expires: moment().add(14, 'days').toDate()
    });
  }

  function createAdvisorReferral(groupUrlId) {
    removeOtherPromotionCookies();

    var matches = groupUrlId.match(/^([^,]+),?(.*)$/);
    var urlId = matches[1];
    var link = matches[2];
    $cookies.putObject('group_url_id', {
      urlId: urlId,
      promotionRedeemedAt: moment().toDate(),
      invitationLink: link
    }, {
      expires: moment().add(90, 'days').toDate()
    });
  }

  function getGroupUrlId() {
    var advisorReferral = $cookies.getObject('group_url_id');
    return advisorReferral && advisorReferral.urlId;
  }

  function createAffiliateReferral(affiliateLink, trackingCode) {
    removeOtherPromotionCookies();

    $cookies.putObject('leadDynoTracking', {
      affiliateLink: affiliateLink,
      trackingCode: trackingCode,
      promotionRedeemedAt: moment().toDate()
    }, {
      expires: moment().add(90, 'days').toDate()
    });
  }

  function createAutoApplyPromoCode(code) {
    removeOtherPromotionCookies();

    $cookies.putObject('auto_apply_promo_code', {
      code: code,
      promotionRedeemedAt: moment().toDate()
    }, {
      expires: moment().add(14, 'days').toDate()
    });
  }

  return {
    createAutoApplyPromoCode: createAutoApplyPromoCode,
    createReferAFriend: createReferAFriend,
    createAdvisorReferral: createAdvisorReferral,
    createAffiliateReferral: createAffiliateReferral,
    existingCookie: existingCookie,
    getGroupUrlId: getGroupUrlId
  };
}
