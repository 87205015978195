'use strict';

angular
  .module('controller.modal.advisor.confirm-account-approval', [
    'ram',
    'service.user-notifications'
  ])
  .controller('ModalAdvisorConfirmAccountApprovalCtrl', [
    '$scope',
    'ram',
    '$timeout',
    'userNotifications',
    controller
  ]);

function controller($scope, ram, $timeout, userNotifications) {
  $scope.identityVerified = new ram.Accessor(false);
  $scope.naafSigned = new ram.Accessor(false);
  $scope.buttonEnabled = false;

  let checkCounter = 0;

  function checkEsignUrl() {
    if ($scope.esignUrl) {
      $scope.buttonEnabled = true;
    } else if (checkCounter < 5) {
      checkCounter++;
      $timeout(checkEsignUrl, 1000);
    } else {
      const baseErrorMessage = 'Unable to fetch the e-sign URL. Please try again.';
      const contactSupportMessage = 'If the issue persists, please contact the development team.';
      const errorMessage = `${
        $scope.$root.errorMessageEsignUrl || baseErrorMessage
      } ${contactSupportMessage}`;

      userNotifications.showError(errorMessage, angular.element('#user-notification-placeholder'));
    }
  }

  $timeout(checkEsignUrl, 1000);
}
