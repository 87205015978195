'use strict';

angular.module('controller.modal.appetite', [
    'animation.risk-appetite-question',
    'directive.input',
    'ngAnimate',
    'model.CompletedRiskAppetiteQuiz'
  ])
  .controller('AppetiteCtrl', [
    '$scope',
    '$animate',
    '$timeout',
    '$uibModalInstance',
    'riskAppetiteQuiz',
    'options',
    'CompletedRiskAppetiteQuiz',
    appetiteController
  ]);

function appetiteController($scope, $animate, $timeout, $modalInstance, riskAppetiteQuiz, options, CompletedRiskAppetiteQuiz) {
  options = options || {};
  $scope.step = 0;
  $scope.questions = _.sortBy(riskAppetiteQuiz.riskAppetiteQuizQuestions(), function(question) {
    return question.sortOrder();
  });
  $scope.buttonsDisabled = false;

  var selectedChoices = _.map($scope.questions, function(question) {
    return {
      riskAppetiteQuizQuestionId: question.id
    };
  });

  var resourceOptions = {};

  if (options.useCookie) {
    resourceOptions.resource = 'cookie';
    resourceOptions.cache = false;
  }

  $scope.completedRiskAppetiteQuiz = CompletedRiskAppetiteQuiz.new({
    riskAppetiteQuizId: riskAppetiteQuiz.id,
    selectedRiskAppetiteQuizChoices: selectedChoices
  }, resourceOptions);

  /**
   * Gets the completed percentage of the interview.
   * @return {Number} Completed percentage.
   */
  $scope.progress = function() {
    return ($scope.step + 1) / $scope.questions.length * 100;
  };

  var sectionChangeDelay = 400,
    animationDurationPlusPadding = 600,
    throttleWaitPeriod = sectionChangeDelay + animationDurationPlusPadding;

  /**
   * Go to the previous step in the interview.
   */
  $scope.lastSection = _.throttle(function() {
    if ($scope.step > 0) {
      $scope.buttonsDisabled = true;
      $timeout(function() {
        $animate.leave(currentQuestion(), {
          addClass: 'risk-appetite-question-backward',
          removeClass: 'risk-appetite-question-forward'
        }).then(function() {
          $scope.backward = true;
          $scope.step = Math.max(0, $scope.step - 1);
          $scope.buttonsDisabled = false;
        });
      }, sectionChangeDelay);
    }
  }, throttleWaitPeriod);

  /**
   * Scrolls to the top of the page, then increments step or, if already
   * on the last step, redirects to preview.
   */
  $scope.nextSection = _.throttle(function() {
    if (!$scope.$root.validateForm($scope.riskAppetiteQuestionForm)) {
      return;
    } else {
      $scope.buttonsDisabled = true;
      $timeout(function() {
        if ($scope.step < $scope.questions.length - 1) {
          $animate.leave(currentQuestion()).then(function() {
            $scope.step += 1;
            $scope.buttonsDisabled = false;
          });
        } else {
          $modalInstance.close($scope.completedRiskAppetiteQuiz);
        }
      }, sectionChangeDelay);
    }
  }, throttleWaitPeriod);

  function currentQuestion() {
    return angular.element('#question-' + $scope.step);
  }

  var riskAppetiteAnswers = $scope.completedRiskAppetiteQuiz.selectedRiskAppetiteQuizChoices().map(function(question, index) {
    return 'completedRiskAppetiteQuiz.selectedRiskAppetiteQuizChoices()[' + index + '].riskAppetiteQuizChoiceId()';
  });

  $scope.$watchGroup(riskAppetiteAnswers, function(newAnswers, oldAnswers) {
    if (newAnswers[$scope.step] !== oldAnswers[$scope.step]) {
      $scope.nextSection();
    }
  });

  $scope.$on('entered-backward', function() {
    $scope.backward = false;
    currentQuestion().addClass('risk-appetite-question-forward');
  });
}
