'use strict';

function interviewFactory(Section) {

  /**
   * @class A container class for interview questions grouped into sections.
   *
   * @method hasSection
   * @method question
   */
  var Interview = function(props) {
    _.extend(this, props);
    this.sections = [];
  };

  /**
   * Attaches a new section to the interview.
   * This is used to dynamically generate interviews from the client and
   * may eventually be phased out.
   *
   * @param  {String}   heading         Heading for the section
   * @param  {Boolean}  returnSection   Set true to return the new section
   * @return {Object}   The newly instantiated Section or this Interview
   */
  Interview.prototype.hasSection = function(heading, returnSection) {
    var section = new Section(heading, this);
    this.sections.push(section);
    return returnSection ? section : this;
  };

  /**
   * Get all questions included in this interview.
   * @return {Array}
   */
  Interview.prototype.questions = function() {
    return _.chain(this.sections)
      .pluck('questions')
      .flatten()
      .value();
  };

  /**
   * Search all sections of the interview for a question.
   *
   * @param  {String} questionName Name of the question to search for.
   * @return {Question} The matching question or undefined if none found.
   */
  Interview.prototype.question = function(questionName) {
    var question;
    _.find(this.sections, function(section) {
      question = _.findWhere(section.questions, {
        accessor: questionName
      });
      return question;
    });
    return question;
  };

  Interview.prototype.toJSON = function() {
    return {
      sections: this.sections
    };
  };

  return Interview;
}

angular.module('model.Interview', [
    'model.Section'
  ])
  .factory('Interview', [
    'Section',
    interviewFactory
  ]);
