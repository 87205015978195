'use strict';

function adviceCentreController() {}

angular.module('controller.advice-centre', [
    'controller.advice-centre.account-off-track',
    'controller.advice-centre.all-messages',
    'controller.advice-centre.spousal-rrsp'
  ])
  .controller('adviceCentreCtrl', [
    adviceCentreController
  ]);
