'use strict';

angular.module('controller.advise.transfer-requests', [
    'controller.advise.transfer-requests.all-transfer-requests',
    'controller.advise.transfer-requests.bbs-upcoming-transfer-requests',
    'controller.advise.transfer-requests.unmatched-transfers',
    'controller.advise.transfer-requests.account-transfers'
  ])
  .controller('AdviseTransferRequestsCtrl', [
    '$scope',
    'unmatchedTransferNotifications',
    'unmatchedTransfers',
    adviseTransferRequestsController
  ]);

function adviseTransferRequestsController($scope, unmatchedTransferNotifications, unmatchedTransfers) {
  $scope.transferInstructions = [];
  $scope.unmatchedTransfers = {
    notifications: unmatchedTransferNotifications,
    transfers: unmatchedTransfers
  };

  $scope.actionManagerAllRequests = {};
  $scope.actionManagerAccountTransfers = {};

  $scope.onChangeTabDoResetAllRequests = function() {
    $scope.actionManagerAllRequests.reset();
  };

  $scope.onChangeTabDoResetAccountTransfers = function() {
    $scope.actionManagerAccountTransfers.reset();
  };

  $scope.numColumns = 12; // manual count by programmer.
}
