'use strict';

function invalidSinForClientToEnterDirective() {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function(scope, $el, attrs, ctrl) {
      if (attrs.invalidSinForClientToEnter !== 'true') {
        return;
      }

      ctrl.$validators.invalidsinforclienttoenter = function(modelValue) {
        if (modelValue === '000000000') {
          return false;
        }
        return true;
      };
    }
  };
}

angular.module('directive.invalid-sin-for-client-to-enter', [])
  .directive('invalidSinForClientToEnter', [
    invalidSinForClientToEnterDirective
  ]);
