'use strict';

angular.module('controller.interview.personal.contact-info', [
    'model.Lead',
    'service.loading-indicator',
    'service.user-notifications',
  ])
  .controller('interviewPersonalContactInfoCtrl', [
    '$scope',
    '$state',
    'Lead',
    'loadingIndicator',
    'userNotifications',
    interviewPersonalContactInfoCtrl
  ]);

function interviewPersonalContactInfoCtrl($scope, $state, Lead, loadingIndicator, userNotifications) {
  $scope.lead = Lead.new();

  $scope.updateLead = function(firstName, lastName, email, phoneNumber) {
    $scope.lead.firstName(firstName);
    $scope.lead.lastName(lastName);
    $scope.lead.email(email);
    $scope.lead.phoneNumber(phoneNumber);
  };

  $scope.$parent.$parent.nextSection = function() {
    loadingIndicator.show();
    $scope.lead.save()
      .then(function() {
        $state.go($state.current.data.next);
      })
      .catch(function() {
        userNotifications.showError(
          'Something went wrong while processing your contact information. Please try again later.'
        );
      })
      .finally(function() {
        loadingIndicator.hide();
      });
  };
  $scope.$parent.$parent.lastSection = function() {
    $state.go($state.current.data.last);
  };

  $scope.$parent.$parent.step = $state.current.data.step;
  $scope.$parent.$parent.header = $state.current.data.header;
  $scope.$parent.$parent.userCanGoBack = true;
}
