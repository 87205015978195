'use strict';

angular.module('controller.advise.reports', [
    'model.Fund',
    'service.loading-indicator',
  ])
  .controller('AdviseReportsCtrl', [
    '$scope',
    '$q',
    '$http',
    'config',
    'ram',
    'Fund',
    'loadingIndicator',
    'portfolioSelections',
    adviseReportsController
  ]);

function adviseReportsController($scope, $q, $http, config, ram, Fund, loadingIndicator, portfolioSelections) {
  /**
   * Investment Portfolio Dashboard Section
   */
  $scope.color = function(assetDescription) {
    return Fund.constructor.color(assetDescription);
  };

  var defaultSelection = {
    portfolioType: 'core',
    riskLevel: 5
  };

  angular.extend($scope, {
    portfolioSelections: portfolioSelections,
    portfolioType: {
      options: [{
        label: 'Core',
        value: 'core'
      }, {
        label: 'Socially Responsible',
        value: 'resp'
      }, {
        label: 'All',
        value: 'all'
      }],
      selection: new ram.Accessor(defaultSelection.portfolioType)
    },
    riskLevel: new ram.Accessor(defaultSelection.riskLevel),
    start: new ram.Accessor()
  });

  $scope.currentSelection = function() {
    return $scope.portfolioType.selection() + $scope.riskLevel();
  };

  $scope.findMarketValue = function(fundId) {
    return $scope.portfolioSelections[$scope.currentSelection()].marketValues[fundId];
  };

  $scope.calculateWeight = function(fundId) {
    return $scope.findMarketValue(fundId) / $scope.sumMarketValue() * 100;
  };

  $scope.sumMarketValue = function() {
    if (!$scope.portfolioSelections[$scope.currentSelection()].marketValueSum) {
      var sum = 0;
      _.each($scope.portfolioSelections[$scope.currentSelection()].marketValues, function(value) {
        sum += parseFloat(value);
      });
      $scope.portfolioSelections[$scope.currentSelection()].marketValueSum = sum;
    }

    return $scope.portfolioSelections[$scope.currentSelection()].marketValueSum;
  };

  $scope.$watchGroup(['portfolioType.selection()', 'riskLevel()'], handleSelectionUpdate);

  function handleSelectionUpdate(values) {
    var portfolioType = values[0],
      riskLevel = values[1],
      selection = portfolioType + riskLevel;

    if (!$scope.portfolioSelections[selection]) {
      loadingIndicator.show();
      var promises = [];
      $scope.portfolioSelections[selection] = {};

      promises.push($http.get(marketValueUrl())
        .then(function(response) {
          return response.data;
        })
        .then(addSelectionToSelectionsObject));

      $q.all(promises).then(function() {
        loadingIndicator.hide();
      });
    }

    function addSelectionToSelectionsObject(data) {
      $scope.portfolioSelections[selection].allocations = data.allocations;
      $scope.portfolioSelections[selection].mer = data.fee;
      $scope.portfolioSelections[selection].marketValues = data.marketValues;
    }

    function marketValueUrl() {
      var result = '/api/model_portfolios/summary.json?';

      var corePortfolioOption = _.findWhere(config.types.AccountPortfolioOption, {
        name: 'core_portfolio'
      });

      var sociallyResponsiblePortfolioOption = _.findWhere(config.types.AccountPortfolioOption, {
        name: 'socially_responsible_portfolio'
      });

      if (riskLevel !== 'All') {
        result += 'risk=' + riskLevel;
      }
      if (riskLevel !== 'All' && portfolioType !== 'all') {
        result += '&';
      }
      if (portfolioType !== 'all') {
        result += 'portfolio_option_id=' + (portfolioType === 'core' ? corePortfolioOption.id : sociallyResponsiblePortfolioOption.id);
      }
      return result;
    }
  }
  /**** END of Section */

}
