'use strict';

angular.module('directive.account-goal-detail', [
    'config',
    'service.account-service'
  ])
  .directive('accountGoalDetail', [
    'config',
    'accountService',
    accountGoalDetailDirective
  ]);

function accountGoalDetailDirective(config, accountService) {
  return {
    restrict: 'E',
    scope: {
      client: '=',
      newAccountFlows: '=',
      account: '=',
      accountFlowJointApplicant: '=',
      ima: '='
    },
    templateUrl: 'directives/account-goal-detail.html',
    controller: ['$scope', function($scope) {
      $scope.$watch('account.type()', function(accountType) {
        $scope.showJurisdiction = false;
        $scope.showJointApplicantEmail = false;
        if (accountType && (accountType.name === 'lif' || accountType.name === 'lira')) {
          $scope.showJurisdiction = true;
        } else {
          $scope.account.jurisdictionId.reset();
        }
        if (accountType && accountType.joint) {
          $scope.showJointApplicantEmail = true;
        }
      });

      $scope.accountTypes = _.reject(config.types.Account, function(accountType) {
        return accountType.name === 'corporate' || accountType.name === 'joint_no_right_of_survivorship' || accountType.name === 'group_rrsp';
      });

      if (!accountService.canAddTrialAccountFlow($scope.client, $scope.newAccountFlows)) {
        removeTrialAccountType();
      }

      $scope.jurisdictions = config.types.AccountJurisdiction;

      $scope.accountPortfolioOptions = _.reject(config.types.AccountPortfolioOption, function(accountPortfolioOption) {
        return accountPortfolioOption.name === 'adaptive_portfolio' && !canSelectAdaptivePortfolioOption();
      });

      function canSelectAdaptivePortfolioOption() {
        return $scope.$root.currentUser.canSelectAdaptivePortfolio() &&
          isClientImaCreatedAfterAdaptivePortfolioFeatureStartDate();
      }

      function isClientImaCreatedAfterAdaptivePortfolioFeatureStartDate() {
        if ($scope.ima) {
          return new Date($scope.ima.createdAt()) >= new Date(config.env.adaptivePortfolioFeatureStartDate);
        } else {
          return new Date() >= new Date(config.env.adaptivePortfolioFeatureStartDate);
        }
      }

      function removeTrialAccountType() {
        $scope.accountTypes = _.reject($scope.accountTypes, function(accountType) {
          return accountType.name === 'trial';
        });
      }
    }]
  };
}
