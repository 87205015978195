'use strict';

var MOBILE_RESOURCES_DIR = 'mobile/';

// Detecting whether we are on mobile
function initMobileDetection(featureTogglesProvider, $cookies) {
  /* jshint validthis: true */

  var calculateIsMobile = function() {
    return window.mobileAndTabletCheck();
  };

  var calculateIsApp = function() {
    if (window.location.search.includes('app=1')) {
      $cookies.put('mobile-app', 1);
      return true;
    }
    return !!$cookies.get('mobile-app');
  };

  var isMobileCache = featureTogglesProvider.isEnabled('adaptiveViews') ? calculateIsMobile() : false;
  var isAppCache;

  this.isMobile = function() {
    return isMobileCache;
  };

  this.isApp = function() {
    if (_.isUndefined(isAppCache)) {
      isAppCache = calculateIsApp();
    }
    return isAppCache;
  };

  this.isSafari = function() {
    return window.isSafari();
  };

  this.isDesktopSafari = function() {
    return /safari/i.test(navigator.userAgent) &&
      !/chrome/i.test(navigator.userAgent) &&
      !window.mobileAndTabletCheck();
  };
}

// Configuration + methods for states
function initStateHelpers() {
  /* jshint validthis: true */

  this.hasMobileView = function(state) {
    return state && state.hasMobileView;
  };

  this.getTemplateUrlForState = function(state) {
    var templateDir = (this.isMobile() && this.hasMobileView(state) ? MOBILE_RESOURCES_DIR : '') + 'views/';
    return templateDir + state.name.replace(/\./g, '/') + '.html';
  };
}

// Methods for including templates by src
function initSrcHelpers() {
  /* jshint validthis: true */

  this.getTemplateUrlForSrc = function(src, hasMobileView) {
    if (this.isMobile() && hasMobileView) {
      return MOBILE_RESOURCES_DIR + src;
    }
    return src;
  };
}

function adaptiveViewsProvider(featureTogglesProvider) {
  /* jshint validthis: true */

  var $cookies = angular.injector(['ngCookies']).get('$cookies');
  initMobileDetection.bind(this)(featureTogglesProvider, $cookies);
  initStateHelpers.bind(this)();
  initSrcHelpers.bind(this)();

  var _this = this;
  this.$get = function() {
    return {
      isMobile: _this.isMobile,
      isApp: _this.isApp,
      hasMobileView: _this.hasMobileView,
      getTemplateUrlForState: _this.getTemplateUrlForState,
      getTemplateUrlForSrc: _this.getTemplateUrlForSrc,
      isSafari: _this.isSafari,
      isDesktopSafari: _this.isDesktopSafari
    };
  };
}

angular.module('service.adaptive-views', [
    'service.feature-toggles'
  ])
  .provider('adaptiveViews', [
    'featureTogglesProvider',
    adaptiveViewsProvider
  ]);
