'use strict';

angular.module('controller.advise.accounts', [
    'directive.flex-container',
    'directive.flex-boxes',
    'model.PaginatedAccount',
    'model.Paginator',
    'directive.secured',
    'service.pagination.filter-service',
    'service.modals'
  ])
  .controller('AdviseAccountsCtrl', [
    '$scope',
    'PaginatedAccount',
    'Paginator',
    'config',
    'paginationFilterService',
    'modals',
    adviseAccountsController
  ]);

function adviseAccountsController(
  $scope,
  PaginatedAccount,
  Paginator,
  config,
  paginationFilterService,
  modals
) {
  $scope.filterParam = '';
  $scope.bulkAccounts = [];
  $scope.numColumns = ($scope.security.can('log_in_as_client') ? 6 : 5);
  $scope.accountPortfolioOptionIds = {};
  _.each(config.types.AccountPortfolioOption, function(option) {
    $scope.accountPortfolioOptionIds[option.name] = option.id;
  });

  const _getDataFromPaginator = function (parameter) {
    $scope.filterParam = parameter.paginationMeta().filter();
    return parameter.accounts();
  };

  var initialFilter = ['status.open'];
  $scope.initialActions = function() {
    return [{
      type: 'FLEX-FILTER',
      filterList: initialFilter
    }];
  };

  $scope.paginator = new Paginator(PaginatedAccount, null, _getDataFromPaginator);

  var filterData = [];

  // filter out statuses related to "New Accounts".
  paginationFilterService.addEnumFilterData(_.reject(config.types.AccountStatus, function(status) {
    return _.contains(['started', 'pending', 'approved', 'rejected', 'expired'], status.name);
  }), filterData, 'Status', 'status');

  paginationFilterService.addEnumFilterData(_.filter(config.types.Account, function(type){
    return type.showOnFilter;
  }), filterData, 'Type', 'type');

  $scope.filterData = filterData;
  $scope.filterModel = paginationFilterService.toFilterModel(filterData, initialFilter);

  $scope.showDownloadModal = function () {
    modals.downloadTable($scope.filterParam);
  };
}
