'use strict';

angular.module('controller.dashboard.summary', [
    'service.group-activities-service',
    'service.user-notifications',
    'service.account-service',
    'component.chart-set',
    'component.chart-set-period',
    'directive.dashboard-account-processing-messages',
    'ram'
  ])
  .controller('DashboardSummaryCtrl', [
    '$scope',
    '$state',
    '$stateParams',
    '$http',
    '$window',
    '$cookies',
    'ram',
    'config',
    'accountService',
    'groupActivitiesService',
    'identityVerificationStatus',
    'userNotifications',
    summaryController
  ]);

/**
 * In custom view the start and end dates passed to the graph directives are
 * determined by the start- and end-handles.
 *
 * In non-custom view the start- and end-handles play no role.
 */

function summaryController(
  $scope,
  $state,
  $stateParams,
  $http,
  $window,
  $cookies,
  ram,
  config,
  accountService,
  groupActivitiesService,
  identityVerificationStatus,
  userNotifications
) {
  var periods = [{
    label: 'From inception'
  }, {
    label: '1 Year',
    start: moment().subtract(1, 'years').toDate()
  }, {
    label: 'YTD',
    start: moment().startOf('years').toDate()
  },{
    label: 'Quarterly',
    start: moment().subtract(3, 'months').toDate()
  }];

  var activityStyle = {
    deposit: {
      color: 'text-primary',
      icon: 'fa-download',
      xOffset: 0,
      yOffset: -1
    },
    contribution: {
      color: 'text-primary',
      icon: 'fa-download',
      xOffset: 0,
      yOffset: -1
    },
    withdrawal: {
      color: 'text-danger',
      icon: 'fa-upload',
      xOffset: 0,
      yOffset: -1
    },
    dividend: {
      color: 'text-primary',
      icon: 'fa-money',
      xOffset: 0,
      yOffset: -1
    },
    rebalance: {
      color: 'text-warning',
      icon: 'fa-random',
      xOffset: 0,
      yOffset: 0
    },
    interest: {
      color: 'text-info',
      icon: 'fa-refresh',
      xOffset: 0,
      yOffset: -1
    },
    fee: {
      color: 'text-danger',
      icon: 'fa-calculator',
      xOffset: 0,
      yOffset: 0
    },
    'fee_adjustment': {
      color: 'text-danger',
      icon: 'fa-calculator',
      xOffset: 0,
      yOffset: 0
    },
    'hst': {
      color: 'text-danger',
      icon: 'fa-bank',
      xOffset: 1,
      yOffset: 0
    },
    'gst': {
      color: 'text-danger',
      icon: 'fa-bank',
      xOffset: 1,
      yOffset: 0
    },
    'pst': {
      color: 'text-danger',
      icon: 'fa-bank',
      xOffset: 1,
      yOffset: 0
    },
    'qst': {
      color: 'text-danger',
      icon: 'fa-bank',
      xOffset: 1,
      yOffset: 0
    },
    'withholding_tax': {
      color: 'text-danger',
      icon: 'fa-bank',
      xOffset: 1,
      yOffset: -2
    },
    modify: {
      color: 'text-warning',
      icon: 'fa-exchange',
      xOffset: 0,
      yOffset: -1
    },
    rebate: {
      color: 'text-success',
      icon: 'fa-reply',
      xOffset: 0,
      yOffset: -1
    },
    'tax_on_fee': {
      color: 'text-danger',
      icon: 'fa-percent',
      xOffset: 0,
      yOffset: 0
    },
    'resp_grant': {
      color: 'text-success',
      icon: 'fa-graduation-cap',
      xOffset: 0,
      yOffset: 0
    },
    'rdsp_grant': {
      color: 'text-primary',
      icon: 'fa-medkit',
      xOffset: 0,
      yOffset: 0
    },
    'custodian_change': {
      color: 'text-info',
      icon: 'fa-exchange',
      xOffset: 0,
      yOffset: -1
    },
    'service_fee': {
      color: 'text-danger',
      icon: 'fa-upload',
      xOffset: 0,
      yOffset: -1
    },
    'default': {
      color: 'text-info',
      icon: 'fa-info',
      xOffset: 0,
      yOffset: 0
    }
  };

  const platform = config.env.platform;

  angular.extend($scope, {
    isWsi: platform && platform.name === 'WSI',
    periods: periods,
    period: new ram.Accessor(periods[0]),
    activityStyle: activityStyle,
    hasOpenAccount: accountService.hasOpenAccount($scope.accounts),
    hasFundedAccount: accountService.hasFundedAccount($scope.accounts),
    groupedActivities: groupActivitiesService.groupByDateTypeDescription($scope.account.activities()),
    identityVerificationStatus: identityVerificationStatus
  });

  if ($stateParams.showFormEsignedAlert) {
    userNotifications.showInfo(
      'You have successfully signed the account transfer form.' +
      '<br>' +
      'Account transfers could take up to 4 weeks. ' +
      'We will send you an email when we receive your funds.'
    );
  }

  $scope.getActivityStyle = function(activity) {
    let style = activityStyle[activity.type().name];

    if(!style) {
      style = activityStyle['default'];
    }
    
   return style;
  };

  $scope.accountId = function(id) {
    if (arguments.length) {
      $state.go($state.current, {
        accountId: id
      });
    }
    return $scope.account.id;
  };

  if ($stateParams.showSuccessMessage) {
    userNotifications.showInfo($stateParams.showSuccessMessage);
  }
}
