'use strict';

function statementFactory(ram) {

  var Statement = new ram.Collection('Statement', {
    readers: ['date', 'type'],
    // schema: config.schemas.InvestmentManagementAgreement,
    resources: {
      default: new ram.resources.Http('/api/statements/:id.json'),
      cookie: new ram.resources.Cookie('statements')
    }
  });

  // Statement.prototype.initialize = function(attrs) {
  // this.date(new Date(attrs.date));
  // };

  return Statement;
}

angular.module('model.Statement', [
    'ram',
    'config'
  ])
  .factory('Statement', [
    'ram',
    'config',
    statementFactory
  ]);
