'use strict';


function editableFieldDirective(directiveHelper) {
  var STATES = {
    editing: 'editing',
    staticContent: 'staticContent',
    staticEditable: 'staticEditable'
  };

  var calculateState = function($scope) {
    if (!$scope.editToggle) {
      return STATES.editing;
    }
    if (!$scope.editingEnabled) {
      $scope.editingOn = false;
      return STATES.staticContent;
    }
    if ($scope.editingOn) {
      return STATES.editing;
    }
    return STATES.staticEditable;
  };

  return {
    restrict: 'E',
    scope: true,
    transclude: {
      fieldLabel: '?fieldLabel',
      staticContent: '?staticContent',
      staticContentExtra: '?staticContentExtra',
      formElement: 'formElement'
    },
    templateUrl: 'directives/editable-field.html',

    link: function($scope, element, attrs) {
      directiveHelper.setAttrToScope('staticContent', $scope, attrs);

      $scope.fieldLabel = attrs.fieldLabel;
      $scope.tooltip = attrs.tooltip;
      $scope.labelClass = attrs.labelClass;
      $scope.rowClass = attrs.rowClass;
      $scope.hideButtons = attrs.hideButtons === 'true';

      // control section
      $scope.vars = {
        state: STATES.editing
      };

      var stateChangeHandler = function() {
        $scope.vars.state = calculateState($scope);
      };
      directiveHelper.setBoolAttrToScope('editToggle', $scope, attrs, stateChangeHandler);

      var editingEnabled = directiveHelper.setBoolAttrToScope('editingEnabled', $scope, attrs, stateChangeHandler);
      directiveHelper.setBoolAttrToScope('editingOn', $scope, attrs, stateChangeHandler);
      directiveHelper.setAttrToScope('staticClass', $scope, attrs);

      $scope.isEditing = function() {
        return $scope.vars.state === STATES.editing;
      };
      $scope.isStaticContent = function() {
        return $scope.vars.state === STATES.staticContent;
      };
      $scope.isStaticEditable = function() {
        return $scope.vars.state === STATES.staticEditable;
      };

      // if field is initialized and editingEnabled=true
      // than make it editable by default
      if (editingEnabled) {
        $scope.editingOn = true;
      }
    },

    controller: ['$scope', function($scope) {
      $scope.toggleEdit = function() {
        $scope.editingOn = true;
      };
    }]
  };
}

angular.module('directive.editableField', [
    'service.directive-helper'
  ])
  .directive('editableField', [
    'directiveHelper',
    editableFieldDirective
  ]);
