'use strict';

angular.module('directive.bank-accounts-section', [
    'directive.bank-account-card',
    'model.BankAccount',
    'service.securityService'
  ])
  .directive('bankAccountsSection', [
    'securityServiceFactory',
    'BankAccount',
    bankAccountsSectionDirective
  ]);

function bankAccountsSectionDirective(securityServiceFactory, BankAccount) {
  return {
    restrict: 'E',
    scope: {
      bankAccounts: '=',
      readOnlyMode: '=',
      totalBankAccountsCount: '=',
      hasFccAccount: '='
    },
    templateUrl: 'directives/bank-accounts-section.html',
    link: function($scope) {
      $scope.canAddBankAccount = function() {
        return securityRequirement() && bankAccountsLimitRequirement();
      };

      function securityRequirement() {
        return securityServiceFactory.create($scope.$root.currentUser).isClient();
      }

      function bankAccountsLimitRequirement() {
        return $scope.totalBankAccountsCount < BankAccount.constructor.MAX_NUMBER_PER_CLIENT;
      }

      $scope.updateTotalBankAccountsCount = function(newVal) {
        $scope.totalBankAccountsCount = newVal;
      };
    }
  };
}
