'use strict';

angular.module('directive.input.timeframe', [
    'service.adaptive-views',
    'service.autoTimeframeService'
  ])
  .directive('timeframe', [
    'adaptiveViews',
    'autoTimeframeService',
    directive
  ]);

function directive(adaptiveViews, autoTimeframeService) {
  return {
    replace: true,
    restrict: 'E',
    scope: {
      form: '=',
      goal: '=',
      questions: '='
    },
    templateUrl: 'directives/input/timeframe.html',
    link: function($scope) {
      $scope.isMobile = adaptiveViews.isMobile();
      $scope.question = $scope.questions.timeframe;
      $scope.minYears = 1;
      $scope.maxYears = 100;

      $scope.$watch('goal.notimeframe()', function(notimeframe) {
        if (notimeframe) {
          $scope.goal.timeframe(25);
        }
      });

      $scope.autoTimeframe = function() {
        return autoTimeframeService.autoTimeframe($scope.goal, null, $scope.questions);
      };
    }
  };
}
