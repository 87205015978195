'use strict';

function factory(CodeErrorHandler) {

  // codeHandler have priority over regular handlers
  var codeHandlers = {};
  // for the handlers, the ordering is important, as the first handler that can handle specific error will be executed
  var handlers = [];

  var getErrorCode = function(error) {
    return CodeErrorHandler.getErrorCode(error);
  };

  var getHandler = function(error) {
    var errorCode = getErrorCode(error);
    if (errorCode && codeHandlers[errorCode]) {
      return codeHandlers[errorCode];
    }
    return _.first(_.filter(handlers, function(handler) {
      return handler.canHandle(error);
    }));
  };

  return {
    addCodeHandler: function(code, handler) {
      codeHandlers[code] = new CodeErrorHandler(code, handler);
    },

    addHandler: function(handler) {
      handlers.push(handler);
    },

    canHandle: function(error) {
      return getHandler(error) !== undefined;
    },

    handleError: function(error, context) {
      var handler = getHandler(error);
      if (handler) {
        return handler.handle(error, context);
      }
      return false;
    }
  };
}

angular.module('service.error.handler-registry', [
    'service.error.CodeErrorHandler'
  ])
  .factory('handlerRegistry', [
    'CodeErrorHandler',
    factory
  ]);
