'use strict';
/* Date Validation:
 *
 * Use these directives in concert with each other.  You would always want to use validateDate (validate-date) along with
 * one or more of the others depending on what you want to do.
 *
 * Use the `$validators` methods in your help block.
 *
    <input
      class="form-control"
        ...
      validate-date
      validate-present-or-future-date
      validate-within-one-year
    >
    <help-block
      ng-attr-for="input[name='date{{$parent.$id}}']"
      error-pattern="Wrong date format"
      error-required="This field is required"
      error-validdate="Please enter a valid date"
      error-validfuturedate="You must enter a present or future date"
      error-validwithinoneyear="Start date must be within one year"
      class="slide-animation">
    </help-block>

 * NOTE:
 * - The lack of CamelCase and '-' in the help-block is intentional.  Our code forces these strings and method names
 *   to be all lower case, like `validwithinoneyear`.
 */

/*
 * A sanity check used by all the other functions.
 */
var _basicDateCheck = function(dateInput) {
  if (_.isUndefined(dateInput) || dateInput === null || typeof dateInput !== 'string') {
    return false;
  }
  return true;
};

function validateBirthDateDirective() {
  /*
   * Is the date too far in the past to be a valid birth date?  (200 years is the maximum.)
   */
  var birthDateCheck = function(dateInput) {
    if (!_basicDateCheck(dateInput)) {
      return false;
    }
    var momentSelected = moment(dateInput, 'MM-DD-YYYY', true);
    if (!momentSelected.isValid()) {
      return true; // Let validate-date catch it
    }
    var dateSelected = momentSelected.toDate();

    var now = new Date();
    var minYearsAgo = -200;
    var dateMustBeBefore = new Date(now.getFullYear() + minYearsAgo, now.getMonth(), now.getDate());
    if (dateSelected < dateMustBeBefore) {
      return false;
    }
    return true;
  };

  /*
   * You must have been born at least 18 years ago.
   */
  var birthDateRangeCheck = function(dateInput, minYearsOld) {
    if (birthDateCheck(dateInput) === false) {
      return true; // This check fails only if the previous has passed.
    }

    var momentSelected = moment(dateInput, 'MM-DD-YYYY', true);
    if (!momentSelected.isValid()) {
      return true; // Let validate-date catch it
    }

    var now = moment();

    // Use clone to avoid potential confusion due to mutability.
    var minBirthDate = now.clone().subtract(minYearsOld, 'years');

    if (momentSelected > minBirthDate) {
      return false;
    }
    return true;
  };

  return {
    restrict: 'A',
    require: 'ngModel',
    link: function(scope, $el, attrs, ctrl) {

      var minYearsOld = 18;
      if (attrs.minYearsOld) {
        minYearsOld = attrs.minYearsOld;
      }

      ctrl.$validators.validdateinpastcheck = function(modelValue) {
        if (ctrl.$isEmpty(modelValue)) {
          return true;
        }
        return birthDateCheck(modelValue);
      };

      ctrl.$validators.validdaterange = function(modelValue) {
        if (ctrl.$isEmpty(modelValue)) {
          return true;
        }
        return birthDateRangeCheck(modelValue, minYearsOld);
      };
    }
  };
}

/*
 * General date validation
 */
function validateDateDirective() {
  var patterns;

  var dateCheck = function(dateInput) {
    if (!_basicDateCheck(dateInput)) {
      return false;
    }

    if (patterns.date.test(dateInput) === false) {
      return false;
    }

    var momentSelected = moment(dateInput, 'MM-DD-YYYY', true);
    var dateSelected = momentSelected.toDate();

    if (momentSelected.isValid() === false) {
      return false;
    }

    if (dateSelected !== undefined && isNaN(dateSelected.valueOf())) {
      return false;
    }
    return true;
  };

  var controller = ['patterns', function(_patterns) {
    patterns = _patterns;
  }];

  return {
    restrict: 'A',
    require: 'ngModel',
    controller: controller,
    link: function(scope, $el, attrs, ctrl) {

      ctrl.$validators.validdate = function(modelValue) {
        if (ctrl.$isEmpty(modelValue)) {
          return true;
        }
        return dateCheck(modelValue);
      };
    }
  };
}

/*
 * Validates a date to make sure it is not in the past.
 */
function validatePresentOrFutureDate() {

  var dateCheck = function(dateInput) {
    if (!_basicDateCheck(dateInput)) {
      return false;
    }

    var dateSelected = moment(dateInput, 'MM-DD-YYYY').toDate();
    var now = moment().startOf('day').toDate();
    if (dateSelected < now) {
      return false;
    }
    return true;
  };

  return {
    restrict: 'A',
    require: 'ngModel',
    link: function(scope, $el, attrs, ctrl) {

      // Conditionally skip this check by setting attr to string 'false'
      if (attrs.validatePresentOrFutureDate && attrs.validatePresentOrFutureDate !== 'false') {
        ctrl.$validators.validfuturedate = function(modelValue) {
          if (ctrl.$isEmpty(modelValue)) {
            return true;
          }
          return dateCheck(modelValue);
        };
      }
    }
  };
}

/*
 * Validates a date to make sure it is within 1 year of today.
 */
function validateWithinOneYearDirective() {

  var dateCheck = function(dateInput) {
    if (!_basicDateCheck(dateInput)) {
      return false;
    }

    var dateSelected = moment(dateInput, 'MM-DD-YYYY').toDate();
    var maxDate = moment().add(1, 'y').toDate();

    if (dateSelected > maxDate) {
      return false;
    }
    return true;
  };

  return {
    restrict: 'A',
    require: 'ngModel',
    link: function(scope, $el, attrs, ctrl) {

      ctrl.$validators.validwithinoneyear = function(modelValue) {
        if (ctrl.$isEmpty(modelValue)) {
          return true;
        }
        return dateCheck(modelValue);
      };
    }
  };
}

angular.module('directive.validate-date', [
    'service.patterns',
  ])
  .directive('validateBirthDate', [
    validateBirthDateDirective
  ])
  .directive('validateDate', [
    'patterns',
    validateDateDirective
  ])
  .directive('validatePresentOrFutureDate', [
    validatePresentOrFutureDate
  ])
  .directive('validateWithinOgit neYear', [
    validateWithinOneYearDirective
  ]);
