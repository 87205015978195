'use strict';

angular.module('service.init-unsaved-changes-tracking', [
    'service.form-modification-tracker'
  ])
  .factory('unsavedChangesTracking', [
    'formModificationTracker',
    unsavedChangesTracking
  ]);

function unsavedChangesTracking(formModificationTracker) {

  return {
    init: initUnsavedChangesTracking
  };

  function initUnsavedChangesTracking($rootScope, $scope, $window, $state, specialMessage) {

    var removeStateChangeEventCallback = $rootScope.$on('$stateChangeStart', function($event, state) {
      formModificationTracker.onFormExit($event,
        // userHasUnsavedChangesCallback
        function() {
          // this is required to prevent loading indicator to be shown
          $rootScope.hideLoading();
        },
        // userWantsToLeaveCallback
        function() {
          $state.go(state.name);
        },
        // noUnsavedChangesCallback
        null,
        specialMessage
      );
    });

    function onBrowserExitAction(event) {
      angular.element(':focus').trigger('blur');

      if (formModificationTracker.hasUnsavedChanged()) {
        // will prompt user before closing the browser tab/refreshing
        var message = 'Are you sure you want to leave this page?\n\nYour changes have not been saved.';

        event.returnValue = message;
        return message;
      }
    }

    var jqWindow = angular.element($window);
    jqWindow.on('beforeunload', onBrowserExitAction);

    var logoutAction = $rootScope.logout;
    $rootScope.logout = function() {
      formModificationTracker.onFormExit(null, null, logoutAction, logoutAction, specialMessage);
    };

    $scope.$on('$destroy', function() {
      jqWindow.off('beforeunload', onBrowserExitAction);
      removeStateChangeEventCallback();

      $rootScope.logout = logoutAction;
    });
  }
}
