angular
  .module('service.impersonation-service', [])
  .factory('impersonationService', ['$http', '$cookies', '$window', impersonationService]);

function impersonationService($http, $cookies, $window) {
  const impersonatingCookieExist = function () {
    return angular.isDefined($cookies.get('impersonating'));
  };

  const whoIsImpersonating = function () {
    return $cookies.get('who_impersonating');
  };

  const revertToOriginalUser = function () {
    return $http.post('/api/users/revert_to_original_user').then(
      function () {
        $window.location.href = '/dashboard';
      },
      function () {
        window.Sentry &&
          window.Sentry.captureMessage(
            'There was an error getting the data.  Please log in again.'
          );
      }
    );
  };

  return {
    impersonatingCookieExist,
    whoIsImpersonating,
    revertToOriginalUser
  };
}
