'use strict';

angular.module('form-helper-successor-holder', [
    'model.SuccessorHolder'
  ])
  .factory('formHelperSuccessorHolder', [
    '$q',
    '$http',
    'SuccessorHolder',
    factory
  ]);

function factory($q, $http, SuccessorHolder) {
  function save(vars) {
    var relativeParams = vars.scope.beneficiary.personalRelation().relative().toJSON();
    var personalRelationParams = vars.scope.beneficiary.personalRelation().toJSON();
    var successorHolderParams = vars.scope.beneficiary.toJSON();

    return $http.post('/api/successor_holders/advisor_update', {
      personId: relativeParams.id,
      firstName: relativeParams.firstName,
      middleName: relativeParams.middleName,
      lastName: relativeParams.lastName,
      sin: relativeParams.sin,
      personalRelationId: personalRelationParams.id,
      typeId: personalRelationParams.typeId,
      successorHolderId: successorHolderParams.id,
      accountId: successorHolderParams.accountId,
      userId: vars.scope.client.id
    });
  }

  function createBeneficiary(vars, mode, personalRelationId) {
    var params = _getSuccessorHolderParams(vars, personalRelationId);
    var localBeneficiary = new SuccessorHolder(params);
    localBeneficiary.personalRelation.update(vars.scope.person.spouseRelation().toJSON());
    vars.baseScope.successorHolder = localBeneficiary; // for the save code
    vars.scope.beneficiary = localBeneficiary; // for the code here
    vars.baseScope.beneficiaries = null; // for the save code
  }

  function create(vars, personalRelationId) {
    var params = _getSuccessorHolderParams(vars, personalRelationId);
    return SuccessorHolder.create(params);
  }

  function _getSuccessorHolderParams(vars, personalRelationId) {
    return {
      accountId: vars.scope.account.id,
      personalRelationId: personalRelationId
    };
  }

  return {
    save: save,
    create: create,
    createBeneficiary: createBeneficiary
  };
}
