'use strict';

angular.module('controller.home.portfolios', [
    'directive.portfolio-performance',
    'model.ModelPortfolio',
    'service.investments-bar-prep-data',
    'service.loading-indicator',
    'service.portfolio-line-chart-data-service'
  ])
  .controller('HomePortfolioCtrl', [
    '$scope',
    '$q',
    'ram',
    'funds',
    'invBarPrepData',
    'loadingIndicator',
    'ModelPortfolio',
    'portfolioLineChartDataService',
    'portfolioSelections',
    'presetQuestion',
    'storedGoal',
    'storedUser',
    portfolioCtrl
  ]);

function portfolioCtrl($scope, $q, ram, funds, invBarPrepData, loadingIndicator, ModelPortfolio, portfolioLineChartDataService, portfolioSelections, question, storedGoal, storedUser) {
  angular.extend($scope, {
    goal: storedGoal,
    portfolioSelections: portfolioSelections,
    question: question,
    user: storedUser
  });
}
