'use strict';

function initLegalForm($scope, $q, brokerageAccounts, insiderRoles, entityListService) {
  var currentUser = $scope.currentUser;
  var person = $scope.person;
  var legalInfo = $scope.legalInfo;

  var insiderRolesController = entityListService.createInsiderRolesController($scope, insiderRoles);
  var brokerageAccountsController = entityListService.createBrokerageAccountsController($scope, brokerageAccounts);

  function saveEntities(entitiesController) {
    if (entitiesController) {
      return entitiesController.saveAll();
    }
  }

  function saveInsiderRoles() {
    return saveEntities(insiderRolesController);
  }

  function resetInsiderRoles() {
    return person.insiderRoles.ready()
      .then(function(insiderRoles) {
        return insiderRolesController.reset(insiderRoles);
      });
  }

  function saveBrokerageAccounts() {
    return saveEntities(brokerageAccountsController);
  }

  function resetBrokerageAccounts() {
    return currentUser.brokerageAccounts.ready()
      .then(function(brokerageAccounts) {
        return brokerageAccountsController.reset(brokerageAccounts);
      });
  }

  return {
    insiderRoles: insiderRolesController,
    brokerageAccounts: brokerageAccountsController,
    save: function() {
      return $q.all([currentUser.save(), legalInfo.save()])
        .then(function() {
          var promises = _.union(
            saveInsiderRoles(),
            saveBrokerageAccounts()
          );

          return $q.all(promises);
        });
    },
    reset: function() {
      return $q.all([currentUser.fetch(true), legalInfo.fetch(true)])
        .then(function() {
          var promises = _.union(
            resetInsiderRoles(),
            resetBrokerageAccounts()
          );

          return $q.all(promises);
        });
    }
  };
}

function profileLegalController($scope, $q, dropdownOptions, entityListService) {
  $scope.legalInfo = $scope.person.legalInfo();
  var formLegal = initLegalForm($scope, $q, $scope.brokerageAccounts, $scope.insiderRoles, entityListService);

  $scope.yesOrNo = dropdownOptions.yesOrNo();
  $scope.onChange = angular.noop;

  /* jshint validthis: true */
  this.save = formLegal.save;
  this.reset = formLegal.reset;
  this.insiderRoles = formLegal.insiderRoles;
  this.brokerageAccounts = formLegal.brokerageAccounts;

  var self = this;

  $scope.$watch('currentUser.publicCompanyInsiders()', function(setting) {
    if (setting && self.insiderRoles.getAll().length === 0) {
      self.insiderRoles.createNew();
    }
    self.insiderRoles.getAllCompleted = _.filter(self.insiderRoles.getAll(), function(insiderRole) {
      return _.isNumber(insiderRole.id);
    });
  });

  $scope.$on('destroyLastInsiderRecord', function() {
    $scope.currentUser.publicCompanyInsiders(false);
  });

  $scope.$watch('currentUser.hasBrokerageAccounts()', function(setting) {
    if (setting && self.brokerageAccounts.getAll().length === 0) {
      self.brokerageAccounts.createNew();
    }
    self.brokerageAccounts.getAllCompleted = _.filter(self.brokerageAccounts.getAll(), function(brokerageAccount) {
      return _.isNumber(brokerageAccount.id);
    });
  });

  $scope.$on('destroyLastBrokerageRecord', function() {
    $scope.currentUser.hasBrokerageAccounts(false);
  });
}

angular.module('controller.profile.legal', [
    'directive.input',
    'directive.user.legal-info',
    'service.dropdown-options',
    'service.entity-list-service'
  ])
  .controller('ProfileLegalCtrl', [
    '$scope',
    '$q',
    'dropdownOptions',
    'entityListService',
    profileLegalController
  ]);
