'use strict';

angular.module('controller.onboarding.personal.beneficiary', [
    'service.beneficiary-service'
  ])
  .controller('OnboardingPersonalBeneficiaryController', [
    '$scope',
    'beneficiaryService',
    onboardingBeneficiaryController
  ]);

function onboardingBeneficiaryController(
  $scope,
  beneficiaryService
) {
  $scope.shouldAssignBeneficiary = function() {
    return beneficiaryService.shouldAssignBeneficiary($scope.account);
  };
}
