'use strict';

angular.module('model.RiskAppetiteQuizQuestion', [
    'model.RiskAppetiteQuizChoice'
  ])
  .factory('RiskAppetiteQuizQuestion', [
    'ram',
    riskAppetiteQuizQuestionFactory
  ]);

function riskAppetiteQuizQuestionFactory(ram) {

  /**
   * Constructor for resource-aware RiskAppetiteQuizQuestion model.
   * @class
   */
  var RiskAppetiteQuizQuestion = new ram.Collection('RiskAppetiteQuizQuestion', {
    hasMany: {
      riskAppetiteQuizChoices: 'RiskAppetiteQuizChoice'
    },
    resources: {
      default: new ram.resources.Http('/api/risk_appetite_quiz_questions/:id.json'),
      cookie: new ram.resources.Cookie('risk_appetite_quiz_questions')
    },
  });

  return RiskAppetiteQuizQuestion;
}
