'use strict';

function referralFactory(ram, config) {

  var Referral = new ram.Collection('Referral', {
    belongsTo: {
      referrer: 'User',
      referee: 'User'
    },
    hasMany: {
      payments: 'ReferralPayment'
    },
    schema: config.schemas.Referral,
    resources: {
      default: new ram.resources.Http('/api/referrals/:id.json'),
      cookie: new ram.resources.Cookie('referrals')
    }
  });

  return Referral;
}

angular.module('model.Referral', [
    'ram',
    'config',
    'model.ReferralPayment'
  ])
  .factory('Referral', [
    'ram',
    'config',
    referralFactory
  ]);
