'use strict';

/**
 * Defines a wrapper for a set of equally sized buttons that behave like radio
 * inputs and use bootstrap styling.
 */
function radioButtonDirective(optionHelper) {
  return {
    restrict: 'E',
    scope: {
      name: '@',
      options: '=',
      optionLabel: '@',
      optionValue: '@',
      optionDescription: '@',
      ngModel: '=',
      ngChange: '&',
      help: '@',
      focusHelp: '@',
      title: '@',
      label: '@',
      class: '@',
      labelClass: '@',
      inputClass: '@',
      btnClass: '@',
      helpClass: '@',
      disabled: '&'
    },
    templateUrl: 'directives/input/radio-button.html',
    link: optionHelper.link
  };
}

angular.module('directive.input.radio-button', [
    'templates',
    'animations',
    'directive.focus',
    'directive.input.option-helper',
  ])
  .directive('radioButton', [
    'optionHelper',
    radioButtonDirective
  ]);
