'use strict';

function adviseOrdersController(
  $scope,
  $state,
  $http,
  orders,
  funds,
  Order,
  loadingIndicator,
  modals
) {
  $scope.orders = orders;
  $scope.showAll = false;
  $scope.moment = moment;

  $scope.createOrder = function() {
    loadingIndicator.show();
    Order.new()
      .save()
      .then(function() {
        $state.reload();
      })
      .finally(function() {
        loadingIndicator.hide();
      });
  };

  $scope.deleteOrder = function(order) {
    loadingIndicator.show();
    $http.delete('/api/orders/' + order.id + '.json')
      .then(function() {
        $state.reload();
      })
      .finally(function() {
        loadingIndicator.hide();
      });
  };

  $scope.refreshQuotes = function() {
    loadingIndicator.show();
    $http.put('/api/funds/update_quotes.json')
      .then(function() {
        $state.reload();
      })
      .finally(function() {
        loadingIndicator.hide();
      });
  };

  $scope.$watch('showAll', function(showAll) {
    $scope.funds = _.chain(funds)
      .filter(function(fund) {
        return fund.symbol() !== 'CAD' && (showAll || !fund.assetClass.is.otherFunds());
      })
      .sortBy(function(fund) {
        return fund.assetClass.is.otherFunds() + fund.symbol();
      })
      .value();
  });

  $scope.showAllFunds = function() {
    $scope.showAll = true;
  };

  $scope.hideOtherFunds = function() {
    $scope.showAll = false;
  };

  $scope.showDownloadModal = function () {
    modals.downloadTable();
  };
}

angular.module('controller.advise.orders', [
    'service.modals',
    'model.Order',
    'service.loading-indicator'
  ])
  .controller('AdviseOrdersCtrl', [
    '$scope',
    '$state',
    '$http',
    'orders',
    'funds',
    'Order',
    'loadingIndicator',
    'modals',
    adviseOrdersController
  ]);
