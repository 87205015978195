'use strict';

class DateRangeController {
  constructor(
    $scope,
    ram
  ) {
    this.$scope = $scope;
    this.ram = ram;
  }

  $onInit() {
    this.interval = parseInt(this.interval);
    this.startDate = this.initStartDate(this.endDate);
    this.$scope.months = createMonths();

    this.$scope.monthPart = new this.ram.Accessor();
    this.$scope.dayPart = new this.ram.Accessor();
    this.$scope.yearPart = new this.ram.Accessor();
    this.initEndDate(this.endDate);

    this.dateRangePicked({
      startDate: this.startDate,
      endDate: this.endDate
    });
  }

  initStartDate(date) {
    return moment(date).startOf('month').subtract(parseInt(this.interval) - 1, 'months').toDate();
  }

  initEndDate(date) {
    this.$scope.monthPart(date.getMonth());
    this.$scope.dayPart(date.getDate());
    this.$scope.yearPart(date.getFullYear());
  }

  calculateDates(field) {
    var selectedEndDay = field === 'day' ? this.$scope.dayPart() : null;
    this.endDate = this.calculatedEndDate(selectedEndDay);
    this.startDate = field === 'day' ?
      moment(this.endDate).subtract(this.interval, 'months').startOf('day').add(1, 'day').toDate() :
      moment(this.endDate).subtract(this.interval, 'months').endOf('month').startOf('day').add(1, 'day').toDate();
    this.initEndDate(this.endDate);
    this.dateRangePicked({
      startDate: this.startDate,
      endDate: this.endDate
    });
  }

  calculatedEndDate(day) {
    var newMoment = moment().year(this.$scope.yearPart()).month(this.$scope.monthPart()).date(1);
    if (day) {
      newMoment.date(day);
    } else {
      newMoment.endOf('month');
    }
    return newMoment.toDate();
  }
}

function createMonths() {
  return _.map(moment.months(), function(month, index) {
    return {
      label: month,
      monthNumber: index // [0 - 11]
    };
  });
}

export default DateRangeController;
