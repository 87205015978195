'use strict';

function statementsController($scope, statements, ram) {

  $scope.accounts = [{
    title: 'All Accounts',
    number: function() {
      return 'N/A';
    }
  }].concat($scope.currentUser.accounts);

  $scope.statementTypes = [{
    label: 'All statements'
  }, {
    label: 'Monthly performance',
    value: 'Monthly'
  }, {
    label: 'Quarterly performance',
    value: 'Quarterly'
  }, {
    label: 'Tax forms',
    value: 'Tax'
  }];

  $scope.periods = [{
    label: 'All Time',
    start: -Infinity
  }, {
    label: 'YTD',
    start: moment().subtract(1, 'y')._d
  }, {
    label: '30-day',
    start: moment().subtract(30, 'd')._d
  }];

  $scope.account = new ram.Accessor($scope.accounts[0]);
  $scope.statementType = new ram.Accessor($scope.statementTypes[0]);
  $scope.period = new ram.Accessor($scope.periods[0]);

  $scope.$watchGroup(['account()', 'statementType()', 'period()'], function(group) {
    var query = {};

    if (group[0].id) {
      query.accountId = group[0].id;
    }

    if (group[1].value) {
      query.type = group[1].value;
    }

    statements.query(query, function(accountStatements) {
      $scope.statements = _.chain(accountStatements)
        .filter(function(statement) {
          return statement.date > group[2].start;
        })
        .sortBy('date')
        .value()
        .reverse();
    });
  });

  $scope.formatDate = function(date) {
    return moment(date).format('MMM D, YYYY');
  };

  $scope.accountById = function(id) {
    if ($scope.currentUser) {
      return _.findWhere($scope.currentUser.accounts, {
        id: id
      });
    }
  };
}

angular.module('controller.dashboard.activity.statements', [
    'service.statements',
    'ram'
  ])
  .controller('DashboardStatementsCtrl', [
    '$scope',
    'statements',
    'ram',
    statementsController
  ]);
