'use strict';

angular.module('directive.input.text-input', [])
  .directive('textInput', [
    directive
  ]);

function directive() {
  return {
    replace: true,
    restrict: 'E',
    scope: true,
    require: 'ngModel',
    templateUrl: 'directives/input/text-input.html',
    link: function(scope, element, attrs, ngModelController) {
      ngModelController.$parsers.push(function(viewValue) {
        var newModelValue = viewValue.trim().length === 0 ? null : viewValue;
        return newModelValue;
      });
    }
  };
}
