'use strict';

function phoneNumberFactory(ram, config, patterns) {

  var PhoneNumber = new ram.Collection('PhoneNumber', {
    bind: ['parseFull'],
    enums: {
      type: config.types.PhoneNumber
    },
    schema: config.schemas.PhoneNumber,
    resources: {
      default: new ram.resources.Http('/api/phone_numbers/:id.json'),
      cookie: new ram.resources.Cookie('phone_numbers')
    }
  });

  PhoneNumber.prototype.initialize = function() {
    this.full = new ram.Accessor(this.getFormattedNumber());
  };

  PhoneNumber.prototype.getFormattedNumber = function() {
    var full = '';
    var countryFormatString = '%s ';

    // International numbers need special treatment.  They must be stored in the station code
    // so that they don't fall into the complicated regex that validates phone numbers.
    if (this.countryCode() && this.countryCode() !== '1') {
      countryFormatString = '+%s ';
      full += s.sprintf(countryFormatString, this.countryCode());
      full += this.stationCode();
      return full;
    }

    // If there is a country code at this point it is '1' and we don't use a '+'
    full += this.countryCode() ? s.sprintf(countryFormatString, this.countryCode()) : '';
    if (this.areaCode() && this.exchangeCode() && this.stationCode()) {
      full += s.sprintf('(%s) %s-%s', this.areaCode(), this.exchangeCode(), this.stationCode());
    }
    return full;
  };

  PhoneNumber.prototype.getFormattedNumberWithExtension = function() {
    var full = this.getFormattedNumber();
    if (this.extension()) {
      full += ' ext. ' + this.extension();
    }
    return full;
  };

  PhoneNumber.prototype.isInternational = function(input) {
    var test = null;

    // If you pass in an argument it will use it, otherwise it uses .full()
    if (arguments.length) {
      test = input;
    } else {
      test = this.full();
    }

    return (test.match(/^\+/) && !test.match(/^\+1\s/));
  };

  PhoneNumber.prototype.parseFull = function() {
    var full = this.full() || '';
    var match = null;

    if (this.isInternational(full)) {
      match = full.match(patterns.internationalPhoneNumber);
      this.countryCode(match && match[1]);
      this.areaCode(null);
      this.exchangeCode(null);
      this.stationCode(match && match[3]);
    } else {
      match = full.match(patterns.phoneNumber);
      this.countryCode((match && match[1]) ? match[1] : null);
      this.areaCode(match && match[2]);
      this.exchangeCode(match && match[3]);
      this.stationCode(match && match[4]);
    }

    // Clear extension if this isn't a busines number.
    if (this.typeId() !== 1) {
      this.extension(null);
    }

    if (match) {
      this.full(this.getFormattedNumber());
    }
  };

  PhoneNumber.prototype.validate = function() {
    var full = this.full();

    if (this.isInternational(full)) {
      return patterns.internationalPhoneNumber.test(full);
    }
    return patterns.phoneNumber.test(full);
  };

  return PhoneNumber;
}

angular.module('model.PhoneNumber', [
    'ram',
    'config',
    'service.patterns'
  ])
  .factory('PhoneNumber', [
    'ram',
    'config',
    'patterns',
    phoneNumberFactory
  ]);
