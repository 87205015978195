'use strict';

angular.module('controller.advisor-new-client.verify-identity', [
    'model.Person',
    'ram',
    'service.form-helper',
    'service.user-notifications',
    'service.adaptive-views',
    'service.modals'
  ])
  .controller('AdvisorNewClientVerifyIdentityController', [
    '$http',
    '$scope',
    '$rootScope',
    '$state',
    'Auth',
    'formHelper',
    'Person',
    'details',
    'ram',
    'userNotifications',
    'adaptiveViews',
    'modals',
    advisorNewClientVerifyIdentityController
  ]);

function advisorNewClientVerifyIdentityController(
  $http,
  $scope,
  $rootScope,
  $state,
  Auth,
  formHelper,
  Person,
  details,
  ram,
  userNotifications,
  adaptiveViews,
  modals
) {
  if ($rootScope.currentUser) {
    modals.advisorUserLoggedInModal($rootScope.currentUser.fullName())
    .result
    .then(function(result) {
      if (result === 'sign-in') {
        Auth.logout().then(()=>{
          $state.reload();
        });
        return result;
      } else if (result === 'dashboard') {
        $state.go('advise.overview').then(()=>$state.reload());
      }
    });
  }

  $scope.$root.branding = details.branding;
  $scope.$root.signature = details.signature;
  $scope.isMobile = adaptiveViews.isMobile();

  $scope.person = new Person();
  $scope.email = new ram.Accessor("");
  $scope.advisorName = details.advisorName;

  $scope.onDateOfBirthChange = function(person, date) {
    person.birthDate(date.toDate());
  };

  $scope.onPhoneNumberChange = angular.noop;

  function params() {
    return {
      firstName: $scope.person.firstName(),
      lastName: $scope.person.lastName(),
      sin: $scope.person.sin(),
      birthDate: $scope.person.birthDate(),
      email: $scope.email()
    };
  }

  $scope.verifyIdentity = function(formController) {
    if (formController.$invalid) {
      formHelper.notDone($scope, formController);
    } else {
      $http.put('/api/advisor_create_new_client_flows/' + $state.params.advisorNewClientFlowPassword + '/verify_identity', params())
        .then(function() {
            // Refresh page so $scope.currentUser is set
            $scope.$root.toState = 'advisor-new-client.add-password';
            $state.reload('site');
          },
          function() {
            userNotifications.showError('The information you have provided does not match our records. Please try again. If the issue persists please contact ' + $scope.advisorName + ' for assistance.');
          });
    }
  };
}
