'use strict';

class LinkCopyHandlerService {
  constructor($timeout) {
    this.$timeout = $timeout;
  }

  onLinkCopied(e, message) {
    const actionMsg = message ? message : 'Copied!';
    e.clearSelection();
    this._showTooltipMessage(angular.element(e.trigger), actionMsg);
  }

  onLinkCopyError(e, message) {
    let actionMsg = '';
    if (message) {
      actionMsg = message;
    } else if (/iPhone|iPad/i.test(navigator.userAgent)) {
      actionMsg = 'Please copy manually';
    } else if (/Mac/i.test(navigator.userAgent)) {
      actionMsg = 'Press ⌘-C to copy';
    } else {
      actionMsg = 'Press Ctrl-C to copy';
    }
    this._showTooltipMessage(angular.element(e.trigger), actionMsg);
  }

  _showTooltipMessage(button, message, timeout) {
    timeout = timeout || 2000;
    button
      .tooltip({
        html: true,
        title: message,
        placement: 'bottom',
        trigger: 'manual'
      })
      .tooltip('show');

    this.$timeout(() => {
      button.tooltip('hide');
    }, timeout);
  }
}

LinkCopyHandlerService.$inject = [
  '$timeout'
];

export default LinkCopyHandlerService;
