'use strict';

function adviseReferralsController($scope, referrals) {
  $scope.referrals = referrals;

  $scope.referralStatuses = [
    'invited',
    'signed-up',
    'trial-sign-up',
    'account-funded',
    'completed'
  ];
}

angular.module('controller.advise.referrals', [])
  .controller('AdviseReferralsCtrl', [
    '$scope',
    'referrals',
    adviseReferralsController
  ]);
