'use strict';

angular.module('controller.advise.create-client.email', [
    'model.AdvisorCreateNewClientFlow',
    'model.Person',
    'ram',
    'service.form-helper',
    'service.new-client-creator',
    'service.user-notifications',
    'service.dropdown-options'
  ])
  .controller('AdviseCreateClientEmailCtrl', [
    '$http',
    '$scope',
    '$state',
    '$stateParams',
    'Person',
    'formHelper',
    'newClientCreator',
    'ram',
    'userNotifications',
    'advisorDetails',
    'dropdownOptions',
    adviseCreateClientEmailCtrl
  ]);

function adviseCreateClientEmailCtrl(
  $http,
  $scope,
  $state,
  $stateParams,
  Person,
  formHelper,
  newClientCreator,
  ram,
  userNotifications,
  advisorDetails,
  dropdownOptions
) {
  $scope.email = new ram.Accessor();
  $scope.isSubmitting = false;
  $scope.person = new Person();
  $scope.existingClient = new ram.Accessor(false);
  $scope.externalClientId = new ram.Accessor('');
  $scope.registeredInProvince = new ram.Accessor(false);
  $scope.sharedOffice = new ram.Accessor(true);
  $scope.repCodeOptions = advisorDetails.repCodes.map((repCode) => {return { code: repCode.code, id: repCode.id, shareOffice: repCode.shareOffice };});
  $scope.repCodeId = new ram.Accessor(advisorDetails.repCodes[0].id);
  $scope.sharedOfficeEntities = new ram.Accessor($scope.repCodeOptions.filter(function (repCode) { return repCode.id == $scope.repCodeId() })[0].shareOffice);
  $scope.yesOrNo = dropdownOptions.yesOrNo();
  $scope.groupName = advisorDetails.groupName;

  $scope.onDateOfBirthChange = function(person, date) {
    person.birthDate(date.toDate());
  };
  $scope.onRepCodeIdChange = function(repCodeId) {
    $scope.sharedOfficeEntities = new ram.Accessor($scope.repCodeOptions.filter(function (repCode) { return repCode.id == repCodeId() })[0].shareOffice);
  };

  function doSubmit() {
    $scope.isSubmitting = true;
    var extraParams = {
      repCodeId: $scope.repCodeId(),
      externalClientId: $scope.externalClientId(),
      sharedOfficeEntities: $scope.sharedOffice() ? $scope.sharedOfficeEntities() : '',
      registeredInProvince: $scope.registeredInProvince()
    };
    newClientCreator.create($scope.email(), $scope.person, extraParams)
      .then(function() {
        return $state.go('advise.create-client.success');
      })
      .catch(function(response) {
        var errors = response.data.errors;

        if (errors && errors.alreadyCreated) {
          $scope.existingClientApplicationId = errors.alreadyCreated.existingRecordId;
        } else if (errors && errors.email) {
          userNotifications.showError('The provided email has already been taken. Please provide a different email address.');
        } else {
          userNotifications.showError('Something went wrong. Please try again later.');
        }
      })
      .finally(function() {
        $scope.isSubmitting = false;
      });
  }

  $scope.submit = function(formController) {
    $scope.existingClientApplicationId = null;
    userNotifications.clearNotification();

    if (formController.$invalid) {
      formHelper.notDone($scope, formController);
    } else {
      doSubmit();
    }
  };

  $scope.cancelAction = function() {
    var modalTitle = 'Are you sure?';
    var modalBody = 'This action will delete the current application.';

    $scope.$root.genericConfirmationModal(modalTitle, modalBody).result
      .then(function() {
        $state.go('advise.overview');
      });
  };
}
