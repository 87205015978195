'use strict';

angular.module('model.ClientReadModel', [
    'ram',
    'config',
    'model.AdvisorCreateNewClientFlow',
    'model.PhoneNumber'
  ])
  .factory('ClientReadModel', [
    'ram',
    'config',
    clientReadModelFactory
  ]);

function clientReadModelFactory(ram, config) {

  /**
   * Constructor for PaginatedClient model.
   * @class
   */
  var ClientReadModel = new ram.Collection('ClientReadModel', {
    hasOne: {
      advisorCreateNewClientFlow: 'AdvisorCreateNewClientFlow'
    },
    hasMany: {
      phoneNumbers: 'PhoneNumber'
    },
    schema: config.schemas.ClientReadModel,
    resources: {
      default: new ram.resources.Http('/api/client_read_models/:id.json'),
      cookie: new ram.resources.Cookie('client_read_models')
    }
  });

  return ClientReadModel;
}
