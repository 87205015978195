'use strict';

angular.module('controller.modal.schedule-call', ['service.adaptive-views'])
  .controller('modalScheduleCallCtrl', [
    '$sce',
    '$scope',
    'adaptiveViews',
    'hubspotUrl',
    scheduleCallCtrl
  ]);

function scheduleCallCtrl($sce, $scope, adaptiveViews, hubspotUrl) {
  var extBreakPoint = 575, // The minimum width to show double panes for schedule a call
    headerHeight,
    headerHeightRegular = 70,
    headerHeightSmall = 40,
    height,
    scrHeight = window.innerHeight,
    scrWidth = window.innerWidth,
    smallHeaderCutoff = 700,
    width;

  var defaultMAHubspotUrl = 'https://app.hubspot.com/meetings/modernadvisor/phone';

  if (hubspotUrl) {
    $scope.hubspotUrl = $sce.trustAsResourceUrl(hubspotUrl);
  } else {
    $scope.hubspotUrl = $sce.trustAsResourceUrl(defaultMAHubspotUrl);
  }


  if (adaptiveViews.isApp()) {
    height = scrHeight;
    width = scrWidth;
  } else {
    // On small phones, the regular header is too large
    if (scrHeight < smallHeaderCutoff) {
      headerHeight = headerHeightSmall;
    } else {
      headerHeight = headerHeightRegular;
    }

    if (scrWidth < extBreakPoint) {
      height = scrHeight - headerHeight;
      width = 320;
    } else {
      height = 700;
      width = 575;
    }
  }

  $scope.height = height;
  $scope.width = width;

  // Hide the vertical scroll bar from the page from which the modal is called; re-enable upon
  // destruction of scope.
  angular.element('body').css('overflow-y', 'hidden');
  $scope.$on('$destroy', function() {
    angular.element('body').css('overflow-y', 'auto');
  });
}
