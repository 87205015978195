'use strict';

/**
 * Controller for the preview view.
 */

angular.module('controller.preview', [
    'component.investment-charts.model-portfolio-pie-chart',
    'controller.preview.fees',
    'controller.preview.investments',
    'controller.preview.performance',
    'controller.preview.projections',
    'config',
    'directive.input',
    'ui.bootstrap',
    'service.accountCreator',
    'service.account-service',
    'service.check-incomplete-accounts-service',
    'service.goal-service',
    'service.investment-charts.asset-allocation-data-generator',
    'service.investments-bar-prep-data',
    'service.securityService',
    'ram'
  ])
  .controller('PreviewCtrl', [
    '$rootScope',
    '$scope',
    '$state',
    '$window',
    '$cookies',
    'config',
    'ima',
    'ram',
    'modelPortfolio',
    'questions',
    'storedAccount',
    'storedUser',
    'storedGoal',
    'accountCreator',
    'accountService',
    'invBarPrepData',
    'advisorGroupUrl',
    'checkIncompleteAccountsService',
    'goalService',
    'investmentChartsAssetAllocationDataGenerator',
    'securityServiceFactory',
    'advisorReferralDetails',
    previewController
  ]);

function previewController(
  $rootScope,
  $scope,
  $state,
  $window,
  $cookies,
  config,
  ima,
  ram,
  modelPortfolio,
  questions,
  account,
  user,
  goal,
  accountCreator,
  accountService,
  invBarPrepData,
  advisorGroupUrl,
  checkIncompleteAccountsService,
  goalService,
  investmentChartsAssetAllocationDataGenerator,
  securityServiceFactory,
  advisorReferralDetails
) {
  var savedPortfolioId;
  $rootScope.branding = advisorReferralDetails.branding;

  $scope.optimizelyVariationName = null;

  $scope.user = user;
  $scope.goal = goal;
  $scope.account = account;

  // For checking if it is possible to generate the graph on page, if invalid go to /interview/goal
  // Only a temporary fix. Should be changed to check in the route.
  if (!account.type() || !goal.risk()) {
    $window.location = $scope.$root.config.env.marketingUrl;
  }

  $scope.questions = _.indexBy(questions, function(question) {
    return question.accessor();
  });

  if ($scope.currentUser) {
    var currentUserSecurity = securityServiceFactory.create($scope.currentUser);
    $scope.showFees = currentUserSecurity.isInternalClient() && currentUserSecurity.isDigitalClient();
  } else {
    $scope.showFees = !advisorGroupUrl;
  }

  $scope.accountPortfolioOptions = _.reject(config.types.AccountPortfolioOption, function(accountPortfolioOption) {
    return accountPortfolioOption.name === 'adaptive_portfolio' && !canSelectAdaptivePortfolioOption();
  });

  $scope.portfolioOptionTooltipType = canSelectAdaptivePortfolioOption() ? 'core_resp_adapt' : 'core_resp';

  function canSelectAdaptivePortfolioOption() {
    if (!$scope.$root.currentUser) {
      // No logged in user
      return _activeInvestorDnaCookie() &&
        _currentDateAfterAdaptivePortfolioFeatureStartDate();
    }

    // For logged in user
    return $scope.$root.currentUser.canSelectAdaptivePortfolio() &&
      imaCreatedAfterAdaptivePortfolioFeatureStartDate();
  }

  function imaCreatedAfterAdaptivePortfolioFeatureStartDate() {
    if (ima) {
      return new Date(ima.createdAt()) >= new Date(config.env.adaptivePortfolioFeatureStartDate);
    } else {
      return _currentDateAfterAdaptivePortfolioFeatureStartDate();
    }
  }

  function _currentDateAfterAdaptivePortfolioFeatureStartDate() {
    return new Date() >= new Date(config.env.adaptivePortfolioFeatureStartDate);
  }

  function _activeInvestorDnaCookie() {
    var cookieData = $cookies.get('group_url_id');
    return cookieData && !!cookieData.match(/InvestorDNA-Wealth/); // matches in the JSON just fine
  }

  /****************************************
   * Mobile code
   */
  $scope.projection = {
    initial: new ram.Accessor(),
    target: new ram.Accessor(),
    monthlyContribution: new ram.Accessor()
  };
  /* End of mobile code **********************/

  var _prepareGraph = function(portfolio) {
    if (!portfolio) {
      return;
    }

    var chartData = investmentChartsAssetAllocationDataGenerator.getData(portfolio.nonZeroAllocations());

    $scope.allocationDataForPieChart = _.map(chartData, function(dataRow) {
      return {
        color: dataRow.color,
        label: dataRow.allocation.fund().assetClass().label,
        value: dataRow.allocation.weight()
      };
    });

    $scope.allocationDataForChartLegend = _.map(chartData, function(dataRow) {
      return {
        color: dataRow.color,
        assetClass: dataRow.allocation.fund().assetClass().label,
        ticker: dataRow.allocation.fund().symbol(),
        weight: dataRow.allocation.weight()
      };
    });

    var allocationsForBarChart = _.map(chartData, function(chartDataRow) {
      return chartDataRow.allocation;
    });

    var invChartDataObject = invBarPrepData.getChartDataForAllocations(allocationsForBarChart, $scope.account);
    if (invChartDataObject) {
      $scope.toBeStacked = invChartDataObject.toBeStacked;
      $scope.investmentsChartData = invChartDataObject.investmentsChartData;
    }
  };

  $scope.portfolio = modelPortfolio;
  savedPortfolioId = $scope.portfolio.id;
  _prepareGraph($scope.portfolio);

  $scope.amountAtEndOfTimeframe = function() {
    var projection = _.findWhere($scope.portfolio.projections(), {
      percentile: 50
    });
    var simulatedProjection = projection.simulate($scope.goal.initial(), $scope.goal.ongoing());
    var startDate = moment(_.first(simulatedProjection.values).date);
    var dateAtEndOfTimeframe = startDate.add(goal.timeframe(), 'years');
    return _.find(simulatedProjection.values, function(simulatedBalance) {
      return simulatedBalance.date.getTime() === dateAtEndOfTimeframe.toDate().getTime();
    }).balance();
  };

  $scope.$watch('goal.riskRecommendation()', function() {
    $scope.goal.save();
  });
  $scope.$watch('goal.riskOffset()', function() {
    $scope.goal.save();
  });

  function updatePortfolio(newValues, oldValues) {
    if (_.isEqual(newValues, oldValues) || !$scope.account || !$scope.goal) {
      return;
    }

    goalService.fetchModelPortfolio($scope.goal, $scope.account)
      .then(function(modelPortfolio) {
        $scope.portfolio = modelPortfolio;
        savedPortfolioId = modelPortfolio.id;
        _prepareGraph(modelPortfolio);
      });
  }

  /**
   * Watch for changes that affect portfolio selection.
   */
  $scope.$watchGroup(['goal.risk()', 'account.portfolioOptionId()'], updatePortfolio);

  function onPreviewConfirmed() {
    if (!$scope.currentUser) {
      return $state.go('interview.account-credentials');
    }

    accountCreator.addAccount($scope, $scope.currentUser, $scope.account)
      .then(function() {
        // refresh user's accounts
        return $scope.currentUser.accounts.ready();
      })
      .then(function() {
        $scope.account = null;
      });
  }

  /** For the new onboarding demo, next will jump straight into email and password for trial accounts, or
   * continue with onboarding by launching the goalReviewModal.
   **/
  $scope.nextButton = function() {

    if ($scope.account.type.is.trial()) {
      onPreviewConfirmed();
    } else {
      $scope.goalReviewModal($scope.account,
          $scope.goal,
          savedPortfolioId, // $scope.portfolio is getting changed by another callback after this page is rendered.  This local
          // variable shadows the setting and is used to pass the portfolio into the review modal.
          $scope.user,
          $scope.questions,
          $scope.toBeStacked,
          $scope.investmentsChartData
        ).result
        .then(function(result) {
          if (result) {
            onPreviewConfirmed();
          }
        });
    }
  };

  checkIncompleteAccountsService.call($scope, $state);
}
