'use strict';

angular.module('directive.upcoming-transfers-table', [
    'service.adaptive-views',
    'service.group-by-date',
    'service.transfer-account-data-provider',
    'service.user-notifications'
  ])
  .directive('upcomingTransfersTable', [
    'adaptiveViews',
    'groupByDateService',
    'transferAccountDataProvider',
    'userNotifications',
    upcomingTransfersTableDirective
  ]);

function upcomingTransfersTableDirective(adaptiveViews, groupByDateService, transferAccountDataProvider, userNotifications) {
  return {
    restrict: 'E',
    templateUrl: adaptiveViews.getTemplateUrlForSrc('directives/upcoming-transfers-table.html', true),
    scope: {
      accounts: '=',
      bankAccounts: '=',
      heading: '@',
      transferInstructionsContainer: '='
    },
    controller: ['$scope', function($scope) {
      transferAccountDataProvider.setInputs(undefined, $scope.accounts, $scope.bankAccounts);

      var _sortTransferInstructionsByDate = function(transferInstructions) {
        return groupByDateService.call(transferInstructions, 'nextTransferDate');
      };

      var setUpTransferInstructions = function(transferInstructions) {
        return _.chain(transferInstructions)
          .each(function(transferInstruction) {
            _.extend(transferInstruction, {
              transferFrom: transferAccountDataProvider.getAccountById(
                transferInstruction.transferFromType(),
                transferInstruction.transferFromId()
              ),
              transferTo: transferAccountDataProvider.getAccountById(
                transferInstruction.transferToType(),
                transferInstruction.transferToId()
              )
            });
          })
          .sortBy(function(transferInstruction) {
            return transferInstruction.startDate();
          })
          .value();
      };

      var cancelDisplayOptions = function(transferInstruction) {
        return {
          isAccountTransfer: false,
          canCancel: transferInstruction.canCancel(),
          cancelWithMessage: transferInstruction.hasInProgressRequests()
        };
      };

      $scope.$watchCollection('transferInstructionsContainer.transferInstructions', function(transferInstructions) {
        $scope.transferInstructions = setUpTransferInstructions(transferInstructions);

        if ($scope.$root.isMobile) {
          $scope.transferInstructionsByDate = _sortTransferInstructionsByDate($scope.transferInstructions);
        }
      });

      $scope.cancel = function(transferInstruction) {
        $scope.$root.transferCancelConfirmationModal(cancelDisplayOptions(transferInstruction)).result
          .then(function() {
            return transferInstruction.cancel();
          })
          .then(function() {
            // Need to reload the transferInstructions for keeping the
            // upcoming transfers table in the unfunded messages in sync
            if (transferInstruction.transferToType() === 'Account') {
              return transferInstruction.transferTo.transferInstructions.ready(true);
            }
          })
          .then(function() {
            $scope.transferInstructions = _.filter(
              $scope.transferInstructions,
              function(element) {
                return element.id !== transferInstruction.id;
              }
            );
            userNotifications.showInfo('Your transfer has been cancelled.');
            $scope.transferInstructionsByDate = _sortTransferInstructionsByDate($scope.transferInstructions);
          });
      };

      $scope.clientLabel = function(sourceAccount, secondAccount) {
        // Due to policy scopes and access levels, the account passed to the UX may be undefined.
        // In this case, we know it is a bank account.  Polymorphic for Account and BankAccount.
        var firstName = secondAccount ? secondAccount.clientFirstName() : undefined;
        return sourceAccount ? sourceAccount.clientLabel() : firstName + '\'s bank account';
      };

      $scope.getDetails = function(transferInstruction) {
        return transferAccountDataProvider.getDetailsMessage(transferInstruction);
      };
    }]
  };
}
