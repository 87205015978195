'use strict';

angular.module('controller.advisor-new-client', [
    'controller.advisor-new-client.add-password',
    'controller.advisor-new-client.verify-identity',
    'controller.advisor-new-client.view',
    'controller.advisor-new-client.goodbye'
  ])
  .controller('AdvisorNewClientController', [
    '$scope',
    '$window',
    advisorNewClientController
  ]);

function advisorNewClientController($scope, $window) {
  $scope.cancel = function() {
    var title = 'Are you sure you want to leave this page?';
    var modalBody = 'You can visit this page again by following the link in your email at a later time.';
    $scope.$root.genericConfirmationModal(title, modalBody)
      .result
      .then(function() {
        $window.location = $scope.$root.config.env.marketingUrl;
      });
  };
}
