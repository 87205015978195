'use strict';

angular.module('controller.advise.issues', [
    'directive.flex-container',
    'directive.flex-boxes',
    'model.PaginatedIssue',
    'model.Paginator',
    'service.pagination.filter-service',
    'service.user-notifications'
  ])
  .controller('AdviseIssuesCtrl', [
    '$scope',
    '$state',
    '$http',
    'config',
    'Paginator',
    'PaginatedIssue',
    'paginationFilterService',
    'loadingIndicator',
    'userNotifications',
    adviseIssuesController
  ]);

function adviseIssuesController($scope, $state, $http, config, Paginator, PaginatedIssue, paginationFilterService, loadingIndicator, userNotifications) {

  $scope.linkToClient = function(issue) {
    if (issue.clientId()) {
      return 'advise.client.view({id: ' + issue.clientId() + '})';
    } else {
      return '#';
    }
  };

  $scope.issueType = function(issue) {
    return config.types.Issue[issue.typeId()].label;
  };

  /******************************* Pagination methods */
  $scope.issues = [];
  $scope.actionManagerIssues = {};

  var initialFilter = [
    'state.open',
    'type.verify_identity',
    'type.compliance',
    'type.allotment',
    'type.uncategorized',
    'type.document_upload',
    'type.bank_account',
    'type.transfer_request',
    'type.raise_cash_request'
  ];

  $scope.initialActions = function() {
    return [{
      type: 'FLEX-FILTER',
      filterList: initialFilter
    }];
  };
  var filterData = [];

  filterData.push({
    id: filterData.length,
    group: 'State',
    label: 'Open',
    code: 'state.open'
  });

  filterData.push({
    id: filterData.length,
    group: 'State',
    label: 'closed',
    code: 'state.closed'
  });
  paginationFilterService.addEnumFilterData(config.types.Issue, filterData, 'Type', 'type');

  $scope.filterData = filterData;
  $scope.filterModel = paginationFilterService.toFilterModel(filterData, initialFilter);

  var _getDataFromPaginator = function(parameter) {
    return parameter.issues();
  };

  $scope.paginator = new Paginator(PaginatedIssue, null, _getDataFromPaginator);
  /******************************* end Pagination methods */

  $scope.option = 'none';
  var clearNotification = false;

  $scope.selectAll = function() {
    _.forEach($scope.issues, function(issue) {
      if (issue.isClosed()) {
        return;
      }
      issue.selected = true;
    });

    $scope.option = 'all';
  };

  $scope.selectNone = function() {
    _.forEach($scope.issues, function(issue) {
      issue.selected = null;
    });

    $scope.option = 'none';
  };

  function closeBulkIssues(issueIds) {
    $http.put('/api/issues/close_bulk.json', {
        id: issueIds
      })
      .then(function(response) {
        var closedIssues = response.data;
        $scope.selectNone();
        $state.reload()
          .finally(function() {
            userNotifications.showSuccess(closedIssues.length + ' issues successfully closed');
          });
      })
      .finally(function() {
        loadingIndicator.hide();
      });
  }

  function showCloseIssuesConfirmation(selectedIssues) {
    $scope.genericConfirmationModal('Close Issues',
        'Are you sure you want to close ' + selectedIssues.length + ' issues?',
        'btn-success')
      .result
      .then(function() {
        loadingIndicator.show();

        var issueIds = _.map(selectedIssues, function(issue) {
          return issue.id;
        });

        closeBulkIssues(issueIds);
      });
  }

  $scope.closeSelected = function() {
    userNotifications.clearNotification();

    var selectedIssues = _.filter($scope.issues, function(issue) {
      return issue.selected === true;
    });

    if (!selectedIssues.length) {
      userNotifications.showWarning('Nothing is selected');
      return;
    }

    showCloseIssuesConfirmation(selectedIssues);
  };

  $scope.$watch('issues', onIssuesChanged);

  function onIssuesChanged() {
    if ($scope.issues.length === 0) {
      return;
    }

    $scope.selectNone();
    if (clearNotification) {
      userNotifications.clearNotification();
    }
    clearNotification = true;
  }
}
