'use strict';

angular.module('routes.advise.upload-mutual-fund-trades', [
    'react.component.advise.upload-mutual-fund-trades',
    'templates',
    'ui.router'
  ])
  .config([
    '$stateProvider',
    configRoutes
  ]);

function configRoutes($stateProvider) {
  function authorizeSuperAdvisor(authorizer) {
    return authorizer.checkPermission('superAdvisor');
  }

  $stateProvider
    .state('advise.upload-mutual-fund-trades', {
      url: '/upload-mutual-fund-trades',
      abstract: false,
      resolve: {
        authorize: ['authorizer', authorizeSuperAdvisor]
      },
      template: '<div ui-view></div>'
    })
    .state('advise.upload-mutual-fund-trades.file', {
      url: '/file',
      controller: [
        () => {}
      ],
      resolve: {},
      template: `<react-upload-mutual-fund-trades></react-upload-mutual-fund-trades>`
    });
}
