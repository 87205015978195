'use strict';

function postMessageProvider(adaptiveViews) {
  /* jshint validthis: true */
  var isApp = adaptiveViews.isApp();

  this.$get = function() {
    return {
      postMessage: function(eventData) {
        if (isApp) {
          window.postMessage(JSON.stringify(eventData), '*');
        }
      }
    };
  };
}

angular.module('service.mobile-post-message-bridge', [
    'service.adaptive-views'
  ])
  .provider('mobilePostMessageBridge', [
    'adaptiveViewsProvider',
    postMessageProvider
  ]);
