'use strict';

const ID_DOC_TYPE_NAMES = ['id_1', 'id_2'];

angular
  .module('controller.modal.advisor-id-verify-confirm', ['ram', 'service.loading-indicator'])
  .controller('ModalAdvisorIdVerifyConfirm', [
    '$http',
    '$q',
    '$scope',
    '$uibModalInstance',
    'personId',
    'documents',
    'loadingIndicator',
    '$cookies',
    controller
  ]);

function controller(
  $http,
  $q,
  $scope,
  $modalInstance,
  personId,
  documents,
  loadingIndicator,
  $cookies
) {
  $scope.informationMessageForUser = '';
  $scope.notes = '';
  $scope.canConfirm = false;

  const latestIdDocuments = ID_DOC_TYPE_NAMES.map((typeName) => {
    return documents.filter((document) => document.type().name === typeName).slice(-1)[0];
  }).filter((document) => document);

  $scope.latestIdDocuments = latestIdDocuments;
  $scope.openedDocumentsIds = new Set();

  $scope.trackDocumentOpened = function (id) {
    $scope.openedDocumentsIds.add(id);
    $scope.canConfirm = $scope.openedDocumentsIds.size === 2 && latestIdDocuments.length === 2;
  };

  $scope.confirm = function () {
    if (!$scope.canConfirm) return;

    loadingIndicator.show();

    return $http
      .post('/api/advisor/manual_identity_checks.json', {
        personId,
        notes: $scope.notes
      })
      .then(function () {
        $scope.informationMessageForUser = 'Id verified!';
        $cookies.put(`manually-confirmed-${personId}`, true);
        $modalInstance.close();
      })
      .catch(function (error) {
        const message = error.data.message || 'Could not verify identity';
        $scope.informationMessageForUser = 'Error: ' + message;

        return $q.reject(error);
      })
      .finally(function () {
        loadingIndicator.hide();
      });
  };
}
