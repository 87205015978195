'use strict';

angular.module('controller.wsi-interview', [
    'controller.wsi-interview.goal',
    'controller.wsi-interview.account-type',
    'controller.wsi-interview.spousal-rrsp',
    'controller.wsi-interview.financial',
    'controller.wsi-interview.risk',
    'controller.wsi-interview.conflicts-and-recommendations',
    'controller.wsi-interview.preview',
    'ui.bootstrap',
    'ui.router',
    'model.Question',
    'animations',
    'directive.input',
    'ui.slider',
    'directive.ionSlider',
    'directive.interview.navbar',
    'service.adaptive-views',
    'service.check-incomplete-accounts-service'
  ])
  .controller('WsiInterviewCtrl', [
    '$cookies',
    '$scope',
    '$state',
    '$stateParams',
    '$rootScope',
    'storedAccount',
    'storedUser',
    'storedGoal',
    '$timeout',
    'checkIncompleteAccountsService',
    'config',
    'advisorReferralDetails',
    controller
  ]);

function controller($cookies, $scope, $state, $stateParams, $rootScope, account, user, goal, $timeout, checkIncompleteAccountsService, config, advisorReferralDetails) {
  $rootScope.branding = advisorReferralDetails.branding;

  $scope.isPreview = function() {
    return $state.current.name.startsWith('wsi-interview.preview');
  };

  $scope.account = account;
  $scope.user = user;
  $scope.isOpeningGroupSavingsPlan = function() {
    return !!$cookies.get('group_savings_plan');
  };

  $scope.isAdvisorCreateNewAccountFlow = function() {
    return $scope.account.advisorCreateNewAccountFlowId() !== undefined;
  };

  function newCookieOrCookieIsStale() {
    if (!$scope.user.updatedAt()) {
      // new cookies won't have this
      return true;
    }
    return $scope.currentUser.updatedAt() > $scope.user.updatedAt(); // cookie is stale
  }

  if ($scope.currentUser && newCookieOrCookieIsStale()) {
    // user is already logged in
    var current = _.pick($scope.currentUser.toJSON(),
      'nonFinancialAssets', 'financialAssets', 'income', 'riskAppetite', 'totalLiabilities',
      'appetite0', 'appetite1', 'appetite2', 'appetite3', 'appetite4', 'appetite5', 'appetite6', 'appetite7');

    _.each(current, function(value, key) {
      if (value !== null && !_.isUndefined(value)) {
        $scope.user[key](value);
      }
    });
    $scope.user.save({}, {
      expires: moment().add(30, 'minutes').format()
    });
  }

  $scope.goal = goal;
  // fix the preview not loading
  goal.advisorRiskOffset(0);

  // Preselections
  if ($stateParams.preselectResponsiblePortfolioOption) {
    account.portfolioOptionId(
      _.findWhere(config.types.AccountPortfolioOption, {
        name: 'socially_responsible_portfolio'
      }).id
    );
    account.save();
  } else if (_.isUndefined(account.portfolioOptionId())) {
    account.portfolioOptionId(
      _.findWhere(config.types.AccountPortfolioOption, {
        name: 'core_portfolio'
      }).id
    );
    account.save();
  }

  if ($scope.isOpeningGroupSavingsPlan()) {
    account.type(_.findWhere(config.types.Account, {
      name: 'group_rrsp'
    }));
    account.save();
  }

  $scope.$watch('errorMessage', function(newValue) {
    if (newValue) {
      $timeout(function() {
        if ($scope.errorMessage === newValue) {
          $scope.errorMessage = null;
        }
      }, 3000);
    }
  });

  $scope.progress = function() {
    return $state.current.data && $state.current.data.progress;
  };

  $scope.validateCookie = function() {
    if ($cookies.getObject('accounts') && $cookies.getObject('goals') && $cookies.getObject('users')) {
      return true;
    }

    if ($state.current.name === 'interview.goal') {
      $scope.account.id = undefined;
      $scope.account.save();
      $scope.goal.id = undefined;
      $scope.goal.save();
      $scope.user.id = undefined;
      $scope.user.save();

      return true;
    }

    $state.go('interview.goal', {}, {
      reload: true
    }).then(function() {
      var title = 'Your session has expired!';
      var body = 'Your session has expired due to inactivity.\n' +
        'You have been redirected to the first step of the account application.';
      $rootScope.errorModal(title, body);
    });

    return false;
  };

  $scope.nextButtonLabel = function() {
    if ($state.is('interview.personal.end')) {
      return 'Return to Home Page';
    } else {
      return 'Next';
    }
  };

  $scope.hideNavbar = function() {
    return $state.current.data.hideNavBar;
  };

  checkIncompleteAccountsService.call($scope, $state);
}
