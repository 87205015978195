'use strict';

function flexSearchBoxDirective() {
  return {
    restrict: 'E',
    scope: true,
    require: '^flexContainer',
    templateUrl: 'directives/flex-search-box.html',
    link: function(scope, element, attrs, controller) {
      scope.placeholder = attrs.placeholder;
      scope.search = {
        term: null
      };

      controller.addListener(function() {
        var lastState = controller.state.lastState;
        if (!controller.state.search || lastState === 'RESET-ALL') {
          scope.search.term = '';
        }
      });

      scope.reset = function() {
        scope.search.term = '';
        if (controller.paginationMeta() && controller.paginationMeta().searchTerm()) {
          controller.dispatch('RESET-SEARCH');
        }
      };

      scope.dispatch = function(searchTerm) {
        if (!searchTerm || searchTerm.trim().length === 0) {
          if (controller.state.search) {
            // If the user has searched for something then an empty search box resets.
            // If no such search has been done, then pressing enter on an empty box does nothing.
            controller.dispatch('RESET-SEARCH');
          }
          return;
        }
        controller.dispatch('FLEX-SEARCH', {
          searchTerm: searchTerm
        });
      };

      scope.hasResults = function() {
        var hasResults = controller.state.search && (controller.hasResults || !controller.initialQueryHasResults);
        // Alter the size of the `Reset` button based on `hasResults`.
        if (hasResults) {
          element.find('input').css('width', '247px'); // This width was optimized visually.
          element.find('button.reset-button').css('width', '45px');
        } else {
          element.find('input').css('width', '300px');
          element.find('button.reset-button').css('width', '0px');
        }
        return hasResults;
      };
    }
  };
}

function flexFilterBoxDirective() {
  return {
    restrict: 'E',
    scope: {
      options: '=',
      selectedModel: '=',
      innerClass: '@',
      checkboxes: '='
    },
    require: '^flexContainer',
    templateUrl: 'directives/flex-filter-box.html',
    link: function(scope, element, attrs, controller) {
      scope.numActive = 0;

      controller.addListener(function() {
        var lastState = controller.state.lastState;
        if (lastState === 'RESET-FILTER' || lastState === 'RESET-ALL') {
          scope.selectedModel = [];
        }
      });

      scope.onClose = function() {
        var list = _.map(scope.selectedModel, function(idObject) {
          return scope.options[idObject.id].code;
        });
        scope.dispatch(list);
      };

      scope.dispatch = function(args) {
        controller.dispatch('FLEX-FILTER', {
          filterList: args
        });
      };

      if (attrs.innerClass) {
        scope.innerClass = attrs.innerClass;
      }
    }
  };
}

function flexPaginationBoxDirective() {
  return {
    restrict: 'E',
    scope: true,
    require: '^flexContainer',
    templateUrl: 'directives/flex-pagination-box.html',
    link: function (scope, element, attrs, controller) {
      controller.addListener(function() {
        var currentPage = parseInt(controller.paginationMeta().currentPage());
        const currentPerPageState = parseInt(controller.state.perPage);
        const button = document.getElementById(`change-row-button-${currentPerPageState}`);
        const buttons = document.getElementsByName('button');

        if (button.id === `change-row-button-${currentPerPageState}`) {
          for (let i = 0; i < buttons.length; i++) {
            buttons[i].classList.remove('current-page');
          }
          button.classList.add('current-page');
        }
        scope.paginate = {
          page: currentPage,
          totalPages: controller.paginationMeta().totalPages(),
          isFirst: (currentPage <= 1),
          isLast: (currentPage >= parseInt(controller.paginationMeta().totalPages()))
        };
        scope.paginationMeta = controller.paginationMeta();
      });

      scope.dispatch = function (absolutePage, relativePage, infinityJump, perPage) {
        const pageParams = {};
        var pageNum = scope.paginate.page;
        var lastPageNum = parseInt(controller.paginationMeta().currentPage());
        if (absolutePage && absolutePage > 0) {
          pageNum = absolutePage;
        } else if (relativePage) {
          pageNum = lastPageNum + relativePage;
        } else if (infinityJump) {
          pageNum = 1;
          if (infinityJump > 0) {
            pageNum = parseInt(controller.paginationMeta().totalPages());
          }
        }
        if (lastPageNum !== pageNum) pageParams.page = pageNum;
        if (perPage) pageParams.perPage = perPage;
        if (Object.keys(pageParams).length === 0) return;

        controller.dispatch('FLEX-SET-PAGE', pageParams);
      };

      scope.resetInput = function() {
        scope.paginate.page = controller.paginationMeta().currentPage();
      };
    }
  };
}

function flexColumnHeadingBoxDirective() {
  return {
    restrict: 'E',
    scope: true,
    transclude: true,
    require: '^flexContainer',
    templateUrl: 'directives/flex-column-heading-box.html',
    link: function(scope, element, attrs, controller) {
      scope.serverTag = attrs.serverTag;
      scope.sortMode = 0;

      controller.addListener(function() {
        scope.isSelected = (scope.serverTag === controller.paginationMeta().sortBy());
        if (!scope.isSelected) {
          scope.sortMode = 0;
        } else {
          scope.sortMode = (controller.paginationMeta().sortDirection() === true ? +1 : -1);
        }
      });

      scope.changeSort = function() {
        var newMode;
        var sortFieldName = scope.serverTag;
        switch (scope.sortMode) {
          case 0:
            newMode = +1;
            break;

          case -1:
            newMode = 0;
            sortFieldName = '';
            break;

          case +1:
            newMode = -1;
            break;
        }
        scope.sortMode = newMode;
        scope.dispatch({
          field: sortFieldName,
          direction: scope.sortMode
        });
      };

      scope.dispatch = function(args) {
        controller.dispatch('SORT-BY', {
          sort: {
            sortBy: args.field,
            order: args.direction
          }
        });
      };
    }
  };
}

function flexSearchEmptyResultSetDirective() {
  return {
    restrict: 'E',
    scope: true,
    require: '^flexContainer',
    template: '<div ng-show="displaySearchMessage()">' +
      '  <p>Sorry, no results were found for "{{getSearchTerm()}}".</p>' +
      '  <a ng-click="resetSearch()">Go back to main list</a>' +
      '</div>' +
      '<div ng-show="displayFilterMessage()">' +
      '  <p>There are no results found for the filters applied.</p>' +
      '  <a ng-click="resetFilter()">Reset the filter</a>' +
      '</div>' +
      '<div ng-show="zeroResultsExist">' +
      '  <p>There is no information to display.</p>' +
      '</div>',

    link: function(scope, element, attrs, controller) {
      scope.zeroResultsExist = (controller.state.search === '' && controller.state.filter.length === 0);

      scope.displaySearchMessage = function() {
        if (scope.zeroResultsExist) {
          return false;
        }
        if (controller.state.lastState === 'FLEX-SEARCH' || (controller.state.lastState === 'RESET-FILTER' && controller.state.search)) {
          return true;
        }
        return false;
      };

      scope.displayFilterMessage = function() {
        if (scope.zeroResultsExist) {
          return false;
        }
        if (controller.state.lastState === 'FLEX-FILTER' || (controller.state.lastState === 'RESET-SEARCH' && controller.state.filter.length > 0)) {
          return true;
        }
        return false;
      };

      scope.getSearchTerm = function() {
        return controller.paginationMeta() && controller.paginationMeta().searchTerm();
      };

      scope.getLastState = function() {
        return controller.state.lastState;
      };

      scope.resetSearch = function() {
        controller.dispatch('RESET-SEARCH');
      };

      scope.resetFilter = function() {
        controller.dispatch('RESET-FILTER');
      };
    }
  };
}

angular.module('directive.flex-boxes', [
    'angularjs-dropdown-multiselect'
  ])
  .directive('flexSearchBox', [
    flexSearchBoxDirective
  ])
  .directive('flexFilterBox', [
    flexFilterBoxDirective
  ])
  .directive('flexPaginationBox', [
    flexPaginationBoxDirective
  ])
  .directive('flexColumnHeadingBox', [
    flexColumnHeadingBoxDirective
  ])
  .directive('flexSearchEmptyResultSet', [
    flexSearchEmptyResultSetDirective
  ]);
