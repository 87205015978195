'use strict';

angular.module('service.error.NotAuthenticatedErrorHandler', ['service.loading-indicator'])
  .factory('NotAuthenticatedErrorHandler', [
    '$window',
    'Auth',
    'loadingIndicator',
    factory
  ]);

function factory($window, Auth, loadingIndicator) {

  var NotAuthenticatedErrorHandler = function() {};

  NotAuthenticatedErrorHandler.prototype.canHandle = function(error) {
    return error === 'Not Authenticated' || (error.status && error.status === 401);
  };

  NotAuthenticatedErrorHandler.prototype.handle = function() {
    if (Auth.isAuthenticated()) {
      Auth.logout()
        .finally(function() {
          loadingIndicator.show();
          $window.location.href = '/signin?sessionExpired=true';
        });
    }
    return true;
  };

  return NotAuthenticatedErrorHandler;
}
