'use strict';

angular.module('controller.profile.bank.add-success', [
    'model.BankAccount'
  ])
  .controller('ProfileBankSuccessCtrl', [
    '$scope',
    'bankAccounts',
    'BankAccount',
    profileBankSuccessController
  ]);

function profileBankSuccessController($scope, bankAccounts, BankAccount) {
  $scope.showAddMore = bankAccounts.length < BankAccount.constructor.MAX_NUMBER_PER_CLIENT;

  $scope.title = 'Please check your email';
  $scope.body = 'We’ve emailed you a PDF document that authorizes our back office, Worldsource\n' +
      'Financial Management Inc., to link your bank account. Please check your inbox and digitally\n' +
      'sign this document.';
}
