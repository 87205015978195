'use strict';

angular.module('model.Person', [
    'model.Address',
    'model.InsiderRole',
    'model.PhoneNumber',
    'model.PersonalRelation',
    'model.HouseholdInvitation',
    'model.HouseholdMembership',
    'model.TaxStatus',
    'model.LegalInfo',
    'model.JointApplicant',
    'ram'
  ])
  .factory('Person', [
    '$http',
    'ram',
    'config',
    'InsiderRole',
    personFactory
  ]);

function personFactory($http, ram, config, InsiderRole) {

  /**
   * @class A model representing a generic person - could be an applicant,
   *        spouse, annuitant, etc.
   */
  var Person = new ram.Collection('Person', {
    accessors: ['numActiveAccounts', 'hasCompletedClientFlowWithPendingAccountFlows'],
    belongsTo: {
      user: 'User'
    },
    bind: ['hasSpouse', 'isEmployed'],
    enums: {
      maritalStatus: config.types.MaritalStatus,
      employmentStatus: config.types.EmploymentStatus,
      gender: config.types.Gender
    },
    hasOne: {
      homeAddress: 'Address',
      workAddress: 'Address',
      mailingAddress: 'Address',
      spouseRelation: 'PersonalRelation',
      householdMembership: 'HouseholdMembership',
      legalInfo: 'LegalInfo'
    },
    hasMany: {
      accounts: 'Account',
      addresses: 'Address',
      householdInvitations: 'HouseholdInvitation',
      insiderRoles: 'InsiderRole',
      participations: 'Participant',
      phoneNumbers: 'PhoneNumber',
      personalRelations: 'PersonalRelation',
      publicCompanyInsiderRoles: 'InsiderRole',
      publicCompanyOwnerRoles: 'InsiderRole',
      securitiesIndustryRoles: 'InsiderRole',
      taxStatuses: 'TaxStatus',
      jointApplicants: 'JointApplicant'
    },
    resources: {
      default: new ram.resources.Http('/api/people/:id.json'),
      cookie: new ram.resources.Cookie('people')
    },
    schema: config.schemas.Person
  });

  /**
   * Get or set a person's age using their birthDate.
   *
   * @method age
   * @param  {Number} val New age to set if defined, otherwise returns the
   *                      current age.
   * @return {Number}
   */
  Person.prototype.age = function(val) {
    if (arguments.length && val !== null) {
      this.birthDate(moment().subtract(val, 'y').startOf('y')._d);
    }
    return moment().diff(this.birthDate(), 'y');
  };

  /**
   * When marital status changes, save and then force reload of spouse relation.
   *
   * @method maritalStatusChange
   */
  Person.prototype.maritalStatusChange = function() {
    return this.save()
      .then(function(self) {
        return self.spouseRelation.ready(true);
      });
  };

  /**
   * Check whether person has a spouse.
   *
   * @method  hasSpouse
   * @return {Boolean}
   */
  Person.prototype.hasSpouse = function() {
    if (this.spouseRelation() === undefined) {
      return false;
    }

    return true;
  };

  /**
   * This hack is used to make spouseRelation appear like a real association.
   * @param  {Boolean} force Bypass request cache if true
   * @return {Promise}
   */
  function fetchSpouseRelation(force) {
    var self = this; // jshint ignore:line

    var promise = self._owner.personalRelations.ready(force);
    if (promise !== self._promise) {
      self._promise = promise;
      promise.then(function(relations) {
        self._current = _.find(relations, function(relation) {
          return relation.type.is.spouse() || relation.type.is.commonLaw();
        });
      });
    }
    return self._promise;
  }

  function createFetchMethodForAddresWithType(typeId) {
    return function(force) {
      // this here is an Association!
      var self = this; // jshint ignore:line

      var promise = self._owner.addresses.ready(force);
      if (promise !== self._promise) {
        self._promise = promise;
        promise.then(function(addresses) {
          self._current = _.find(addresses, function(address) {
            return address.typeId() === typeId;
          });
        });
      }
      return self._promise;
    };
  }

  /**
   * Replace the _fetch method of spouseRelation which is actually a pseudo-
   * association scope applied to personalRelations.
   */
  Person.prototype.initialize = function() {
    this.spouseRelation._fetch = fetchSpouseRelation;

    // This is hack to get around problem when ram will return address from the cache even if
    // it's different address type. The reason for that is that cache key for address (which is '{person.id:xxx}') does
    // not contain address type.
    this.homeAddress._fetch = createFetchMethodForAddresWithType(0);
    this.mailingAddress._fetch = createFetchMethodForAddresWithType(1);
    this.workAddress._fetch = createFetchMethodForAddresWithType(2);
  };

  /**
   * Check whether person is employed.
   *
   * @method isEmployed
   * @return {Boolean}
   */
  Person.prototype.isEmployed = function() {
    return this.employmentStatus() && this.employmentStatus().isEmployed;
  };

  /**
   * Make Person data members consistent with each other and then save.
   *
   * @method makeConsistentAndSave
   * @return {promise}
   */
  Person.prototype.makeConsistentAndSave = function() {
    // If the person is no longer employed then set the employer and occupation to null.
    if (!this.isEmployed()) {
      this.employer(null);
      this.occupation(null);
      this.employerType(null);
    }

    // Add any other measures here...
    //

    return this.save();
  };

  /**
   * Check whether person is a public company insider.
   *
   * @method  isInsider
   * @return {Boolean}
   */
  Person.prototype.isInsider = function() {
    return _.any(this.insiderRoles(), function(role) {
      return role.isInsider();
    });
  };

  /**
   * Check whether person is a public company owner.
   *
   * @method  isOwner
   * @return {Boolean}
   */
  Person.prototype.isOwner = function() {
    return _.any(this.insiderRoles(), function(role) {
      return role.isOwner();
    });
  };

  /**
   * Check whether person is a securities industry insider.
   *
   * @method  isDealer
   * @return {Boolean}
   */
  Person.prototype.isDealer = function() {
    return _.any(this.insiderRoles(), function(role) {
      return role.isDealer();
    });
  };

  /**
   * Instantiate a new insider role record for this person.
   *
   * @method newInsiderRole
   * @param  {Object} attrs Attributes of the new role
   * @return {InsiderRole}
   */
  Person.prototype.newInsiderRole = function(attrs) {
    var role = InsiderRole.new({
      personId: this.id
    });
    role.update(attrs);
    this.insiderRoles.ready();
    return role;
  };

  Person.prototype.citizenshipLabel = function() {
    return this.enumLabel(this.citizenship(), config.types.Country);
  };

  // At the moment we assume a person has only one tax status. This is a convenience method to fetch it.
  Person.prototype.taxStatus = function() {
    return this.taxStatuses()[0];
  };

  // Determine if this person is allowed to have an address outside of Canada.  Normally this is a flat no, but
  // the superadvisor can decide in some cases.  If the person only has a trial account, then the address MUST be in
  // Canada.
  Person.prototype.mustLiveInCanada = function(spouseJointApplicant) {
    var deepAccessorClientCountry = 'homeAddress.country';
    var deepAccessorSpouseCountry = 'spouseRelation.relative.homeAddress.country';
    return this.numActiveAccounts() === 0 &&
      (_.deepAccess(this, deepAccessorClientCountry) !== 'CA' || (this.hasSpouse() && spouseJointApplicant && _.deepAccess(this, deepAccessorSpouseCountry) !== 'CA'));
  };

  Person.prototype.relationshipManagerInfo = function() {
    return $http.get('/api/people/' + this.id + '/relationship_manager_info')
      .then(function(response) {
        return response.data;
      });
  };

  return Person;
}
