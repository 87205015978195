'use strict';

angular.module('controller.modal.advisor-change-client-group', [
    'service.loading-indicator',
    'service.form-helper',
    'directive.input.date-input',
    'directive.staticField',
    'model.Person'
  ])
  .controller('advisorChangeClientGroupModalController', [
    '$scope',
    '$http',
    '$uibModalInstance',
    'formHelper',
    'Person',
    'ram',
    'loadingIndicator',
    'client',
    'currentAdvisor',
    'allAdvisors',
    'pendingAdvisor',
    advisorChangeClientGroupModalCtrl
  ]);

function advisorChangeClientGroupModalCtrl(
  $scope,
  $http,
  $modalInstance,
  formHelper,
  Person,
  ram,
  loadingIndicator,
  client,
  currentAdvisor,
  allAdvisors,
  pendingAdvisor
) {
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // - Helpers
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  function _dataFor(results, key) {
    var data = results && results.status === 200 && results.data ? results.data : null;
    if (data && key) {
      return data[key];
    }
    return data;
  }

  function _asLabelValue(label, value) {
    return {
      label: label,
      value: value
    };
  }

  function _getFormattedDate(date) {
    return moment(date)
      .startOf('day')
      .toDate();
  }

  function _getSortedNames(allAdvisors) {
    return _.chain(_dataFor(allAdvisors))
      .map(function(datum, index) {
        let fullName = datum.firstName + ' ' + datum.lastName;
        let label = fullName + ' - ' + datum.id.toString() + ' - ' + datum.institution;
        return _asLabelValue(label, index);
      })
      .sortBy('label')
      .value();
  }

  function _sendToServer(currentUserId, newGroupUrl, effectiveDate) {
    loadingIndicator.show();
    $scope.error = false;
    $scope.state = 'working';
    $scope.informationMessageForUser = 'Working...';

    return $http.post('/api/advisor/set_new_relationship_advisor', {
        currentUserId: currentUserId,
        newGroupUrl: newGroupUrl,
        effectiveDate: effectiveDate
      })
      .then(function() {
        $scope.informationMessageForUser = 'The change will be applied when the client signs the new IMA.';
        $scope.state = 'done';
        loadingIndicator.hide();
        return true;
      })
      .catch(function() {
        $scope.informationMessageForUser = 'An error occurred.';
        $scope.error = true;
        $scope.state = 'preparing';
        loadingIndicator.hide();
      });
  }
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // - Scope
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  angular.extend($scope, {
    state: 'preparing',
    amount: new ram.Accessor(),
    client: client,
    advisor: new Person(_dataFor(currentAdvisor, 'advisor')),
    pendingAdvisor: pendingAdvisor,
    since: _dataFor(currentAdvisor, 'since'),
    effectiveDate: new ram.Accessor(moment().startOf('day').toDate()),
    allAdvisors: _dataFor(allAdvisors),
    names: _getSortedNames(allAdvisors),
    serviceLevels: [],
    newAdvisor: new ram.Accessor(null),
    newServiceLevel: new ram.Accessor(null),
    changeName: changeName,
    confirm: confirm,
    advisorInfo: advisorInfo,
    pendingAdvisorInfo: pendingAdvisorInfo,
    pendingServiceLevel: pendingServiceLevel
  });

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // - Scope Methods
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  function changeName(form) {
    $scope.serviceLevels = _.map($scope.allAdvisors[$scope.newAdvisor()].serviceLevels, function(datum) {
      return _asLabelValue(datum.name, datum.groupUrl);
    });
    $scope.newServiceLevel(_.first($scope.serviceLevels).value);
    if (form) {
      form.serviceLevel.$invalid = false;
      angular.element('select[name=serviceLevel]').trigger('focus');
    }
  }

  function validate() {
    return $scope.newAdvisor() !== null;
  }

  function confirm(form) {
    var effectiveDate = _getFormattedDate($scope.effectiveDate());
    if (form.$pristine || !formHelper.done(form, validate)) {
      formHelper.notDone($scope, form);
      return;
    }
    _sendToServer($scope.client.id, $scope.newServiceLevel(), effectiveDate);
  }

  function advisorInfo() {
    let institution = _dataFor(currentAdvisor, 'institution');
    let advisorUserId = $scope.advisor.userId();
    let fullName = $scope.advisor.fullName();
    if (!fullName) {
      return '';
    }
    return `${fullName} - ${advisorUserId} - ${institution}`;
  }

  function pendingAdvisorInfo() {
    let institution = _dataFor(pendingAdvisor, 'institution');
    let advisorUserId = _dataFor(pendingAdvisor, 'advisorId');
    let fullName = _dataFor(pendingAdvisor, 'advisorName');

    if (!fullName) {
      return '';
    }
    return `${fullName} - ${advisorUserId} - ${institution}`;
  }

  function pendingServiceLevel() {
    let serviceLevel = _dataFor(pendingAdvisor, 'serviceLevel');

    if (!serviceLevel) {
      return '';
    }
    return `${serviceLevel}`;
  }
}
