'use strict';

function directiveHelperFactory() {
  var _setAttrToScope = function(attrName, $scope, attrs, callback, parseFunc) {
    attrs.$observe(attrName, function() {
      var value = parseFunc(attrs[attrName]);

      $scope[attrName] = value;
      if (callback) {
        callback(value);
      }
    });

    // try to get value from parent if defined
    if (callback) {
      if (attrs[attrName] === undefined) {
        $scope.$watch(attrName, function(value) {
          callback(value);
        });
      }
    }
    if (attrs[attrName]) {
      return parseFunc(attrs[attrName]);
    }
    if ($scope[attrName]) {
      return $scope[attrName];
    }
  };

  var setBoolAttrToScope = function(attrName, $scope, attrs, callback) {
    return _setAttrToScope(attrName, $scope, attrs, callback, function(value) {
      return value === 'true';
    });
  };

  var setAttrToScope = function(attrName, $scope, attrs, callback) {
    return _setAttrToScope(attrName, $scope, attrs, callback, function(value) {
      return value;
    });
  };

  return {
    setAttrToScope: setAttrToScope,
    setBoolAttrToScope: setBoolAttrToScope
  };
}

angular.module('service.directive-helper', [])
  .factory('directiveHelper', [
    directiveHelperFactory
  ]);
