'use strict';

angular
  .module('directive.bank-account-card', [
    'model.BankAccount',
    'service.patterns',
    'service.securityService',
    'filter.masked-text',
    'service.user-notifications'
  ])
  .directive('bankAccountCard', [
    'BankAccount',
    'patterns',
    'securityServiceFactory',
    'userNotifications',
    bankAccountCardDirective
  ]);

function bankAccountCardDirective(BankAccount, patterns, securityServiceFactory, userNotifications) {
  return {
    restrict: 'E',
    scope: {
      bankAccount: '=',
      readOnlyMode: '=',
      hasFccAccount: '='
    },
    templateUrl: 'directives/bank-account-card.html',
    controller: ['$scope', function($scope) {
      var currentUserSecurity = securityServiceFactory.create($scope.$root.currentUser);
      $scope.editModeOn = false;
      $scope.patterns = patterns;
      $scope.formName = 'changeBankNumbersForm' + $scope.$id;
      $scope.pendingBankAccount = BankAccount.new();

      $scope.canResendAddForm = function() {
        return currentUserSecurity.canAccess('bank_accounts') && !$scope.bankAccount.status.is.draft() && $scope.bankAccount.userHasNonTrialCsiAccount();
      };

      $scope.canVerify = function() {
        return currentUserSecurity.canAccess('bank_accounts') &&
          ($scope.bankAccount.status.is.unverified() || $scope.bankAccount.status.is.pendingVerification());
      };

      $scope.canUnverify = function() {
        return currentUserSecurity.canAccess('bank_accounts') &&
          ($scope.bankAccount.status.is.verified() || $scope.bankAccount.status.is.pendingVerification());
      };

      $scope.canDelete = function() {
        return currentUserSecurity.isClient();
      };

      $scope.resendAddForm = function() {
        $scope.$root.advisorModifyBankApprovalModal($scope.bankAccount, 'resend')
          .result
          .then(function() {
            return $scope.bankAccount.resendAddForm();
          });
      };
      $scope.canEdit = function() {
        return currentUserSecurity.canAccess('bank_accounts') && !$scope.bankAccount.status.is.draft();
      };

      $scope.edit = function() {
        $scope.pendingBankAccount.bankCode($scope.bankAccount.bankCode());
        $scope.pendingBankAccount.transit($scope.bankAccount.transit());
        $scope.pendingBankAccount.number($scope.bankAccount.number());
        $scope.pendingBankAccount.bankName($scope.bankAccount.bankName());
        $scope.editModeOn = true;
      };

      $scope.cancel = function() {
        $scope.saveError = false;
        $scope.editModeOn = false;
      };

      $scope.save = function() {
        $scope.saveError = false;
        $scope.isSaving = true;

        $scope.bankAccount.editNumbersAndBankName(
            $scope.pendingBankAccount.bankCode(),
            $scope.pendingBankAccount.transit(),
            $scope.pendingBankAccount.number(),
            $scope.pendingBankAccount.bankName()
          )
          .then(function() {
            $scope.editModeOn = false;
          })
          .catch(function() {
            $scope.saveError = true;
          })
          .finally(function() {
            $scope.isSaving = false;
          });
      };

      $scope.delete = function() {
        var modalTitle = 'Remove Account Details';
        var modalBody = $scope.bankAccount.transferInstructions()
          ? 'This account is linked to a recurring transaction. If you delete this bank account, you must set up a new recurring EFT to ensure the transactions continue.<br><br>Are you sure you want to delete this bank account?'
          : 'Are you sure you want to delete this bank account?';

        $scope.$root.genericConfirmationModal(modalTitle, modalBody, 'btn-danger', 'Delete bank account', 'Cancel')
          .result
          .then(function() {
            return $scope.bankAccount.delete();
          })
          .then(function() {
            userNotifications.showSuccess('You have successfully removed a bank account.');
            var bankAccountIndex = $scope.$parent.bankAccounts.findIndex(function(bankAccount) {
              return bankAccount.id === $scope.bankAccount.id;
            });
            if (bankAccountIndex !== -1) {
              $scope.$parent.bankAccounts.splice(bankAccountIndex, 1);
              $scope.$parent.updateTotalBankAccountsCount($scope.$parent.totalBankAccountsCount - 1);
            }
          });
      };

      $scope.title = function() {
        if ($scope.bankAccount.status.is.draft()) {
          return '';
        }
        return $scope.bankAccount.descriptiveLabel();
      };

      $scope.unverify = function() {
        $scope.$root.advisorModifyBankApprovalModal($scope.bankAccount, 'unverify')
          .result
          .then(function() {
            return $scope.bankAccount.unverify();
          });
      };

      $scope.verify = function() {
        $scope.$root.advisorModifyBankApprovalModal($scope.bankAccount, 'verify')
          .result
          .then(function() {
            return $scope.bankAccount.verify();
          });
      };
    }]
  };
}
