'use strict';

function modalTransferController($scope, transferInstruction, transferDisplayData) {
  $scope.transferInstruction = transferInstruction;
  $scope.transferDisplayData = transferDisplayData;

  $scope.setBigModal = function() {
    if ($scope.confirmMessage) {
      angular.element('.modal:not(.menu)').addClass('big-dialog-disable-flexbox');
    }
  };
}

angular.module('controller.modal.transfer', [])
  .controller('modalTransferCtrl', [
    '$scope',
    'transferInstruction',
    'transferDisplayData',
    modalTransferController
  ]);
