'use strict';

function legalInfoFactory(ram, config) {
  var LegalInfo = new ram.Collection('LegalInfo', {
    belongsTo: {
      person: 'Person'
    },
    resources: {
      default: new ram.resources.Http('/api/legal_infos/:id.json'),
      cookie: new ram.resources.Cookie('legal_infos')
    },
    schema: config.schemas.LegalInfo
  });

  return LegalInfo;
}

angular.module('model.LegalInfo', [
    'config',
    'ram'
  ])
  .factory('LegalInfo', [
    'ram',
    'config',
    legalInfoFactory
  ]);
