'use strict';

function controller($rootScope, $timeout, $state) {

  $timeout(function() {
    $state.go($state.params.redirect, {}, {
        notify: false
      })
      .then(function() {
        $rootScope.$broadcast('$stateChangeSuccess');
      });
  }, $state.params.after);

}

angular.module('controller.optimizing', [
    'directive.fancyLoad',
    'directive.interview.navbar'
  ])
  .controller('OptimizingCtrl', [
    '$rootScope',
    '$timeout',
    '$state',
    controller
  ]);
