'use strict';

function checkboxDirective() {
  return {
    restrict: 'E',
    scope: {
      ngModel: '=',
      ngChange: '&',
      form: '=',
      help: '@',
      focusHelp: '@',
      title: '@',
      label: '@',
      name: '@',
      class: '@',
      checkboxClass: '@',
      inputClass: '@',
      labelClass: '@',
      helpClass: '@'
    },
    templateUrl: 'directives/input/checkbox.html'
  };
}

angular.module('directive.input.checkbox', [
    'templates',
    'animations',
    'directive.focus'
  ])
  .directive('checkbox', [
    checkboxDirective
  ]);
