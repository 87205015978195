import template from './model-portfolio-pie-chart.html';
import controller from './model-portfolio-pie-chart.controller';

const modelPortfolioPieChartComponent = {
  template,
  bindings: {
    allocationData: '<'
  },
  controller: ['$scope', controller],
  controllerAs: '$ctrl'
};

export default modelPortfolioPieChartComponent;
