'use strict';

function directive() {
  return {
    replace: true,
    restrict: 'E',
    scope: true,
    template: '<input type="radio" name="{{inputName}}" ng-model-options="{getterSetter: true}" ng-value="true">',
    link: function($scope, element) {
      // this is required for having a nice radio style
      element.after('<span class="radio-dot outer"><span class="inner"></span></span>');
    }
  };
}

angular.module('directive.input.radio-input', [])
  .directive('radioInput', [
    directive
  ]);
