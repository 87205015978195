'use strict';

class HoldingsDataGenerator {
  constructor(investmentChartsAllocationOrPositionColourMapperAndSorter) {
    this.investmentChartsAllocationOrPositionColourMapperAndSorter = investmentChartsAllocationOrPositionColourMapperAndSorter;
  }

  getAggregateData(positions) {
    positions = this._generateAggregatedPositions(positions);
    positions = this.investmentChartsAllocationOrPositionColourMapperAndSorter.getColourMappedAndSortedPositions(positions);

    return positions;
  }

  getNonAggregateData(positions) {
    return this.investmentChartsAllocationOrPositionColourMapperAndSorter.getColourMappedAndSortedPositions(positions);
  }

  _snakelize(str) {
    return str.replace(/([A-Z])/g, (a) => {
      return `_${a.toLowerCase()}`;
    });
  }

  _aggregateMarketValue(positions) {
    var reducer = function(acc, position) {
      return acc + parseFloat(position.marketValue());
    };
    return _.reduce(positions, reducer, 0);
  }

  _getInvestmentsByType(investmentsGroup, investmentsType) {
    const investments = investmentsGroup.find(investments => investments.type === investmentsType);

    return {
      investmentsList: investments?.investments,
      date: investments[this._snakelize('lastUpdatedAt')]
    };
  }

  _investments(fundCusip) {
    if (fundCusip === 'GCG496') { return this._getInvestmentsByType(window.config.fundInvestments.incomeInvestments, 'core'); }
    if (fundCusip === 'GCG495') { return this._getInvestmentsByType(window.config.fundInvestments.equityInvestments, 'core'); }
    if (fundCusip === 'GCG409') { return this._getInvestmentsByType(window.config.fundInvestments.incomeInvestments, 'esg'); }
    if (fundCusip === 'GCG404') { return this._getInvestmentsByType(window.config.fundInvestments.equityInvestments, 'esg'); }

    return null;
  }

  _hasInvestments(investments) {

    return Boolean(investments);
  }

  _aggregatePositionsOfSameFund(primitivePositions) {
    var position = {};
    var fund = {};
    var primitiveFund = primitivePositions[0].fund();
    position._marketValue = this._aggregateMarketValue(primitivePositions);
    position.marketValue = (function() {
      return this._marketValue;
    }).bind(position); // use bind to fix the lexical scope of 'this'
    fund.label = primitiveFund.label;
    fund.color = primitiveFund.color;
    fund.assetClassId = primitiveFund.assetClassId;
    position.fundId = primitiveFund.id;
    position.fundSymbol = primitiveFund.symbol();
    position.assetTypeId = primitiveFund.assetTypeId();
    position.investments = this._investments(primitiveFund.cusip());
    position.hasInvestments = this._hasInvestments(position.investments);
    position.color = fund.color;
    position.fundLabel = fund.label();
    position.label = position.fundLabel;
    position._fund = fund;
    position.fund = (function() {
      return this._fund;
    }).bind(position);
    return position;
  }

  _generateAggregatedPositions(positions) {
    return _.chain(positions)
      .groupBy(function(position) {
        return position.fundId();
      })
      .map(this._aggregatePositionsOfSameFund.bind(this))
      .value();
  }
}

HoldingsDataGenerator.$inject = [
  'investmentChartsAllocationOrPositionColourMapperAndSorter'
];

export default HoldingsDataGenerator;
