'use strict';

angular.module('controller.profile.risk', [
    'service.loading-indicator',
    'service.modals',
    'directive.user.risk-appetite'
  ])
  .controller('ProfileRiskCtrl', [
    '$scope',
    '$q',
    '$http',
    'config',
    'loadingIndicator',
    'modals',
    profileRiskController
  ]);

function initRiskForm($scope, $q, $http) {
  var currentUser = $scope.currentUser;
  var oldInvestorType = currentUser.investorType().label;

  function showConfirmationOnSave() {
    return $scope.confirmRiskAppetiteChangeModal(currentUser, oldInvestorType)
      .result
      .then(function(changedAccounts) {
        return $scope.completedRiskAppetiteQuiz.save()
          .then(function() {
            return currentUser.save();
          })
          .then(function() {
            oldInvestorType = currentUser.investorType().label;
            return $q.all(_.map(changedAccounts, function(changedAccount) {
              var changedGoal = changedAccount.account.accountGoal().goal();
              var path = ['', 'api', 'goals', changedGoal.id, 'update_risk_recommendation'].join('/');
              return $http.put(path);
            }));
          });
      });
  }

  return {
    save: function() {
      if (currentUser.riskAppetite.changed()) {
        if ($scope.kycUpdate) {
          // we don't want to update goal risk recommendation yet, kyc has additional step (account-settings) for that
          return $scope.completedRiskAppetiteQuiz.save()
            .then(function() {
              return currentUser.save();
            });
        } else {
          return showConfirmationOnSave();
        }
      } else if ($scope.completedRiskAppetiteQuiz) {
        return $scope.completedRiskAppetiteQuiz.save()
          .then(function() {
            return currentUser.save();
          });
      } else {
        return $q.resolve(null);
      }
    },
    reset: function() {
      $scope.completedRiskAppetiteQuiz = undefined;
      return currentUser.fetch(true);
    },
    isDirty: function() {
      return $scope.completedRiskAppetiteQuiz;
    }
  };
}

function profileRiskController($scope, $q, $http, config, loadingIndicator, modals) {
  var formRisk = initRiskForm($scope, $q, $http);


  /* jshint validthis: true */
  this.openAndHandleAppetiteModal = function() {
    modals.appetiteModal($scope.currentUser, {
        autoSave: false
      })
      .result
      .then(function(completedRiskAppetiteQuiz) {
        loadingIndicator.show();
        $scope.completedRiskAppetiteQuiz = completedRiskAppetiteQuiz;
        return completedRiskAppetiteQuiz.calculateRiskAppetiteTypeId();
      })
      .then(function(calculatedRiskAppetiteTypeId) {
        $scope.calculatedRiskAppetiteType = config.types.RiskAppetiteType[calculatedRiskAppetiteTypeId];
        $scope.currentUser.riskAppetite($scope.calculatedRiskAppetiteType.value);
      })
      .finally(function() {
        loadingIndicator.hide();
      });
  };

  this.save = formRisk.save;
  this.reset = formRisk.reset;
  this.isDirty = formRisk.isDirty;
}
