'use strict';

angular.module('controller.advise.create-external-advisor', [
    'directive.input.phone-number-input',
    'directive.input.full-name',
    'directive.input.email',
    'model.PhoneNumber',
    'model.Address',
    'service.user-notifications',
  ])
  .controller('AdviseCreateExternalAdvisorCtrl', [
    '$scope',
    '$http',
    '$timeout',
    '$document',
    'ram',
    'config',
    'userNotifications',
    'csrfToken',
    'PhoneNumber',
    'Address',
    adviseCreateExternalAdvisorCtrl
  ]);


function adviseCreateExternalAdvisorCtrl($scope, $http, $timeout, $document, ram, config, userNotifications, csrfToken, PhoneNumber, Address) {
  const PHONE_TYPE_BUSINESS = config.types.PhoneNumber.findByName('Business').id;
  const LANDING_PAGE_BASE = 'https://get.modernadvisor.ca/';

  $scope.firstName = new ram.Accessor();
  $scope.lastName = new ram.Accessor();
  $scope.phoneNumber = new PhoneNumber();
  $scope.address = new Address();
  $scope.organizationName = new ram.Accessor();
  $scope.organizationShortName = new ram.Accessor();
  $scope.email = new ram.Accessor();
  $scope.landingPage = new ram.Accessor();
  $scope.serviceLevels = [{
    code: new ram.Accessor(),
    fee: new ram.Accessor()
  }, {
    code: new ram.Accessor(),
    fee: new ram.Accessor()
  }, {
    code: new ram.Accessor(),
    fee: new ram.Accessor()
  }, {
    code: new ram.Accessor(),
    fee: new ram.Accessor()
  }];
  $scope.externalAdvisorServices = {}; // Uses an object to get around AngularJS behaviour.
  $scope.coWorkerName = new ram.Accessor();
  $scope.urlBase = new ram.Accessor();
  $scope.urlComponent = new ram.Accessor();
  $scope.feeScheduleLabelPrefix = new ram.Accessor();
  $scope.notes = {}; // Uses an object to get around AngularJS behaviour.
  $scope.imaName = new ram.Accessor();
  $scope.createNew = new ram.Accessor(true);
  $scope.addToExisting = new ram.Accessor(false);
  $scope.advisorOptions = [];
  $scope.searchName = new ram.Accessor();
  $scope.advisorToCopy = new ram.Accessor();
  var fileInput = $document[0].getElementById('select-file');
  _resetForm();

  /*
   * Create constructs that the scope expects.  Used with our standard controls for phone number, person
   */
  $scope.person = {
    firstName: $scope.firstName,
    middleName: angular.noop,
    lastName: $scope.lastName,
  };
  $scope.onPhoneNumberChange = angular.noop; // ignore this part of the widget

  /*
   * Methods
   */
  $scope.doneAction = function(form) {
    $scope.feeScheduleLabelPrefix($scope.organizationShortName() && $scope.organizationShortName().length > 0 ?
      $scope.organizationShortName() : $scope.urlComponent()); // They are the same.

    if ($scope.organizationName() && !$scope.organizationShortName()) {
      $scope.organizationShortName($scope.organizationName());
    }

    if ($scope.advisorToCopy()) {
      $scope.coWorkerName($scope.advisorToCopy().payload.advisor);
    }
    if (!_validateData()) {
      return;
    }

    _send(form);
  };

  $scope.resetNotification = function() {
    userNotifications.clearNotification();
  };

  $scope.resetForm = function(form) {
    if ($scope.createNew()) {
      _resetForm(form);
    } else {
      _resetForm(form);
      _resetFormForAddToExisting(form);
      $scope.advisorOptions = [];
      $scope.searchName(null);
      $scope.advisorToCopy(null);
    }
  };

  $scope.slugFor = function(code) {
    const retVal = [];
    if ($scope.urlBase()) {
      retVal.push($scope.urlBase());
    }
    retVal.push($scope.urlComponent());
    retVal.push(code);
    return retVal.join('-');
  };

  $scope.pickCreateNew = function(selected) {
    if (selected) {
      $scope.createNew(true);
      $scope.addToExisting(false);
      $scope.advisorOptions = [];
      $scope.searchName(null);
      $scope.advisorToCopy(null);
      _resetForm();
    } else {
      $scope.createNew(false);
      $scope.addToExisting(true);
      _resetFormForAddToExisting();
    }
  };

  $scope.searchAction = function() {
    _search($scope.searchName());
  };

  $scope.changeAdvisorToCopy = function() {
    const data = $scope.advisorToCopy();
    $scope.organizationName(data.payload.institution);
    $scope.organizationShortName(data.payload.institutionShortName);
    $scope.urlBase(data.payload.urlBase);
  };

  $scope.selectFile = function() {
    fileInput.click();
  };

  /*
   * Watchers
   */
  $scope.$watch('organizationName()', (orgName) => {
    if (orgName && !$scope.organizationShortName()) {
      $scope.organizationShortName(orgName);
    }
    if (orgName && !$scope.urlBase()) {
      $scope.urlBase(_createOrgUrlSlug(orgName));
    }
    if (!orgName && $scope.urlBase()) {
      $scope.urlBase(null);
    }
  });

  $scope.$watch('[firstName(), lastName()]', (values) => {
    if (values[0] && values[1]) {
      $scope.urlComponent(_createAdvisorUrlSlug($scope.firstName(), $scope.lastName()));
    }
  });

  $scope.cobrandLogoImageChanged = function() {
    $scope.logoPreviewExists = true;

    $scope.logoName = fileInput.files[0].name;
    _showPreviewImage(fileInput);
  };

  /*
   * Helpers
   */
  function _createOrgUrlSlug(orgName) {
    const parts = orgName.split(' ');
    return parts[0].substr(0, 10).toLowerCase();
  }

  function _createAdvisorUrlSlug(firstName, lastName) {
    return `${firstName.toLowerCase().substr(0, 1)}${lastName.toLowerCase()}`;
  }

  function _validateData() {
    if ($scope.landingPage() !== '' && $scope.landingPage() === LANDING_PAGE_BASE) {
      window.scrollTo(0, 0);
      $timeout(() => userNotifications.showError(`Please enter a valid landing page.`));
      return false;
    }
    return true;
  }

  function _send(form) {
    var formData, request;

    formData = _generateFormData();
    request = new XMLHttpRequest();
    request.open('POST', '/api/super_advisor/new_external_advisor_details.json');
    request.setRequestHeader('X-CSRF-Token', csrfToken());

    request.onload = function() {
      var result = JSON.parse(request.response);
      if (this.status === 200) {
        userNotifications.showSuccess(`File "${result.name}" saved to S3.`);
        $timeout(() => $scope.resetForm(form), 250);
      } else {
        userNotifications.showError(result.message);
      }
      window.scrollTo(0, 0);
    };
    request.onerror = function() {
      window.scrollTo(0, 0);
      userNotifications.showError(`There was a problem saving the data.`);
    };

    request.send(formData);
  }

  function _serviceLevelJsonMapper(serviceLevel) {
    return {
      code: serviceLevel.code(),
      fee: serviceLevel.fee()
    };
  }

  function _resetForm(form) {
    $scope.firstName('');
    $scope.lastName('');
    $scope.phoneNumber.full('');
    $scope.phoneNumber.extension('');
    $scope.phoneNumber.typeId(PHONE_TYPE_BUSINESS);
    $scope.address.locality('');
    $scope.address.region('');
    $scope.address.postalCode('');
    $scope.address.streetAddress('');
    $scope.address.unit('');
    $scope.address.country('CA');
    $scope.address.sameAsId(null);
    $scope.organizationName('');
    $scope.organizationShortName('');
    $scope.email('');
    $scope.landingPage(LANDING_PAGE_BASE);
    $scope.coWorkerName('');
    $scope.urlBase('');
    $scope.urlComponent('');
    $scope.feeScheduleLabelPrefix('');
    $scope.imaName('');
    _resetServiceLevels([
      ['a', '1.00'],
      ['b', '0.75'],
      ['c', '0.50'],
      ['d', '0.25']
    ]);
    $scope.externalAdvisorServices = {
      text: `Identifying your short, medium, and long-term financial goals
Acting as a resource with regards to your financial plan
Reviews of overall wealth strategies
Tax planning
Assisting with the opening of your ModernAdvisor account(s)
`
    };
    $scope.notes = {
      toggle: false,
      text: ''
    }; // Uses an object to get around AngularJS behaviour.

    $scope.logoName = '';
    $scope.logoPreviewExists = false;
    fileInput.value = '';
    document.getElementById('preview').src = '';

    if (form) {
      form.$setPristine();
      form.$setUntouched();
    }
  }

  function _resetFormForAddToExisting(form) {
    $scope.organizationName('');
    $scope.organizationShortName('');
    $scope.urlBase('');
    $scope.feeScheduleLabelPrefix('');
    $scope.landingPage('');
    _resetServiceLevels([
      ['', ''],
      ['', ''],
      ['', ''],
      ['', '']
    ]);
    $scope.externalAdvisorServices = {
      text: ''
    };
    if (form) {
      form.$setPristine();
      form.$setUntouched();
    }
  }

  function _resetServiceLevels(input) {
    input.forEach(([code, fee], index) => {
      $scope.serviceLevels[index].code(code);
      $scope.serviceLevels[index].fee(fee);
    });

  }

  function _search(name) {
    $http.get(`/api/super_advisor/new_external_advisor_details/advisor_search.json?name=${name}`)
      .then(result => {
        _setSearchOnScreen(result.data);
      })
      .catch(() => console.log('Error'));
  }

  function _setSearchOnScreen(data) {
    $scope.advisorOptions = data.map(item => {
      return {
        label: `${item.institution} - ${item.advisor}`,
        payload: item,
      };
    });
  }

  function _showPreviewImage(obj) {
    var fileReader = new FileReader();
    fileReader.onload = function() {
      document.getElementById('preview').src = fileReader.result;
    };
    fileReader.readAsDataURL(obj.files[0]);
  }

  function _generateFormData() {
    /* jshint camelcase: false */

    var formData = new FormData();

    if (fileInput.files[0]) {
      formData.append(
        'file',
        fileInput.files[0]
      );
    }

    var params = {
      logo_name: $scope.logoName,
      ima_name: $scope.imaName(),
      organization_name: $scope.organizationName(),
      organization_short_name: $scope.organizationShortName(),
      url_base: $scope.urlBase(),
      first_name: $scope.firstName(),
      last_name: $scope.lastName(),
      url_component: $scope.urlComponent(),
      phone_number: $scope.phoneNumber.full(),
      extension: $scope.phoneNumber.extension(),
      email: $scope.email(),
      co_worker_name: $scope.coWorkerName(),
      fee_schedule_label_prefix: $scope.feeScheduleLabelPrefix(),
      landing_page: $scope.landingPage(),
      external_advisor_services: $scope.externalAdvisorServices.text,
      notes: $scope.notes.text,
      address: JSON.stringify($scope.address.toJSON()),
      service_levels: JSON.stringify($scope.serviceLevels.map(_serviceLevelJsonMapper)),
    };

    for (var key in params) {
      formData.append(
        key, params[key]
      );
    }

    return formData;
  }

  /* for debugging
  function _print() {
    const data = new Map();
    data.set('organizationName', {});
    data.set('organizationShortName', {});
    data.set('urlBase', {});
    data.set('firstName', {});
    data.set('lastName', {});
    data.set('urlComponent', {});
    data.set('phoneNumber', {
      method: 'full'
    });
    data.set('address', {
      method: 'full'
    });
    data.set('email', {});
    data.set('coWorkerName', {});
    data.set('feeScheduleLabelPrefix', {});
    data.set('landingPage', {});

    for (const [key, meta] of data.entries()) {
      if (meta.method) {
        console.log(`${key} = ${$scope[key][meta.method]()}`);
      } else {
        console.log(`${key} = ${$scope[key]()}`);
      }
    }

    $scope.serviceLevels.forEach(sl => console.log(`SL = ${sl.code()}, ${sl.fee()}`));
    console.log($scope.externalAdvisorServices);
    console.log($scope.notes.text);
  }
  */
}
