'use strict';

function dropdownOptionsFactory() {

  var yesOrNo = [{
    label: 'Yes',
    value: true
  }, {
    label: 'No',
    value: false
  }];

  var residencies = [{
    label: 'Canada',
    value: 'CA'
  }, {
    label: 'Other',
    value: 'null'
  }];

  return {
    yesOrNo: function() {
      return yesOrNo;
    },
    residencies: function() {
      return residencies;
    }
  };
}
angular.module('service.dropdown-options', [])
  .factory('dropdownOptions', [
    dropdownOptionsFactory
  ]);
