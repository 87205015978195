'use strict';

angular.module('controller.advise.transfer-instruction', [
    'service.loading-indicator'
  ])
  .controller('AdviseTransferInstructionCtrl', [
    '$scope',
    'loadingIndicator',
    'transferInstruction',
    adviseTransferInstructionController
  ]);

function adviseTransferInstructionController($scope, loadingIndicator, transferInstruction) {
  $scope.transferInstruction = transferInstruction;
  $scope.transferRequests = _.sortBy($scope.transferInstruction.transferRequests(), function(transferRequest) {
    return transferRequest.dateToBeProcessed();
  }).reverse();

  $scope.fail = function(transferRequest) {
    var modalTitle = 'Fail Transfer Instance?';
    var modalBody = 'Are you sure you want to fail this transfer instance?';

    $scope.genericConfirmationModal(modalTitle, modalBody, 'btn-danger')
      .result
      .then(function() {
        loadingIndicator.show();
        return transferRequest.forceFail();
      })
      .finally(function() {
        loadingIndicator.hide();
      });
  };

  $scope.complete = function(transferRequest) {
    var modalTitle = 'Complete Transfer Instance?';
    var modalBody = 'Are you sure you want to complete this transfer instance?';

    $scope.genericConfirmationModal(modalTitle, modalBody, 'btn-success')
      .result
      .then(function() {
        loadingIndicator.show();
        return transferRequest.forceComplete();
      })
      .finally(function() {
        loadingIndicator.hide();
      });
  };
}
