'use strict';

function insiderRoleFormDirective(ram, InsiderRole, PersonalRelation) {
  return {
    restrict: 'E',
    templateUrl: 'directives/insider-role-form.html',
    controller: ['$scope', function($scope) {

      angular.extend($scope, {
        newType: new ram.Accessor(),
        newCompany: new ram.Accessor(),
        newTicker: new ram.Accessor(),
        selectedRelation: new ram.Accessor($scope.selfRelation),
        newFirstName: new ram.Accessor(),
        newLastName: new ram.Accessor(),
        newRelationType: new ram.Accessor()
      });

      var newRelation = PersonalRelation.new({
        relative: {
          firstName: 'other...'
        }
      });

      $scope.$watchCollection('relations', function(relations) {
        $scope.allRelations = _.flatten([$scope.selfRelation, relations, newRelation]);
      });

      $scope.filterRoles = function(person) {
        return _.filter(person.insiderRoles(), $scope.roleFilter);
      };

      function relationCreated(relation) {
        $scope.selectedRelation(relation);
        $scope.clearRelation();
        $scope.syncRelations();
      }

      $scope.createRelation = function(firstName, lastName, relationType) {
        if (!firstName || !lastName || !relationType) {
          return;
        }
        PersonalRelation.create({
            personId: $scope.person.id,
            relative: {
              firstName: firstName,
              lastName: lastName
            },
            typeId: relationType.id
          })
          .then(relationCreated);
      };

      $scope.clearRelation = function() {
        $scope.newFirstName('');
        $scope.newLastName('');
      };

      $scope.createRole = function(relative) {
        var type = $scope.newType();
        var company = $scope.newCompany();
        var ticker = $scope.newTicker();
        if (!type || !company || !ticker) {
          return;
        }
        var role = InsiderRole.new({
          company: company,
          personId: relative.id,
          ticker: ticker
        });
        role.type(type);
        role.save()
          .then(function() {
            relative.insiderRoles.ready();
            $scope.clearRole();
          });
      };

      $scope.clearRole = function() {
        $scope.newCompany('');
        $scope.newTicker('');
      };

    }]
  };
}

angular.module('directive.insider-role-form', [
    'ram'
  ])
  .directive('insiderRoleForm', [
    'ram',
    'InsiderRole',
    'PersonalRelation',
    insiderRoleFormDirective
  ]);
