'use strict';

angular.module('controller.account.registration', [
    'Devise',
    'model.User',
    'filter.string',
    'ram',
    'service.passwordChecker',
    'service.loading-indicator'
  ])
  .controller('accountRegistrationCtrl', [
    '$scope',
    '$state',
    'Auth',
    'User',
    'ram',
    '$http',
    '$rootScope',
    '$analytics',
    'passwordChecker',
    'mode',
    'loadingIndicator',
    accountRegistrationController
  ]);

function accountRegistrationController($scope, $state, Auth, User, ram, $http, $rootScope, $analytics, passwordChecker, mode, loadingIndicator) {

  $scope.user = User.new({
    person: {}
  });
  $scope.confirm = new ram.Accessor();
  $scope.source = new ram.Accessor();
  $scope.errors = '';

  $scope.$watch('user.password()', function(password) {
    $scope.confirmPattern = passwordChecker.checkPasswordPattern(password);
  });

  var useTrialAccount = true;
  if (mode && mode.accountType === 'full-account') {
    useTrialAccount = false;
  }

  var trackRegistration = function(user) {
    $analytics.setUserProperties({
      name: user.person().firstName() + ' ' + user.person().lastName(),
      email: $scope.user.email(),
      'user_id': $scope.user.id,
      trial: useTrialAccount
    });
    $analytics.setUsername($scope.user.email());
    $analytics.eventTrack('registered', {
      category: 'signup',
      label: useTrialAccount ? 'trial' : 'regular'
    });
  };

  $scope.register = function(isModal) {
    $scope.user.trialAccount(useTrialAccount);
    $scope.showLoading = true;
    loadingIndicator.show();
    $scope.user.save()
      .then(function() {
        loadingIndicator.hide();
        $scope.showLoading = false;
        $scope.error = '';
        $scope.email = $scope.user.email();
        trackRegistration($scope.user);
        $state.go('/investment/goal');
        if (isModal) {
          $scope.$close();
        }
      }, function(result) {
        loadingIndicator.hide();
        $scope.showLoading = false;
        $scope.errors = result.data.errors;
      });
  };
}
