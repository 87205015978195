'use strict';

angular.module('routes.advise.add-client', [
    'controller.advise.add-client',
    'templates',
    'ui.router'
  ])
  .config([
    '$stateProvider',
    configRoutes
  ]);

function configRoutes($stateProvider) {

  function resolveRedirectToState(redirect, resolveAdvisorDetails) {
    if (resolveAdvisorDetails.multiServiceLevels) {
      redirect.to('advise.add-client.service-level').always();
    } else {
      redirect.to('advise.add-client.add-advisors').always();
    }
  }

  function resolveAdvisorDetails($http) {
    return $http.get('/api/advisor/advisor_details.json')
      .then(function(response) {
        if (response.data) {
          return response.data;
        }
      });
  }

  $stateProvider
    .state('advise.add-client', {
      url: '/add-client',
      controller: 'AdviseAddClientCtrl',
      templateUrl: 'views/advise/add-client.html',
      resolve: {
        advisorDetails: ['$http', resolveAdvisorDetails],
        redirectToDefault: ['redirect', 'advisorDetails', resolveRedirectToState]
      }
    })
    .state('advise.add-client.service-level', {
      url: '/service-level',
      controller: 'AddClientServiceLevelCtrl',
      resolve: {},
      templateUrl: 'views/advise/add-client/service-level.html'
    })
    .state('advise.add-client.add-advisors', {
      url: '/relationship-managers',
      controller: 'AddClientAddAdvisorsCtrl',
      resolve: {},
      templateUrl: 'views/advise/add-client/add-advisors.html'
    });
}
