'use strict';

function countdownDirective(Util, $interval) {
  return {
    restrict: 'A',
    scope: {
      date: '@',
      countdownModClass: '@',
      labelModClass: '@',
      timerModClass: '@'
    },
    templateUrl: 'directives/countdown.html',
    link: function(scope) {
      var future;
      future = new Date(scope.date);
      $interval(function() {
        var diff;
        diff = Math.floor((future.getTime() - new Date().getTime()) / 1000);
        scope.dhms = Util.dhms(diff);
      }, 1000);
    }
  };
}

angular.module('directive.countdown', [])
  .directive('countdown', [
    'Util',
    '$interval',
    countdownDirective
  ]).factory('Util', [function() {
    return {
      dhms: function(t) {
        var days, hours, minutes, seconds;
        days = Math.floor(t / 86400);
        t -= days * 86400;
        hours = Math.floor(t / 3600) % 24;
        t -= hours * 3600;
        minutes = Math.floor(t / 60) % 60;
        t -= minutes * 60;
        seconds = t % 60;
        return {
          days: days,
          hours: hours,
          minutes: minutes,
          seconds: seconds
        };
      }
    };
  }]);
