'use strict';

function previewInvestmentsController($scope, goal) {
  $scope.investmentPoint = {};
  $scope.marketValue = function(d) {
    return d && d.weight() * goal.initial();
  };
}

angular.module('controller.preview.investments', [])
  .controller('PreviewInvestmentsController', [
    '$scope',
    'storedGoal',
    previewInvestmentsController
  ]);
