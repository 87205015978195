'use strict';

angular.module('service.goal-service', [
    'model.ModelPortfolio'
  ])
  .factory('goalService', [
    '$http',
    'ModelPortfolio',
    goalService
  ]);

function goalService($http, ModelPortfolio) {
  var calculateRiskRecommendation = function(user, goal) {
    var request = {
      financialAssets: user.financialAssets(),
      income: user.income(),
      initial: goal.initial(),
      nonFinancialAssets: user.nonFinancialAssets(),
      ongoing: goal.ongoing(),
      riskAppetite: user.riskAppetite(),
      timeframe: goal.timeframe(),
      totalLiabilities: user.totalLiabilities()
    };

    return $http.get('api/goals/new/risk.json', {
        params: _.underscoreKeys(request)
      })
      .then(function(response) {
        return response.data;
      });
  };

  var fetchModelPortfolio = function(goal, account) {
    var query = {
      risk: goal.risk(),
      taxable: !account.type().registered,
      portfolioOptionId: account.portfolioOptionId()
    };

    var portfolio = null;
    return ModelPortfolio.find(query)
      .then(function(modelPortfolio) {
        portfolio = modelPortfolio;
        return portfolio.allocations.ready();
      })
      .then(function() {
        return portfolio;
      });
  };

  return {
    calculateRiskRecommendation: calculateRiskRecommendation,
    fetchModelPortfolio: fetchModelPortfolio
  };
}
