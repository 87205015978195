'use strict';

function mobileDocumentCardDirective() {
  return {
    restrict: 'E',
    scope: {
      docType: '=',
      accountInfo: '=',
      pendingSignature: '=',
      url: '='
    },
    templateUrl: 'directives/mobile-document-card.html'
  };
}

function mobileActivityCardDirective() {
  return {
    restrict: 'E',
    scope: {
      accountInfo: '=',
      activityType: '=',
      description: '='
    },
    templateUrl: 'directives/mobile-activity-card.html'
  };
}

function mobileTransferCardDirective() {
  return {
    restrict: 'E',
    scope: {
      transferInstruction: '=',
      transferStatus: '=',
      amount: '=',
      fromAccount: '=',
      toAccount: '=',
      deleteCallback: '='
    },
    templateUrl: 'directives/mobile-transfer-card.html',
    link: function(scope) {
      scope.accountInfo = scope.fromAccount + ' to ' + scope.toAccount;
    }
  };
}

function mobileReferralCardDirective() {
  return {
    restrict: 'E',
    scope: {
      referral: '='
    },
    templateUrl: 'directives/mobile-referral-card.html'
  };
}

function mobileEmptyCardDirective() {
  return {
    restrict: 'E',
    scope: false,
    template: '<div class="mobile-list-card"> <p class="mobile-list-card__banner-message">{{bannerMessage}}</p> </div>',
    link: function(scope, element, attrs) {
      scope.bannerMessage = attrs.bannerMessage;
    }
  };
}

function mobileHouseholdInvitationCardDirective() {
  return {
    restrict: 'E',
    scope: {
      householdInvitation: '=',
      accessText: '&',
      acceptInvitation: '=',
      declineInvitation: '='
    },
    templateUrl: 'directives/mobile-household-invitation-card.html'
  };
}

angular.module('directive.mobile-list-cards', [])
  .directive('mobileDocumentCard', [
    mobileDocumentCardDirective
  ])
  .directive('mobileActivityCard', [
    mobileActivityCardDirective
  ])
  .directive('mobileTransferCard', [
    mobileTransferCardDirective
  ])
  .directive('mobileReferralCard', [
    mobileReferralCardDirective
  ])
  .directive('mobileEmptyCard', [
    mobileEmptyCardDirective
  ])
  .directive('mobileHouseholdInvitationCard', [
    mobileHouseholdInvitationCardDirective
  ]);
