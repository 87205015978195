'use strict';

function householdFactory(ram, config, $http) {

  var Household = new ram.Collection('Household', {
    hasMany: {
      householdMemberships: 'HouseholdMembership',
      householdInvitations: 'HouseholdInvitation',
      pendingInvitations: 'HouseholdInvitation'
    },
    schema: config.schemas.Household,
    resources: {
      default: new ram.resources.Http('/api/households/:id.json'),
      cookie: new ram.resources.Cookie('households')
    }
  });

  Household.prototype.inviteUser = function(email, relationshipId) {
    email = email.toLowerCase();
    return $http.post('/api/households/' + this.id + '/invite', {
      householdInvitation: {
        inviteeEmail: email,
        relationshipId: relationshipId
      }
    });
  };

  return Household;
}

angular.module('model.Household', [
    'ram',
    'config',
    'model.HouseholdMembership',
    'model.HouseholdInvitation'
  ])
  .factory('Household', [
    'ram',
    'config',
    '$http',
    householdFactory
  ]);
