'use strict';

function kycUpdateFactory(ram, config) {

  /**
   * Constructor for KycUpdate models.
   * @class
   */
  var KycUpdate = new ram.Collection('KycUpdate', {
    belongsTo: {
      user: 'User'
    },
    schema: config.schemas.KycUpdate,
    resources: {
      default: new ram.resources.Http('/api/kyc_updates/:id.json'),
      cookie: new ram.resources.Cookie('kyc_updates')
    }
  });

  return KycUpdate;
}

angular.module('model.KycUpdate', [
    'ram',
    'config',
    'model.User'
  ])
  .factory('KycUpdate', [
    'ram',
    'config',
    kycUpdateFactory
  ]);
