'use strict';

function factory() {

  function checkPasswordPattern(password) {
    if (password) {
      password = password.replace(/([\`\~\!\@\#\$\%\^\&\*\(\)\-\_\=\+\[\]\{\}\\\|\;\:\'\"\,\.\>\/\?])/g, '\\$1');
      var confirmPattern = new RegExp('^' + password + '$');
      return confirmPattern;
    }
  }

  return {
    checkPasswordPattern: checkPasswordPattern
  };

}

angular.module('service.passwordChecker', [])
  .factory('passwordChecker', [
    factory
  ]);
