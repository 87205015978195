'use strict';

function accountOffTrackController($scope, $state, $cookies, ram, config, currentUser, account, projections, TransferInstruction, transferAccountDataProvider, bankAccounts) {
  transferAccountDataProvider.setInputs(currentUser, [account], bankAccounts);
  $scope.account = account;
  var goal = $scope.goal = account.accountGoals()[0].goal();
  $scope.simulation = projections[1].simulate(goal.initial(), goal.ongoing());
  $scope.goalTargetAmount = goal.targetAmount();
  $scope.goalTargetDeadline = goal.timeframe() + ' years';
  $scope.accountType = account.type().label;
  $scope.recommendedMonthlyContribution = $scope.simulation.findOngoing(goal.targetAmount(), goal.timeframe(), account.balance());
  $scope.recommendedDeposit = $scope.simulation.findInitial(goal.targetAmount(), goal.timeframe(), goal.ongoing()) - account.balance();
  $scope.monthlyContribution = new ram.Accessor($scope.recommendedMonthlyContribution);

  $scope.setupOnce = function() {
    var freq = config.types.TransferInstructionFrequency[0];
    setupTransfer($scope.recommendedDeposit, freq);
  };

  $scope.setupMonthly = function() {
    var freq = config.types.TransferInstructionFrequency[4];
    setupTransfer($scope.recommendedMonthlyContribution, freq);
  };

  function setupTransfer(amount, frequency) {
    $scope.transferInstruction = TransferInstruction.new();
    $scope.transferInstruction.setFrom('BankAccount', bankAccounts[0].id);
    $scope.transferInstruction.setTo('Account', account.id);
    $scope.transferInstruction.frequency(frequency);
    $scope.transferInstruction.amount(amount);
    $scope.transferInstruction.startDate(moment().startOf('day').toDate());
    $scope.transferInstruction.type(config.types.TransferInstruction[1]);
    $scope.transferInstruction.manualCancel(true);

    var transferDisplayData = transferAccountDataProvider.getConfirmationData($scope.transferInstruction);
    _.extend(transferDisplayData, {
      confirmationMode: 'dialog'
    });

    $scope.transferConfirmationModal($scope.transferInstruction, transferDisplayData).result
      .then(function() {
        $scope.transferDisplayData = transferDisplayData;
        _.extend(transferDisplayData, {
          confirmationMode: 'panel'
        });
        $scope.transferInstruction.save().then(function() {
          $cookies.put('on-track', true);
          $state.go('advice-centre.account-off-track-transfer-created');
        });
      });
  }
}

angular.module('controller.advice-centre.account-off-track', [
    'ram',
    'model.TransferInstruction',
    'service.transfer-account-data-provider',
    'service.user-notifications',
  ])
  .controller('adviceCentreAccountOffTrackCtrl', [
    '$scope',
    '$state',
    '$cookies',
    'ram',
    'config',
    'currentUser',
    'account',
    'projections',
    'TransferInstruction',
    'transferAccountDataProvider',
    'bankAccounts',
    'userNotifications',
    accountOffTrackController
  ]);
