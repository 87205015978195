'use strict';

/**
 * Defines a wrapper for the select input that adds bootstrap styling.
 */
function selectorDirective(optionHelper) {
  return {
    restrict: 'E',
    scope: {
      form: '=',
      name: '@',
      id: '@',
      options: '=',
      optionLabel: '@',
      optionValue: '@',
      ngModel: '=',
      ngChange: '&',
      help: '@',
      focusHelp: '@',
      title: '@',
      label: '@',
      class: '@',
      labelClass: '@',
      inputClass: '@',
      selectClass: '@',
      helpClass: '@',
      placeholder: '@',
      optional: '='
    },
    templateUrl: 'directives/input/selector.html',
    link: optionHelper.link
  };
}

angular.module('directive.input.selector', [
    'templates',
    'animations',
    'directive.focus',
    'directive.input.option-helper'
  ])
  .directive('selector', [
    'optionHelper',
    selectorDirective
  ]);
