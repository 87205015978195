'use strict';

/**
 * Defines an attribute directive that sets and clears a variable 'focus' in
 * the current scope as the element gains and loses focus.
 *
 * @return {Object} directive definition
 */
function focusDirective() {
  return {
    restrict: 'A',
    link: function(scope, $el) {

      var t;

      scope.focus = false;

      function setFocus() {
        clearTimeout(t);
        scope.$applyAsync(function() {
          scope.focus = true;
        });
      }

      function blur() {
        scope.$applyAsync(function() {
          scope.focus = false;
        });
      }

      function clearFocus() {
        t = setTimeout(blur, 100);
      }

      $el
        .focusin(setFocus)
        .focusout(clearFocus);

    }
  };
}

angular.module('directive.focus', [])
  .directive('watchFocus', [
    focusDirective
  ]);
