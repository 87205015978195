'use strict';

function differentSinDirective() {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function(scope, $el, attrs, ctrl) {
      ctrl.$validators.differentsin = function(modelValue) {
        if (scope.$parent.$parent.primaryPerson && scope.$parent.$parent.primaryPerson.sin() === modelValue) {
          return false;
        }
        return true;
      };
    }
  };
}

angular.module('directive.different-sin', [])
  .directive('differentSin', [
    differentSinDirective
  ]);
