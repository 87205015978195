'use strict';

angular.module('component.chart-set', [
    'filter.currency',
    'ram',
    'service.trim-balances',
    'service.xirr',
    'directive.chart.bar',
    'directive.d3.cursor'
  ])
  .component('chartSet', {
    templateUrl: 'components/chart-set.html',
    bindings: {
      account: '=',
      formatCurrency: '=',
      period: '&'
    },
    controller: [
      '$scope',
      '$rootScope',
      '$filter',
      'ram',
      'config',
      'trimBalances',
      'xirr',
      chartSetController
    ],
    controllerAs: '$ctrl'
  });

function chartSetController($scope, $rootScope, $filter, ram, config, trimBalances, xirr) {
  /* jshint -W040 */
  var $ctrl = this;
  /* jshint +W040 */

  var firstDate, lastDate;
  const platform = config.env.platform;

  angular.extend($scope, {
    isWsi: platform && platform.name === 'WSI',
    account: $ctrl.account,
    absContributions: 0,
    start: new ram.Accessor(),
    end: new ram.Accessor(),
    startHandleDate: new ram.Accessor(), // Start for custom view
    endHandleDate: new ram.Accessor(), // End for custom view
    isPercent: new ram.Accessor(false),
    formatCurrency: $rootScope.formatCurrency,
    formatDate: $rootScope.formatDate,
    point: {},
    period: $ctrl.period()
  });

  // Resolve balances
  $scope.account.createBalancesHistory();
  // Resolve transfers
  $scope.account.createTransfersHistory();

  $scope.inCustomView = function() {
    return ($scope.period().label === 'Custom') ? true : false;
  };

  $scope.showCharts = function() {
    return $scope.chartData && $scope.chartData.length >= 1;
  };

  $scope.startOrStartHandle = function() { // In custom view use handle instead of start
    if ($scope.inCustomView()) {
      return $scope.startHandleDate();
    } else {
      return $scope.start();
    }
  };

  $scope.endOrEndHandle = function() { // In custom view use handle instead of end
    if ($scope.inCustomView()) {
      return $scope.endHandleDate();
    } else {
      return $scope.end();
    }
  };

  $scope.activate = function(dataSet) {
    $scope.chartData = dataSet;
  };

  $scope.isActive = function(dataSet) {
    return $scope.chartData === dataSet;
  };

  $scope.dashboardSummaryUseBarChart = function() {
    return $scope.isActive($scope.contributions) && $scope.dataPointsInPeriod() > 0 ||
      $scope.isActive($scope.balances) && $scope.dataPointsInPeriod() === 1;
  };

  $scope.dashboardSummaryUseAreaChart = function() {
    return $scope.dataPointsInPeriod() > 1 && !$scope.isActive($scope.contributions);
  };

  $scope.displayMessage = function() {
    if (!$scope.dashboardSummaryUseAreaChart() && !$scope.dashboardSummaryUseBarChart()) {
      if ($scope.isActive($scope.contributions)) {
        return 'There were no contributions or withdrawls ' + $scope.dateRangeTextLowerCase + '.';
      } else {
        return 'No data is available for the selected date range.';
      }
    } else {
      return null;
    }
  };

  $scope.showNoDataForAreaChart = function() {
    var earningsNoData = $scope.isActive($scope.earnings) && $scope.dataPointsInPeriod() < 2;

    return (!$scope.isActive($scope.contributions) && !$scope.dashboardSummaryUseAreaChart() && !$scope.dashboardSummaryUseBarChart()) ||
      earningsNoData;
  };

  $scope.dataPointsInPeriod = function() {
    var start = ($scope.inCustomView() && $scope.startHandleDate()) || $scope.start();
    var end = ($scope.inCustomView() && $scope.endHandleDate()) || $scope.end();

    return $scope.chartData.filter(function(datum) {
      return datum.date >= start && datum.date <= end;
    }).length;
  };

  $scope.cursorText = [{
    value: function(d) {
      return d.date;
    },
    styles: {
      'font-weight': 'bold'
    },
    attrs: {
      'dy': '1em'
    }
  }, {
    value: function(d) {
      return $filter('currency')(d.amount);
    },
    attrs: {
      'dy': '2.5em'
    }
  }];

  $scope.$watchGroup(['startHandleDate()', 'endHandleDate()'], handleDateListener);

  $scope.$watchGroup(['start()', 'end()'], startEndDateListener);

  $scope.$watchCollection('account.transfersHistory()', function(transfers) {
    $scope.contributions = transfers;
  });

  // Must be run before period() watch
  $scope.$watchCollection('account.balancesHistory()', function(balances) {
    if (!balances || !balances.length) {
      return;
    }
    var indexOfLastZeroBalance = trimBalances.indexOfLastZeroBalance(balances);
    balances = trimBalances.toLastZeroBalance(balances);

    if (!balances) {
      return;
    } // Cover case of only 0 balances

    $scope.balances = balances;

    if (!$scope.chartData) {
      $scope.chartData = $scope.balances;
    }

    var account = $scope.account;

    $scope.earnings = account.earningsHistory().slice(indexOfLastZeroBalance);

    var firstBalance = balances[0];
    firstDate = firstBalance && firstBalance.date;

    var lastBalance = balances[balances.length - 1];
    lastDate = lastBalance && lastBalance.date;
  });

  // Must be run after account.balancesHistory watch
  $scope.$watch('period()', function(period) {
    if ($scope.inCustomView()) {
      handleDateListener([$scope.startHandleDate(), $scope.endHandleDate()]);
      $scope.start($scope.startHandleDate());
      $scope.end($scope.endHandleDate());
    } else {
      $scope.start(period && period.start || firstDate);
      $scope.end(lastDate);
    }
  });

  function redoCalculations(values) {

    $scope.calcPerformance = $scope.account.performance(values[1]) - $scope.account.performance(values[0]);
    $scope.absPerformance = Math.abs($scope.calcPerformance);
    $scope.calcContributions = $scope.account.contributions(values[1], values[0]);
    $scope.absContributions = Math.abs($scope.calcContributions);
    $scope.adjustedEarnings = calcAdjustedEarnings(values[0]);
  }

  // Listener for custom mode; in this mode the handles replace start/end
  function handleDateListener(values) {
    if ($scope.inCustomView()) {
      var currentDate = $filter('date')(Date.now(), 'mediumDate');
      var periodStartDate = $filter('date')(values[0], 'mediumDate') || $filter('date')($scope.start(), 'mediumDate');
      $scope.periodEndDate = $filter('date')(values[1], 'mediumDate') || $filter('date')($scope.end(), 'mediumDate');

      if (periodStartDate === $scope.periodEndDate) {
        $scope.dateRangeText = 'On ' + periodStartDate;
        $scope.dateRangeTextLowerCase = 'on ' + periodStartDate;
      } else if ($scope.periodEndDate === currentDate) {
        $scope.dateRangeText = 'Since ' + periodStartDate;
        $scope.dateRangeTextLowerCase = 'since ' + periodStartDate;
      } else {
        $scope.dateRangeText = 'Between ' + periodStartDate + ' and ' + $scope.periodEndDate;
        $scope.dateRangeTextLowerCase = 'between ' + periodStartDate + ' and ' + $scope.periodEndDate;
      }

      redoCalculations(values);
    }
  }

  // Listener for regular mode
  function startEndDateListener(values) {
    // In custom mode the watch for startHandleDate/endHandleDate handles this
    if (!$scope.inCustomView()) {
      var currentDate = $filter('date')(Date.now(), 'mediumDate');
      var periodStartDate = $filter('date')(values[0], 'mediumDate');
      $scope.periodEndDate = $filter('date')(values[1], 'mediumDate');

      if ($scope.periodEndDate === currentDate) {
        $scope.dateRangeText = 'Since ' + periodStartDate;
        $scope.dateRangeTextLowerCase = 'since ' + periodStartDate;
      } else {
        $scope.dateRangeText = 'Between ' + periodStartDate + ' and ' + $scope.periodEndDate;
        $scope.dateRangeTextLowerCase = 'between ' + periodStartDate + ' and ' + $scope.periodEndDate;
      }

      setPercentageForPeriod($scope.period().label);
      redoCalculations(values);
    }
  }

  function setPercentageForPeriod(period) {
    var performanceSummary = $scope.account.performanceSummary ? $scope.account.performanceSummary() : null;
    var calcPercent;
    if ( performanceSummary) {
      switch (period) {
        case 'From Inception':
          calcPercent = performanceSummary.sinceInception;
          break;
        case '1 Year':
          calcPercent = performanceSummary.previous12Months;
          break;
        case 'YTD':
          calcPercent = performanceSummary.ytdRoi;
          break;
        case 'Quarterly':
          calcPercent = performanceSummary.previous3Months;
          break;
        default:
          calcPercent = 0;
          break;
      }
    }
    calcPercent = calcPercent ? parseFloat(calcPercent) : 0;
    $scope.calcPercent = calcPercent;
    $scope.percent = calcPercent !== 0 ? calcPercent.toFixed(2) + '%' : '0%';
  }

  function calcAdjustedEarnings(startDate) {
    var earnings = _.map($scope.earnings, _.clone);
    var index = -1;
    if (startDate) {
      index = _.findIndex($scope.earnings, function(earning) {
        return earning.date.getTime() >= startDate.getTime();
      });
    }
    if (index >= 0) {
      _.each(earnings, function(earning) {
        earning.amount -= $scope.earnings[index].amount;
      });
    }
    return earnings;
  }
}
