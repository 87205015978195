'use strict';

function householdMembershipFactory(ram, config, $http) {

  var HouseholdMembership = new ram.Collection('HouseholdMembership', {
    enums: {
      role: config.types.HouseholdMembershipRole,
      status: config.types.HouseholdMembershipStatus
    },
    belongsTo: {
      person: 'Person',
      household: 'Household'
    },
    schema: config.schemas.HouseholdMembership,
    resources: {
      default: new ram.resources.Http('/api/household_memberships/:id.json'),
      cookie: new ram.resources.Cookie('household_memberships')
    }
  });

  HouseholdMembership.prototype.accessLevel = function() {
    return this.roleId() === config.types.HouseholdMembershipRole.findByName('admin').id ?
      'Can see all household accounts' :
      'Can only see their own accounts';
  };

  HouseholdMembership.prototype.revoke = function() {
    return $http.delete('/api/household_memberships/' + this.id + '/revoke.json');
  };

  return HouseholdMembership;
}

angular.module('model.HouseholdMembership', [
    'ram',
    'config',
    'model.Person',
    'model.Household'
  ])
  .factory('HouseholdMembership', [
    'ram',
    'config',
    '$http',
    householdMembershipFactory
  ]);
