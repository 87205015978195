'use strict';

angular.module('directive.interview.navbar', [
    'templates',
    'animations',
    'directive.focus',
    'service.adaptive-views'
  ])
  .directive('interviewNavbar', [
    '$state',
    'adaptiveViews',
    interviewNavBarDirective
  ]);

function interviewNavBarDirective($state, adaptiveViews) {
  return {
    restrict: 'E',
    templateUrl: adaptiveViews.getTemplateUrlForSrc('directives/interview/navbar.html', true),
    controller: ['$scope', function($scope) {

      $scope.getCurrentStepNumber = function() {
        return $state.current.data && $state.current.data.interviewStep;
      };

      $scope.getLabelForStep = function(stepNumber) {
        var labels = {
          1: 'Your Investment Plan',
          2: 'Your Information',
          3: 'Documents'
        };
        return labels[stepNumber];
      };

      function getStateForStepNumber(stepNumber) {
        var states = {
          2: 'onboarding.personal-combined',
          3: 'onboarding.bank'
        };

        if ($state.current.name.startsWith('interview')) {
          states = _.extend(states, {
            1: 'interview.goal',
            2: 'interview.account-credentials'
          });
        }

        return states[stepNumber];
      }

      $scope.isStepActive = function(stepNumber) {
        return $scope.getCurrentStepNumber() === stepNumber;
      };

      $scope.isStepDone = function(stepNumber) {
        return $scope.getCurrentStepNumber() > stepNumber;
      };

      $scope.canNavigateToStep = function(stepNumber) {
        if (stepNumber === 1) {
          // don't allow to go back to financial stuff
          return false;
        }
        var currentStepNumber = $scope.getCurrentStepNumber();
        if (currentStepNumber === 4) {
          return false;
        }
        return currentStepNumber > stepNumber;
      };

      $scope.navigateToStep = function(stepNumber) {
        if ($scope.canNavigateToStep(stepNumber)) {
          $state.go(getStateForStepNumber(stepNumber));
        }
      };

      $scope.isPersonalInterview = function() {
        return $state.includes('interview.personal');
      };

      $scope.totalNumberOfSteps = function() {
        if ($scope.isPersonalInterview()) {
          return 2;
        } else {
          return 3;
        }
      };
    }]
  };
}
