'use strict';

angular.module('controller.modal.incomplete-account', [
    'component.investment-charts.model-portfolio-pie-chart',
    'ui.router',
    'filter.a-or-an',
    'service.investment-charts.asset-allocation-data-generator'
  ])
  .controller('ModalIncompleteAccountCtrl', [
    '$scope',
    '$http',
    '$uibModalInstance',
    '$state',
    '$cookies',
    'account',
    'user',
    'advisorNewAccountFlow',
    'investmentChartsAssetAllocationDataGenerator',
    'storedAccount',
    incompleteAccountController
  ]);

function incompleteAccountController($scope, $http, $modalInstance, $state, $cookies, account, user, advisorNewAccountFlow, investmentChartsAssetAllocationDataGenerator, storedAccount) {
  $scope.user = user;
  $scope.account = account;

  $scope.continueAdvisorNewAccount = !!advisorNewAccountFlow;
  $scope.newAccount = storedAccount;

  if (account) {
    var accountGoal = $scope.account.accountGoals()[0];

    $scope.goal = accountGoal.goal();

    $scope.portfolio = accountGoal.modelPortfolio();
    if ($scope.portfolio.id) {
      $scope.portfolio.allocations.ready();
    }
  }

  var chartData = investmentChartsAssetAllocationDataGenerator.getData($scope.portfolio.nonZeroAllocations());

  $scope.allocationDataForPieChart = _.map(chartData, function(chartDataRow) {
    return {
      color: chartDataRow.color,
      label: chartDataRow.allocation.fund().label(),
      value: chartDataRow.allocation.weight()
    };
  });

  $scope.allocationDataForLegend = _.map(chartData, function(chartDataRow) {
    return {
      color: chartDataRow.color,
      label: chartDataRow.allocation.fund().label(),
      weight: chartDataRow.allocation.weight()
    };
  });

  $scope.externalAdvisor = user.externalAdvisorProfile();

  $scope.moment = moment;

  $scope.startOver = function() {
    $http.delete('/api/accounts/' + account.id + '.json')
      .then(function() {
        if (advisorNewAccountFlow) {
          return $state.go('advisor-new-account.continue', {
            advisorNewAccountFlowId: advisorNewAccountFlow.id
          });
        } else if ($scope.currentUser.isClient()) {
          return $state.go('interview.goal', {}, {
            reload: 'site'
          });
        }
      })
      .then(function() {
        $modalInstance.close('start_over');
      });
  };

  $scope.continuePrevious = function() {
    $cookies.remove('currentAdvisorNewAccountFlow');
    var newState = 'interview.goal';
    if (account.progress() >= 5) {
      newState = 'onboarding.personal-combined';
    }
    $state.go(newState);

    $modalInstance.close('continue');
  };

  $scope.setBigModal = function() {
    if ($scope.showExtra) {
      angular.element('.modal:not(.menu)').addClass('big-dialog-disable-flexbox');
    } else {
      angular.element('.modal:not(.menu)').removeClass('big-dialog-disable-flexbox');
    }
  };
}
