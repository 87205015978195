'use strict';

angular.module('controller.profile.bank.confirm-email', [
    'service.user-notifications',
    'service.reload-current-user'
  ])
  .controller('ProfileBankEmailCtrl', [
    '$scope',
    '$state',
    'userNotifications',
    'reloadCurrentUser',
    profileBankEmailController
  ]);

function profileBankEmailController($scope, $state, userNotifications, reloadCurrentUser) {
  if ($scope.currentUser.confirmed()) {
    $state.go('profile.bank.add-success');
  }

  $scope.userNotConfirmed = !$scope.currentUser.confirmed();
  $scope.sendReconfirmation = function() {
    $scope.currentUser.sendConfirmation().then(function() {
      userNotifications.showSuccess('Sent confirmation email successfully. Please check your email.');
    });
  };

  reloadCurrentUser($scope, function() {
    userNotifications.showSuccess('<strong>Thanks!</strong> Your email address has been successfully confirmed.');
    $scope.userNotConfirmed = !$scope.currentUser.confirmed();
  });

  $scope.done = function() {
    if ($scope.currentUser.confirmed()) {
      $state.go('profile.bank.add-success');
    } else {
      userNotifications.showError('Please confirm your email address in order to continue.');
    }
  };

}
