'use strict';

angular.module('routes.preview', [
    'model.AdvisorCreateNewClientFlow',
    'controller.preview',
    'templates',
    'ui.router',
    'service.adaptive-views',
    'service.branding-machine',
    'ngCookies'
  ])
  .config([
    '$stateProvider',
    'adaptiveViewsProvider',
    'brandingMachineProvider',
    configRoutes
  ]);

/**
 * Initialize angular UI router for advisor dashboard.
 *
 * @param  {Object} $stateProvider API used to declare application states.
 */
function configRoutes($stateProvider, adaptiveViewsProvider, brandingMachineProvider) {

  function resolveAllocations(modelPortfolio) {
    return modelPortfolio && modelPortfolio.allocations.ready();
  }

  function resolveRisk($http, storedGoal, storedUser) {
    var params = {
      financialAssets: storedUser.financialAssets(),
      income: storedUser.income(),
      initial: storedGoal.initial(),
      nonFinancialAssets: storedUser.nonFinancialAssets(),
      ongoing: storedGoal.ongoing(),
      riskAppetite: storedUser.riskAppetite(),
      timeframe: storedGoal.timeframe(),
      totalLiabilities: storedUser.totalLiabilities()
    };
    return $http.get('api/goals/new/risk.json', {
      params: _.underscoreKeys(params)
    });
  }

  function resolveModelPortfolio(risk, storedAccount, storedGoal, ModelPortfolio) {
    storedGoal.riskRecommendation.set(risk.data, true);
    return ModelPortfolio.find({
        risk: storedGoal.risk(),
        taxable: storedAccount.type() && !storedAccount.type().registered,
        portfolioOptionId: storedAccount.portfolioOptionId()
      })
      .then(function(modelPortfolio) {
        return modelPortfolio && modelPortfolio.projections.ready()
          .then(function() {
            return modelPortfolio;
          });
      });
  }

  function resolveGoal(storedGoal) {
    return storedGoal;
  }

  function resolveAdvisorGroupUrl() {
    var $cookies = angular.injector(['ngCookies']).get('$cookies');
    var referral = $cookies.getObject('group_url_id');
    return referral && referral.urlId;
  }

  function resolveClientFlow(AdvisorCreateNewClientFlow, currentUser) {
    if (!currentUser) {
      return null;
    }
    return AdvisorCreateNewClientFlow.where({
        clientId: currentUser.person().id
      })
      .then(function(list) {
        if (list.length === 0) {
          return null;
        }
        return _.first(list);
      });
  }

  function resolveAdvisorReferralDetails(currentUser, clientFlow) {
    return brandingMachineProvider.getAdvisorDetails(currentUser, clientFlow && clientFlow.advisorReferralCode());
  }

  function resolveRedirectToInterviewPlatform($location, $q, $state, config) {
    let platform = config.env.platform;

    if (platform && platform.name === 'WSI') {
      // From https://stackoverflow.com/a/30592506 Gustav's comment
      // The last `return $q.reject()` was not used as it seems to work without it.
      return $q.reject()
        .catch(function() {
          $state.transitionTo('wsi-interview.goal', $location.search(), {
            location: 'replace'
          });
        });
    }
  }

  $stateProvider
    .state('preview', {
      url: '/preview',
      parent: 'site',
      abstract: true,
      hasMobileView: true,
      data: {
        title: 'Preview',
        interviewStep: 1
      },
      resolve: {
        redirectToInterviewPlatform: ['$location', '$q', '$state', 'config', resolveRedirectToInterviewPlatform],
        risk: ['$http', 'storedGoal', 'storedUser', resolveRisk],
        modelPortfolio: ['risk', 'storedAccount', 'storedGoal', 'ModelPortfolio', resolveModelPortfolio],
        advisorGroupUrl: [resolveAdvisorGroupUrl],
        clientFlow: ['AdvisorCreateNewClientFlow', 'currentUser', resolveClientFlow],
        advisorReferralDetails: ['currentUser', 'clientFlow', resolveAdvisorReferralDetails],
        ima: ['currentUser', function(currentUser) {
          if (currentUser) {
            return currentUser.investmentManagementAgreement.ready();
          }
        }]
      },
      views: {
        'main': {
          controller: 'PreviewCtrl'
        },
        'navbar': {
          templateUrl: 'navbar/interview.html'
        },
        footer: {
          templateUrl: adaptiveViewsProvider.isMobile() ? 'mobile/partials/footer.html' : 'partials/footer-minimal.html'
        },
        //these are here for A/B testing for Variation #1
        'projections@preview': {
          controller: 'PreviewProjectionsController',
          templateUrl: 'views/preview/projections.html',
          resolve: {
            goal: ['storedGoal', resolveGoal]
          }
        },
        'investments@preview': {
          controller: 'PreviewInvestmentsController',
          templateUrl: 'views/preview/investments.html',
          resolve: {
            allocations: ['modelPortfolio', resolveAllocations]
          }
        }
      }
    })
    .state('preview.investments', {
      url: '',
      controller: 'PreviewInvestmentsController',
      resolve: {
        allocations: ['modelPortfolio', resolveAllocations]
      }
    })
    .state('preview.projections', {
      controller: 'PreviewProjectionsController',
      resolve: {
        goal: ['storedGoal', resolveGoal]
      }
    })
    .state('preview.performance', {
      controller: 'PreviewPerformanceController'
    })
    .state('preview.fees', {});
}
