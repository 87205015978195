'use strict';

angular.module('controller.iframe-widgets.portfolio-performance', [
    'directive.portfolio-performance'
  ])
  .controller('iframeWidgetsPortfolioPerformanceCtrl', [
    '$scope',
    'portfolioSelections',
    iframeWidgetsPortfolioPerformanceController
  ]);

function iframeWidgetsPortfolioPerformanceController($scope, portfolioSelections) {
  $scope.portfolioSelections = portfolioSelections;
}
