'use strict';

angular.module('controller.advise.trade-reconciliation.create-report', [
  'ram',
  'csrf',
  'service.form-helper',
  'service.user-notifications',
]).controller('AdviseTradeReconciliationCreateReportCtrl', [
  '$document',
  '$scope',
  '$state',
  '$http',
  '$timeout',
  'csrfToken',
  'custodians',
  'formHelper',
  'ram',
  'userNotifications',
  adviseTradeReconciliationCreateReportController
]);

function adviseTradeReconciliationCreateReportController($document, $scope, $state, $http, $timeout, csrfToken, custodians, formHelper, ram, userNotifications) {
  var defaultDate = moment().subtract(1, 'days');
  $scope.tradeDateMonth = new ram.Accessor(defaultDate.format('MMMM'));
  $scope.tradeDateDay = new ram.Accessor(defaultDate.date());
  $scope.tradeDateYear = new ram.Accessor(defaultDate.year());
  $scope.tradeDate = new ram.Accessor();
  $scope.months = moment.months();

  $scope.custodians = custodians;
  $scope.custodianId = new ram.Accessor();
  var fileInput = $document[0].getElementById('select-file');
  $scope.fileStatus = 'No file loaded yet';
  $scope.allocationsFileName = 'Please select your file';
  $scope.hasAllocationsFile = true;
  $scope.isValidAllocationsFile = false;

  $scope.isSaving = false;

  $scope.pleaseSelectFileMessage = {
    show: false,
    text: 'Please select your file'
  };

  $scope.$watchGroup(['tradeDateMonth()', 'tradeDateDay()', 'tradeDateYear()'], function(newDateValues) {
    var dateString = newDateValues[0] + ' ' + newDateValues[1] + ', ' + newDateValues[2];
    $scope.tradeDate(new Date(dateString));
  });

  function generateTradeReconciliationReportParamsWithAllocationsFile() {
    // TODO: We might want to generate a unique name for the file on the server
    //       instead of using the original name of the file.
    return {
      tradeDate: tradeDateApiFormat(),
      custodianId: $scope.custodianId(),
      noAllocationsFile: false,
      allocationsFileName: $scope.allocationsFileName
    };
  }

  function generateTradeReconciliationReportParamsWithNoAllocationFile() {
    return {
      tradeDate: tradeDateApiFormat(),
      custodianId: $scope.custodianId(),
      noAllocationsFile: true,
    };
  }

  function tradeDateApiFormat() {
    return moment($scope.tradeDate()).format('YYYY-MM-DD');
  }

  function generateTradeReconciliationReportFormData() {
    var formData = new FormData();
    var params;

    if ($scope.hasAllocationsFile) {
      formData.append(
        'file',
        fileInput.files[0],
        $scope.allocationsFileName
      );
      params = generateTradeReconciliationReportParamsWithAllocationsFile();
    } else {
      params = generateTradeReconciliationReportParamsWithNoAllocationFile();
    }

    _.each(params, function(val, key) {
      var address = s.sprintf('trade_reconciliation_report[%s]', s.underscored(key));
      formData.append(address, val);
    });

    return formData;
  }

  $scope.selectFile = function() {
    fileInput.click();
  };

  $scope.updateFileNameAndContent = function() {
    $scope.pleaseSelectFileMessage.show = false;

    var newFile = fileInput.files[0];

    $scope.isValidAllocationsFile = validateFile(newFile);
    if (!$scope.isValidAllocationsFile) {
      return;
    }
    $scope.allocationsFileName = newFile.name;
    $scope.fileStatus = 'Successfully loaded file! (' + newFile.size + ' bytes)';
  };

  function validateFile(file) {
    if (!file) {
      $scope.fileStatus = 'No file selected.';
      $scope.allocationsFileName = 'Please select your file.';
      return false;
    }

    if (file.size / 1024 / 1024 > 2) {
      $scope.fileStatus = 'file is too big.';
      $scope.allocationsFileName = file.name;
      return false;
    }
    return true;
  }

  function getFile() {
    userNotifications.clearNotification();

    $http.post('/api/trade_reconciliation_reports/report_body.json')
      .then(function(result) {
        if (result.status === 201) {
          $state.go('advise.trade-reconciliation.report-creation-details', {
            id: result.data.id,
            resultsSummary: result.data.resultsSummary
          });
        }
      })
      .catch(function(error) {
        var errorMsg = error.data.error || 'Something went wrong while retrieving the report. Please try again later.';
        userNotifications.showError(errorMsg);
      })
      .finally(function() {
        $scope.isSaving = false;
      });
  }

  $scope.submitForm = function(formController) {
    userNotifications.clearNotification();

    if ($scope.hasAllocationsFile && !$scope.isValidAllocationsFile) {
      $scope.pleaseSelectFileMessage.show = true;
      return;
    }

    if (formController.$invalid) {
      formHelper.notDone($scope, formController);
    } else {
      $scope.isSaving = true;
      var formData = generateTradeReconciliationReportFormData();

      var request = new XMLHttpRequest();
      request.open('POST', '/api/trade_reconciliation_reports.json');
      request.setRequestHeader('X-CSRF-Token', csrfToken());
      request.onload = function() {
        var result = JSON.parse(request.response);
        if (this.status === 200) {
          $timeout(getFile, 7500);
        } else {
          var errorMessage = result.error || 'There was an unexpected result. Please try again later.';
          userNotifications.showError(errorMessage);
          $scope.isSaving = false;
        }
      };
      request.onerror = function() {
        userNotifications.showError('Something went wrong while creating the report. Please try again later.');
        $scope.isSaving = false;
      };
      request.send(formData);
    }
  };
}
