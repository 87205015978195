'use strict';

angular.module('controller.interview.service-options', [
    'component.or-divider',
    'ram'
  ])
  .controller('InterviewServiceOptionsController', [
    '$cookies',
    '$scope',
    '$state',
    'ram',
    interviewServiceOptionsController
  ]);

function interviewServiceOptionsController($cookies, $scope, $state, ram) {
  var serviceOptionCookieName = 'interview_service_option';
  var originalSeviceOptionChoice = $cookies.get(serviceOptionCookieName);

  $scope.serviceOptions = {
    digital: 'digital',
    personal: 'personal'
  };

  $scope.serviceOption = new ram.Accessor(originalSeviceOptionChoice);

  $scope.$watch('serviceOption()', function(newServiceOption) {
    $cookies.put(serviceOptionCookieName, newServiceOption, {
      expires: moment().add(7, 'days').toDate()
    });
  });

  // TODO: refactor this into parent interview controller
  $scope.$parent.nextSection = function() {

    if ($scope.serviceOption() === $scope.serviceOptions.digital) {
      $state.go('/investment/goal');
    } else {
      $state.go('interview.personal.contact-info');
    }
  };

  $scope.$parent.lastSection = function() {
    $state.go($state.current.data.last);
  };

  $scope.$parent.step = $state.current.data.step;
  $scope.$parent.header = $state.current.data.header;
  $scope.$parent.userCanGoBack = false;
}
