'use strict';

angular.module('routes.password', [
    'controller.minimal-footer',
    'controller.password.change',
    'controller.password.recover',
    'controller.password.recover-advisor',
    'controller.password.reset',
    'controller.password.reset-advisor',
    'controller.password.advisor-initial-set',
    'controller.navbar.advise',
    'templates',
    'ui.router',
    'service.adaptive-views',
    'service.two-factor-authenticator'
  ])
  .config([
    '$stateProvider',
    'adaptiveViewsProvider',
    configRoutes
  ]);

/**
 * Initialize angular UI router for password management.
 *
 * @param  {Object} $stateProvider API used to declare application states.
 */
function configRoutes($stateProvider, adaptiveViewsProvider) {

  function resolveOneTimeUserName($stateParams) {
    return $stateParams.u;
  }

  function resolveOneTimePassword($stateParams) {
    return $stateParams.p;
  }

  function resolveAdvisorFirstName($http, $stateParams) {
    return $http.get(`/api/advisor/advisor_name.json?one_time_user_name=${$stateParams.u}`)
      .then(response => {
        return response.data.firstName;
      })
      .catch(error => {
        console.warn(`User name could not be fetched - ${error.statusText}`);
      });
  }

  $stateProvider
    .state('password', {
      abstract: true,
      parent: 'site',
      url: '/password',
      hasMobileView: true,
      views: {
        'main': {
          templateUrl: adaptiveViewsProvider.getTemplateUrlForSrc('views/password.html', true)
        },
        'navbar': {
          templateUrl: 'navbar/password.html'
        },
        'footer': {
          controller: 'minimalFooterCtrl',
          templateUrl: adaptiveViewsProvider.isMobile() ? 'mobile/partials/footer.html' : 'partials/footer-minimal.html'
        }
      }
    })
    .state('password.change', {
      url: '/change',
      hasMobileView: true,
      cssClass: 'no-mobile-footer',
      controller: 'PasswordChangeController',
      data: {
        tracking: {
          pageTitle: 'Change your password',
          pageType: 'form',
          contents: {
            category: {
              primary: "profile",
              subcategory1: "security"
            },
            content: [{
              type: "form",
            }],
          },
          forms: [{
            id: "password-change",
            name: "Change your password",
            type: "modification"
          }]
        }
      }
    })
    .state('password.recover', {
      url: '/recover',
      hasMobileView: true,
      cssClass: 'no-mobile-footer',
      controller: 'PasswordRecoverController',
      data: {
        tracking: {
          pageTitle: 'Recover your password',
          pageId: 'password-link-request',
          pageType: 'form',
          contents: [
            {
              type: 'form',
              category: {
                primary: 'profile',
                subcategory1: 'security'
              }
            }
          ],
          forms: [
            {
              id: 'password-link-request',
              name: 'Password Link Request',
              type: 'password-link-request'
            }
          ]
        }
      }
    })
    .state('password.recover-advisor', {
      url: '/recover-advisor',
      hasMobileView: false,
      cssClass: 'no-mobile-footer',
      controller: 'PasswordRecoverController',
      data: {
        tracking: {
          pageTitle: 'Request a link to set up your password',
          pageId: 'password-link-request',
          pageType: 'form',
          contents: [
            {
              type: 'form',
              category: {
                primary: 'profile',
                subcategory1: 'security'
              }
            }
          ],
          forms: [
            {
              id: 'password-link-request',
              name: 'Password Link Request',
              type: 'password-link-request'
            }
          ]
        }
      }
    })
    .state('password.reset', { 
      url: '/reset?token',
      hasMobileView: true,
      cssClass: 'no-mobile-footer',
      controller: 'PasswordResetController',
      data: {
        tracking: {
          pageTitle: 'Request a link to set up your password',
          pageId: 'password-reset',
          pageType: 'form',
          contents: [
            {
              type: 'form',
              category: {
                primary: 'profile',
                subcategory1: 'security'
              }
            }
          ],
          forms: [
            {
              id: 'password-reset',
              name: 'Password reset',
              type: 'password-reset'
            }
          ]
        }
      }
    })
    .state('password.reset-advisor', {
      url: '/reset-advisor?token',
      hasMobileView: true,
      cssClass: 'no-mobile-footer',
      controller: 'PasswordResetController',
      data: {
        tracking: {
          pageTitle: 'Request a link to set up your password',
          pageId: 'password-reset-advisor',
          pageType: 'form',
          contents: [
            {
              type: 'form',
              category: {
                primary: 'profile',
                subcategory1: 'security'
              }
            }
          ],
          forms: [
            {
              id: 'password-reset-advisor',
              name: 'Password Reset Advisor',
              type: 'password-reset-advisor'
            }
          ]
        }
      }
    })
    .state('password.pre-set-password-success', {
      url: '/pre-set-password-success',
      hasMobileView: false,
      cssClass: 'no-mobile-footer',
      controller: 'PasswordResetController',
      data: {
        tracking: {
          pageTitle: 'Request a link to set up your password',
          pageId: 'set-password-success',
          pageType: 'form',
          contents: [
            {
              type: 'form',
              category: {
                primary: 'profile',
                subcategory1: 'security'
              }
            }
          ],
          forms: [
            {
              id: 'set-password-success',
              name: 'Password Set Success',
              type: 'set-password-success'
            }
          ]
        }
      }
    })
    .state('password.set-password-success', {
      url: '/set-password-success',
      hasMobileView: true,
      cssClass: 'no-mobile-footer',
      controller: 'PasswordResetController',
      data: {
        tracking: {
          pageTitle: 'Request a link to set up your password',
          pageId: 'set-password-success',
          pageType: 'form',
          contents: [
            {
              type: 'form',
              category: {
                primary: 'profile',
                subcategory1: 'security'
              }
            }
          ],
          forms: [
            {
              id: 'set-password-success',
              name: 'Password Set Success',
              type: 'set-password-success'
            }
          ]
        }
      }
    })
    .state('password.advisor-initial-set', {
      url: '/advisor-initial-set?u&p',
      hasMobileView: true,
      cssClass: 'no-mobile-footer',
      controller: 'PasswordAdvisorInitialSetController',
      data: {
        tracking: {
          pageTitle: 'Advisor Initial Setup',
          pageId: 'advisor-initial-set',
          pageType: 'form',
          contents: [
            {
              type: 'form',
              category: {
                primary: 'profile',
                subcategory1: 'security'
              }
            }
          ],
          forms: [
            {
              id: 'advisor-initial-set',
              name: 'Advisor Initial Setup',
              type: 'advisor-initial-set'
            }
          ]
        }
      },
      resolve: {
        oneTimeUserName: ['$stateParams', resolveOneTimeUserName],
        oneTimePassword: ['$stateParams', resolveOneTimePassword],
        advisorFirstName: ['$http', '$stateParams', resolveAdvisorFirstName]
      },
    });
}
