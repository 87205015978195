'use strict';

function directive() {
  return {
    replace: true,
    restrict: 'E',
    scope: true,
    templateUrl: 'directives/input/form-group.html',
    transclude: true,
    link: function(scope, element, attrs, controller, transclude) {

      var formName = element.closest('form').attr('name');
      scope.form = scope[formName];
      scope.inputName = attrs.inputName;

      transclude(scope, function(clone) {
        element.append(clone);
      });
    }
  };
}

angular.module('directive.input.form-group', [])
  .directive('formGroup', [
    directive
  ]);
