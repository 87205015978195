'use strict';

angular.module('directive.account-transfers-table', [
    'service.adaptive-views',
    'service.group-by-date',
    'service.user-notifications'
  ])
  .directive('accountTransfersTable', [
    '$state',
    'AccountTransfer',
    'Document',
    'config',
    'adaptiveViews',
    'groupByDateService',
    'userNotifications',
    accountTransfersTableDirective
  ]);

function accountTransfersTableDirective($state, AccountTransfer, Document, config, adaptiveViews, groupByDateService, userNotifications) {
  return {
    controller: ['$scope', accountTransfersTableCtrl],
    restrict: 'E',
    templateUrl: adaptiveViews.getTemplateUrlForSrc('directives/account-transfers-table.html', true),
    scope: {
      accountTransfers: '=',
      account: '=',
      heading: '@'
    }
  };

  function accountTransfersTableCtrl($scope) {
    var getCancelDisplayOptions = function( /*accountTransfer*/ ) {
      var cancelDisplayOptions = {
        isAccountTransfer: true,
        canCancel: true,
        cancelWithMessage: false
      };
      // At this point you can modify `cancelDisplayOptions.canCancel` depending on the status of accountTransfer
      return cancelDisplayOptions;
    };

    $scope.cancel = function(accountTransfer) {
      $scope.$root.transferCancelConfirmationModal(getCancelDisplayOptions(accountTransfer)).result
        .then(function() {
          accountTransfer.cancel()
            .then(function() {
              userNotifications.showTransientSuccess('Account transfer cancelled successfully.');
            }, function() {
              userNotifications.showTransientError('We were unable to cancel your account transfer. Please contact us, should the problem persist.');
            })
            .then(function() {
              return $scope.account.accountTransfers.ready(true);
            })
            .then(function(reloadedTransfers) {
              $scope.accountTransfers = reloadedTransfers;
            });
        });
    };

    if ($scope.$root.isMobile) {
      $scope.$watchCollection('accountTransfers', function(accountTransfers) {
        $scope.accountTransfersByDate = groupByDateService.call(accountTransfers, 'createdAt');
      });
    }

  }
}
