'use strict';

angular.module('controller.advise.create-client', [
    'controller.advise.create-client.email'
  ])
  .controller('AdviseCreateClientCtrl', [
    adviseCreateClientCtrl
  ]);

function adviseCreateClientCtrl() {}
