'use strict';

function factory(config) {

  function getActivityTypes() {
    const activities = [...config.types.Activity];
    return [{
      label: 'All Activities',
      name: 'all',
      // The Dropdown UX needs an id
      id: config.types.Activity.length + 1 // The next in the list
    }].concat(activities.sort((a, b) => a.label.localeCompare(b.label)));
  }

  function activities(account, type, period) {
    return _.chain(account.activities())
    .filter(function(activity) {
      var typeMatch = (type.name === 'all') || type.id === activity.type().id;
        return typeMatch && (period.start === null || activity.date() > period.start) && activity.type().hidden !== true;
      })
      .sortBy(function(activity) {
        return -activity.date();
      })
      .value();
  }

  function accountById(currentUser, id) {
    if (currentUser) {
      return _(currentUser.viewableAccounts()).findWhere({
        id: id
      });
    }
  }

  function getTotalAmount(activities) {
    return activities.reduce(function(acc, activity) {
      return acc + (+activity.amount());
    }, 0);
  }

  function displayTotalAmount(type) {
    var typesWithoutAmounts = ['all', 'rebalance', 'modify'];
    return !typesWithoutAmounts.includes(type.name);
  }

  return {
    displayTotalAmount: displayTotalAmount,
    getActivityTypes: getActivityTypes,
    getTotalAmount: getTotalAmount,
    activities: activities,
    accountById: accountById
  };

}

angular.module('service.activityDisplayHelper', [])
  .factory('activityDisplayHelper', [
    'config',
    factory
  ]);
