'use strict';

angular.module('controller.advise.new-account-applications', [
    'service.modals',
    'service.bulkDataLoader',
    'component.email-client-flow-button',
    'directive.paginationnavigator',
    'directive.flex-container',
    'directive.flex-boxes',
    'model.PaginatedAccountApplication',
    'model.ApplicationReadModel',
    'model.User',
    'model.Paginator',
    'model.Account',
    'model.AdvisorCreateNewClientFlow',
    'model.AdvisorCreateNewAccountFlow',
    'directive.secured',
    'service.pagination.filter-service',
    'service.user-notifications'
  ])
  .controller('AdviseNewAccountApplicationsCtrl', [
    '$scope',
    '$rootScope',
    '$state',
    '$http',
    '$q',
    '$window',
    'User',
    'Account',
    'PaginatedAccountApplication',
    'Paginator',
    'AdvisorCreateNewClientFlow',
    'AdvisorCreateNewAccountFlow',
    'ApplicationReadModel',
    'advisorDetails',
    'modals',
    'config',
    'paginationFilterService',
    'securityServiceFactory',
    'userNotifications',
    controller
  ]);

function controller(
  $scope,
  $rootScope,
  $state,
  $http,
  $q,
  $window,
  User,
  Account,
  PaginatedAccountApplication,
  Paginator,
  AdvisorCreateNewClientFlow,
  AdvisorCreateNewAccountFlow,
  ApplicationReadModel,
  advisorDetails,
  modals,
  config,
  paginationFilterService,
  securityServiceFactory,
  userNotifications,
) {
  $scope.bulkAccounts = [];

  $scope.filterParam = '';

  /******************************* Pagination methods */
  const _getDataFromPaginator = function (parameter) {
    $scope.filterParam = parameter.paginationMeta().filter();
    return parameter.accounts();
  };

  const pendingReviewWfmAdvisorStatus = 'pending_review.wfm_advisor';
  const pendingReviewWfmBranchManagerStatus = 'pending_review.wfm_branch_manager';

  let initialFilter = [];
  if ($rootScope.security.isWfmAdvisor()) {
    initialFilter = ['status.prefill_not_sent', 'status.prefill_sent', 'status.application_started', `status.${pendingReviewWfmAdvisorStatus}`];
  } else if ($rootScope.security.isWfmBranchManager()) {
    initialFilter = [`status.${pendingReviewWfmBranchManagerStatus}`];
  }

  $scope.initialActions = function () {
    return [
      {
        type: 'SORT-BY',
        sort: {
          sortBy: $scope.isAdvisor($rootScope.currentUser) ? 'rejectedByBMFirst' : 'startedAt',
          order: -1
        }
      },
      {
        type: 'FLEX-FILTER',
        filterList: initialFilter
      }
    ];
  };

  var filterData = [];

  // this is a tweak to remove hidden status and replace "Pending Review" with
  // "Pending Advisor review" and "Pending BM review"
  const configARMStatuses = _createApplicationReadModelStatuses();

  // Filter out statuses related to accounts that are open.  They will get omitted from the view.
  paginationFilterService.addEnumFilterData(
    _.reject(configARMStatuses, function(status) {
      return _.contains(['open'], status.name);
    }), filterData, 'Status', 'status');

  paginationFilterService.addEnumFilterData(_.filter(config.types.Account, function(type) {
    return type.showOnFilter;
  }), filterData, 'Type', 'type');

  $scope.filterData = filterData;
  $scope.filterModel = paginationFilterService.toFilterModel(filterData, initialFilter);

  $scope.paginator = new Paginator(PaginatedAccountApplication, null, _getDataFromPaginator);

  /******************************* end Pagination methods */

  $scope.rejectionIconTitle = function(application) {
    return $scope.isBranchManager($rootScope.currentUser) ? 'Previously rejected by you.' : `Rejected by ${application.accountReadModel().lastRejectedBy()}`;
  };

  $scope.rejectedByBranchManager = function(application) {
    return (
      application.accountReadModel()?.lastRejectedByRole() === window.config.env.roles.branchManager &&
      application.status().name === 'pending_review'
    ); // jshint ignore:line
  };

  $scope.needsIdentityVerification = function (application) {
    return application.needsIdentityVerification() && $scope.isAdvisor($rootScope.currentUser);
  };

  $scope.canApprove = function (application) {
    if (!application.status.is.pendingReview() || !application.identityVerified()) {
      return false;
    }

    if ($scope.isAdvisor($rootScope.currentUser)) {
      return (
        _isPendingReview(application) &&
        $scope.currentUserisApprover(application) &&
        !application.needsIdentityVerification()
      );
    } else {
      return $scope.currentUserisApprover(application) && !application.needsIdentityVerification();
    }
  };

  $scope.currentUserisApprover = function (application) {
    return (
      application.latestAccountApproval()?.role === $rootScope.currentUser.roleName() ||
      ($scope.isAdvisor($rootScope.currentUser) && application.status().name === 'approved')
    ); // jshint ignore:line
  };

  $scope.canSubmit = function (application) {
    if (!application.status.is.pendingReview()) {
      return false;
    }

    return (
      _isPendingSubmit(application) &&
      $scope.isAdvisor($rootScope.currentUser) &&
      $scope.currentUserisApprover(application) &&
      !application.needsIdentityVerification()
    );
  };

  $scope.isAdvisor = function (user) {
    return user.roleName() === window.config.env.roles.advisor;
  };

  $scope.isBranchManager = function (user) {
    return user.roleName() === window.config.env.roles.branchManager;
  };

  $scope.showButtons = function(application) {
    return application.status().name !== 'rejected_by_wfm' && application.status().name !== 'rejected_by_client';
  };

  $scope.approve = function (application) {
    application.get_esign_url().then(
      (response) => {
        $scope.$root.esignUrl = response.esignUrl;
      },
      (error) => {
        $scope.$root.errorMessageEsignUrl =
          error.data.error || 'Unable to fetch the e-sign URL. Please try again.';
      }
    ); // jshint ignore:line

    modals.confirmAccountApproval().result.then(() => {
      window.open($scope.esignUrl, '_blank').focus();
    });
  };

  $scope.addNote = async function (application) {
    await $http.post(`/api/accounts/${application.accountId()}/mark_notes_read`);
    modals.addAccountNote(application).result.catch(() => application.reload());
  };

  $scope.showDownloadModal = function () {
    modals.downloadTable($scope.filterParam);
  };

  $scope.submit = function(application) {
    application.approve().then(() => {
      $state.reload();
    });
  };

  $scope.reject = function(application) {
    modals.rejectApplicationModal()
      .result.then(function(reason) {
        var path = [
          '', 'api', 'accounts', application.accountId(), 'reject'
        ].join('/');
        $http.put(path, {
          account: {
            rejectionReason: reason
          }
        }).then(function() {
          $state.reload();
        });
      });
  };

  $scope.resendForms = function(application) {
    modals.resendFormsModal(application.accountReadModel(), application, application.advisorCreateNewClientFlow())
      .result.then(function() {
        // Nothing to do once this modal is closed. The modal is purely informational.
        return true;
      });
  };

  $scope.regenerateForms = function(accountRM) {
    modals.genericConfirmationModal('Are you sure you want to regenerate the forms for this account?',
        'The old NAAF will be deleted. A new NAAF will be sent to the client. The IMA will be included as well if the client has not signed one.',
        'btn-success')
      .result
      .then(function() {
        $scope.$root.showLoading();
        return accountRM.regenerateForms();
      }).finally(function() {
        $scope.$root.hideLoading();
      });
  };

  $scope.loadStatus = function(application) {
    modals.applicationStatusModal(application.accountReadModel(), application, application.advisorCreateNewClientFlow())
      .result.then(function() {
        // Nothing to do once this modal is closed. The modal is purely informational.
      });
  };

  $scope.emailActionCallback = function(clientFlow) {
    $scope.bulkAccounts = $scope.bulkAccounts.map(application => {
      if (_applicationAdvisorCreateNewClientFlowId(application) === clientFlow.id) {
        application.statusId(config.types.ApplicationReadModelStatus.findByName('prefill_sent').id);
        application.sentAt(new Date());
      }
      return application;
    });
  };

  function _isPendingSubmit(application) {
    const pendingSubmitId = _.findWhere(config.types.AccountApproval, {
      name: 'pending_submit'
    }).id;

    return application.latestAccountApproval()?.statusId == pendingSubmitId// jshint ignore:line
  }

  function _isPendingReview(application) {
    const pendingId = _.findWhere(config.types.AccountApproval, {
      name: 'pending'
    }).id;

    return application.latestAccountApproval()?.statusId == pendingId // jshint ignore:line
  }

  function _relatedAccountFlows(clientFlowId) {
    return AdvisorCreateNewAccountFlow.where({
      advisorCreateNewClientFlowId: clientFlowId
    }, {
      force: true
    });
  }

  function _specifiedAccountFlow(id) {
    return AdvisorCreateNewAccountFlow.find({
        id: id
      })
      .then(flow => [flow]);
  }

  $scope.addNewClient = function() {
    //TODO
  };

  $scope.canDeleteApplicationAfterStarted = function(application) {
    return application.accountReadModel().canDelete();
  };

  $scope.deleteApplicationAfterStarted = function(application) {
    modals.deleteApplicationModal(application.accountReadModel().accountId())
      .result.then(function() {
        $state.reload();
      });
  };

  $scope.deleteAction = function(application) {
    const clientFlowId = _applicationAdvisorCreateNewClientFlowId(application);
    const deleteErrorMessage = 'Unable to delete the account. Please try again. If the problem persists, please contact us.';
    const modalTitle = 'Delete account prefill?';
    let _accountFlows;
    let modalBody;
    let errorHappened = false;
    let deleteLastPrefill = false;

    const promise = clientFlowId ? _relatedAccountFlows(clientFlowId) : _specifiedAccountFlow(application.advisorCreateNewAccountFlowId());
    promise.then((list) => {
        _accountFlows = list;
        switch (_accountFlows.length) {
          case 0:
            modalBody = 'This client has no accounts.  Are you sure you want to delete this client?';
            return $scope.$root.genericConfirmationModal(modalTitle, modalBody).result;
          case 1:
            return Account.where({
                userId: application.userId()
              }, {
                force: true
              })
              .then((accounts) => {
                if (accounts.length === 0) {
                  deleteLastPrefill = true;
                  return $scope.$root.confirmDeleteLastPrefillForClient().result;
                } else {
                  modalBody = 'Are you sure you want to delete this account?';
                  return $scope.$root.genericConfirmationModal(modalTitle, modalBody).result;
                }
              });
          default:
            modalBody = 'Are you sure you want to delete this account?';
            return $scope.$root.genericConfirmationModal(modalTitle, modalBody).result;
        }
      })
      .then((modalParam) => {
        if (!clientFlowId) {
          return _handleSimpleDelete(application);
        } else if (deleteLastPrefill) {
          return _handleDeleteLastPrefill(application, modalParam);
        } else if (_accountFlows.length === 0) {
          return _handleZeroAccountFlows(application);
        } else {
          return _handleSimpleDelete(application);
        }
      })
      .then(() => {
        if (errorHappened) {
          return;
        }
        userNotifications.showTransientSuccess('The account has been successfully deleted.');
      });

    function _handleSimpleDelete(application) {
      return AdvisorCreateNewAccountFlow.constructor
        .delete(application.advisorCreateNewAccountFlowId())
        .then(() => clientFlowId && application.advisorCreateNewClientFlow().advisorCreateNewAccountFlows.ready())
        .then(() => _filterResults(application.advisorCreateNewAccountFlowId(), _filterByAccountFlow))
        .catch(() => {
          userNotifications.showError(deleteErrorMessage);
          errorHappened = true;
        });
    }

    function _handleDeleteLastPrefill(application, modalParam) {
      if (modalParam === 'Remove client') {
        return application.advisorCreateNewClientFlow().delete()
          .then(() => _filterResults(clientFlowId, _filterByClientFlow))
          .catch(() => {
            userNotifications.showError(deleteErrorMessage);
            errorHappened = true;
          });

      } else {
        let _newApplication;
        return application.unlink()
          .then((newApplication) => {
            _newApplication = new ApplicationReadModel(newApplication.data);
            return AdvisorCreateNewAccountFlow.constructor
              .delete(application.advisorCreateNewAccountFlowId());
          })
          .then(() => _mapResults(_newApplication))
          .then(() => _newApplication.advisorCreateNewClientFlow.ready({
            force: true
          }))
          .then(() => AdvisorCreateNewAccountFlow.where({
            advisorCreateNewClientFlowId: clientFlowId
          }))
          .catch(() => {
            userNotifications.showError(deleteErrorMessage);
            errorHappened = true;
          });
      }
    }

    function _handleZeroAccountFlows(application) {
      return application.advisorCreateNewClientFlow().delete()
        .then(() => _filterResults(clientFlowId, _filterByClientFlow))
        .catch(() => {
          userNotifications.showError(deleteErrorMessage);
          errorHappened = true;
        });
    }
  };

  $scope.canRegenerateForms = function(application) {
    return application.accountReadModel()?.canRegenerateForms() && $scope.currentUserisApprover(application); // jshint ignore:line
  };

  function _filterResults(id, worker) {
    $scope.bulkAccounts = $scope.bulkAccounts.filter(application => worker(id, application));
  }

  function _mapResults(newApplication) {
    $scope.bulkAccounts = $scope.bulkAccounts.map(application => {
      if (application.id === newApplication.id) {
        return newApplication;
      } else {
        return application;
      }
    });
  }

  function _filterByAccountFlow(accountFlowId, application) {
    return application.advisorCreateNewAccountFlowId() !== accountFlowId;
  }

  function _filterByClientFlow(clientFlowId, application) {
    return _applicationAdvisorCreateNewClientFlowId(application) !== clientFlowId;
  }

  function _createApplicationReadModelStatuses() {
    const configARMStatusesWithoutHidden = config.types.ApplicationReadModelStatus.filter((status) => !status.hidden);

    // JSON.parse(JSON.stringify()) is used to create a deep copy so that it does not affect config.types.ApplicationReadModelStatus.
    let configARMStatuses = JSON.parse(JSON.stringify(configARMStatusesWithoutHidden));

    const orgPendingReview = config.types.ApplicationReadModelStatus.findByName('pending_review');
    const indexPendingReview = configARMStatusesWithoutHidden.indexOf(orgPendingReview);
    configARMStatuses.splice(indexPendingReview, 1);

    let pendingReviewWfmAdvisor = JSON.parse(JSON.stringify(orgPendingReview));
    pendingReviewWfmAdvisor.label = 'Pending Advisor Review';
    pendingReviewWfmAdvisor.name = pendingReviewWfmAdvisorStatus;
    configARMStatuses.splice(indexPendingReview, 0, pendingReviewWfmAdvisor);

    let pendingReviewWfmBranchManager = JSON.parse(JSON.stringify(orgPendingReview));
    pendingReviewWfmBranchManager.label = 'Pending BM Review';
    pendingReviewWfmBranchManager.name = pendingReviewWfmBranchManagerStatus;
    configARMStatuses.splice(indexPendingReview + 1, 0, pendingReviewWfmBranchManager);

    return configARMStatuses;
  }

  function _applicationAdvisorCreateNewClientFlowId(application) {
    if (typeof application.advisorCreateNewClientFlow() === 'undefined') {
      return undefined;
    } else {
      return application.advisorCreateNewClientFlow().id;
    }
  }
}
