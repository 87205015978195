'use strict';

angular
  .module('controller.modal.advisor.add-account-note', [
    'ram',
    'service.loading-indicator',
    'model.Note'
  ])
  .directive('elemReady', [
    '$parse',
    '$timeout',
    function ($parse, $timeout) {
      return {
        restrict: 'A',
        link: function ($scope, elem, attrs) {
          $timeout(function () {
            elem.ready(function () {
              $scope.$apply(function () {
                const func = $parse(attrs.elemReady);
                func($scope);
              });
            });
          });
        }
      };
    }
  ])
  .controller('ModalAddAccountNoteCtrl', [
    '$scope',
    '$timeout',
    'ram',
    'loadingIndicator',
    'account',
    'Note',
    controller
  ]);

function controller($scope, $timeout, ram, loadingIndicator, account, Note) {
  $scope.newNote = new ram.Accessor('');

  $scope.account = account;
  $scope.existingNotes = $scope.account.notes();

  $scope.resetNoteForm = function () {
    $scope.newNote('');
    setTextAreaHeight(MIN_TEXTAREA_HEIGHT);
  };

  $scope.saveNote = function () {
    Note.create({ accountId: $scope.account.id, text: $scope.newNote() }).then(function (note) {
      $scope.existingNotes.unshift(note);
      $scope.resetNoteForm();
      $timeout(setHeightBelowForAll, 50);
    });
  };

  $scope.setHeightBelow = function (index) {
    const box = document.getElementById('textarea-' + index);
    const height = box.scrollHeight;
    box.style.height = height + 'px';
  };

  const MIN_TEXTAREA_HEIGHT = 95;

  const setTextAreaHeight = function (height, e) {
    const element = typeof e === 'object' ? e.target : document.getElementById('advisor-new-note');
    element.style.height = height + 'px';
  };

  const setHeightBelowForAll = function () {
    _.each($scope.existingNotes, function (_note, index) {
      $scope.setHeightBelow(index);
    });
  };
}
