'use strict';

function homeController() {}

angular.module('controller.home', [
    'controller.home.main',
    'controller.home.portfolios',
    'controller.home.pricing',
    'controller.home.etfs'
  ])
  .controller('HomeCtrl', [
    homeController
  ]);
