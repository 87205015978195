'use strict';

angular.module('controller.modal.advisor.application-status', [])
  .controller('ModalAdvisorApplicationsStatusCtrl', [
    '$scope',
    'accountReadModel',
    'applicationReadModel',
    'clientFlow',
    applicationStatusController
  ]);

function applicationStatusController($scope, accountReadModel, applicationReadModel, clientFlow) {

  $scope.accountReadModel = accountReadModel;
  $scope.applicationReadModel = applicationReadModel;
  $scope.clientFlow = clientFlow;
  $scope.data = {
    hasPrefill: applicationReadModel && applicationReadModel.advisorCreateNewAccountFlowId(),
    client: null,
    account: null,
    startDate: null,
    prefillDate: null,
    passwordSet: null,
    applicationCompleted: false,
    sentAt: null,
    openedAt: null,
    rejectedAt: null,
  };

  if (!accountReadModel && applicationReadModel) {
    $scope.data.client = _trimOrDefault(applicationReadModel.clientName());
    $scope.data.account = applicationReadModel.type()?.statementName || applicationReadModel.type()?.label;
    $scope.data.prefillDate = applicationReadModel.startedAt();
    $scope.data.sentAt = applicationReadModel.sentAt();
    $scope.data.openedAt = applicationReadModel.openedAt();
    $scope.data.passwordSet = clientFlow && clientFlow.status() === 'completed';
    $scope.data.rejectedAt = applicationReadModel.rejectedAt();
    $scope.data.noDataPresent = !applicationReadModel.accountId() && !applicationReadModel.advisorCreateNewAccountFlowId();

  } else if (accountReadModel && $scope.data.hasPrefill) {
    $scope.data.client = accountReadModel.clientName();
    $scope.data.account = accountReadModel.type()?.statementName || accountReadModel.type()?.label;
    $scope.data.startDate = accountReadModel.startedAt();
    $scope.data.prefillDate = applicationReadModel.startedAt();
    $scope.data.sentAt = applicationReadModel.sentAt();
    $scope.data.openedAt = applicationReadModel.openedAt();
    $scope.data.rejectedAt = applicationReadModel.rejectedAt();

  } else {
    $scope.data.client = accountReadModel.clientName();
    // New accounts may not have an account type set yet so this can be null.
    $scope.data.account = accountReadModel.type()?.statementName || accountReadModel.type()?.label;
    $scope.data.startDate = accountReadModel.startedAt();
  }

  if (accountReadModel) {
    accountReadModel.approvalDetails = {};
    accountReadModel.approvalDetails.loading = true;

    accountReadModel.applicationStatus()
      .then(function(result) {
          accountReadModel.approvalDetails = result.data;
          $scope.data.applicationCompleted = accountReadModel.approvalDetails.applicationCompleted;

         let percentage = accountReadModel.approvalDetails.progress;

          accountReadModel.approvalDetails.progressStyle = {
            width: percentage + '%'
          };
        },
        function() {
          accountReadModel.approvalDetails.error = true;
        })
      .finally(function() {
        accountReadModel.approvalDetails.loading = false;
      });
  }

  function _trimOrDefault(clientName) {
    if (clientName.trim().length === 0) {
      return '(blank)';
    }
    return clientName;
  }
}
