'use strict';

angular.module('model.AdvisorCreateNewAccountFlow', [
    'config',
    'ram'
  ])
  .factory('AdvisorCreateNewAccountFlow', [
    '$http',
    'ram',
    'config',
    advisorCreateNewAccountFlowFactory
  ]);

function advisorCreateNewAccountFlowFactory($http, ram, config) {
  var AdvisorCreateNewAccountFlow = new ram.Collection('AdvisorCreateNewAccountFlow', {
    bind: ['clientLabel', 'type', 'descriptiveLabel'],
    belongsTo: {
      advisor: 'Person',
      client: 'Person',
      advisorCreateNewClientFlow: 'AdvisorCreateNewClientFlow'
    },
    resources: {
      default: new ram.resources.Http('/api/advisor_create_new_account_flows/:id.json'),
      cookie: new ram.resources.Cookie('advisor_create_new_account_flows')
    },
    enums: {
      accountType: config.types.Account,
      jurisdiction: config.types.AccountJurisdiction
    },
  });

  AdvisorCreateNewAccountFlow.constructor.incompleteFlowsForCurrentUser = function() {
    return $http.get('/api/advisor_create_new_account_flows/incomplete_flows.json')
      .then(function(response) {
        return _.map(response.data, function(data) {
          // Temporary objects do not try to manipulate
          return AdvisorCreateNewAccountFlow.new(data);
        });
      });
  };

  AdvisorCreateNewAccountFlow.prototype.type = function() {
    return this.accountType();
  };

  AdvisorCreateNewAccountFlow.prototype.descriptiveLabel = function() {
    return this.clientLabel();
  };

  AdvisorCreateNewAccountFlow.prototype.clientLabel = function() {
    if (this.accountNickname()) {
      return this.accountType().label + ' - ' + this.accountNickname();
    } else {
      return this.accountType().label;
    }
  };

  AdvisorCreateNewAccountFlow.constructor.delete = function(accountFlowId) {
    // Specifically using $http.delete instead of accountFlow.destroy since
    // since accountFlow.destroy will cause the accountFlow properties to
    // immediately update to be blank. This causes the accountFlow to have blank
    // fields in the UI after it is deleted and before the accountFlows are
    // updated.
    return $http.delete('/api/advisor_create_new_account_flows/' + accountFlowId + '.json');
  };

  return AdvisorCreateNewAccountFlow;
}
