'use strict';

function progressBarDirective() {
  return {
    restrict: 'E',
    templateUrl: 'directives/progress-bar.html',
    scope: {
      percentDone: '@',
      minAmountLabel: '@',
      maxAmountLabel: '@',
      currentAmountLabel: '@'
    },
    link: function(scope) {
      scope.progressWidthStyle = {
        width: scope.percentDone + '%'
      };
    }
  };
}

angular.module('directive.progress-bar', [])
  .directive('progressBar', [
    progressBarDirective
  ]);
