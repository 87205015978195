'use strict';

angular.module('controller.interview.goal', [
    'model.Question',
    'ram'
  ])
  .controller('InterviewGoalController', [
    '$cookies',
    '$scope',
    '$state',
    'questions',
    interviewGoalController
  ]);

function initNavigation($cookies, $scope, $state) {
  // TODO: refactor this into parent interview controller
  $scope.$parent.nextSection = function() {
    if ($state.params.preselectTrial || $scope.isOpeningGroupSavingsPlan()) {
      $state.go('interview.financial');
    } else if ($scope.isAdvisorCreateNewAccountFlow()) {
      if ($scope.account.type.joint()) {
        $state.go('interview.account-type');
      } else if ($scope.account.type.is.spousalRrsp()) {
        $state.go('interview.spousal-rrsp');
      } else {
        $state.go('interview.financial');
      }
    } else {
      $state.go($state.current.data.next);
    }
  };
  $scope.$parent.lastSection = function() {
    if (!$scope.currentUser && $cookies.get('interview_service_option') === 'digital') {
      $state.go('interview.service-options');
    } else if (!$scope.currentUser && isExternalAdvisorReferral()) {
      $state.go('interview.advisor-referral-disclosures');
    } else {
      $state.go($state.current.data.last);
    }
  };
  $scope.$parent.step = $state.current.data.step;
  $scope.$parent.header = $state.current.data.header;
  $scope.$parent.userCanGoBack = !$scope.currentUser && ($cookies.get('interview_service_option') === 'digital' || isExternalAdvisorReferral());

  function isExternalAdvisorReferral() {
    const groupUrlIdCookie = $cookies.getObject('group_url_id');
    return groupUrlIdCookie && !groupUrlIdCookie.urlId.startsWith('mapersonal');
  }
}

function interviewGoalController($cookies, $scope, $state, questions) {
  if ($scope.$root.pushStatePending) {
    history.pushState(null, null, $scope.$root.pushStatePending);
    $scope.$root.pushStatePending = undefined;
  }

  initNavigation($cookies, $scope, $state);

  $scope.questions = _.indexBy(questions, function(question) {
    return question.accessor();
  });

  // Options for mobile selectors
  $scope.timeframeOptions = makeSelectorOptions('timeframe');
  $scope.retirementAgeOptions = makeSelectorOptions('retirementAge');
  $scope.childsAgeOptions = makeSelectorOptions('childsAge');

  function makeSelectorOptions(timeframeAccessor) {
    var timeframe = $scope.questions[timeframeAccessor],
      values = _.range(timeframe.min(), timeframe.max(), timeframe.step()),
      options = {},
      prefix = timeframe.prefix(),
      suffix = timeframe.suffix(),
      lastSuffix;

    if (timeframeAccessor === 'retirementAge') {
      lastSuffix = ' years or more';
    } else if (timeframeAccessor === 'childsAge') {
      lastSuffix = ' years or older';
    } else {
      lastSuffix = ' years or longer';
    }

    _.each(values, function(value) {
      if (value === 1) {
        options[value] = prefix + value + suffix.replace('years', 'year');
      } else {
        options[value] = prefix + value + suffix;
      }
    });
    options[timeframe.max()] = prefix + timeframe.max() + lastSuffix;

    return options;
  }

  var savedRetirementAge = null;
  const save_for_retirement_label = window.config.types.AccountTypesRecommender.filter((goal) => {return goal.name === "save_for_retirement"})[0].label
  
  if ($scope.goal.preset() === save_for_retirement_label && $scope.goal.retirementAge()) {
    savedRetirementAge = $scope.goal.retirementAge();
  }

  // initialize to default timeframe if is empty
  if (!$scope.goal.timeframe()) {
    $scope.goal.preset($scope.goal.preset());
  }

  var retirementAgeQuestion = $scope.questions.retirementAge;
  var likelyRetirementAge = Math.min(
    Math.max(
      retirementAgeQuestion.min(),
      65 - $scope.user.age()
    ),
    retirementAgeQuestion.max()
  );

  $scope.$watch('goal.preset()', function(newGoal) {
    if (savedRetirementAge !== null) {
      $scope.goal.retirementAge(savedRetirementAge);
      savedRetirementAge = null;
      return;
    }

    if (newGoal === save_for_retirement_label) {
      $scope.goal.retirementAge(likelyRetirementAge);
    }
  });

  $scope.$watch('goal.notimeframe()', function(notimeframe) {
    if (notimeframe) {
      $scope.goal.timeframe(25);
      $scope.goal.save();
    }
  });
}
