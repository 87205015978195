'use strict';

angular.module('service.download-csv', [])
  .factory('downloadCsv', [downloadCsvFactory]);

function downloadCsvFactory() {

  function downloadBalancesCsv(accounts) {
    var csvRows = ['account number' + ',' +
        'date' + ',' +
        'balance'
      ],
      accountsProcessed = [];

    accounts.forEach(function(account) {
      accountsProcessed.push(account.number());
      account.balances().forEach(function(balance) {
        csvRows.push(account.number() + ',' +
          moment(balance.date()).format('YYYY-MM-DD') + ',' +
          balance.amount());
      });
    });

    var csvString = csvRows.join('\n'),
      downloadButton = document.getElementById('download-balances');
    downloadButton.href = 'data:attachment/csv,' + encodeURIComponent(csvString);

    // Currently assumes all accounts belong to the same client
    var filename = accounts[0].clientName() + ' - balances for accounts ' + accountsProcessed.join(' ') + '.csv';

    downloadButton.download = filename;
  }

  return {
    balances: downloadBalancesCsv
  };
}
