'use strict';

angular.module('controller.landing.welcome', [])
  .controller('WelcomeCtrl', [
    '$scope',
    '$state',
    '$stateParams',
    welcomeController
  ]);

function welcomeController($scope, $state, $stateParams) {
  if ($stateParams.affiliateName && $stateParams.affiliatePromo) {
    $scope.affiliateName = $stateParams.affiliateName;
    $scope.affiliatePromo = $stateParams.affiliatePromo;

    if ($scope.affiliatePromo.match(/bonus$/)) {
      $scope.wordSoItSoundsRight = 'a'; // ... you'll receive a $50 signup bonus ==> insert the word 'a'
    }
  } else if ($stateParams.referrerName) {
    $scope.referrerName = $stateParams.referrerName;
    $scope.firstName = $stateParams.referrerName.split(' ')[0];
  } else {
    window.location.href = '404.html';
  }
}
