'use strict';

angular.module('directive.dashboard-account-processing-messages', [
    'directive.account-processing-message',
    'directive.upcoming-transfers-table',
    'service.adaptive-views'
  ])
  .directive('dashboardAccountProcessingMessages', [
    'adaptiveViews',
    dashboardAccountProcessingMessagesDirective
  ]);

function dashboardAccountProcessingMessagesDirective(adaptiveViews) {
  return {
    restrict: 'E',
    scope: {
      account: '=',
      accountTransfers: '=',
      currentUser: '=',
      identityVerificationStatus: '='
    },
    templateUrl: 'directives/dashboard-account-processing-messages.html',
    controller: ['$scope', function($scope) {

      function needsSignature(document) {
        if (!document) {
          return true;
        } else {
          return !document.signedAt() && !document.widgetRedirectReceivedAt();
        }
      }

      $scope.isMobile = $scope.$root.isMobile;
      $scope.custodian = $scope.account.custodian();
      var account = $scope.account;
      var currentUser = $scope.currentUser;
      var naaf = account.newAccountApplicationForm();
      var ima = currentUser.investmentManagementAgreement();
      var naafNotSigned = needsSignature(naaf);
      var imaNotSigned = needsSignature(ima);
      var inlineNaaf = naaf && !_.isEmpty(naaf.widgetUrl());
      var inlineIma = ima && !_.isEmpty(ima.widgetUrl());

      // The transfer instructions need to be placed in an object in order for the
      // directives to pick up any changes to the transfer instructions
      var transferInstructionsContainer = {
        transferInstructions: []
      };

      $scope.transferInstructionsContainer = transferInstructionsContainer;

      $scope.$watchCollection('accountTransfers', function(accountTransfers) {
        $scope.pendingAccountTransfers = pendingAccountTransfersForAccount(accountTransfers);
      });

      $scope.$watchCollection('account.transferInstructions()', function(transferInstructions) {
        transferInstructionsContainer.transferInstructions = transferInstructions;
      });

      var oldestAccount = _.chain(currentUser.accounts())
        .reject(function(account) {
          return account.type.is.trial();
        })
        .sortBy('createdAt')
        .first()
        .value();

      var identityNotVerified = function() {
        return !$scope.identityVerificationStatus.identityVerified;
      };

      var documentsNeedSignature = function() {
        return (account.id === oldestAccount.id) && (imaNotSigned || naafNotSigned);
      };

      var imaNaafBundleNeedSignature = function() {
        return documentsNeedSignature() && ima && naaf &&
          (ima.adcKey() === naaf.adcKey() || (inlineNaaf && ima.widgetId() === naaf.widgetId()));
      };

      var isPending = function() {
        return account.status.is.pending() ||
          account.status.is.approved() ||
          (account.status.is.started() && account.applicationCompleted() && !naafNotSigned && !imaNotSigned);
      };

      var isOpen = function() {
        return account.status.is.open();
      };

      var isOpenButNotFunded = function() {
        return isOpen() && account.transfers().length === 0;
      };

      var hasPendingTransfer = function() {
        return $scope.pendingAccountTransfers.length > 0 ||
          (transferInstructionsContainer.transferInstructions &&
            transferInstructionsContainer.transferInstructions.length > 0);
      };

      function pendingAccountTransfersForAccount(accountTransfers) {
        return _.filter(accountTransfers, function(accountTransfer) {
          return accountTransfer.accountId() === account.id && !accountTransfer.status.is.completed();
        });
      }

      $scope.inlineIma = !!inlineIma;
      $scope.inlineNaaf = !!inlineNaaf;
      $scope.ima = ima;
      $scope.naaf = naaf;

      $scope.processingStatus = function() {
        // trial account messgages are handled in the dashboard views
        if (account.type.is.trial()) {
          return;
        }

        if (isOpenButNotFunded() && hasPendingTransfer()) {
          return 'openButNotFundedAndTransfersInProgress';
        } else if (isOpenButNotFunded()) {
          return 'openButNotFunded';
        } else if (isOpen()) {
          return 'open';
        } else if (isPending() && hasPendingTransfer()) {
          return 'pendingAndTransfersInProgress';
        } else if (isPending()) {
          return 'pending';
        } else if (identityNotVerified()) {
          return 'identityNotVerified';
        } else if (imaNaafBundleNeedSignature()) {
          return 'imaNaafBundleNeedSignature';
        } else if (naafNotSigned) {
          return 'naafNeedsSignature';
        } else if (imaNotSigned) {
          return 'imaNeedsSignature';
        }
      };

      $scope.isApp = adaptiveViews.isApp();

      $scope.transferForms = [{
        label: 'registered',
        url: 'assets/account-transfer-registered.pdf'
      }, {
        label: 'non-registered',
        url: 'assets/account-transfer-nonregistered.pdf'
      }];

      $scope.accountType = account.type().label;
    }]
  };
}
