import template from './date-range.html';
import controller from './date-range.controller';

const dateRangeComponent = {
  template,
  bindings: {
    startDate: '<',
    endDate: '<',
    interval: '@',
    dateRangePicked: '&'
  },
  controller: ['$scope', 'ram', controller],
  controllerAs: '$ctrl'
};

export default dateRangeComponent;
