'use strict';

function testProfileController($scope, $q, dropdownOptions) {
  $scope.yesOrNo = dropdownOptions.yesOrNo();
  var currentUser = $scope.currentUser;

  return {
    save: function() {
      console.log('chunk one saving...');
      return currentUser.save();
    },
    reset: function() {
      return currentUser.fetch(true);
    },
    isDirty: function() {
      return false;
    }
  };
}

function testProfileControllerChunkTwo($scope, $q, dropdownOptions) {
  $scope.yesOrNo = dropdownOptions.yesOrNo();
  var currentUser = $scope.currentUser;

  return {
    save: function() {
      console.log('chunk two saving...');
      var twentyFiveYearsAgo = moment().subtract(25, 'y').toDate();
      if (moment(currentUser.person().birthDate()).toDate() < twentyFiveYearsAgo) {
        var deferred = $q.defer();
        deferred.reject('reject reason, foo was false');
        return deferred.promise; // return failure to test failure option
      } else {
        return currentUser.save();
      }
    },
    reset: function() {
      return currentUser.fetch(true);
    },
    isDirty: function() {
      return false;
    }
  };
}

angular.module('controller.profile.test', [
    'directive.input',
    'directive.editableContext',
    'directive.editableField',
    'service.dropdown-options'
  ])
  .controller('TestProfileCtrl', [
    '$scope',
    '$q',
    'dropdownOptions',
    testProfileController
  ])
  .controller('TestProfileCtrlChunkTwo', [
    '$scope',
    '$q',
    'dropdownOptions',
    testProfileControllerChunkTwo
  ]);
