'use strict';

angular.module('routes.dashboard', [
    'ui.router',
    'controller.dashboard',
    'controller.dashboard-footer',
    'controller.dashboard.navbar',
    'model.AdvisorCreateNewAccountFlow',
    'model.AdvisorCreateNewTransferFlow',
    'model.AccountTransfer',
    'model.Goal',
    'model.Household',
    'model.HouseholdInvitation',
    'model.KycUpdate',
    'model.Profile',
    'model.Referral',
    'model.TransferInstruction',
    'service.account-service',
    'service.adaptive-views'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'config',
    'adaptiveViewsProvider',
    configRoutes
  ]);

/**
 * Initialize angular UI router for client dashboard.
 *
 * @param  {Object} $stateProvider API used to declare application states.
 */
function configRoutes($stateProvider, $urlRouterProvider, config, adaptiveViewsProvider) {

  function resolveTrialAccount(accounts) {
    return _.find(accounts, function(account) {
      return account.type && account.type.is.trial();
    });
  }

  function resolveViewableAccounts($q, currentUser) {
    if (currentUser) {
      return currentUser.viewableAccounts();
    }

    return [];
  }

  function resolveAccounts(currentUser, viewableAccounts, accountService) {
    if (!currentUser) {
      return [];
    }

    var ownAccounts = _.filter(viewableAccounts, function(account) {
      return account.userIsHolder();
    });
    var hasHouseholdAccounts = ownAccounts.length < viewableAccounts.length;

    var visible = accountService.getVisibleAccounts(viewableAccounts);
    var visibleOwned = accountService.getVisibleAccounts(ownAccounts);
    if (visible.length === 0) {
      var trial = resolveTrialAccount(ownAccounts);
      if (trial) {
        visible.push(trial);
      }
    }

    if (visibleOwned.length > 1) {
      visible.unshift(currentUser.accountsCombined);
    }

    if (hasHouseholdAccounts) {
      visible.unshift(currentUser.householdCombined);
    }

    var sorted = _.sortBy(visible, function(account) {
      return account.userIsHolder() ? 0 : 1;
    });

    return sorted;
  }

  function resolveAccountTransfers(account) {
    return account && account.accountTransfers.ready();
  }

  /**
   * Get the list of accounts that the user can make account transfers to.
   * This excludes trial accounts, but includes all accounts that are open, approved, pending,
   * and started.  Even if the account doesn't have a number.
   *
   * @returns {object}              - An array of accounts that may be smaller than
   *                                  currentUser.accounts()
   */
  function resolveTransferableAccounts(accounts) {
    var transferableAccounts = _.filter(accounts, function(account) {
      return account.userIsHolder() &&
        account.status &&
        (account.status.is.started() || account.status.is.pending() || account.status.is.approved() || account.status.is.open()) &&
        !account.type.is.trial();
    });
    return transferableAccounts;
  }

  function resolveAccount($stateParams, accounts) {
    if (!$stateParams.accountId) {
      return _.first(accounts);
    }
    var id = $stateParams.accountId === '-' || $stateParams.accountId === 'h' ? $stateParams.accountId : +$stateParams.accountId;
    return _.findWhere(accounts, {
      id: id
    }) || _.first(accounts);
  }

  function resolveAccountGoals(account) {
    if (account.isCombinedAccount()) {
      return;
    }
    return account.accountGoals.ready();
  }

  function resolveBalances($q, accounts) {
    _.map(accounts, function(account) {
      account.createBalancesHistory();
    });
    return true;
  }

  function resolvePositions(account) {
    if (!account) {
      return undefined;
    }
    return account
      .positions
      .ready()
      .then(function() {
        return account.nonZeroPositions();
      });
  }

  function resolveFunds(positions, $q) {
    return $q.all(_.map(positions, function(position) {
      return position.fund.ready();
    }));
  }

  function resolveActivities(account) {
    return account && account.activities.ready();
  }

  function resolveBankAccounts(currentUser) {
    return currentUser && currentUser.bankAccounts.ready();
  }

  function resolveIncompleteAccountFlows(AdvisorCreateNewAccountFlow) {
    return AdvisorCreateNewAccountFlow.constructor.incompleteFlowsForCurrentUser();
  }

  function resolveGoal(account, accountGoals) {
    if (account.isCombinedAccount()) {
      return;
    }
    return accountGoals[0].goal.ready();
  }

  function resolveModelPortfolio(account) {
    if (account.isCombinedAccount()) {
      return;
    }

    var modelPortfolio;

    return account.accountGoals.ready()
      .then(function(accountGoals) {
        return accountGoals[0].modelPortfolio.ready();
      })
      .then(function(modelPortfolioResult) {
        modelPortfolio = modelPortfolioResult;
        return modelPortfolio.allocations.ready();
      })
      .then(function() {
        return modelPortfolio;
      });
  }

  function resolveTransfers($q, accounts) {
    return $q.all(_.map(accounts, function(account) {
        return account.transfers();
      }))
      .then(function() {
        _.map(accounts, function(account) {
          account.createTransfersHistory();
        });
        return true;
      });
  }

  function resolveAccountOpeningDocuments(currentUser, accounts) {
    if (!currentUser) {
      return [];
    }

    const documentTypes = [
      'ima',
      'naaf',
      'kyc',
      'rc520',
      'rc518',
      'w8ben',
      'w8ben_joint',
      'w9',
      'w9_joint',
      'cadb',
      'account_agreement_bfs',
      'bfs_reg_application',
      'ccs_wfm_consent_form'
    ];
    const documentTypesRequiringSignature = [
      'ima',
      'naaf',
      'rc520',
      'rc518',
      'w8ben',
      'w8ben_joint',
      'w9',
      'w9_joint',
      'account_agreement_bfs',
      'bfs_reg_application',
      'ccs_wfm_consent_form'
    ];

    var createdAt = _.deepAccess(_.last(accounts), 'createdAt');

    var documents = [];
    var allDocs = currentUser.documents();
    var mostRecentKyc;

    // Helper; Populates 'documents' with docs other than IPSs and populates mostRecentIps and sets mostRecentKyc
    var filterDocs = function(doc) {
      if (doc.type.is.kyc()) {
        if (!mostRecentKyc || doc.createdAt() > mostRecentKyc.createdAt()) {
          mostRecentKyc = doc;
        }
      } else if (_.contains(documentTypes, doc.type().name)) {
        documents.push(doc);
      }
    };

    // Filter allDocs to only get desired docs; store in documents
    _.each(allDocs, filterDocs);

    // Add mostRecentKyc to documents
    if (mostRecentKyc) {
      documents.push(mostRecentKyc);
    }

    var createRelatedAccountObj = function(doc) {
      if (doc.relatedAccount()) {
        return _.find(accounts, function(account) {
          return account.id === doc.relatedAccount().id;
        });
      }
    };

    documents = documents
      .map(function(doc) {
        var pendingSignature = _.contains(documentTypesRequiringSignature, doc.type().name) && !doc.signedAt();
        return {
          url: doc.url(),
          label: doc.type().label,
          date: doc.createdAt(),
          account: createRelatedAccountObj(doc),
          pendingSignature: pendingSignature
        };
      });

      documents.push({
        url: 'assets/gc_equity_i.pdf',
        label: 'GC One Equity Fund I',
        date: createdAt,
        symbol: "GCG495"
      }, {
        url: 'assets/gc_income_i.pdf',
        label: 'GC One Fixed Income Fund I',
        date: createdAt,
        symbol: "GCG496"
      }, {
        url: 'assets/sg_growth_100_i.pdf',
        label: 'Sustainable Growth 100 Fund I',
        date: createdAt,
        symbol: "GCG404"
      }, {
        url: 'assets/sg_income_100_i.pdf',
        label: 'Sustainable Income 100 Fund I',
        date: createdAt,
        symbol: "GCG409"
      });

    return documents;
  }

  function resolveConfirmsDocuments(currentUser, accounts) {
    if (!currentUser) {
      return [];
    }

    return filterDocuments(currentUser, function(doc) {
      return (doc.type().tradeConfirmation);
    });
  }

  function filterDocuments(currentUser, predicate) {
    if (!currentUser) {
      return [];
    }

    return _.filter(currentUser.documents(), predicate);
  }

  function resolveTaxDocuments(currentUser) {
    return filterDocuments(currentUser, function(doc) {
      return (doc.type().tax);
    });
  }

  function resolveStatements(currentUser) {
    return filterDocuments(currentUser, function(doc) {
      return (doc.type().statement);
    });
  }

  function authorize(authorizer) {
    return authorizer('Client');
  }

  function authorizeNotPersonalClient(authorizer) {
    return authorizer.blockPermission('personalClient');
  }

  function resolveIncompleteAccount(currentUser, accounts, accountService) {
    return currentUser && accountService.findIncompleteAccount(currentUser.accounts());
  }

  function resolveRedirectToState(redirect, currentUser, accounts, accountService) {
    var newState = accountService.getNavigationState(currentUser.accounts(), currentUser);

    if (adaptiveViewsProvider.isApp() && !accountService.hasCompletedAccount(accounts)) {
      redirect.to('mobile-app-messages.client-onboarding').always();
    } else if (newState) {
      redirect.to(newState).always();
      return true;
    }
  }

  function resolveInvestmentManagementAgreement(currentUser) {
    if (currentUser) {
      return currentUser.investmentManagementAgreement.ready();
    }
  }

  function resolveNewAccountApplicationForms($q, accounts) {
    var regularAccounts = _.reject(accounts, function(account) {
      return account.isCombinedAccount();
    });

    return _.map(regularAccounts, function(regularAccount) {
      return regularAccount.newAccountApplicationForm();
    });
  }

  function resolveTransferRequests(TransferRequest) {
    return TransferRequest.where({});
  }

  function resolveTransferInstructions($q, TransferInstruction) {
    return TransferInstruction.where({});
  }

  function resolveWfmTransferableAccounts($http, currentUser) {
    return $http
      .get('/api/users/' + currentUser.id + '/external_accounts')
      .then(function (response) {
        return response.data.externalAccounts;
      });
  }

  function resolveKycUpdate(currentUser, KycUpdate) {
    return currentUser && KycUpdate.find({
      userId: currentUser.id,
      completed: false
    });
  }

  function resolveExternalAdvisor(currentUser) {
    return currentUser && currentUser.externalAdvisorProfile();
  }

  function resolveReferrals(currentUser, Referral) {
    return currentUser && Referral.where({
      referrerId: currentUser.id
    });
  }

  function resolveReferralDetails($http) {
    return $http.get('/api/users/referral_details.json')
      .then(function(response) {
        if (response.data) {
          return response.data;
        }
      });
  }

  function resolveHousehold(Household) {
    return Household.where({})
      .then(function(households) {
        return households[0];
      });
  }

  function resolvePendingHouseholdInvitations(HouseholdInvitation) {
    return HouseholdInvitation.where({
      statusId: config.types.HouseholdInvitationStatus.findByName('pending').id
    });
  }

  function resolveInlineSignatureDocuments(currentUser) {
    if (!currentUser) {
      return [];
    }

    return _.filter(currentUser.documents(), function(document) {
      return document.needsSignature() && document.inlineSignature();
    });
  }

  function resolveProfile(Profile) {
    return Profile.where({})
      .then(function(profiles) {
        return profiles[0];
      });
  }

  function resolveCustodian(accounts) {
    // This is assuming that all accounts are at the same custodian.
    if (accounts.length > 1 && accounts[0].isCombinedAccount()) {
      // The first account is a CombinedAccount which does not have the custodian.
      return accounts[1].custodian();
    } else {
      return accounts[0].custodian();
    }
  }

  function resolveIdentityVerificationStatus($http) {
    return $http.get('api/identity_verification_status.json')
      .then(function(response) {
        return response.data;
      });
  }

  function createSummaryStateDefinition() {
    if (adaptiveViewsProvider.isMobile()) {
      return {
        url: '/account/:accountId/summary?showFormEsignedAlert',
        templateUrl: 'mobile/views/dashboard/summary.html',
        data: {
          menuTitle: 'Summary'
        },
        params: {
          showSuccessMessage: null
        },
        hasMobileView: true,
        cssClass: 'with-account-selector',
        controller: 'MobileDashboardSummaryCtrl',
        resolve: {
          positions: ['account', resolvePositions],
          fund: ['positions', '$q', resolveFunds]
        }
      };
    }

    return {
      url: '/account/:accountId/summary?showFormEsignedAlert',
      controller: 'DashboardSummaryCtrl',
      data: {
        tracking: {
          pageTitle: 'Account summary',
          pageId: 'accounts',
          pageType: 'product-account-performance',
          contents: {
            content: [{
              type: "summary",
            }],
            category: { primary: "accounts" }
          },
        }
      },
      params: {
        showSuccessMessage: null
      },
      resolve: {
        externalAdvisor: ['currentUser', resolveExternalAdvisor]
      }
    };
  }

  // for backward compatibility of '/dashboard' URL
  $urlRouterProvider.when('/dashboard', ['$state', function($state) {
    $state.transitionTo('dashboard.summary');
  }]);

  $stateProvider
    .state('dashboard', {
      url: '/dashboard',
      data: {
        menuTitle: 'Accounts'
      },
      params: {
        accountId: '-'
      },
      hasMobileView: true,
      parent: 'site',
      resolve: {
        authorize: ['authorizer', authorize],
        viewableAccounts: ['$q', 'currentUser', resolveViewableAccounts],
        accounts: ['currentUser', 'viewableAccounts', 'accountService', 'authorize', resolveAccounts],
        account: ['$stateParams', 'accounts', resolveAccount],
        accountTransfers: ['account', resolveAccountTransfers],
        trialAccount: ['accounts', resolveTrialAccount],
        balances: ['$q', 'accounts', resolveBalances],
        activities: ['account', resolveActivities],
        transfers: ['$q', 'accounts', resolveTransfers],
        accountService: 'accountService',
        // The following line *must* depend on 'accounts' or else it won't take any
        // new accounts just created into it's decision process.
        incompleteAccount: ['currentUser', 'accounts', 'accountService', resolveIncompleteAccount],
        route: ['redirect', 'currentUser', 'accounts', 'accountService', resolveRedirectToState],
        ima: ['currentUser', resolveInvestmentManagementAgreement],
        naaf: ['$q', 'accounts', resolveNewAccountApplicationForms],
        kycUpdate: ['currentUser', 'KycUpdate', resolveKycUpdate],
        household: ['Household', resolveHousehold],
        pendingHouseholdInvitations: ['HouseholdInvitation', resolvePendingHouseholdInvitations],
        inlineSignatureDocuments: ['currentUser', resolveInlineSignatureDocuments],
        bankAccounts: ['currentUser', resolveBankAccounts],
        incompleteAccountFlows: ['AdvisorCreateNewAccountFlow', resolveIncompleteAccountFlows],
        advisorProfile: ['Profile', resolveProfile],
        identityVerificationStatus: ['$http', resolveIdentityVerificationStatus]
      },
      views: {
        'main': {
          controller: 'DashboardCtrl'
        },
        'navbar': {
          controller: 'DashboardNavbarCtrl',
          templateUrl: adaptiveViewsProvider.getTemplateUrlForSrc('navbar/dashboard.html', true)
        },
        'footer': {
          controller: 'DashboardFooterCtrl',
          templateUrl: adaptiveViewsProvider.isMobile() ? 'mobile/partials/footer.html' : 'partials/footer-minimal.html'
        }
      },
      abstract: true
    })
    .state('dashboard.goals', {
      url: '/goals',
      data: {
        menuTitle: 'Goals',
        tracking: {
          pageTitle: 'Goals',
          pageType: 'goal-list',
          contents: {
            content: [{
              type: "list",
            }],
            category: { primary: "accounts", subcategory1: "goals" },
          },
          lists: [{
            id: "account-goals",
            name: "Goals",
            type: "goals"
          }]
        }
      },
      controller: 'DashboardGoalSummaryController',
      templateUrl: adaptiveViewsProvider.isMobile() ? 'mobile/views/dashboard/goal-summary.html' : 'views/dashboard/goal-summary.html',
      resolve: {
        goals: ['$q', 'Goal', function($q, Goal) {
          return Goal.where({});
        }],
        accountGoals: ['$q', 'goals', function($q, goals) {
          return $q.all(
              _.map(goals, function(goal) {
                return goal.accountGoals.ready();
              })
            )
            .then(function(accountGoals) {
              return _.flatten(accountGoals);
            });
        }],
        accounts: ['$q', 'accountGoals', function($q, accountGoals) {
          return $q.all(
            _.map(accountGoals, function(accountGoal) {
              return accountGoal.account.ready();
            })
          );
        }],
        completedAccountGoals: ['$q', 'accountGoals', 'accounts', function($q, accountGoals, accounts) {
          return $q.all(
            accountGoals.filter(accountGoal => {
              const accountId = accountGoal.accountId();
              const currentAccount = accounts.find(account => account.id === accountId);
              const currentAccountCompleted = currentAccount.applicationCompleted();

              return currentAccountCompleted;
            })
          );
        }],
        modelPortfolios: ['$q', 'completedAccountGoals', function($q, completedAccountGoals) {
          return $q.all(
            completedAccountGoals.map(accountGoal => accountGoal.modelPortfolio.ready())
          );
        }],
        projections: ['$q', 'modelPortfolios', function($q, modelPortfolios) {
          return $q.all(
            _.map(modelPortfolios, function(modelPortfolio) {
              return modelPortfolio === undefined ? [] : modelPortfolio.projections.ready();
            })
          );
        }],
        allocations: ['$q', 'modelPortfolios', function($q, modelPortfolios) {
          return $q.all(
            _.map(modelPortfolios, function(modelPortfolio) {
              return modelPortfolio === undefined ? [] : modelPortfolio.allocations.ready();
            })
          ).then(function(allocations) {
            return _.flatten(allocations);
          });
        }],
        funds: ['$q', 'allocations', function($q, allocations) {
          return $q.all(
            _.map(allocations, function(allocation) {
              return allocation.fund.ready();
            })
          );
        }],
        transferInstructions: ['TransferInstruction', function(TransferInstruction) {
          return TransferInstruction.where({});
        }]
      }
    })
    .state('dashboard.summary', createSummaryStateDefinition())
    .state('dashboard.account', {
      url: '/account/:accountId/modify',
      data: {
        menuTitle: 'Account',
        backButtonState: 'dashboard.summary'
      },
      hasMobileView: true,
      cssClass: 'with-account-selector',
      controller: 'DashboardAccountCtrl',
      resolve: {
        authorize: ['authorizer', authorizeNotPersonalClient],
        accountGoals: ['account', resolveAccountGoals],
        goal: ['account', 'accountGoals', resolveGoal],
        modelPortfolio: ['account', resolveModelPortfolio]
      }
    })
    .state('dashboard.holdings', {
      url: '/account/:accountId/holdings',
      data: {
        menuTitle: 'Holdings',
        tracking: {
          pageTitle: 'Holdings',
          pageType: 'product-account-holdings',
          contents: {
            category: { primary: "accounts", subcategory1: "holdings" },
            content: [{
              type: "list",
            }]
          },
          lists: [{
            id: "account-holdings",
            name: "Holdings",
            type: "holdings"
          }]
        }
      },
      controller: 'DashboardHoldingsCtrl',
      resolve: {
        positions: ['account', resolvePositions],
        fund: ['positions', '$q', resolveFunds]
      }
    })
    .state('dashboard.schedule-call', {
      url: '/schedule-call',
      templateUrl: 'views/dashboard/schedule-call.html',
      controller: 'modalScheduleCallCtrl',
      resolve: {
        hubspotUrl: ['advisorProfile', function(advisorProfile) {
          return advisorProfile && advisorProfile.hubspotUrl();
        }]
      }
    })
    .state('dashboard.transfer', {
      url: '/transfer?showFormEsignedAlert&initialTab',
      data: {
        menuTitle: 'Transfer',
        tracking: {
          pageTitle: 'Move Funds',
          pageId: 'funding',
          pageType: 'transactions',
          contents: {
            content: [{
              type: "form",
            }],
            category: { primary: "accounts", subcategory1: "transfers" }
          },
          forms: [{
            id: "transaction-funds-transfer",
            name: "Set up transaction details",
            type: "transaction"
          }]
        }
      },
      params: {
        defaultTransferType: null,
        initialTab: 'new-transfer',
        initialMobileViewTransfersState: 'upcoming-transfers',
        showSuccessMessage: null
      },
      hasMobileView: true,
      controller: 'DashboardTransferCtrl',
      resolve: {
        transferableAccounts: ['accounts', resolveTransferableAccounts],
        transferRequests: ['TransferRequest', resolveTransferRequests],
        transferInstructions: ['$q', 'TransferInstruction', resolveTransferInstructions],
        wfmTransferableAccounts: ['$http', 'currentUser', resolveWfmTransferableAccounts]
      }
    })
    .state('dashboard.transfer-account-submitted', {
      url: '/account-transfer/:accountTransferId/submitted',
      data: {
        menuTitle: 'Transfer Account'
      },
      hasMobileView: true,
      controller: 'DashboardTransferAccountSubmittedCtrl',
      resolve: {
        submittedAccountTransfer: ['AccountTransfer', '$stateParams', function(AccountTransfer, $stateParams) {
          return AccountTransfer.find({
            id: $stateParams.accountTransferId
          });
        }]
      }
    })
    .state('dashboard.activity', {
      url: '/account/:accountId/activity',
      data: {
        menuTitle: 'Activity',
        tracking: {
          pageTitle: 'Activity',
          pageType: 'transactions',
          contents: {
            content: [{
              type: "list",
            }],
            category: { primary: "accounts", subcategory1: "activity" }
          },
          lists: [{
            id: "account-transactions",
            name: "Activity",
            type: "activity"
          }]
        }
      },
      hasMobileView: true,
      cssClass: 'with-account-selector',
      controller: 'DashboardActivityController',
      resolve: {
        activities: ['account', resolveActivities]
      }
    })
    .state('dashboard.documents', {
      url: '/account/:accountId/documents',
      data: {
        menuTitle: 'Documents',
        tracking: {
          pageTitle: 'E-Documents',
          pageId: 'documents',
          pageType: 'document-list',
          contents: {
            content: [{
              type: "list",
            }],
            category: { primary: "accounts", subcategory1: "documents" }
          },
          lists: [{
            id: "account-documents",
            name: "Documents",
            type: "documents"
          }]
        }
      },
      cssClass: 'with-account-selector',
      hasMobileView: true,
      controller: 'DashboardDocumentsCtrl',
      resolve: {
        custodian: ['accounts', resolveCustodian],
        statements: ['currentUser', resolveStatements],
        taxDocuments: ['currentUser', resolveTaxDocuments],
        accountOpeningDocuments: ['currentUser', 'accounts', resolveAccountOpeningDocuments],
        confirmsDocuments: ['currentUser', 'accounts', resolveConfirmsDocuments]
      }
    })
    .state('dashboard.refer-a-friend', {
      url: '/refer-a-friend',
      data: {
        menuTitle: ''
      },
      controller: 'DashboardReferAFriendController',
      resolve: {
        authorize: ['authorizer', authorizeNotPersonalClient],
        referrals: ['currentUser', 'Referral', resolveReferrals],
        referralDetails: ['$http', resolveReferralDetails]
      }
    });
}
