'use strict';

angular.module('model.PersonalRelation', [
    'ram',
    'config',
    'model.Person'
  ])
  .factory('PersonalRelation', [
    'ram',
    'config',
    personalRelationFactory
  ]);

function personalRelationFactory(ram, config) {

  var PersonalRelation = new ram.Collection('PersonalRelation', {
    enums: {
      type: config.types.PersonalRelation
    },
    belongsTo: {
      person: 'Person',
      relative: 'Person'
    },
    schema: config.schemas.PersonalRelation,
    serialize: ['relative'],
    resources: {
      default: new ram.resources.Http('/api/personal_relations/:id.json'),
      cookie: new ram.resources.Cookie('personal_relations')
    }
  });

  /**
   * Look at the type of relation and return a noun describing the relative.
   * @return {String}
   */
  PersonalRelation.prototype.informalName = function() {
    return this.type.is.commonLaw() ? 'partner' : this.type().label;
  };

  return PersonalRelation;
}
