'use strict';

function factory(ram, config) {

  var BrokerageAccount = new ram.Collection('BrokerageAccount', {
    belongsTo: {
      user: 'User'
    },
    resources: {
      default: new ram.resources.Http('/api/brokerage_accounts/:id.json'),
      cookie: new ram.resources.Cookie('brokerage_accounts')
    },
    schema: config.schemas.BrokerageAccount
  });

  return BrokerageAccount;
}

angular.module('model.BrokerageAccount', [
    'ram',
    'config'
  ])
  .factory('BrokerageAccount', [
    'ram',
    'config',
    factory
  ]);
