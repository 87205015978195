'use strict';

angular.module('model.Note', [
    'ram',
    'config',
    'model.Person',
    'model.Account'
  ])
  .factory('Note', [
    'ram',
    'config',
    noteFactory
  ]);

function noteFactory(ram, config) {

  var Note = new ram.Collection('Note', {
    accessors: ['fullName'],
    belongsTo: {
      person: 'Person',
      account: 'Account'
    },
    resources: {
      default: new ram.resources.Http('/api/notes/:id.json'),
      cookie: new ram.resources.Cookie('notes')
    },
    schema: config.schemas.Note
  });

  return Note;
}
