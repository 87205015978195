'use strict';

angular.module('directive.input.validate-decimal-places', [])
  .directive('validateDecimalPlaces', [
    validateDecimalPlacesDirective
  ]);

function validateDecimalPlacesDirective() {
  return {
    restrict: 'A',
    require: 'ngModel',
    scope: {
      maxNumberOfDecimalPlaces: '@'
    },
    link: function(scope, element, attrs, ngModelCtrl) {
      ngModelCtrl.$validators.maxdecimalplaces = function(value) {
        if (!_.isNumber(value)) {
          return true;
        }

        var decimalsMatch = value.toString().match(/\.(\d*)/);

        if (decimalsMatch) {
          return decimalsMatch[1].length <= scope.maxNumberOfDecimalPlaces;
        } else {
          return true;
        }
      };
    }
  };
}
