'use strict';

angular.module('routes.iframe-widgets.etfs', [
    'controller.iframe-widgets.etfs',
    'service.resolve-helpers.etfs',
    'templates',
    'ui.router'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    configRoutes
  ]);

function configRoutes($stateProvider, $urlRouterProvider) {

  function routeToFirstEtf($state) {
    $state.transitionTo('iframe-widgets.etfs.ishares-core-cci');
  }

  $urlRouterProvider
    .when('/iframe-widgets/etfs', ['$state', routeToFirstEtf]);

  function resolveChartData(etf, etfsResolveHelpers) {
    return etfsResolveHelpers.resolveChartData(etf);
  }

  $stateProvider
    .state('iframe-widgets.etfs', {
      url: '/etfs',
      abstract: true,
      data: {
        title: 'Etfs'
      },
      resolve: {
        chartData: function() {
          return undefined;
        },
        etf: function() {
          return angular.noop;
        }
      },
      views: {
        '': {
          controller: 'iframeWidgetsEtfsCtrl',
          templateUrl: 'views/iframe-widgets/etfs.html'
        }
      }
    })
    //Canadian Stocks
    .state('iframe-widgets.etfs.ishares-core-cci', {
      url: '/ishares-core-capped-composite-index-cad-hedged-etf',
      controller: 'iframeWidgetsEtfsCtrl',
      resolve: {
        etf: ['etfsResolveHelpers', function(etfsResolveHelpers) {
          return etfsResolveHelpers.etfs.xic;
        }],
        chartData: ['etf', 'etfsResolveHelpers', resolveChartData]
      },
      templateUrl: 'views/home/etfs/details.html'
    })
    .state('iframe-widgets.etfs.ishares-esg-advanced-msci', {
      url: '/ishares-esg-advanced-msci-index-etf',
      controller: 'iframeWidgetsEtfsCtrl',
      resolve: {
        etf: ['etfsResolveHelpers', function(etfsResolveHelpers) {
          return etfsResolveHelpers.etfs.xcsr;
        }],
        chartData: ['etf', 'etfsResolveHelpers', resolveChartData]
      },
      templateUrl: 'views/home/etfs/details.html'
    })
    //US Stocks
    .state('iframe-widgets.etfs.vanguard-us-tm', {
      url: '/vanguard-us-total-market-cad-hedged-etf',
      controller: 'iframeWidgetsEtfsCtrl',
      resolve: {
        etf: ['etfsResolveHelpers', function(etfsResolveHelpers) {
          return etfsResolveHelpers.etfs.vus;
        }],
        chartData: ['etf', 'etfsResolveHelpers', resolveChartData]
      },
      templateUrl: 'views/home/etfs/details.html'
    })
    .state('iframe-widgets.etfs.ishares-msci-kld-400', {
      url: '/ishares-msci-kld-400-social-etf',
      controller: 'iframeWidgetsEtfsCtrl',
      resolve: {
        etf: ['etfsResolveHelpers', function(etfsResolveHelpers) {
          return etfsResolveHelpers.etfs.dsi;
        }],
        chartData: ['etf', 'etfsResolveHelpers', resolveChartData]
      },
      templateUrl: 'views/home/etfs/details.html'
    })
    //Non-North American Developed Market Stocks
    .state('iframe-widgets.etfs.vanguard-ftse-dena', {
      url: '/vanguard-ftse-developed-ex-north-america-cad-hedged-etf',
      controller: 'iframeWidgetsEtfsCtrl',
      resolve: {
        etf: ['etfsResolveHelpers', function(etfsResolveHelpers) {
          return etfsResolveHelpers.etfs.vef;
        }],
        chartData: ['etf', 'etfsResolveHelpers', resolveChartData]
      },
      templateUrl: 'views/home/etfs/details.html'
    })
    //Emerging Market Stocks
    .state('iframe-widgets.etfs.vanguard-ftse-em', {
      url: '/vanguard-ftse-emerging-markets-etf',
      controller: 'iframeWidgetsEtfsCtrl',
      resolve: {
        etf: ['etfsResolveHelpers', function(etfsResolveHelpers) {
          return etfsResolveHelpers.etfs.vee;
        }],
        chartData: ['etf', 'etfsResolveHelpers', resolveChartData]
      },
      templateUrl: 'views/home/etfs/details.html'
    })
    //Canadian Bonds
    .state('iframe-widgets.etfs.vanguard-canadian-stb', {
      url: '/vanguard-canadian-short-term-bond-etf',
      controller: 'iframeWidgetsEtfsCtrl',
      resolve: {
        etf: ['etfsResolveHelpers', function(etfsResolveHelpers) {
          return etfsResolveHelpers.etfs.vsb;
        }],
        chartData: ['etf', 'etfsResolveHelpers', resolveChartData]
      },
      templateUrl: 'views/home/etfs/details.html'
    })
    .state('iframe-widgets.etfs.ishares-laddered-etf', {
      url: '/ishares-1-5-year-laddered-government-bond-index-etf',
      controller: 'iframeWidgetsEtfsCtrl',
      resolve: {
        etf: ['etfsResolveHelpers', function(etfsResolveHelpers) {
          return etfsResolveHelpers.etfs.clf;
        }],
        chartData: ['etf', 'etfsResolveHelpers', resolveChartData]
      },
      templateUrl: 'views/home/etfs/details.html'
    })
    //Emerging Market Bonds
    .state('iframe-widgets.etfs.bmo-emerging-markets-bch', {
      url: '/bmo-emerging-markets-bond-cad-hedged-index-etf',
      controller: 'iframeWidgetsEtfsCtrl',
      resolve: {
        etf: ['etfsResolveHelpers', function(etfsResolveHelpers) {
          return etfsResolveHelpers.etfs.zef;
        }],
        chartData: ['etf', 'etfsResolveHelpers', resolveChartData]
      },
      templateUrl: 'views/home/etfs/details.html'
    })
    //8
    .state('iframe-widgets.etfs.vanguard-capped-reits', {
      url: '/vanguard-capped-reits-index-etf',
      controller: 'iframeWidgetsEtfsCtrl',
      resolve: {
        etf: ['etfsResolveHelpers', function(etfsResolveHelpers) {
          return etfsResolveHelpers.etfs.vre;
        }],
        chartData: ['etf', 'etfsResolveHelpers', resolveChartData]
      },
      templateUrl: 'views/home/etfs/details.html'
    })
    .state('iframe-widgets.etfs.ishares-msci-eafe-esg-optimized', {
      url: '/ishares-msci-eafe-esg-optimized-etf',
      controller: 'iframeWidgetsEtfsCtrl',
      resolve: {
        etf: ['etfsResolveHelpers', function(etfsResolveHelpers) {
          return etfsResolveHelpers.etfs.esgd;
        }],
        chartData: ['etf', 'etfsResolveHelpers', resolveChartData]
      },
      templateUrl: 'views/home/etfs/details.html'
    })
    .state('iframe-widgets.etfs.ishares-msc-em-esg-optimized', {
      url: '/ishares-msc-em-esg-optimized-etf',
      controller: 'iframeWidgetsEtfsCtrl',
      resolve: {
        etf: ['etfsResolveHelpers', function(etfsResolveHelpers) {
          return etfsResolveHelpers.etfs.esge;
        }],
        chartData: ['etf', 'etfsResolveHelpers', resolveChartData]
      },
      templateUrl: 'views/home/etfs/details.html'
    })
    .state('iframe-widgets.etfs.ishares-global-reit', {
      url: '/ishares-global-reit-etf',
      controller: 'iframeWidgetsEtfsCtrl',
      resolve: {
        etf: ['etfsResolveHelpers', function(etfsResolveHelpers) {
          return etfsResolveHelpers.etfs.reet;
        }],
        chartData: ['etf', 'etfsResolveHelpers', resolveChartData]
      },
      templateUrl: 'views/home/etfs/details.html'
    });
}
