'use strict';

angular.module('controller.interview.advisor-referral-disclosures', [
    'ram'
  ])
  .controller('InterviewAdvisorReferralDisclosuresController', [
    '$cookies',
    '$scope',
    '$state',
    'advisorReferralDetails',
    'ram',
    interviewAdvisorReferralDisclosuresController
  ]);

function interviewAdvisorReferralDisclosuresController($cookies, $scope, $state, advisorReferralDetails, ram) {
  $scope.advisorName = advisorReferralDetails.advisorName;
  $scope.institutionName = advisorReferralDetails.institutionName;
  $scope.hasServiceFee = advisorReferralDetails.hasServiceFee;

  $scope.acknowledgedAdvisorReferral = new ram.Accessor(false);
  $scope.acknowledgedAdvisorNotPartnerOfMa = new ram.Accessor(false);
  $scope.acknowledgedAdvisorReceivesServiceFee = new ram.Accessor(false);
  $scope.acknowledgedSharingPersonalInfoWithAdvisor = new ram.Accessor(false);

  $scope.$parent.nextSection = function() {
    $cookies.put('acknowledged_advisor_referral_disclosures', true);
    $state.go($state.current.data.next);
  };

  $scope.$parent.lastSection = function() {
    $state.go($state.current.data.last);
  };

  $scope.$parent.step = $state.current.data.step;
  $scope.$parent.header = $state.current.data.header;
  $scope.$parent.userCanGoBack = false;
}
