'use strict';

angular.module('model.AccountTransfer', [
    'model.AccountTransferSecurity',
    'model.Document',
    'model.User'
  ])
  .factory('AccountTransfer', [
    '$http',
    'ram',
    'config',
    accountTransferFactory
  ]);

function accountTransferFactory($http, ram, config) {

  var AccountTransfer = new ram.Collection('AccountTransfer', {
    bind: ['details', 'isUserCancellable'],
    enums: {
      category: config.types.AccountTransferCategory,
      dsc: config.types.AccountTransferDsc,
      status: config.types.AccountTransferStatus
    },
    belongsTo: {
      account: 'Account'
    },
    hasMany: {
      accountTransferSecurities: 'AccountTransferSecurity'
    },
    hasOne: {
      accountTransferForm: 'Document'
    },
    schema: config.schemas.AccountTransfer,
    resources: {
      default: new ram.resources.Http('/api/account_transfers/:id.json'),
      cookie: new ram.resources.Cookie('account_transfers')
    }
  });

  AccountTransfer.prototype.details = function() {
    var transferType = this.inKind() ? 'in kind ' : 'in cash ';
    return (this.partialTransfer() ? 'Partial ' : 'Full ') +
      transferType +
      'transfer of account ' +
      this.externalAccountNumber() +
      ' from ' +
      this.institutionName();
  };

  AccountTransfer.prototype.cancel = function() {
    return $http.put('api/account_transfers/' + this.id + '/cancel');
  };

  AccountTransfer.prototype.finalize = function() {
    return $http.put('api/account_transfers/' + this.id + '/finalize');
  };

  AccountTransfer.prototype.markAsSent = function() {
    return $http.put('api/account_transfers/' + this.id + '/mark_as_sent');
  };

  AccountTransfer.prototype.markAsFailed = function() {
    return $http.put('api/account_transfers/' + this.id + '/mark_as_failed');
  };

  AccountTransfer.prototype.markAsRejected = function() {
    return $http.put('api/account_transfers/' + this.id + '/mark_as_rejected');
  };

  AccountTransfer.prototype.markAsCompleted = function() {
    return $http.put('api/account_transfers/' + this.id + '/mark_as_completed');
  };

  AccountTransfer.prototype.isUserCancellable = function() {
    return this.status().canCancel && this.account() && this.account().userIsHolder();
  };

  AccountTransfer.prototype.reload = function() {
    return AccountTransfer.find({
      id: this.id
    }, {
      force: true
    });
  };

  AccountTransfer.prototype.statusHighlightLevel = function() {
    let daysStatus = {};
    const FINISHED_STATUS_IDS = [2, 3, 5, 6]; //Status id for: Cancelled, Complete, Declined, Rejected. Considered finished requests
    if (FINISHED_STATUS_IDS.includes(this.status().id)) {
      return 'finished';
    }

    const createdAt = moment(this.createdAt());
    const currentDate = moment();
    daysStatus.diffDays = currentDate.diff(createdAt, 'days');

    if (daysStatus.diffDays >= 28) {
      daysStatus.status = 'incomplete-28';
    } else if (daysStatus.diffDays >= 14) {
      daysStatus.status = 'incomplete-14';
    }

    return daysStatus;
  };

  return AccountTransfer;
}
