'use strict';

function sectionFactory(Question) {

  /**
   * @class Represents a section in an interview containing questions.
   *
   * @method hasQuestion
   * @method complete
   * @method save
   *
   * @param {String}    heading   Text to display at the head of this section
   * @param {Interview} interview Interview that this belongs to
   */
  var Section = function(attrs) {

    this.heading = attrs.heading;
    this.questions = [];
  };

  /**
   * Associate a question with this section.
   *
   * @param  {String}  text             Text of the new question
   * @param  {String}  name             Name for the new question
   * @param  {String}  type             Type of the new question
   * @param  {Boolean} returnQuestion   Whether to return the newly instantiated question
   * @return {Object} The newly instantiated question or this question
   */
  Section.prototype.hasQuestion = function(text, name, type, returnQuestion) {
    var question = arguments.length === 1 ? text : new Question(text, name, type);
    this.questions.push(question);
    return returnQuestion ? question : this;
  };

  /**
   * Checks if all applicable questions in this section have been answered.
   *
   * @return {Boolean}
   */
  Section.prototype.complete = function() {
    return _.chain(this.questions)
      .filter(function(question) {
        return question.show();
      })
      .every(function(question) {
        return _.has(question, 'answer');
      })
      .value();
  };

  return Section;
}

angular.module('model.Section', [
    'model.Question'
  ])
  .factory('Section', [
    'Question',
    sectionFactory
  ]);
