'use strict';

const RESOURCES_PATHS = {
  allTabs: '/api/exports/multiple_tables',
  accountApplications: '/api/exports/account_applications',
  accounts: '/api/exports/accounts',
  clients: '/api/exports/clients',
  orders: '/api/exports/orders',
  bankAccounts: '/api/exports/bank_accounts'
};

const RESOURCE_PARAM_NAMES = {
  accountApplications: 'account_applications',
  accounts: 'accounts',
  clients: 'clients',
  orders: 'orders',
  bankAccounts: 'bank_accounts'
};

angular
  .module('controller.modal.advisor.download-table', ['ram'])
  .directive('elemReady', [
    '$parse',
    '$timeout',
    function ($parse, $timeout) {
      return {
        restrict: 'A',
        link: function ($scope, elem, attrs) {
          $timeout(function () {
            elem.ready(function () {
              $scope.$apply(function () {
                const func = $parse(attrs.elemReady);
                func($scope);
              });
            });
          });
        }
      };
    }
  ])
  .controller('ModalDownloadTableCtrl', ['$scope', '$timeout', 'ram', 'filterParam', controller]);

function controller($scope, $timeout, ram, filterParam) {
  $scope.format = new ram.Accessor();

  $scope.allTabs = new ram.Accessor();
  $scope.accountApplications = new ram.Accessor();
  $scope.accounts = new ram.Accessor();
  $scope.clients = new ram.Accessor();
  $scope.orders = new ram.Accessor();
  $scope.bankAccounts = new ram.Accessor();

  $scope.formatError = '';
  $scope.tabsError = '';

  $scope.formatsList = [
    {
      id: 1,
      label: 'XLS',
      value: 'xls'
    },
    {
      id: 2,
      label: 'XLSX',
      value: 'xlsx'
    },
    {
      id: 3,
      label: 'ODS',
      value: 'ods'
    },
    {
      id: 4,
      label: 'CSV',
      value: 'csv'
    }
  ];

  const resolveResources = () => {
    const resources = [];
    Object.keys(RESOURCES_PATHS).forEach((resourceName) => {
      if (!$scope[resourceName]()) return;

      resources.push(resourceName);
    });

    return resources;
  };

  $scope.downloadFiles = function () {
    $scope.formatError = '';
    $scope.tabsError = '';

    const resources = resolveResources();
    if (!$scope.format()) {
      $scope.formatError = 'Please select a format';
      return;
    }

    if (resources.length === 0) {
      $scope.tabsError = 'Please select at least one tab';
      return;
    }

    if (resources.includes('allTabs')) {
      if ($scope.format() === 'csv') {
        $scope.formatError = 'CSV is not supported for multiple tabs';
        return;
      }

      const resourcesParam = Object.values(RESOURCE_PARAM_NAMES).join(',');
      const basePath = RESOURCES_PATHS.allTabs;
      window.location.href = `${basePath}.${$scope.format()}?resources=${resourcesParam}`;
      return;
    }

    if (resources.length === 1) {
      const basePath = RESOURCES_PATHS[resources[0]];
      window.location.href = `${basePath}.${$scope.format()}?filter=${filterParam.filterParam}`;
      return;
    }

    if ($scope.format() === 'csv') {
      $scope.formatError = 'CSV is not supported for multiple tabs';
      return;
    }

    const basePath = RESOURCES_PATHS.allTabs;
    const resourcesParam = resources
      .map((resourceName) => RESOURCE_PARAM_NAMES[resourceName])
      .join(',');
    window.location.href = `${basePath}.${$scope.format()}?resources=${resourcesParam}`;
  };
}
