'use strict';

angular.module('controller.advise.add-client.add-advisors', [
    'ram',
    'service.form-helper',
    'service.user-notifications'
  ])
  .controller('AddClientAddAdvisorsCtrl', [
    '$http',
    '$scope',
    'advisorDetails',
    'formHelper',
    'ram',
    'userNotifications',
    addAdvisorCtrl
  ]);

function addAdvisorCtrl($http, $scope, advisorDetails, formHelper, ram, userNotifications) {

  function formatAdvisorList(advisorList) {
    return _.map(advisorList, function(advisor) {
      return {
        label: advisor.fullName,
        value: advisor.id
      };
    });
  }

  $scope.allAdvisors = formatAdvisorList(advisorDetails.institutionAdvisors);

  $scope.selectableAdvisors = function() {
    return $scope.allAdvisors;
  };

  $scope.primaryAdvisor.advisorId($scope.currentUser.person().id);

  $scope.secondaryAdvisor = null;

  $scope.addSecondaryAdvisor = function() {
    $scope.secondaryAdvisor = {
      advisorId: new ram.Accessor(null),
      commissionSplit: new ram.Accessor(null)
    };
  };

  $scope.removeSecondaryAdvisor = function() {
    $scope.secondaryAdvisor = null;
  };

  function totalPrimaryAndSecondaryCommission() {
    if ($scope.secondaryAdvisor) {
      return $scope.primaryAdvisor.commissionSplit() + $scope.secondaryAdvisor.commissionSplit();
    } else {
      return $scope.primaryAdvisor.commissionSplit();
    }
  }

  $scope.updateHouseCommission = function() {
    if (totalPrimaryAndSecondaryCommission() > 0) {
      $scope.houseCommission = (100 - totalPrimaryAndSecondaryCommission()) / 100;
    } else {
      $scope.houseCommission = null;
    }
  };

  $scope.$watch('secondaryAdvisor', function() {
    $scope.updateHouseCommission();
  });

  function validateAdvisorUniqueness() {
    if ($scope.secondaryAdvisor) {
      return $scope.primaryAdvisor.advisorId() !== $scope.secondaryAdvisor.advisorId();
    } else {
      return true;
    }
  }

  function validateCommissionTotal() {
    return totalPrimaryAndSecondaryCommission() <= 100;
  }

  function getFormValidationError() {
    if (!validateAdvisorUniqueness()) {
      return 'There is a person in more than one relationship manager role';
    } else if (!validateCommissionTotal()) {
      return 'Commissions must not add up to more than 100%';
    }
  }

  $scope.getInvitationLink = function() {
    if ($scope.form.$invalid) {
      formHelper.notDone($scope, $scope.form);
      return;
    }

    var formValidationError = getFormValidationError();

    if (formValidationError) {
      userNotifications.showTransientError(formValidationError);
      return;
    }

    var advisors = [{
      advisorId: $scope.primaryAdvisor.advisorId(),
      feeShare: $scope.primaryAdvisor.commissionSplit(),
      primary: true
    }];

    if ($scope.secondaryAdvisor) {
      advisors.push({
        advisorId: $scope.secondaryAdvisor.advisorId(),
        feeShare: $scope.secondaryAdvisor.commissionSplit(),
        primary: false
      });
    }

    $http.post('/api/advisor/invitation_link', {
        groupUrlId: $scope.getUrlId(),
        advisors: advisors
      })
      .then(function(invitationLink) {
        $scope.inviteClientModal(invitationLink.data.url);
      })
      .catch(function() {
        userNotifications.showTransientError('Something went wrong. Please try again.');
      });
  };
}
