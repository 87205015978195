'use strict';

/**
 * Defines a wrapper for the interview question field
 */
function questionFieldDirective() {
  return {
    restrict: 'E',
    transclude: true,
    scope: {
      optionLabel: '@',
      optionValue: '@',
      optionDescription: '@',
      help: '@',
      form: '=',
      focusHelp: '@',
      title: '@',
      label: '@',
      name: '@',
      class: '@',
      inputClass: '@',
      labelClass: '@',
      helpClass: '@'
    },
    templateUrl: 'directives/interview/question-field.html'
  };
}

angular.module('directive.interview.question-field', [
    'templates',
    'animations',
    'directive.focus'
  ])
  .directive('interviewQuestionField', [
    questionFieldDirective
  ]);
