'use strict';

angular.module('component.advise.resp-grant-document-subform', [
    'model.Document',
    'service.form-helper',
    'ram'
  ])
  .component('respGrantDocumentSubform', {
    templateUrl: 'components/resp-grant-subform.html',
    bindings: {
      possibleRelatedToObjects: '=',
      reset: '=',
      client: '=',
      type: '='
    },
    controller: ['$scope', 'ram', 'Document', 'formHelper', 'userNotifications', respGrantController],
    controllerAs: '$ctrl'
  });

function respGrantController($scope, ram, Document, formHelper, userNotifications) {
  /* jshint -W040 */
  var $ctrl = this;
  $scope.possibleRelatedToObjects = this.possibleRelatedToObjects;
  $scope.reset = this.reset;
  /* jshint +W040 */

  function resetNewDocument() {
    $scope.newDocument = new Document({
      userId: $ctrl.client.id,
      typeId: $ctrl.type.id,
      relatedToType: 'Account'
    });
  }
  resetNewDocument();

  $scope.accountId = new ram.Accessor();

  $scope.submitForm = function(formController) {
    if (formController.$invalid) {
      formHelper.notDone($scope, formController);
    } else if ($scope.possibleRelatedToObjects.length > 0) {
      $scope.isSaving = true;
      $scope.newDocument.relatedToId($scope.accountId());
      $scope.newDocument.prepareForPrefill()
        .then(function() {
          $scope.reset();
          userNotifications.showSuccess('The document has been sent to you for prefilling.');
        })
        .catch(function() {
          userNotifications.showError('Something went wrong while sending the document. Please try again later.');
        })
        .finally(function() {
          $scope.isSaving = false;
        });
    } else {
      userNotifications.showError('There are no accounts to prepare this document for.');
    }
  };
}
