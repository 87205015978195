'use strict';

var DEFAULT_ID = 'default';

function loadingIndicatorFactory($rootScope, $q, $compile, $templateRequest) {
  var contexts = {};

  var templateCache = null;
  var loadTemplate = function() {
    if (templateCache) {
      return $q.when(templateCache);
    }
    return $templateRequest('partials/loading-indicator.html')
      .then(function(result) {
        templateCache = result;
        return templateCache;
      });
  };

  return {
    show: function(message, id, wrapperElement) {
      if (!id) {
        id = DEFAULT_ID;
      }
      if (!wrapperElement) {
        wrapperElement = $('body');
      }

      if (contexts[id]) {
        // only one indicator at a time
        return false;
      }
      var context = {
        element: null
      };
      contexts[id] = context;

      loadTemplate().then(function(template) {
        context.element = $compile(template)($rootScope);

        if (!contexts[id]) {
          // it was hidden before it was fully shown
          return;
        }
        context.element.find('.loading-message').html(message);
        wrapperElement.append(context.element);
      });

      return true;
    },

    hide: function(id) {
      if (!id) {
        id = DEFAULT_ID;
      }

      if (contexts[id]) {
        var context = contexts[id];
        contexts[id] = null;

        if (context.element) {
          context.element.remove();
        }
      }
    },

    aroundPromise: function(promise, message, id, element) {
      var _this = this;
      var didShow = _this.show(message, id, element);

      return $q.when(promise)
        .finally(function() {
          if (didShow) {
            _this.hide(id);
          }
        });
    }
  };
}

angular.module('service.loading-indicator', [])
  .factory('loadingIndicator', [
    '$rootScope',
    '$q',
    '$compile',
    '$templateRequest',
    loadingIndicatorFactory
  ]);
