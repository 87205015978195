'use strict';

function issueFactory(ram, config) {

  var Issue = new ram.Collection('Issue', {
    belongsTo: {
      assignedTo: 'User',
      createdBy: 'User'
    },
    enums: {
      type: config.types.Issue
    },
    hasMany: {
      issueComments: 'IssueComment'
    },
    schema: config.schemas.Issue,
    resources: {
      default: new ram.resources.Http('/api/issues/:id.json'),
      cookie: new ram.resources.Cookie('issues')
    }
  });

  Issue.prototype.isClosed = function() {
    return this.closedAt() !== null;
  };

  return Issue;
}

angular.module('model.Issue', [
    'ram',
    'model.IssueComment'
  ])
  .factory('Issue', [
    'ram',
    'config',
    issueFactory
  ]);
