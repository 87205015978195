'use strict';

function accountSwitchFactory($http, $window) {
  return {

    switchToUser: function(newUser) {
      return $http.post('/api/users/switch_to_user', {
          newUser: newUser
        })
        .then(function() {
            $window.location.href = '/dashboard';
          },
          function() {
            console.log('There was an error getting the data.  Please log in again.');
          });
    }
  };
}
angular.module('service.accountSwitch', [])
  .factory('accountSwitch', [
    '$http',
    '$window',
    accountSwitchFactory
  ]);
