'use strict';

function passwordDirective() {
  return {
    restrict: 'E',
    scope: {
      passwordModel: '=',
      passwordChange: '&',
      passwordPattern: '=',
      form: '=',
      help: '@',
      focusHelp: '@',
      patternError: '@',
      title: '@',
      label: '@',
      name: '@',
      class: '@',
      placeholder: '@',
      inputClass: '@',
      labelClass: '@',
      helpClass: '@'
    },
    templateUrl: 'directives/input/password.html'
  };
}

angular.module('directive.input.password', [
    'templates',
    'animations',
    'directive.focus'
  ])
  .directive('password', [
    passwordDirective
  ]);
