'use strict';

angular.module('service.fee-calculator', [])
  .factory('feeCalculator', [
    feeCalculatorFactory
  ]);

function feeCalculatorFactory() {
  var fees = {
    minAccount: {
      level: 0,
      minAmount: 0,
      rate: 0
    },
    smallAccount: {
      level: 1,
      minAmount: 10000,
      rate: 0.005
    },
    mediumAccount: {
      level: 2,
      minAmount: 100000,
      rate: 0.004
    },
    largeAccount: {
      level: 3,
      minAmount: 500000,
      rate: 0.0035
    }
  };

  //mutual fund cost is the same until we decide otherwise for these account levels
  var averageMutualFundRate = 0.0235;
  var portfolioRate = 0.0025;
  var annualRoR = 0.06;

  var _getFeeInfo = function(amount) {
    var sortedFees = _.chain(fees)
      .toArray()
      .sortBy('minAmount')
      .value()
      .reverse();

    return _.find(sortedFees, function(fee) {
      return fee.minAmount <= amount;
    });
  };

  var getFeeLevel = function(amount) {
    return _getFeeInfo(amount).level;
  };

  var getAverageMutualFundCost = function(amount) {
    return amount * averageMutualFundRate;
  };

  var getFeeRate = function(amount) {
    return _getFeeInfo(amount).rate;
  };

  var getFeeCost = function(amount) {
    return amount * getFeeRate(amount);
  };

  var getAnnualRoR = function() {
    return annualRoR;
  };

  var getAverageMutualFundRate = function() {
    return averageMutualFundRate;
  };

  var getLargeAcountFeeRate = function() {
    return fees.largeAccount.rate;
  };

  var getPortfolioRate = function() {
    return portfolioRate;
  };

  var getSmallAccountFeeRate = function() {
    return fees.smallAccount.rate;
  };

  return {
    getAnnualRoR: getAnnualRoR,
    getAverageMutualFundCost: getAverageMutualFundCost,
    getAverageMutualFundRate: getAverageMutualFundRate,
    getFeeCost: getFeeCost,
    getFeeLevel: getFeeLevel,
    getFeeRate: getFeeRate,
    getLargeAcountFeeRate: getLargeAcountFeeRate,
    getPortfolioRate: getPortfolioRate,
    getSmallAccountFeeRate: getSmallAccountFeeRate
  };
}
