'use strict';

angular.module('model.RiskAppetiteCalculation', [
    'model.CompletedRiskAppetiteQuiz',
  ])
  .factory('RiskAppetiteCalculation', [
    'ram',
    riskAppetiteCalculationFactory
  ]);

function riskAppetiteCalculationFactory(ram) {

  /**
   * Constructor for resource-aware RiskAppetiteCalculation model.
   * @class
   */
  var RiskAppetiteCalculation = new ram.Collection('RiskAppetiteCalculation', {
    belongsTo: {
      completedRiskAppetiteQuiz: 'CompletedRiskAppetiteQuiz'
    },
    resources: {
      default: new ram.resources.Http('/api/risk_appetite_calculations/:id.json'),
      cookie: new ram.resources.Cookie('risk_appetite_calculations')
    },
  });

  return RiskAppetiteCalculation;
}
