'use strict';

angular.module('service.investments-bar-prep-data', [])
  .factory('invBarPrepData', [investmentsBarPrepData]);

function investmentsBarPrepData() {
  return {
    getChartDataForPositions: getChartDataForPositions,
    getChartDataForAllocations: getChartDataForAllocations
  };

  function getChartDataForAllocations(allocations, account) {
    return getChartData(_.map(allocations, function(allocation) {
      return {
        fund: allocation.fund(),
        value: allocation.weight()
      };
    }), account);
  }

  function getChartDataForPositions(positions, account) {
    return getChartData(_.map(positions, function(position) {
      return {
        fund: position.fund(),
        value: position.marketValue()
      };
    }), account);
  }

  /**
   * Takes an account and its positions and returns an object that contains the data required for an
   * investments bar chart as two elements.
   *
   * @param entries Entries for the graph (array of {fund: ..., value: ...})
   * @param account The account for which the investments bar chart is to be created.
   * @return {object} The two element object containing the data in a format necessary for constructing
   * an investments bar chart.
   */
  function getChartData(entries, account) {
    if (account.isCombinedAccount()) {
      return;
    }

    var toBeStacked = [],
      investmentsChartData = {};

    investmentsChartData.account = account.type().label;

    var i = entries.length - 1;
    while (i >= 0) {
      setData(i);
      i--;
    }

    function setData(i) {
      var entry = entries[i],
        fund = entry.fund,
        sym = fund.symbol();

      if (entry.value > 0) {
        toBeStacked.unshift(sym);
        investmentsChartData[sym] = {
          value: entry.value,
          color: fund.color
        };
      }
    }

    return {
      toBeStacked: toBeStacked,
      investmentsChartData: investmentsChartData
    };
  }
}
