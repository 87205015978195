'use strict';

class BeneficiaryFormRespController {
  constructor(
    $scope,
    $q,
    ram,
    config,
    AdvisorBeneficiaryForm,
    RespBeneficiary,
    Beneficiary,
    spouseOrPartner,
    beneficiaryParamsGenerator
  ) {
    this.$scope = $scope;
    this.$q = $q;
    this.config = config;
    this.ram = ram;
    this.AdvisorBeneficiaryForm = AdvisorBeneficiaryForm;
    this.RespBeneficiary = RespBeneficiary;
    this.Beneficiary = Beneficiary;
    this.spouseOrPartner = spouseOrPartner;
    this.beneficiaryParamsGenerator = beneficiaryParamsGenerator;
  }

  $onInit() {
    this.allBeneficiaries = [];
    this.primaryPerson = this.client;
    this.primaryCaregiver = this.beneficiaryRelativeFunction('primaryCaregiver');
    this.legalGuardian = this.beneficiaryRelativeFunction('legalGuardian');
    this.spouse = this.client.spouseRelation() && this.client.spouseRelation().relative();
    this.accountType = this.accountFlow.accountType;
    this.isFamilyResp = this.accountType.is.familyResp();
    this.subforms.saveBeneficiary = this._saveBeneficiary.bind(this);
    this.respBeneficiaryRelationTypes = this.Beneficiary.getBeneficiaryRelationTypes('respBeneficiary', this.accountType().name);

    this.addRespBeneficiary();
    this.updateSelectBoxOptions();
    this.$scope.$watch('this.client.fullName()', this.updateSelectBoxOptions.bind(this));
    this.$scope.$watch('this.client.maritalStatus()', this.updateSelectBoxOptions.bind(this));
    this.$scope.$watch('this.spouse.fullName()', this.updateSelectBoxOptions.bind(this));
  }

  checkFirstDateOfBirthChange(person, date) {
    person.birthDate(date.toDate());
  }

  addRespBeneficiary() {
    if (this._canAddRespBeneficiary()) {
      this.allBeneficiaries.push(this._createBeneficiary());
    }
  }

  removeRespBeneficiary(beneficiary) {
    const index = _.indexOf(this.allBeneficiaries, beneficiary);
    if (this.isFamilyResp && index >= 0) {
      this.allBeneficiaries.splice(index, 1);
    }
  }

  updateSelectBoxOptions() {
    const options = [
      this._createOption(this.client.fullName(), this.client.id)
    ];
    if (this.client.hasSpouse() && this.spouse) {
      const spouseFullName = this.spouse.fullName().trim() || ('Your ' + this.getRelationshipName());
      options.push(this._createOption(spouseFullName, this.spouse.id));
    }
    options.push(this._createOption('Other', 'other'));
    this.primaryCaregiverOptions = options;
    this.legalGuardianOptions = options;
  }

  // copied from beneficiary-resp directive
  beneficiaryRelativeFunction(name) {
    const otherValue = 'other';
    const otherAccessor = beneficiary => {
      return beneficiary[name + 'Other'];
    };
    const idAccessor = beneficiary => {
      return beneficiary[name + 'Id'];
    };
    const setter = (beneficiary, value) => {
      const other = (value === otherValue);
      otherAccessor(beneficiary).call(null, other);
      idAccessor(beneficiary).call(null, other ? null : value);
    };
    const getter = beneficiary => {
      return otherAccessor(beneficiary).call(null) ? otherValue : idAccessor(beneficiary).call(null);
    };
    // can't use arrow fucntion, 'this' needs to refer to the subscope of each
    // beneficiary dynamically
    return function(value) {
      const beneficiary = this.$parent.$parent.beneficiary;
      return (arguments.length > 0) ? setter(beneficiary, value) : getter(beneficiary);
    };
  }

  getRelationshipName() {
    return this.spouseOrPartner.relationshipName(this.client);
  }

  _createOption(label, value) {
    return {
      label: label,
      value: value
    };
  }

  _createBeneficiary() {
    const params = this.beneficiaryParamsGenerator.getAdvisorBeneficiaryFormParams(
      this.client.user().id,
      this.accountFlow.id,
      null,
      this.client.homeAddress().id
    );
    const beneficiary = this.AdvisorBeneficiaryForm.new(params);
    beneficiary.type(this.RespBeneficiary.name);
    return beneficiary;
  }

  _canAddRespBeneficiary() {
    return this.isFamilyResp || (this.accountType().resp && this.allBeneficiaries.length === 0);
  }

  _saveBeneficiary() {
    const promises = [];
    _.each(this.allBeneficiaries, beneficiary => {
      promises.push(beneficiary.deepSave());
    });
    return this.$q.all(promises);
  }
}

export default BeneficiaryFormRespController;
