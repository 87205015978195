'use strict';

function factory($state) {

  var NotAuthorizedErrorHandler = function() {};

  NotAuthorizedErrorHandler.prototype.canHandle = function(error) {
    return error === 'Not Authorized';
  };

  NotAuthorizedErrorHandler.prototype.handle = function(error, context) {
    console.error(error, context);
    return $state.go('account.signin', {}, {
      reload: true
    });
  };

  return NotAuthorizedErrorHandler;
}

angular.module('service.error.NotAuthorizedErrorHandler', [])
  .factory('NotAuthorizedErrorHandler', [
    '$state',
    factory
  ]);
