'use strict';

function factory(ram, config) {

  return new ram.Collection('InvestmentPolicyStatement', {
    belongsTo: {
      account: 'Account'
    },
    schema: config.schemas.InvestmentPolicyStatement,
    resources: {
      default: new ram.resources.Http('/api/investment_policy_statements/:id.json'),
      cookie: new ram.resources.Cookie('investment_policy_statements')
    },
  });
}

angular.module('model.InvestmentPolicyStatement', [
    'ram',
    'config'
  ])
  .factory('InvestmentPolicyStatement', [
    'ram',
    'config',
    factory
  ]);
