'use strict';

angular.module('routes.advise.bulk-id-verification', [
    'controller.advise.bulk-id-verification',
    'templates',
    'ui.router'
  ])
  .config([
    '$stateProvider',
    configRoutes
  ]);

function configRoutes($stateProvider) {
  function authorizeSuperAdvisor(authorizer) {
    return authorizer.checkPermission('superAdvisor');
  }

  function resolveResultsFiles($http) {
    return $http.get('/api/super_advisor/bulk_identity_check_results.json')
      .then(function(result) {
        return _.map(result.data, function(item) {
          var timeObj = moment.unix(item.time).toDate();
          return Object.assign({}, item, {
            timeObj: timeObj
          });
        });
      });
  }

  function resolveCurrentStatus($http) {
    return $http.get('/api/super_advisor/bulk_identity_check_statuses.json')
      .then(function(result) {
        return result.data;
      });
  }

  $stateProvider
    .state('advise.bulk-id-verification', {
      url: '/bulk-id-verification',
      abstract: true,
      controller: 'AdviseBulkIdVerificationCtrl',
      resolve: {
        authorize: ['authorizer', authorizeSuperAdvisor]
      },
      template: '<div ui-view></div>'
    })
    .state('advise.bulk-id-verification.upload-list', {
      url: '/upload-list',
      controller: 'AdviseBulkIdUploadListCtrl',
      resolve: {
        resultsFiles: ['$http', resolveResultsFiles],
        currentStatus: ['$http', resolveCurrentStatus]
      },
      templateUrl: 'views/advise/bulk-id-verification/upload-list.html'
    });
}
