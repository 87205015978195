'use strict';

angular.module('model.RespBeneficiary', [
    'ram',
    'config',
    'model.PersonalRelation',
    'model.Account',
    'model.Person'
  ])
  .factory('RespBeneficiary', [
    'ram',
    'config',
    'Person',
    respBeneficiaryFactory
  ]);

function respBeneficiaryFactory(ram, config, Person) {
  var RespBeneficiary = new ram.Collection('RespBeneficiary', {
    belongsTo: {
      personalRelation: 'PersonalRelation',
      // primaryCaregiver: 'Person',  // This line and the next are broken.  Ram will change the Person associated with User when you change one of these.  Its a bug
      // legalGuardian: 'Person',
      account: 'Account'
    },
    schema: config.schemas.RespBeneficiary,
    serialize: ['personalRelation'],
    resources: {
      default: new ram.resources.Http('/api/resp_beneficiaries/:id.json'),
      cookie: new ram.resources.Cookie('resp_beneficiaries')
    }
  });

  /**
   * Utility function to get an object for a 'belongsTo' relationship
   * This is a workaround until Ram can be fixed.
   *
   * @param _this - 'this' for the base object in question
   * @param accessor - The base name of the accessor
   * @returns ram.Accessor function that displays data (allows getter only)
   */
  function sideLoadPerson(_this, accessor) {
    var personId = _this[accessor + 'Id']();
    if (_.isUndefined(personId) || personId === null) {
      _this[accessor] = new ram.Accessor(function() {
        return '';
      });
    } else {
      Person.find({
          id: personId
        })
        .then(function(person) {
          _this[accessor] = new ram.Accessor(person);
        });
    }
  }

  RespBeneficiary.prototype.initialize = function() {
    sideLoadPerson(this, 'primaryCaregiver');
    sideLoadPerson(this, 'legalGuardian');
    this._addressChanged = _.isUndefined(this._addressChanged) ? false : this._addressChanged;
    this._justCreated = _.isUndefined(this._justCreated) ? false : this._justCreated;
  };

  RespBeneficiary.getRespBeneficiaryRelationTypes = function(beneficiaryType) {
    return _.chain(config.types.PersonalRelation)
      .filter(function(relation) {
        return relation[beneficiaryType] !== -1;
      })
      .sortBy(function(relation) {
        return relation[beneficiaryType];
      })
      .value();
  };

  /**
   * Load sub-objects
   * @returns an array of promises, will be fed to $q.all()
   */
  RespBeneficiary.prototype.loadDependantObjects = function() {
    return [
      this.personalRelation.ready(true)
      // In the future, primaryCaregiver and legalGuardian will go in this array, too.
    ];
  };

  RespBeneficiary.prototype.addressChanged = function(val) {
    if (!_.isUndefined(val)) {
      this._addressChanged = val;
    }
    return this._addressChanged;
  };

  RespBeneficiary.prototype.justCreated = function(val) {
    if (!_.isUndefined(val)) {
      this._justCreated = val;
    }
    return this._justCreated;
  };

  return RespBeneficiary;
}
