'use strict';

angular.module('directive.user.personal-info', [
    'directive.input.full-name',
    'directive.user.employment',
    'directive.user.date-of-birth-dropdowns',
    'directive.user.address-field',
    'directive.user.tax-status',
    'directive.input.phone-number-international',
    'directive.editableField',
    'directive.editableContext',
    'service.patterns',
    'service.save-on-change-handler',
    'service.dropdown-options'
  ])
  .directive('personalInfo', [
    'config',
    'dropdownOptions',
    'TaxStatus',
    personalInfoDirective
  ]);

function personalInfoDirective(config, dropdownOptions) {

  return {
    restrict: 'E',
    scope: {
      currentUser: '=',
      form: '=',
      person: '=',
      // true = directive is used in the account profile view
      profilePage: '=',
      // true = entities will be saved on the field change
      // false = parent have to manage saving the data themselves
      saveOnChange: '=',
      hasFccAccount: '=',
      isClientFilling: '=',
      mode: '@'
    },
    templateUrl: 'directives/user/personal-info.html',
    compile: function(element, attrs) {
      _.defaults(attrs, {
        profilePage: false,
        saveOnChange: false
      });
    },
    controller: ['$scope', 'ram', 'patterns', 'saveOnChangeHandler', function($scope, ram, patterns, saveOnChangeHandler) {
      $scope.config = config;
      $scope.patterns = patterns;

      $scope.yesOrNo = dropdownOptions.yesOrNo();
      $scope.residencies = dropdownOptions.residencies();

      if ($scope.saveOnChange) {
        saveOnChangeHandler.initForScope($scope);
      } else {
        $scope.onPhoneNumberChange = angular.noop;
      }

      $scope.isAdvisorCreateClientMode = function() {
        return $scope.mode && $scope.mode === 'advisor-create-client';
      };
    }]
  };
}
