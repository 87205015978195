'use strict';

function profileFinanceController($scope) {
  var currentUser = $scope.currentUser;

  /* jshint validthis: true */
  this.save = function() {
    return currentUser.save();
  };

  this.reset = function() {
    return currentUser.fetch(true);
  };
}

angular.module('controller.profile.finance', [
    'directive.input'
  ])
  .controller('ProfileFinanceCtrl', [
    '$scope',
    profileFinanceController
  ]);
