'use strict';

function adviseIssueController($scope, issue, Issue, IssueComment, assocUserId, assocUser) {
  $scope.issue = issue;
  $scope.comment = '';
  $scope.assocUserId = assocUserId;
  $scope.assocUser = assocUser;

  $scope.createComment = function() {
    IssueComment.new({
        issueId: $scope.issue.id,
        body: $scope.comment
      })
      .save()
      .then(function() {
        return $scope.issue.issueComments.ready(true);
      });
    $scope.comment = '';
  };

  $scope.userName = function() {
    if ($scope.assocUser) {
      return $scope.assocUser.fullName();
    }
  };

  $scope.closeIssue = function() {
    $scope.issue.closedAt(new Date());
    $scope.issue.save();
  };
}

angular.module('controller.advise.issue', [
    'model.Issue',
    'model.IssueComment'
  ])
  .controller('AdviseIssueCtrl', [
    '$scope',
    'issue',
    'Issue',
    'IssueComment',
    'assocUserId',
    'assocUser',
    adviseIssueController
  ]);
