'use strict';

angular.module('service.dashboard-navbar', [])
  .factory('dashboardNavbarService', [
    '$state',
    dashboardNavbarService
  ]);

function dashboardNavbarService($state) {

  return {
    stateIsAccountRelated: stateIsAccountRelated,
    stateIsGoals: stateIsGoals
  };

  function stateIsAccountRelated() {
    var accountRelatedStates = ['summary', 'account', 'holdings', 'activity', 'documents']
      .map(function(childState) {
        return 'dashboard.' + childState;
      });
    return accountRelatedStates.includes($state.current.name);
  }

  function stateIsGoals() {
    return 'dashboard.goals' === $state.current.name;
  }
}
