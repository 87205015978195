'use strict';

angular.module('service.new-client-creator', [
    'model.AdvisorCreateNewClientFlow',
  ])
  .factory('newClientCreator', [
    'AdvisorCreateNewClientFlow',
    newClientCreatorFactory
  ]);

function newClientCreatorFactory(AdvisorCreateNewClientFlow) {
  function doCreate(email, person, extraParams) {
    return AdvisorCreateNewClientFlow.create({
        clientId: null,
        email: email,
        repCodeId: extraParams.repCodeId,
        externalClientId: extraParams.externalClientId,
        sharedOfficeEntities: extraParams.sharedOfficeEntities,
        registeredInProvince: extraParams.registeredInProvince,
        person: {
          firstName: person.firstName(),
          lastName: person.lastName(),
          birthDate: person.birthDate(),
          sin: person.sin()
        }
      })
      .then(function(flow) {
        return flow;
      });
  }

  return {
    create: doCreate
  };
}
