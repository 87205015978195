'use strict';

angular.module('service.sticky-account-message-board', [])
  .factory('clientDashboardStickyAccountMessageBoard', [
    clientDashboardStickyAccountMessageBoardFunction
  ]);

/**
 * Manages sticky account messages on the client dashboard
 *
 */
function clientDashboardStickyAccountMessageBoardFunction() {
  var currentUser = null;
  var holdingsPageDatabase = {};
  var CASH_FUND_ID = 8;
  var MINIMUM_POSITIONS_FOR_FULLY_FUNDED = 1;

  function isAllCash(account) {
    var numValidPositions = 0;
    _.each(account.positions(), function(position) {
      if (position.fundId() !== CASH_FUND_ID && position.marketValue() > 0.0) {
        numValidPositions++;
      }
    });
    return numValidPositions < MINIMUM_POSITIONS_FOR_FULLY_FUNDED;
  }

  function holdingsPageLowBalanceIndicatorInitialValue(account) {
    return !account.isCombinedAccount() &&
      !account.type.is.trial() &&
      account.balance() < 1000 &&
      account.transfers().length > 0;
  }

  function dissmissWarningIfFullyInvested(account) {
    if (!isAllCash(account)) {
      holdingsPageDatabase[account.id] = false;
    }
  }

  return {
    initialize: function(currentUserInput) {
      if (currentUserInput === currentUser) {
        return false;
      }
      currentUser = currentUserInput;
      return true;
    },
    initializeHoldingsPage: function(currentUserInput, accounts, displayAccount) {
      if (!this.initialize(currentUserInput)) {
        // Rather than load all the holdings in advance, we just override the setting if the account the user has
        // picked is already invested.
        dissmissWarningIfFullyInvested(displayAccount);
        return;
      }
      holdingsPageDatabase = {};
      _.each(accounts, function(account) {
        holdingsPageDatabase[account.id] = holdingsPageLowBalanceIndicatorInitialValue(account);
        if (account.id === displayAccount.id) {
          dissmissWarningIfFullyInvested(displayAccount);
        }
      });
    },
    holdingsPageLowBalanceIndicator: function(account, val) {
      if (!_.isUndefined(val)) {
        holdingsPageDatabase[account.id] = val;
      }
      return holdingsPageDatabase[account.id];
    }
  };
}
