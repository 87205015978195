'use strict';

angular.module('controller.advise.bulk-id-verification.upload-list', [
    'csrf',
    'service.user-notifications'
  ])
  .controller('AdviseBulkIdUploadListCtrl', [
    '$scope',
    '$state',
    '$document',
    '$timeout',
    '$http',
    'csrfToken',
    'userNotifications',
    'resultsFiles',
    'currentStatus',
    adviseBulkIdUploadListController
  ]);

function adviseBulkIdUploadListController($scope, $state, $document, $timeout, $http, csrfToken, userNotifications, resultsFiles, currentStatus) {
  var fileInput = $document[0].getElementById('select-file');
  $scope.isProcessing = false;
  $scope.pleaseSelectFileMessage = {
    show: false,
    text: 'Please select your file.'
  };
  $scope.jobInProgressMessage = {
    show: false,
    text: 'There is a job in progress.'
  };
  $scope.fileStatus = 'No file loaded yet';
  $scope.fileName = 'Please select your file';
  $scope.resultsFiles = resultsFiles;
  $scope.currentStatus = currentStatus;

  _setProcessingStateFromRoutes(currentStatus);

  $scope.selectFile = function() {
    if ($scope.isProcessing) {
      $scope.jobInProgressMessage.show = true;
      return;
    }
    fileInput.click();
  };

  $scope.process = function() {
    var formData, request;

    if (!$scope.fileName || !$scope.isValidFile) {
      $scope.pleaseSelectFileMessage.show = true;
      return;
    }

    $scope.isProcessing = true;
    formData = generateFormData();
    request = new XMLHttpRequest();
    request.open('POST', '/api/super_advisor/bulk_identity_checks.json');
    request.setRequestHeader('X-CSRF-Token', csrfToken());
    request.onload = function() {
      var result = JSON.parse(request.response);
      if (this.status === 201) {
        _setProcessingStateFromButton(result);
      } else {
        var errorMessage = result.error || 'There was an unexpected result. Please try again later.';
        userNotifications.showError(errorMessage);
        $scope.isProcessing = false;
      }
    };
    request.onerror = function() {
      userNotifications.showError('Something went wrong while uploading the data. Please try again later.');
      $scope.isProcessing = false;
    };
    request.send(formData);
  };

  $scope.updateFileNameAndContent = function() {
    $scope.pleaseSelectFileMessage.show = false;

    var newFile = fileInput.files[0];

    $scope.isValidFile = validateFile(newFile);
    if (!$scope.isValidFile) {
      return;
    }
    $scope.fileName = newFile.name;
    $scope.fileStatus = 'Successfully loaded file! (' + newFile.size + ' bytes)';
  };

  $scope.urlFor = function(record) {
    var fileName = encodeURIComponent(_fileNameWithoutExtension(record));
    return '/api/super_advisor/bulk_identity_check_results/' + fileName + '.json';
  };

  $scope.delete = function(record) {
    var fileName = _fileNameWithoutExtension(record);
    var encodedFileName = encodeURIComponent(fileName);
    $http.delete('/api/super_advisor/bulk_identity_check_results/' + encodedFileName + '.json')
      .then(function(response) {
        if (response.status === 200) {
          $scope.resultsFiles = _exceptFile($scope.resultsFiles, fileName);
        }
      });
  };

  function _fileNameWithoutExtension(record) {
    return record.name + '-' + record.time;
  }

  function _setProcessingStateFromRoutes(currentStatus) {
    if (currentStatus.fileName || currentStatus.numToProcess > 0) {
      $scope.isProcessing = true;
    }
  }

  function _setProcessingStateFromButton(result) {
    /* jshint camelcase: false */
    $scope.currentStatus = {
      numTotal: result.num_total,
      numProcessed: 0,
      numToProcess: result.num_total,
      fileName: result.file_name,
      timeStarted: result.time_started
    };
    $scope.$root.$apply();
  }

  function validateFile(file) {
    if (!file) {
      $scope.fileStatus = 'No file selected.';
      $scope.fileName = 'Please select your file.';
      return false;
    }

    if (file.size / 1024 / 1024 > 2) {
      $scope.fileStatus = 'file is too big.';
      $scope.fileName = file.name;
      return false;
    }
    return true;
  }

  function generateFormData() {
    var formData = new FormData();

    formData.append(
      'file',
      fileInput.files[0]
    );

    return formData;
  }

  function _exceptFile(resultsFiles, fileName) {
    return _.filter(resultsFiles, function(file) {
      return !file.fullName.match(fileName);
    });
  }
}
