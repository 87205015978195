'use strict';

angular.module('controller.modal.advisor-modify-portfolio-options', [
    'config',
    'model.Account',
    'service.loading-indicator',
    'ram'
  ])
  .controller('ModalAdvisorModifyPortfolioOptionsCtrl', [
    '$http',
    '$scope',
    '$uibModalInstance',
    'Account',
    'config',
    'ram',
    'account',
    'loadingIndicator',
    modalAdvisorModifyPortfolioOptionsCtrl
  ]);

function modalAdvisorModifyPortfolioOptionsCtrl($http, $scope, $modalInstance, Account, config, ram, account, loadingIndicator) {

  var _reloadData;
  var _done;

  $scope.newPortfolioOption = new ram.Accessor(account.portfolioOptionId());
  $scope.account = account;
  $scope.informationMessageForUser = '';
  $scope.notes = '';
  $scope.accountPortfolioOptionIds = {};
  _.each(config.types.AccountPortfolioOption, function(option) {
    $scope.accountPortfolioOptionIds[option.name] = option.id;
  });

  $scope.accountPortfolioOptions = _.reject(config.types.AccountPortfolioOption, function(accountPortfolioOption) {
    return accountPortfolioOption.name === 'adaptive_portfolio' && !canSelectAdaptivePortfolioOption();
  });

  function canSelectAdaptivePortfolioOption() {
    return $scope.$root.currentUser.canSelectAdaptivePortfolio();
  }

  $scope.save = function() {
    loadingIndicator.show();
    $scope.informationMessageForUser = '';
    $http.post('/api/activity_based_modifications/create_portfolio_option_change', {
        input: {
          accountId: $scope.account.id,
          desiredPortfolioOptionId: $scope.newPortfolioOption().id,
          notes: $scope.notes
        }
      })
      .then(function() {
        _reloadData();
      }, function() {
        loadingIndicator.hide();
        $scope.informationMessageForUser = 'Error saving data';
      });
  };

  _reloadData = function() {
    Account.find({
        id: $scope.account.id
      })
      .then(function(account) {
        account.activities.ready();
      })
      .then(function() {
        _done();
      });
  };

  _done = function() {
    loadingIndicator.hide();
    $modalInstance.close();
  };
}
