'use strict';

function transfersUnmatchedQuery($q, NotificationsUnmatchedTransferQuery, Transfer) {
  return {
    run: function(force) {
      return NotificationsUnmatchedTransferQuery.run(force)
        .then(function(notificationsUnmatchedTransfer) {
          return $q.all(_.map(notificationsUnmatchedTransfer, function(notificationUnmatchedTransfer) {
            return Transfer.find({
              id: notificationUnmatchedTransfer.relatedToId()
            }, {
              force: force
            });
          }));
        });
    }
  };
}

angular.module('query.transfers.unmatched', [
    'model.Transfer',
    'query.notifications.unmatched-transfer'
  ])
  .factory('TransfersUnmatchedQuery', [
    '$q',
    'NotificationsUnmatchedTransferQuery',
    'Transfer',
    transfersUnmatchedQuery
  ]);
