'use strict';

angular.module('directive.input', [
  'directive.input.selector',
  'directive.input.checkbox',
  'directive.input.number',
  'directive.input.slider',
  'directive.input.radio-button',
  'directive.input.radio',
  'directive.input.button-group',
  'directive.input.static',
  'directive.input.email',
  'directive.input.password',
  'directive.input.currency',
  'directive.input.price',
  'directive.input.sin',
  'directive.input.ssn',
  'directive.input.extension',
  // v2 input directives
  'directive.input.checkbox-input',
  'directive.input.currency-input',
  'directive.input.email-input',
  'directive.input.form-group',
  'directive.input.help-block',
  'directive.input.text-input',
  'directive.input.phone-number-input',
  'directive.input.phone-number-international',
  'directive.input.radio-input',
  'directive.input.goal-radio-button',
  'directive.input.promo-code-box',
  'directive.interview.question-field',
  'directive.input.recommendation-radio-button',
  'directive.input.decimal',
  'directive.input.timeframe',
  'directive.input.validate-decimal-places',
  'directive.input.bank-account-numbers',
  'directive.input.bank-account-verification-method'
]);
