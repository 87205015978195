'use strict';

function hashHandlerFactory(featureToggles) {
  var removeHashFromCurrentUrl = function($location) {
    var url = $location.url();
    var index = _.indexOf(url, '#');
    if (index >= 0) {
      $location.url(url.substr(0, index) + '/');
    } else {
      $location.url('/');
    }
  };

  var createResult = function(wasHandled, handlerName, handlerValue) {
    return {
      wasHandled: wasHandled,
      handlerName: handlerName || null,
      handlerValue: handlerValue || null
    };
  };

  var processUrlHashResult = {
    notHandled: function() {
      return createResult(false);
    },
    handled: function(handlerName, handlerValue) {
      return createResult(true, handlerName, handlerValue);
    }
  };

  var defaultProcess = function(urlHash, $location) {
    removeHashFromCurrentUrl($location);
    return urlHash.match(this.pattern)[1];
  };

  var hashProcessors = [{
    name: 'affiliateId',
    pattern: /^\#_._(.*)$/,
    process: function(urlHash, $location) {
      if (urlHash.substr(1) === $location.url().substr(1) && urlHash.length > 4) {
        // The only thing after the base URL is the affiliate code.  We can safely redirect to '/' and keep going.
        return this.defaultProcess(urlHash, $location);
      }
      return false;
    },
    defaultProcess: defaultProcess
  }, {
    name: 'referralCode',
    pattern: /^\#raf-(.*)$/,
    process: defaultProcess
  }, {
    name: 'featureToggle',
    pattern: /^\#feat-(.*)-(.*)$/,
    process: function(urlHash, $location) {
      removeHashFromCurrentUrl($location);

      var match = urlHash.match(this.pattern);
      var featureToggle = {
        enabled: match[1] === 'on',
        feature: match[2]
      };
      try {
        featureToggles.processUrlToggle(featureToggle, $location);
      } catch (e) {
        // when someone uses featureToggle that doesn't exist
        console.error(e);
      }
      return true;
    }
  }];

  return {
    processUrlHash: function(urlHash, $location) {
      if (!urlHash) {
        return processUrlHashResult.notHandled();
      }

      var value = null;
      _.find(hashProcessors, function(processor) {
        if (urlHash.match(processor.pattern)) {
          var result = processor.process(urlHash, $location);
          if (result !== false) {
            value = processUrlHashResult.handled(processor.name, result);
            return true;
          }
        }
      });
      return value || processUrlHashResult.notHandled();
    }
  };
}

angular.module('service.hashHandler', [
    'service.feature-toggles'
  ])
  .factory('hashHandler', [
    'featureToggles',
    hashHandlerFactory
  ]);
