'use strict';

function factory() {
  return {
    relationshipName: function(person) {
      if (person.maritalStatus.is.commonLaw()) {
        return 'partner';
      }
      return 'spouse';
    }
  };
}

angular.module('service.spouse-or-partner', [])
  .factory('spouseOrPartner', [
    factory
  ]);
