'use strict';

angular.module('model.PortfolioTacticalTarget', [
    'ram',
    'config',
    'model.PortfolioTacticalAllocationTarget'
  ])
  .factory('PortfolioTacticalTarget', [
    'ram',
    'config',
    factory
  ]);

function factory(ram, config) {

  var PortfolioTacticalTarget = new ram.Collection('PortfolioTacticalTarget', {
    hasMany: {
      portfolioTacticalAllocationTargets: 'PortfolioTacticalAllocationTarget'
    },
    hasOne: {
      modelPortfolio: 'ModelPortfolio'
    },
    serialize: [
      'portfolioTacticalAllocationTargets'
    ],
    resources: {
      default: new ram.resources.Http('/api/portfolio_tactical_targets/:id.json'),
      cookie: new ram.resources.Cookie('portfolio_tactical_targets')
    },
    schema: config.schemas.PortfolioTacticalTarget
  });

  return PortfolioTacticalTarget;
}
