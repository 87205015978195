'use strict';

angular.module('controller.dashboard.transfer-account-submitted', [])
  .controller('DashboardTransferAccountSubmittedCtrl', [
    '$scope',
    'submittedAccountTransfer',
    transferAccountSubmittedController
  ]);

function transferAccountSubmittedController($scope, submittedAccountTransfer) {
  $scope.submittedAccountTransfer = submittedAccountTransfer;
}
