'use strict';

function extensionDirective(patterns) {
  return {
    replace: true,
    restrict: 'E',
    scope: true,
    require: 'ngModel',
    templateUrl: 'directives/input/extension.html',

    link: function(scope) {
      scope.onChange = function(phoneNumber) {
        phoneNumber.parseFull();
        if (phoneNumber.extension().trim().length === 0 || phoneNumber.extension().match(patterns.extension)) {
          phoneNumber.save();
        }
      };
    },
  };
}

angular.module('directive.input.extension', [
    'service.patterns'
  ])
  .directive('extension', [
    'patterns',
    extensionDirective
  ]);
