'use strict';

function paginationMetaFactory(ram, config) {

  /**
   * Constructor for PaginationMeta model.
   * @class
   */
  var PaginationMeta = new ram.Collection('PaginationMeta', {
    schema: config.schemas.PaginationMeta,
    accessors: ['searchTerm',
      'currentPage',
      'totalPages',
      'sortBy',
      'sortDirection',
      'filter'
    ],
    resources: {
      default: new ram.resources.Http('/api/pagination_metas/:id.json'),
      cookie: new ram.resources.Cookie('pagination_metas')
    }
  });

  return PaginationMeta;
}

angular.module('model.PaginationMeta', [
    'ram',
    'config'
  ])
  .factory('PaginationMeta', [
    'ram',
    'config',
    paginationMetaFactory
  ]);
