'use strict';

var XS_WIDTH_BOUNDARY = 768;
var SELF_SERVICE_ID = '#self-service';
var FULLY_MANAGED_ID = '#fully-managed';

angular.module('directive.plus-service-options', [
    'component.ribbon',
    'directive.learn-more-button'
  ])
  .directive('plusServiceOptions', plusServiceOptionDirective);

plusServiceOptionDirective.$inject = [];

/**
 * This directive pollutes the parent scope!
 * Adds selectedSelfService() and selectedFullyManaged() into parent scope
 * Depends on having elements with id of '#self-service' and '#fully-managed'
 * for anchor in the parent scope
 */
function plusServiceOptionDirective() {
  return {
    restrict: 'E',
    scope: {},
    templateUrl: 'directives/plus-service-options.html',
    controller: ['$scope', '$window', '$location', '$analytics', plusServiceOptionController],
  };
}

function plusServiceOptionController($scope, $window, $location, $analytics) {
  angular.extend($scope, {
    selectedService: fetchSelectedService(),
    selectSelfService: selectSelfService,
    selectFullyManaged: selectFullyManaged,
    selectedSelfService: selectedSelfService,
    selectedFullyManaged: selectedFullyManaged,
    isXsWidth: isXsWidth,
    isLineWrapped: isLineWrapped,
    isLineTripleWrapped: isLineTripleWrapped
  });

  // Pollutes parent scope
  angular.extend($scope.$parent, {
    selectedSelfService: selectedSelfService,
    selectedFullyManaged: selectedFullyManaged
  });

  function selectSelfService() {
    $scope.selectedService = SELF_SERVICE_ID;
    setSelectedServiceLocally();
    track('clicked-invest-online');
  }

  function selectFullyManaged() {
    $scope.selectedService = FULLY_MANAGED_ID;
    setSelectedServiceLocally();
    track('clicked-dedicated-portfolio-manager');
  }

  function selectedSelfService() {
    return $scope.selectedService === SELF_SERVICE_ID;
  }

  function selectedFullyManaged() {
    return $scope.selectedService === FULLY_MANAGED_ID;
  }

  function fetchSelectedService() {
    if (localStorage.getItem('selectedService')) {
      return localStorage.getItem('selectedService');
    } else if ($scope.$root.isMobile || isXsWidth()) {
      return SELF_SERVICE_ID;
    }
  }

  function setSelectedServiceLocally() {
    localStorage.setItem('selectedService', $scope.selectedService);
  }

  function isXsWidth() {
    return $window.innerWidth < XS_WIDTH_BOUNDARY;
  }

  // Case by case function for if any of the line is wrapped. Used to check if spacing needed
  function isLineWrapped() {
    return $window.innerWidth < 529;
  }

  // Case by case function for if any of the line is wrapped. Used to check if spacing needed
  function isLineTripleWrapped() {
    return $window.innerWidth < 366;
  }

  function track(event) {
    $analytics.eventTrack(event + '-' + currentPath());

    function currentPath() {
      var path = $location.path();
      return path === '/' ? 'home' : _.rest(path).join('');
    }
  }
}
