'use strict';

angular.module('controller.modal.advisor.confirm-delete-last-prefill-for-client', [])
  .controller('ConfirmDeleteLastPrefillForClient', [
    '$scope',
    'ram',
    controller
  ]);

function controller($scope, ram) {
  $scope.alsoDeleteClient = new ram.Accessor(false);
  $scope.removeClient = 'Remove client';
  $scope.keepClient = 'Keep client';
}
