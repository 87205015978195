'use strict';

angular.module('routes.advise.adaptive-tactical-edit', [
    'controller.advise.adaptive-tactical-edit',
    'templates',
    'ui.router'
  ])
  .config([
    '$stateProvider',
    configRoutes
  ]);

function configRoutes($stateProvider) {
  $stateProvider
    .state('advise.adaptive-tactical-edit', {
      url: '/adaptive-tactical-edit',
      controller: 'AdviseAdaptiveTacticalEdit',
      templateUrl: 'views/advise/adaptive-tactical.html',
      resolve: {}
    });
}
