'use strict';

function unitQuoteFactory(ram) {
  var UnitQuote = new ram.Collection('UnitQuote', {
    key: [
      /**
       * @todo Fix this! Dirty hack below.
       *       Added adjusted here so that it will be copied to UnitQuote
       *       models as a plain property (not an accessor).
       */
      'adjusted',
      'date',
      'modelPortfolioId'
    ],
  });

  UnitQuote.prototype.initialize = function(attrs) {
    this.date = new Date(attrs.date);
  };

  UnitQuote.prototype.value = function(units) {
    return this.adjusted * units;
  };

  return UnitQuote;
}

angular.module('model.UnitQuote', [
    'ram'
  ])
  .factory('UnitQuote', [
    'ram',
    unitQuoteFactory
  ]);
