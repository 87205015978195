'use strict';

angular.module('routes.esign', [
    'templates',
    'ui.router',
    'controller.esign',
    'model.Document'
  ])
  .config([
    '$stateProvider',
    configRoutes
  ]);

function configRoutes($stateProvider) {

  function resolveEsignDocument(Document, $stateParams) {
    return Document.find({
      id: $stateParams.documentId
    });
  }

  $stateProvider
    .state('esign', {
      url: '/esign',
      parent: 'site',
      params: {
        documentId: undefined
      },
      resolve: {
        esignDocument: ['Document', '$stateParams', resolveEsignDocument]
      },
      views: {
        main: {
          controller: 'EsignCtrl',
          templateUrl: 'views/esign.html'
        },
        navbar: {
          templateUrl: 'navbar/dashboard.html',
        }
      }
    });
}
