'use strict';

angular.module('model.ApplicationReadModel', [
    'ram',
    'config',
    'model.Account',
  ])
  .factory('ApplicationReadModel', [
    '$http',
    'ram',
    'config',
    'Account',
    applicationReadModelFactory
  ]);

function applicationReadModelFactory($http, ram, config, Account) {
  const ApplicationReadModel = new ram.Collection('ApplicationReadModel', {
    bind: ['canEdit', 'canDelete', 'canSend', 'canRegenerateForms', 'getAccount'],
    belongsTo: {
      account: 'Account',
      advisorCreateNewAccountFlow: 'AdvisorCreateNewAccountFlow',
      advisorCreateNewClientFlow: 'AdvisorCreateNewClientFlow',
      accountReadModel: 'AccountReadModel',
      user: 'User',
      primaryAdvisorUser: 'User'
    },
    enums: {
      type: config.types.Account,
      status: config.types.ApplicationReadModelStatus,
      accountStatus: config.types.AccountStatus,
      invitationType: config.types.ApplicationReadModelInvitationType
    },
    schema: config.schemas.ApplicationReadModel,
    resources: {
      default: new ram.resources.Http('/api/application_read_models/:id.json'),
      cookie: new ram.resources.Cookie('application_read_models')
    }
  });

  ApplicationReadModel.prototype.getAccount = function () {
    return Account.find({
      id: this.accountId()
    });
  };

  ApplicationReadModel.prototype.approve = function () {
    const self = this;
    return this.getAccount()
      .then(function (account) {
        return account.approve();
      })
      .then(function () {
        return self.reload();
      });
  };

  ApplicationReadModel.prototype.get_esign_url = function () {
    return this.getAccount().then(function (account) {
      return account.get_esign_url();
    });
  };

  ApplicationReadModel.prototype.canRegenerateForms = function() {
    return this.applicationCompleted() && !this.status.is.open();
  };


  ApplicationReadModel.prototype.canEdit = function() {
    return this.advisorCreateNewClientFlow() && this.advisorCreateNewClientFlow().canEdit();
  };

  ApplicationReadModel.prototype.canDelete = function() {
    const hasClientFlow = !!this.advisorCreateNewClientFlow();
    const hasAccountFlow = !!this.advisorCreateNewAccountFlowId();

    return (hasClientFlow && this.advisorCreateNewClientFlow().canDeleteByAdvisor(this)) ||
      (hasAccountFlow && this.applicationStatus() === 'rejected') ||
      (hasAccountFlow && !hasClientFlow && this.applicationStatus() === 'pending');
  };

  ApplicationReadModel.prototype.canSend = function() {
    return this.advisorCreateNewClientFlow() && this.advisorCreateNewClientFlow().canSendToClient();
  };

  ApplicationReadModel.prototype.unlink = function() {
    return $http.put(`/api/application_read_models/${this.id}/unlink.json`);
  };

  ApplicationReadModel.prototype.statusHighlightLevel = function() {
    let highlightDetails = {};

    if (!this.advisorCreateNewClientFlow() || this.advisorCreateNewClientFlow().status() === 'completed') {
      return highlightDetails;
    }

    let staleDate = moment(this.advisorCreateNewClientFlow().staleDate());
    let currentDate = moment(moment().format('YYYY-MM-DD'));

    highlightDetails.daysUntilStale = staleDate.diff(currentDate, 'days');

    if (highlightDetails.daysUntilStale <= 2) {
      highlightDetails.status = 'danger';
    } else if (highlightDetails.daysUntilStale <= 10) {
      highlightDetails.status = 'warning';
    }

    return highlightDetails;
  };

  ApplicationReadModel.prototype.isBeforeStarted = function() {
    return this.status().id === config.types.ApplicationReadModelStatus.findByName('prefill_not_sent').id ||
      this.status().id === config.types.ApplicationReadModelStatus.findByName('prefill_sent').id
  }

  return ApplicationReadModel;
}
