'use strict';

import dateRangeComponent from './date-range.component';

const DateRangeModule = angular.module('component.advisor.date-range', [
    'directive.input.date-input'
  ])
  .component('dateRange', dateRangeComponent);

export default DateRangeModule;
