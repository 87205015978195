'use strict';

function factory() {
  var defaultPeriods = [{
      label: 'Last 1 month',
      start: moment()
        .subtract(1, 'month')
        .toDate()
    },
    {
      label: 'Last 3 months',
      start: moment()
        .subtract(3, 'month')
        .toDate()
    },
    {
      label: 'Last 6 months',
      start: moment()
        .subtract(6, 'month')
        .toDate()
    },
    {
      label: 'Last 12 months',
      start: moment()
        .subtract(12, 'month')
        .toDate()
    },
    {
      label: 'Year-to-date',
      start: moment()
        .startOf('year')
        .toDate()
    },
    {
      label: 'Since Inception',
      start: null
    }
  ];

  function getPeriods() {

    return defaultPeriods;
  }

  return {
    getPeriods: getPeriods
  };
}

angular.module('service.timeframeDisplayHelper', [])
  .factory('timeframeDisplayHelper', [
    factory
  ]);
