'use strict';

function factory($analytics, $cookies, $modal, $q) {

  /**
   * Check whether visitor is a user
   *
   * @return {Boolean}
   */
  function isUser() {
    return !!$cookies.get('USER');
  }

  /**
   * Open a modal dialog that allows users to sign up for the mailing list.
   *
   * @param {Object} options
   * @param {String} options.email      email address to prefill input
   * @param {String} options.modalTitle title to display
   * @param {String} options.modelBody  paragraph text
   * @return {Promise}                  resolves when modal closes
   */
  function openMailingListModal(options) {
    $analytics.eventTrack('viewed-mailing-list-modal');
    if (!isUser() && !$('.modal-backdrop').hasClass('in')) {
      return $modal.open({
        templateUrl: 'modals/mailing-list.html',
        controller: 'ModalMailingListController',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          options: function() {
            return options;
          }
        }
      }).result;
    }
  }

  /**
   * Check whether use has already signed up for the mailing list.
   *
   * @return {Boolean}
   */
  function onMailingList() {
    return !!$cookies.get('MAILING_LIST_SUBSCRIBER');
  }

  /**
   * Open the mailing list signup modal dialog if user is not already
   * subscribed. Otherwise, return a resolved promise.
   *
   * @return {Promise}
   */
  function emailGate() {
    if (!onMailingList()) {
      return openMailingListModal({
        modalTitle: 'Create your portfolio',
        modalBody: 'Sign up for our mailing list to create your sample portfolio'
      });
    }
    return $q.when();
  }

  /**
   * Send the user's email address via $analytics and store a cookie.
   *
   * @param  {String} email Address to add to mailing list.
   */
  function subscribeMailingList(firstName, email) {
    $analytics.setUserProperties({
      name: firstName,
      email: email
    });
    $analytics.eventTrack('mailing-list-subscribe');
    $cookies.put('MAILING_LIST_SUBSCRIBER', true, {
      expires: 'Fri, 31 Dec 9999 23:59:59 GMT'
    });
    return true;
  }

  /**
   * Add banner when user scrolls 60% down the page.
   *
   */
  function addScrollBanner() {
    if ($(window).scrollTop() > $('#state').height() * 0.6) {
      $('#signup-form').addClass('slideup');
    } else {
      $('#signup-form').removeClass('slideup');
    }
  }

  function removeBanner() {
    $('#signup-form').removeClass('slideup');
    $(window).off('scroll');
  }

  return {
    emailGate: emailGate,
    onMailingList: onMailingList,
    openMailingListModal: openMailingListModal,
    subscribeMailingList: subscribeMailingList,
    addScrollBanner: addScrollBanner,
    removeBanner: removeBanner
  };
}

angular.module('service.marketing', [
    'ngCookies',
    'ui.bootstrap'
  ])
  .factory('marketing', [
    '$analytics',
    '$cookies',
    '$uibModal',
    '$q',
    factory
  ]);
