'use strict';

angular.module('controller.advise.csv-reports-view', [
    'component.advisor.date-range'
  ])
  .controller('AdviseCsvReportsViewCtrl', [
    '$scope',
    '$state',
    '$http',
    'ram',
    adviseReportsViewCtrl
  ]);

function adviseReportsViewCtrl($scope, $state, $http, ram) {
  $scope.reports = [{
    label: 'InvestorDNA Monthly Balances',
    value: 'advise.csv-reports-view.idna',
    name: 'investor_dna'
  }, {
    label: 'Quarterly AUM Demographics',
    value: 'advise.csv-reports-view.aum',
    name: 'aum_demographics'
  }, {
    label: 'Quarterly AUM Advisor Report',
    value: 'advise.csv-reports-view.aum-advisor',
    name: 'aum_advisor'
  }, {
    label: 'Snapshot Beneficiary Report',
    value: 'advise.csv-reports-view.beneficiary',
    name: 'beneficiary'
  }, {
    label: 'External Advisor Group Report',
    value: 'advise.csv-reports-view.advisor-group',
    name: 'advisor_group'
  }, {
    label: 'Quarterly Fund Flows Report',
    value: 'advise.csv-reports-view.fund-flows',
    name: 'fund_flows'
  }, {
    label: 'Quarterly Fund Flows by Advisor Report',
    value: 'advise.csv-reports-view.fund-flows-by-advisor',
    name: 'fund_flows_by_advisor'
  }, {
    label: 'Quarterly Account Balances Report',
    value: 'advise.csv-reports-view.account-balances',
    name: 'account_balances'
  }, {
    label: 'Quarterly AUM Report',
    value: 'advise.csv-reports-view.quarterly-aum',
    name: 'quarterly_aum'
  }, {
    label: 'Quarterly Advisors Added Report',
    value: 'advise.csv-reports-view.advisors-added',
    name: 'advisors_added'
  }];

  $scope.selectedReport = new ram.Accessor($state.current.name);
  $scope.startDate = null;
  $scope.endDate = null;
  $scope.name = _getReportObject($scope.selectedReport()).name;
  $scope.href = null;

  $scope.dateRangePicked = function(startDate, endDate) {
    $scope.startDate = startDate;
    $scope.endDate = endDate;
  };

  $scope.reportSelected = function() {
    $state.go($scope.selectedReport());
    $scope.name = _getReportObject($scope.selectedReport()).name;
    $scope.startDate = null;
    $scope.endDate = null;
  };

  $scope.params = function() {
    var parts = [];
    switch ($scope.name) {
      case 'advisor_group':
      case 'beneficiary':
        parts.push('name=' + $scope.name);
        break;

      case 'quarterly_aum':
      case 'aum_demographics':
        if ($scope.endDate) {
          parts.push('name=' + $scope.name);
          parts.push('end_date=' + _formatDate($scope.endDate));
        }
        break;

      case 'aum_advisor':
        if ($scope.endDate) {
          parts.push('name=' + $scope.name);
          parts.push('start_date=' + _formatDate($scope.startDate));
          parts.push('end_date=' + _formatDate($scope.endDate));
        }
        break;

      case 'investor_dna':
        if ($scope.startDate && $scope.endDate) {
          parts.push('name=' + $scope.name);
          parts.push('start_date=' + _formatDate($scope.startDate));
          parts.push('end_date=' + _formatDate($scope.endDate));
        }
        break;

      case 'fund_flows':
      case 'fund_flows_by_advisor':
      case 'account_balances':
      case 'advisors_added':
        if ($scope.endDate) {
          parts.push('name=' + $scope.name);
          parts.push('start_date=' + _formatDate($scope.startDate));
          parts.push('end_date=' + _formatDate($scope.endDate));
        }
        break;
    }

    if (parts.length === 0) {
      return null;
    }

    return '?' + parts.join('&');
  };

  function _formatDate(date) {
    return moment(date).format('YYYY-MM-DD');
  }

  function _getReportObject(value) {
    return _.find($scope.reports, function(report) {
      return report.value === value;
    }) || {};
  }
}
