'use strict';

angular.module('service.form-modification-tracker', [
    'service.modals'
  ])
  .factory('formModificationTracker', [
    '$q',
    'modals',
    formModificationTracker
  ]);

function formModificationTracker($q, modals) {

  var _currentForm = null;
  var _currentFormDescription = null;
  var _currentController = null;
  var _forms = {};

  return {
    setCurrentForm: function(formName, formDescription) {
      _currentForm = formName;
      _currentFormDescription = formDescription;
    },

    activeSectionController: function(controller) {
      if (arguments.length) {
        _currentController = controller;
      }
      return _currentController;
    },

    hasUnsavedChanged: function() {
      if (!_currentForm) {
        return false;
      }
      return _forms[_currentForm].isDirty();
    },

    registerForm: function(formName, form) {
      _forms[formName] = form;
    },

    unregisterForm: function(formName) {
      _forms[formName] = null;
    },

    discardChanges: function() {
      if (_currentForm) {
        return _forms[_currentForm].reset();
      }
    },

    onOpenSectionForEdit: function(userWantsToLeaveCallback, userWantsToOpenSectionCallback) {
      var _this = this;

      if (_currentForm === null) {
        if (userWantsToOpenSectionCallback) {
          userWantsToOpenSectionCallback();
        }
        return;
      }

      if (!_this.hasUnsavedChanged()) {
        if (userWantsToLeaveCallback) { // Clear out the old stuff
          userWantsToLeaveCallback();
        }
        if (userWantsToOpenSectionCallback) { // Prepare the new stuff
          userWantsToOpenSectionCallback();
        }
        return;
      }

      return modals.unsavedChangesModal(_currentFormDescription).result
        .then(_this.discardChanges)
        .then(function() {
          if (userWantsToLeaveCallback) { // Clear out the old stuff
            userWantsToLeaveCallback();
          }
          if (userWantsToOpenSectionCallback) { // Prepare the new stuff
            userWantsToOpenSectionCallback();
          }
        });
    },

    doReset: function() {
      var _this = this;
      if (_this.activeSectionController()) {
        _this.activeSectionController().toggleEditingEnabled();
        _this.activeSectionController(null);
      }
      _this.setCurrentForm(null);
    },

    onFormExit: function($event, userHasUnsavedChangesCallback, userWantsToLeaveCallback, noUnsavedChangesCallback, specialMessage) {
      var _this = this;

      if (!_this.hasUnsavedChanged()) {
        if (noUnsavedChangesCallback) {
          noUnsavedChangesCallback();
        }
        _this.doReset();
        return;
      }

      if ($event) {
        $event.preventDefault();
      }
      if (userHasUnsavedChangesCallback) {
        userHasUnsavedChangesCallback();
      }

      if (specialMessage && specialMessage.mandatory) {
        modals.genericOkModal(specialMessage.messageTitle, specialMessage.messageBody);
        return;
      }

      return modals.unsavedChangesModal().result
        .then(_this.discardChanges)
        .then(function() {
          if (userWantsToLeaveCallback) {
            userWantsToLeaveCallback();
            _this.doReset();
          }
        });
    },

    getCurrentForm: function() {
      return _forms[_currentForm];
    }
  };
}
