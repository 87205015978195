'use strict';

function paginatedAccountFactory(ram, config) {

  /**
   * Constructor for PaginatedAccount model.
   * @class
   */
  var PaginatedAccount = new ram.Collection('PaginatedAccount', {
    accessors: ['draftStatementOk', 'draftStatementFilename'],
    schema: config.schemas.PaginatedAccount,
    hasOne: {
      paginationMeta: 'PaginationMeta'
    },
    hasMany: {
      accounts: 'AccountReadModel',
    },
    resources: {
      default: new ram.resources.Http('/api/paginated_accounts/:id.json'),
      cookie: new ram.resources.Cookie('paginated_accounts')
    }
  });

  return PaginatedAccount;
}

angular.module('model.PaginatedAccount', [
    'ram',
    'config',
    'model.PaginationMeta',
    'model.AccountReadModel'
  ])
  .factory('PaginatedAccount', [
    'ram',
    'config',
    paginatedAccountFactory
  ]);
