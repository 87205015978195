'use strict';

angular.module('service.new-client-goto-next-state-service', [])
  .factory('newClientGotoNextStateService', [
    NewClientGotoNextStateService
  ]);

function NewClientGotoNextStateService() {
  function go($state, $scope, params) {
    const {
      source,
      groupUrlIdAndLink,
      advisorDetails
    } = params;
    if (source === 'service-level' && advisorDetails.multiRelationshipManagers) {
      $state.go('advise.add-client.add-advisors');

    } else if (source === 'new-client-button' && (advisorDetails.multiServiceLevels || advisorDetails.multiRelationshipManagers)) {
      $state.go('advise.add-client', {
        redirect: true
      });

    } else if (advisorDetails.allowedPortfolioManagers.length === 1) {
      // Use the only PM and skip the selection page
      const portfolioManagerId = advisorDetails.allowedPortfolioManagers[0].id;
      $scope.inviteClientModal({
        ...params,
        portfolioManagerId
      });

    } else {
      // Let the advisor pick a PM
      $state.go('advise.add-client.preferred-portfolio-manager', {
        groupUrlIdAndLink
      });
    }
  }

  return {
    go
  };
}
