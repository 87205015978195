'use strict';

angular.module('directive.beneficiary-regular', [
    'directive.input',
    'model.Person',
    'model.Beneficiary',
    'service.spouse-or-partner',
    'service.beneficiary-service'
  ])
  .directive('beneficiaryRegular', [
    '$q',
    'Person',
    'Beneficiary',
    'beneficiaryService',
    'spouseOrPartner',
    beneficiaryRegularDirective
  ]);

function beneficiaryRegularDirective(
  $q,
  Person,
  Beneficiary,
  beneficiaryService,
  spouseOrPartner
) {
  return {
    restrict: 'E',
    scope: {
      account: '=',
      client: '=',
      beneficiary: '=',
      personalSubforms: '=',
      rowClass: '@',
      foreignScope: '=',
      isClientFilling: '='
    },
    replace: true,
    templateUrl: 'directives/beneficiary-regular.html',

    controller: ['$scope', function($scope) {
      $scope.currentUser = $scope.client; // This may be used in the context of superadvisor is the currentUser
      $scope.person = $scope.client.person();
      $scope.spouse = $scope.person.spouseRelation() && $scope.person.spouseRelation().relative();

      $scope.estateIsBeneficiary = beneficiaryService.estateIsBeneficiary;
      $scope.spouseIsBeneficiary = beneficiaryService.spouseIsBeneficiary;
      $scope.otherIsBeneficiary = beneficiaryService.otherIsBeneficiary;
      $scope.beneficiarySummaryStatement = beneficiaryService.beneficiarySummaryStatement;

      $scope.beneficiaryLoading = function() {
        var loadingState = beneficiaryService.beneficiaryLoading();
        if ($scope.foreignScope) {
          $scope.foreignScope.isSaving = loadingState;
        }
        return loadingState;
      };

      var person = $scope.person;
      beneficiaryService.loadingOff();

      $scope.beneficiaryOfSuccessor = function() {
        if ($scope.account.type.canHaveSuccessorHolder()) {
          if ($scope.account.type.is.tfsa()) {
            return 'successor holder';
          } else {
            return 'successor annuitant';
          }
        }
        return 'beneficiary';
      };

      $scope.beneficiaryRelationTypes = Beneficiary.getBeneficiaryRelationTypes('regularBeneficiary');

      if ($scope.personalSubforms) {
        $scope.personalSubforms.saveBeneficiary = function() {
          if ($scope.beneficiary) {
            return $scope.beneficiary.save();
          }
        };
      }

      $scope.onPersonChange = function(person) {
        if (!beneficiaryService.inlineSave()) {
          return;
        }
        return person.save();
      };

      $scope.onPersonalRelationChange = function(personalRelation) {
        if (!beneficiaryService.inlineSave()) {
          return;
        }
        return personalRelation.save();
      };

      $scope.spouseOrPartner = function() {
        return spouseOrPartner.relationshipName(person);
      };

      // this is fixing the problem when spouse cannot be removed, because it was also linked as a beneficiary
      // this overrides method that is triggered, whet the marital status is changed and FIRST removes
      // the beneficiary relation and then proceeds with the spouse removal
      $scope.person.maritalStatusChange = function() {
        if (!$scope.person.hasSpouse() && beneficiaryService.spouseIsBeneficiary()) {
          return beneficiaryService.maritalStatusChange($scope.person);
        } else {
          return Person.prototype.maritalStatusChange.call($scope.person);
        }
      };

      $scope.$watch('person.spouseRelation()', function(relation) {
        $scope.spouse = relation && relation.relative();
      });

      $scope.$on('$destroy', function() {
        $scope.person.maritalStatusChange = Person.prototype.maritalStatusChange;
      });
    }]
  };
}
