'use strict';

angular.module('controller.interview.financial', [
    'model.Question',
    'ram'
  ])
  .controller('InterviewFinancialController', [
    '$cookies',
    '$scope',
    '$state',
    '$stateParams',
    'questions',
    interviewFinancialController
  ]);

function interviewFinancialController($cookies, $scope, $state, $stateParams, questions) {
  // TODO: refactor this into parent interview controller
  $scope.questions = _.indexBy(questions, function(question) {
    return question.accessor();
  });
  $scope.$parent.nextSection = function() {
    if (!$scope.isOpeningGroupSavingsPlan()) {
      $state.go('interview.risk');
    } else {
      $state.go($state.current.data.next);
    }
  };
  $scope.$parent.lastSection = function() {
    if ($stateParams.preselectTrial || $scope.isOpeningGroupSavingsPlan()) {
      $state.go('/investment/goal');
    } else if ($scope.account.type.is.spousalRrsp()) {
      $state.go($state.current.data.last);
    } else if ($scope.account.type.joint()) {
      $state.go('interview.account-type');
    } else if ($scope.isAdvisorCreateNewAccountFlow()) {
      $state.go('/investment/goal');
    } else {
      $state.go('interview.account-type');
    }
  };
  $scope.$parent.step = $state.current.data.step;
  $scope.$parent.header = $state.current.data.header;
  $scope.$parent.userCanGoBack = true;

  $scope.saveFinancialData = function() {
    $scope.user.updatedAt(moment.utc().toDate());
    $scope.user.save();
  };
}
