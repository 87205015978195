'use strict';

angular.module('controller.advise.client.view', [
    'filter.pretty-print-phone-number',
    'ram',
    'model.Document',
    'model.User',
    'service.download-csv',
    'service.promotion',
    'service.two-factor-authenticator',
    'directive.bank-account-card',
    'directive.editableContext',
    'directive.goal-summary',
    'directive.profile-form',
    'directive.editableField',
    'directive.staticField',
    'directive.with',
    'directive.user.date-of-birth-dropdowns',
    'directive.input.email',
    'directive.user.address-field',
    'service.init-unsaved-changes-tracking',
    'component.filtered-client-documents',
    'service.account-service',
  ])
  .controller('AdviseClientViewCtrl', [
    '$rootScope',
    '$scope',
    '$q',
    '$state',
    '$timeout',
    '$http',
    '$window',
    '$cookies',
    'config',
    'ram',
    'client',
    'Document',
    'User',
    'blankDocument',
    'clientAccounts',
    'feeCreditAccount',
    'twoFactorAuthenticator',
    'promotion',
    'downloadCsv',
    'authorizeSuperAdvisor',
    'unsavedChangesTracking',
    'accountService',
    'goals',
    'transferInstructions',
    'currentFeeSchedule',
    'advisorCreateNewClientFlows',
    'securityServiceFactory',
    adviseClientViewController
  ]);

function adviseClientViewController(
  $rootScope,
  $scope,
  $q,
  $state,
  $timeout,
  $http,
  $window,
  $cookies,
  config,
  ram,
  client,
  Document,
  User,
  blankDocument,
  clientAccounts,
  feeCreditAccount,
  twoFactorAuthenticator,
  promotion,
  downloadCsv,
  authorizeSuperAdvisor,
  unsavedChangesTracking,
  accountService,
  allGoals,
  transferInstructions,
  currentFeeSchedule,
  advisorCreateNewClientFlows,
  securityServiceFactory
) {

  $scope.feeCreditAccount = feeCreditAccount;
  $scope.promo = promotion;
  $scope.documentTypeToAdd = new ram.Accessor();
  $scope.addDocumentTo = new ram.Accessor();
  $scope.client = client;
  $scope.blankDocument = blankDocument;
  $scope.authorizeSuperAdvisor = authorizeSuperAdvisor;
  $scope.clientAccounts = clientAccounts;
  $scope.hasFccAccount = accountService.hasFccAccount(clientAccounts);
  $scope.currentFeeSchedule = currentFeeSchedule;
  $scope.transferInstructions = transferInstructions;
  $scope.advisorCreateNewClientFlow = _.first(advisorCreateNewClientFlows);
  $scope.activeClientFlowInProgress = $scope.advisorCreateNewClientFlow && $scope.advisorCreateNewClientFlow.status() !== 'completed';
  $scope.isClosing = {};


  const security = securityServiceFactory.create($scope.$root.currentUser);
  $scope.allowEdit = security.canAccess('client_personal_info_edit');

  function _runSplitGoals(params) {
    const {
      goals,
      oldGoals
    } = _splitGoalsIntoCategories(allGoals, params);
    $scope.goals = goals;
    $scope.oldGoals = oldGoals;
  }
  _runSplitGoals();

  $scope.upload = {
    state: 'noFile',
    clientId: client.id // Pick files for the client, not the advisor
  };

  unsavedChangesTracking.init($rootScope, $scope, $window, $state);

  $scope.onDateOfBirthChange = function(person, date) {
    person.birthDate(date.toDate());
  };

  $scope.canCloseAccount = function(account) {
    return !account.status.is.closed() && (account.type.is.trial() || account.balance() === 0);
  };

  $scope.closeAccount = function(account, $id) {
    /* jshint quotmark:false */
    var modalBody = s.sprintf("Are you sure you want to close %s's %s account %s?", account.clientName(), account.type().label, account.number() || '(number not assigned)?');
    var title = s.sprintf("Closing %s's %s account", account.clientName(), account.type().label);
    /* jshint quotmark:single */
    if (account.balance() > 0) {
      modalBody += ' <br/>The client will lose the trial earnings. ';
    }

    $scope.genericConfirmationModal(title, modalBody)
      .result
      .then(function() {
        $scope.isClosing[$id] = true;
        return account.close();
      })
      .then(() => {
        return account.reload({
          force: true
        });
      })
      .then((account) => _runSplitGoals({
        closed: account.id
      }))
      .finally(() => {
        $scope.isClosing[$id] = false;
      });
  };

  if ($scope.authorizeSuperAdvisor) {
    $scope.documentTypes = _.reject(config.types.Document, function(doc) {
      return doc.hidden;
    });
  } else {
    $scope.documentTypes = _.filter(config.types.Document, function(doc) {
      return doc.extAdvisorUpload;
    });
  }

  $scope.documentTypes.sort((x, y) => x.label.localeCompare(y.label));

  var userRecordDropdownItem = function() {
    return {
      label: function() {
        return 'User record';
      },
      id: -1 // This is *NOT* a valid database ID, so it will be caught in the controller
    };
  };
  $scope.attachToOptions = clientAccounts.slice(0); // Shallow copy.  Don't re-use the same array.  It is used for display elsewhere too.
  $scope.attachToOptions.unshift(userRecordDropdownItem());
  $scope.attachToOptions = $scope.attachToOptions.concat($scope.client.bankAccounts().slice(0));
  $scope.baseAttachToOptions = $scope.attachToOptions;

  var accountDocumentTypeIds = _.chain($scope.documentTypes)
    .filter({
      relatedToType: 'Account'
    })
    .pluck('id')
    .value();

  var bankAccountDocumentTypeIds = _.chain($scope.documentTypes)
    .filter({
      relatedToType: 'Bank Account'
    })
    .pluck('id')
    .value();

  $scope.$watch('documentTypeToAdd()', function(documentType) {
    if (_.contains(bankAccountDocumentTypeIds, documentType)) {
      $scope.requireRelatedTo = true;
      $scope.attachToOptions = _.filter($scope.baseAttachToOptions, function(option) {
        return option.constructor.name === 'BankAccount';
      });
    } else if (_.contains(accountDocumentTypeIds, documentType)) {
      $scope.requireRelatedTo = true;
      $scope.attachToOptions = _.filter($scope.baseAttachToOptions, function(option) {
        return option.constructor.name === 'Account';
      });
    } else {
      $scope.requireRelatedTo = false;
      $scope.attachToOptions = $scope.baseAttachToOptions;
      $scope.addDocumentTo(null);
    }
  });

  $scope.unlockFilePicker = function() {
    if ($scope.upload.state === 'hasFile') {
      // Have just uploaded a document.  Advisor just picked a new document type or
      // new account.  Need to clear out the dropzone.  Only need to do it once.
      $scope.upload.state = 'noFile';
      $scope.blankDocument = null;
    }

    if ($scope.documentTypeToAdd() >= 0 && $scope.addDocumentTo()) {
      $scope.upload.state = 'hasFile';
      $scope.upload.enable = true;

      $scope.upload.documentType = $scope.documentTypeToAdd();

      if (_.contains(bankAccountDocumentTypeIds, $scope.documentTypeToAdd())) {
        $scope.upload.relatedToType = 'BankAccount';
        $scope.upload.relatedToId = $scope.addDocumentTo();
      } else if (_.contains(accountDocumentTypeIds, $scope.documentTypeToAdd())) { // Valid database IDs are >= 1
        $scope.upload.relatedToType = 'Account';
        $scope.upload.relatedToId = $scope.addDocumentTo();
      } else {
        $scope.upload.relatedToType = 'User';
        $scope.upload.relatedToId = $scope.client.id;
      }
    }
  };

  $scope.done = function() {
    $scope.documentTypeToAdd(null);
    $scope.addDocumentTo(null);
    $scope.upload.state = 'noFile';
    $scope.upload.enable = false;
    $scope.blankDocument = null;
    $state.reload();
  };

  $scope.sendMobileUploadLink = function() {
    $scope.advisorSendClientMobileUploadLink($scope.client.id);
  };

  $scope.deactivateTwoFA = function() {
    $scope.feedbackMessage = {};
    $scope.deactivateClientTwoFA()
      .result
      .then(function() {
        return twoFactorAuthenticator.deactivateForClient($scope.client.id);
      })
      .then(function(result) {
        if (result.error) {
          $scope.feedbackMessage = result;
        } else {
          User.find({
            id: $scope.client.id
          }).then(function(user) {
            $scope.client = user;
          });
        }
      });
  };

  $scope.removePromoAndReload = function(promotionRedemption) {
    $scope.promo.remove(promotionRedemption).then(function() {
      $scope.client.promotionRedemptions.ready(true);
    });
  };

  $scope.downloadBalances = downloadCsv.balances;
  $scope.changeAdvisor = function() {
    $scope.advisorChangeClientGroupModal($scope.client);
  };

  $scope.custodianCode = function(account) {
    return account.custodian().code();
  };

  $scope.addAccountAction = function() {
    let newState = 'advise.add-client-account';
    let stateArgs = {
      id: $scope.client.id
    };

    if ($scope.activeClientFlowInProgress) {
      stateArgs = {
        id: $scope.advisorCreateNewClientFlow.id
      };
      newState = $scope.advisorCreateNewClientFlow.hasAllRequiredFieldsFilled() ?
        'advise.client-application.review' : 'advise.client-application.setup';
    }

    $state.go(newState, stateArgs);
  };

  $scope.canShowMessage = false;

  $scope.removeCookie = function (client) {
    const personId = client.person().id;
    const cookieName = `manually-confirmed-${personId}`;
    const manuallyConfirmedCookie = $cookies.get(cookieName);

    if (manuallyConfirmedCookie === 'true') {
      $scope.canShowMessage = true;
      $cookies.remove(cookieName);
    }
  };

  function _splitGoalsIntoCategories(allGoals, params) {
    params = params || {};
    const output = {
      goals: [],
      oldGoals: []
    };
    allGoals.forEach(goal => {
      const account = goal.accountGoals()[0].account();

      if (account.status.is.closed() || params.closed === account.id) {
        output.oldGoals.push(goal);

      } else {
        output.goals.push(goal);
      }
    });
    return output;
  }
}
