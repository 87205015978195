'use strict';

angular.module('controller.advise.transfer-client-account', [
    'directive.account-transfer-form'
  ])
  .controller('AdviseTransferClientAccountCtrl', [
    '$scope',
    'client',
    'transferableAccounts',
    adviseTransferClientAccountController
  ]);

function adviseTransferClientAccountController($scope, client, transferableAccounts) {
  $scope.client = client;
  $scope.transferableAccounts = transferableAccounts;
}
