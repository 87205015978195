'use strict';

angular.module('controller.advisor-new-account', [
    'controller.advisor-new-account.continue',
    'controller.advisor-new-account.reject',
    'controller.advisor-new-account.stale',
    'controller.advisor-new-account.view'
  ])
  .controller('AdvisorNewAccountController', [
    '$scope',
    'newAccountFlow',
    'advisorDetails',
    function($scope, newAccountFlow, advisorDetails) {
      $scope.branding = advisorDetails.branding;
      $scope.newAccountFlow = newAccountFlow;
    }
  ]);
