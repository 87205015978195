'use strict';

class HoldingsPieChartController {
  constructor(
    $scope
  ) {
    this.$scope = $scope;
    this.$scope.$ctrl.positions = {

      ...this.$scope.$ctrl.positions,
      positions: this.$scope.$ctrl.positions.positions.map(position => {
        const {
          marketValue
        } = position;
        const {
          label,
          color
        } = position.fund();

        return {
          ...position,
          marketValue: marketValue(),
          label: label(),
          color,
        };
      })
    };
  }

  $onInit() {
    this.positionPoint = {};
  }
}

export default HoldingsPieChartController;
