'use strict';

angular.module('service.user-session-service', [
    'service.modals',
    'service.loading-indicator',
    'service.mobile-post-message-bridge',
    'service.adaptive-views'
  ])
  .factory('userSessionService', [
    '$interval',
    '$http',
    '$state',
    '$window',
    'loadingIndicator',
    'adaptiveViews',
    'mobilePostMessageBridge',
    userSessionService
  ]);

function userSessionService($interval, $http, $state, $window, loadingIndicator, adaptiveViews, mobilePostMessageBridge) {
  var _currentTracker = null;
  var _running = false;
  var _currentUser = null;

  var stopSessionCheck = function() {
    _running = false;
    _currentUser = null;

    if (_currentTracker) {
      $interval.cancel(_currentTracker);
      _currentTracker = null;
    }
  };

  var onSessionExpired = function() {
    stopSessionCheck();

    if (adaptiveViews.isApp()) {
      mobilePostMessageBridge.postMessage({
        messageType: 'sessionTimedOut'
      });
    } else if (!$state.includes('iframe-widgets')) {
      loadingIndicator.show();
      $window.location.href = '/signin?sessionExpired=true';
    }
  };

  var processResult = function(result) {
    if (result.data && result.data.userId) {
      var userId = result.data.userId;
      if (userId !== _currentUser.id) {
        // different user already logged in
        onSessionExpired();
      }
    } else {
      // there is no user, session expired
      onSessionExpired();
    }
  };

  var getSessionStatus = function() {
    return $http
      .get('get_session_status.json', {
        'format': 'json'
      });
  };

  var runStatusChecker = function() {
    if (!_running) {
      return;
    }

    getSessionStatus()
      .then(function(result) {
        processResult(result);
      });
  };

  var startSessionCheck = function(currentUser) {
    if (_running) {
      stopSessionCheck();
    }

    _running = true;
    _currentUser = currentUser;

    _currentTracker = $interval(runStatusChecker, 60000, 0, false);
  };

  return {
    getSessionStatus: getSessionStatus,
    startSessionCheck: startSessionCheck,
    stopSessionCheck: stopSessionCheck
  };
}
