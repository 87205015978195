'use strict';

angular.module('controller.modal.activate-two-factor-authentication', [
    'ram',
    'ngSanitize',
    'model.User',
    'service.adaptive-views',
    'service.patterns',
    'service.two-factor-authenticator'
  ])
  .controller('ModalActivateTwoFactorAuthenticationController', [
    '$rootScope',
    '$scope',
    '$sce',
    '$http',
    '$state',
    '$window',
    '$timeout',
    'ram',
    'patterns',
    'twoFactorAuthenticator',
    'User',
    'isMobile',
    'adaptiveViews',
    modalActivateTwoFactorAuthenticationController
  ]);

function modalActivateTwoFactorAuthenticationController($rootScope, $scope, $sce, $http, $state, $window, $timeout, ram, patterns, twoFactorAuthenticator, User, isMobile, adaptiveViews) {
  $scope.isMobile = isMobile;
  $scope.isSafari = adaptiveViews.isSafari;
  $scope.preferredAuthenticationMethod = new ram.Accessor('authApp');
  $scope.otpCode = new ram.Accessor();

  $scope.dismissOrGoToProfile = function() {
    if ($state.is('profile-activate-two-factor-authentication') ||
      $state.is('profile-deactivate-two-factor-authentication')) {
      return $state.go('profile.security');
    } else {
      $scope.$dismiss();
    }
  };
  $scope.phoneNumberPattern = patterns.phoneNumber;
  $scope.phoneNumber = new ram.Accessor();
  // replicate validate method to make the phone-number-international directive work
  // since we are not using a PhoneNumber model
  $scope.phoneNumber.validate = function() {
    return patterns.phoneNumber.test($scope.phoneNumber());
  };

  function formatPhoneNumberForDatabase(phoneNumber) {
    return '+1' + phoneNumber.replace(/[^\d]/g, '');
  }
  $scope.preferredContactMethod = new ram.Accessor();

  $scope.code = new ram.Accessor();

  $scope.steps = [
    'choose-method',
    'contact-info',
    'enter-code',
    'authenticator-app-scan',
    'authenticator-app-verify'
  ];
  $scope.preferredAuthenticationMethodOptions = [{
    label: 'Authenticator app',
    value: 'authApp'
  }, {
    label: 'Phone (SMS/Call)',
    value: 'phone'
  }];

  $scope.preferredContactMethodOptions = [{
    label: 'SMS/Text',
    value: 'text'
  }, {
    label: 'Call',
    value: 'call'
  }];

  $scope.currentStep = 0;
  $scope.isSaving = false;

  $scope.stepIs = function(stepName) {
    return $scope.steps[$scope.currentStep] === stepName;
  };

  function reloadUser() {
    return User.find({
      id: $rootScope.currentUser.id
    }).then(function(user) {
      $rootScope.currentUser = user;
    });
  }

  $scope.sendActivation2faCode = function() {
    $scope.feedbackMessage = {};
    return twoFactorAuthenticator.sendActivationCode(
      formatPhoneNumberForDatabase($scope.phoneNumber()),
      $scope.preferredContactMethod()
    ).then(function(result) {
      $scope.feedbackMessage = result;
    });
  };

  $scope.previousStep = function() {
    $scope.feedbackMessage = {};
    if ($scope.currentStep === 0) {
      $scope.dismissOrGoToProfile();
    } else if ($scope.currentStep === 3) {
      $scope.currentStep = 0;
    } else {
      $scope.currentStep -= 1;
    }
  };

  $scope.copyCodeToClipboard = function() {
    navigator.clipboard.writeText($scope.qrLink);
  };

  $scope.focus = function(elementName) {
    var element = $window.document.getElementsByName(elementName)[0];
    if (element) {
      element.focus();
    }
  };

  $scope.focusOnInput = function(elementName) {
    $timeout(function() {
      $scope.focus(elementName);
    }, 0);
  };

  $scope.nextStep = function() {
    console.log('test');
    console.log($scope.preferredAuthenticationMethod());
    $scope.feedbackMessage = {};
    if ($scope.currentStep === 0) {
      if ($scope.preferredAuthenticationMethod() === 'authApp') {
        $scope.currentStep = 3;
        twoFactorAuthenticator.authenticatorAppPrepare()
          .then(function(result) {
            if (result.error) {
              $scope.feedbackMessage = result;
              $scope.isSaving = false;
            } else {
              reloadUser();
              twoFactorAuthenticator.authenticatorAppQrCode()
                .then(function(result) {
                  if (result.error) {
                    $scope.feedbackMessage = result;
                    $scope.isSaving = false;
                  } else {
                    $scope.qrSvg = $sce.trustAsHtml(result.data.qrSvg);
                    $scope.qrLink = result.data.qrLink;
                  }
                });
            }
          });
      } else {
        $scope.currentStep = 1;
        $scope.focusOnInput('phone-number');
      }
    } else if ($scope.currentStep === 1) {
      twoFactorAuthenticator.sendActivationCode(
        formatPhoneNumberForDatabase($scope.phoneNumber()),
        $scope.preferredContactMethod()
      ).then(function() {
        $scope.currentStep = 2;
        $scope.focusOnInput('code');
      });
    } else if ($scope.currentStep === 2) {
      $scope.isSaving = true;
      twoFactorAuthenticator.activate($scope.code())
        .then(function(result) {
          if (result.error) {
            $scope.feedbackMessage = result;
            $scope.isSaving = false;
          } else {
            reloadUser()
              .then(function() {
                $scope.isSaving = false;
                $scope.dismissOrGoToProfile();
              });
          }
        });
    } else if ($scope.currentStep === 3) {
      $scope.currentStep = 4;
      $scope.focusOnInput('otpCode');
    } else if ($scope.currentStep === 4) {
      const otpCode = $scope.otpCode();
      if (!otpCode || otpCode.length === 0) {
        return;
      }

      $scope.isSaving = true;
      twoFactorAuthenticator.authenticatorAppActivate(otpCode)
        .then(function(result) {
          if (result.error) {
            $scope.feedbackMessage = result;
            $scope.isSaving = false;
          } else {
            reloadUser()
              .then(function() {
                $scope.isSaving = false;
                $scope.dismissOrGoToProfile();
              });
          }
        });
    }
  };
}
