'use strict';
/**
 * This is a mapping table for AdvisorCreateNewAccountFlow and JointApplicants.
 * It is used to stored the joint applicant's email during account creation.
 */
angular.module('model.AccountFlowJointApplicant', [])
  .factory('AccountFlowJointApplicant', [
    '$http',
    'ram',
    'config',
    accountFlowJointApplicant
  ]);

function accountFlowJointApplicant($http, ram, config) {
  var AccountFlowJointApplicant = new ram.Collection('AccountFlowJointApplicant', {
    schema: config.schemas.AccountFlowJointApplicant,
    resources: {
      default: new ram.resources.Http('/api/account_flow_joint_applicants/:id.json'),
      cookie: new ram.resources.Cookie('account_flow_joint_applicants')
    }
  });

  return AccountFlowJointApplicant;
}
