'use strict';

angular.module('controller.advise.add-client.service-level', ['ngclipboard'])
  .controller('AddClientServiceLevelCtrl', [
    '$scope',
    '$state',
    'advisorDetails',
    addNewClientCtrl
  ]);

function addNewClientCtrl($scope, $state, advisorDetails) {
  var options = null;

  if (advisorDetails.serviceLevels.length > 0) {
    options = _.map(advisorDetails.serviceLevels, function(serviceLevel) {
      return {
        descriptionMd: serviceLevel.descriptionMd,
        label: serviceLevel.title + ' - ' + serviceLevel.fees,
        value: serviceLevel.groupUrlIdSuffix
      };
    });
  }

  _.defaults($scope.referralOption, {
    options: options
  });

  if (!$scope.referralOption.selection()) {
    $scope.referralOption.selection($scope.referralOption.options[0].value);
  }

  function getInviteLink() {
    return 'www.modernadvisor.ca/adv/' + $scope.getUrlId();
  }

  $scope.continue = function() {
    if (advisorDetails.multiRelationshipManagers) {
      $state.go('advise.add-client.add-advisors');
    } else {
      $scope.inviteClientModal(getInviteLink());
    }
  };
}
