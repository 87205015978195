'use strict';

function referralPaymentFactory(ram, config) {

  var ReferralPayment = new ram.Collection('ReferralPayment', {
    belongsTo: {
      user: 'User',
      referral: 'Referral'
    },
    schema: config.schemas.ReferralPayment,
    resources: {
      default: new ram.resources.Http('/api/referral_payments/:id.json'),
      cookie: new ram.resources.Cookie('referral_payments')
    }
  });

  ReferralPayment.prototype.state = function() {
    return this.status() + '-' + this.lockStatus();
  };

  return ReferralPayment;
}

angular.module('model.ReferralPayment', [
    'ram',
    'config'
  ])
  .factory('ReferralPayment', [
    'ram',
    'config',
    referralPaymentFactory
  ]);
