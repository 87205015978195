'use strict';

angular.module('model.TradeReconciliationReport', [
    'ram',
    'config',
    'model.Custodian',
    'model.TradeReconciliationMismatch'
  ])
  .factory('TradeReconciliationReport', [
    'ram',
    'config',
    'Custodian',
    factory
  ]);

function factory(ram, config, Custodian) {

  var TradeReconciliationReport = new ram.Collection('TradeReconciliationReport', {
    hasMany: {
      tradeReconciliationMismatches: 'TradeReconciliationMismatch'
    },
    resources: {
      default: new ram.resources.Http('/api/trade_reconciliation_reports/:id.json'),
      cookie: new ram.resources.Cookie('trade_reconciliation_reports')
    },
    schema: config.schemas.TradeReconciliationReport
  });

  TradeReconciliationReport.prototype.attachResultsSummary = function(resultsSummary) {
    this.attachedResultsSummary = resultsSummary;
  };

  TradeReconciliationReport.prototype.custodianName = function() {
    return Custodian.codeFor(this.custodianId());
  };

  TradeReconciliationReport.prototype.fileName = function() {
    if (this.noAllocationsFile()) {
      return 'No allocations requested';
    } else {
      return this.allocationsFileName();
    }
  };

  return TradeReconciliationReport;
}
