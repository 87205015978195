'use strict';

angular.module('routes.profile.bank', [
    'controller.profile.bank',
    'model.User',
    'templates',
    'ui.router'
  ])
  .config([
    '$stateProvider',
    configRoutes
  ]);

function configRoutes($stateProvider) {
  function resolveCurrentUserBankAccounts(currentUser) {
    return currentUser.bankAccounts.ready(true);
  }

  $stateProvider
    .state('profile.bank', {
      url: '/bank',
      abstract: true,
      template: '<div class="disp-flex flx-dir-col flx-g-1" ui-view></div>'
    })
    .state('profile.bank.view', {
      url: '/view',
      data: {
        menuTitle: 'Banking and Finance',
        backButtonState: 'profile.nav',
        tracking: {
          pageTitle: 'Banking and Finance',
          pageId: 'profile:external-financial-accounts',
          pageType: 'profile-settings',
          contents: {
            content: [{
              type: "list",
            }],
            category: { primary: "profile", subcategory1: "external-financial-accounts" }
          },
          lists: [{
            id: "external-financial-accounts",
            name: "Banking Info",
            type: "banking-info"
          }]
        }
      },
      templateUrl: 'views/profile/bank/view.html',
      resolve: {
        bankAccounts: ['currentUser', resolveCurrentUserBankAccounts]
      }
    })
    .state('profile.bank.add', {
      url: '/add',
      data: {
        menuTitle: 'Add a Bank Account',
        tracking: {
          pageTitle: 'Add a Bank Account',
          pageId: 'external-financial-account-add',
          pageType: 'profile-settings-add',
          contents: {
            content: [{
              type: "form",
            }],
            category: { primary: "profile", subcategory1: "external-financial-accounts" }
          },
          forms: [{
            id: "external-financial-account-add",
            name: "Add a Bank Account",
            type: "registration",
            steps_count: 1,
            steps_current: 1,
            step_current_name: "Add a Bank Account",
            steps_section: "Add Bank Account"
          }]
        }
      },
      controller: 'ProfileBankAddCtrl',
      templateUrl: 'views/profile/bank/add.html',
      resolve: {
        institutions: ['bankingResolveHelpers', function(bankingResolveHelpers) {
          return bankingResolveHelpers.resolveInsitutions();
        }]
      }
    })
    .state('profile.bank.confirm-email', {
      url: '/confirm-email',
      data: {
        menuTitle: 'Confirm your email'
      },
      controller: 'ProfileBankEmailCtrl',
      templateUrl: 'views/profile/bank/confirm-email.html'
    })
    .state('profile.bank.add-success', {
      url: '/add-success',
      data: {
        menuTitle: 'Add a Bank Account'
      },
      controller: 'ProfileBankSuccessCtrl',
      templateUrl: 'views/profile/bank/add-success.html',
      resolve: {
        bankAccounts: ['currentUser', resolveCurrentUserBankAccounts]
      }
    });
}
