'use strict';

angular.module('routes.interview.personal', [
    'component.step.contact-info',
    'controller.interview.personal',
    'model.Lead',
    'templates',
    'ui.router'
  ])
  .config([
    '$stateProvider',
    configRoutes
  ]);

function configRoutes($stateProvider) {
  $stateProvider
    .state('interview.personal', {
      url: '/personal',
      abstract: true,
      controller: 'interviewPersonalCtrl',
      template: '<div ui-view></div>'
    })
    .state('interview.personal.contact-info', {
      url: '/contact-info',
      controller: 'interviewPersonalContactInfoCtrl',
      data: {
        progress: 83,
        last: 'interview.service-options',
        next: 'interview.personal.end',
        step: 2,
        title: 'Interview - Contact Information',
        header: 'Tell us your contact information',
        interviewStep: 2
      }
    })
    .state('interview.personal.end', {
      url: '/end',
      controller: 'interviewPersonalEndCtrl',
      template: '<p>One of our advisors will contact you shortly to discuss your goals and to propose a personal plan for you.</p>',
      data: {
        progress: 100,
        last: null,
        next: null,
        step: 3,
        title: 'Interview - End',
        header: 'We will be in touch shortly',
        interviewStep: 3,
        hideNavBar: true
      }
    });
}
