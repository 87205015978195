'use strict';

angular.module('controller.modal.advisor-modify-risk', [
    'directive.risklevelselector',
    'model.Account',
    'model.Goal',
    'service.loading-indicator',
    'ram'
  ])
  .controller('ModalAdvisorModifyAccountRiskCtrl', [
    '$http',
    '$scope',
    '$uibModalInstance',
    'Account',
    'Goal',
    'ram',
    'account',
    'goal',
    'loadingIndicator',
    modalAdvisorModifyAccountRiskCtrl
  ]);

function modalAdvisorModifyAccountRiskCtrl(
  $http,
  $scope,
  $modalInstance,
  Account,
  Goal,
  ram,
  account,
  goal,
  loadingIndicator
) {

  var _reloadData;
  var _done;
  var _goal;

  /**
   * Just copy over the fields required to manipulate the advisor risk offset.  Don't bother with the id.
   * @param  {object} source The actual goal object.
   * @return {object}        A copy of it that can be manipulated without touching the original.
   */
  var _cloneGoal = function(source) {
    _goal = new Goal();
    _goal.riskRecommendation(source.riskRecommendation());
    _goal.riskOffset(source.riskOffset());
    _goal.advisorRiskOffset(source.advisorRiskOffset());
    return _goal;
  };

  $scope.riskSetting = new ram.Accessor(0);
  $scope.account = account;
  $scope.oldGoal = goal;
  $scope.goal = _cloneGoal(goal);
  $scope.informationMessageForUser = '';
  $scope.notes = '';

  $scope.save = function() {
    loadingIndicator.show();
    $scope.informationMessageForUser = '';
    $http.post('/api/activity_based_modifications/create_goal_change', {
        input: {
          goalId: $scope.oldGoal.id,
          accountId: $scope.account.id,
          desiredRiskLevel: $scope.goal.risk(),
          notes: $scope.notes
        }
      })
      .then(function() {
        _reloadData();
      }, function() {
        loadingIndicator.hide();
        $scope.informationMessageForUser = 'Error saving data';
      });
  };

  _reloadData = function() {
    Account.find({
        id: $scope.account.id
      })
      .then(function(account) {
        account.activities.ready();
      })
      .then(function() {
        _done();
      });
  };

  _done = function() {
    loadingIndicator.hide();
    $modalInstance.close();
  };
}
