'use strict';

angular.module('directive.goal-progress', [
    'ram'
  ])
  .directive('goalProgress', [
    'ram',
    'securityServiceFactory',
    goalProgressDirective
  ]);

function goalProgressDirective(ram, securityServiceFactory) {
  return {
    restrict: 'E',
    scope: {
      account: '=',
      goal: '='
    },
    templateUrl: 'directives/goal-progress.html',
    link: function($scope) {
      var goal = $scope.goal;
      var currentUserSecurity = securityServiceFactory.create($scope.$root.currentUser);

      $scope.state = {
        isSettingTarget: false
      };

      $scope.pendingGoalTarget = new ram.Accessor();
      $scope.formName = 'setTargetForm' + $scope.$id;

      $scope.openTargetEditor = function() {
        if (goal.targetAmount()) {
          $scope.pendingGoalTarget(goal.targetAmount());
        } else {
          $scope.pendingGoalTarget(0);
        }

        $scope.state.isSettingTarget = true;
      };

      $scope.setTarget = function() {
        goal.targetAmount($scope.pendingGoalTarget());
        goal.save()
          .then(function() {
            $scope.state.isSettingTarget = false;
          }, function() {
            goal.reset();
          });
      };

      $scope.cancel = function() {
        $scope.state.isSettingTarget = false;
      };

      $scope.balance = function() {
        return $scope.account.balance();
      };

      var precentOfTargetReached = function() {
        var percentage = 0;

        if ($scope.balance() && goal.targetAmount() > 0) {
          percentage = Math.min(($scope.balance() / goal.targetAmount()), 1);
        }

        return percentage * 100;
      };
      $scope.precentOfTargetReachedWidthStyle = function() {
        return {
          width: precentOfTargetReached() + '%'
        };
      };

      $scope.userCanEdit = function() {
        return $scope.account.userIsHolder() || currentUserSecurity.canAccess('set_goal_target');
      };
    }
  };
}
