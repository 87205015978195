'use strict';

class RiskCalculator {
  constructor(config) {
    this.config = config;
  }

  hasConflict(returnObjective, unconstrainedRecommendedRiskLevel) {
    return this.isObjectiveTooHigh(returnObjective, unconstrainedRecommendedRiskLevel) ||
      this.isObjectiveTooLow(returnObjective, unconstrainedRecommendedRiskLevel);
  }

  isObjectiveTooHigh(returnObjective, unconstrainedRecommendedRiskLevel) {
    const returnObjectiveWeight = returnObjective.weight;
    const riskLevelConflictsForReturnOjective = this.riskLevelConflicts()[unconstrainedRecommendedRiskLevel];

    return riskLevelConflictsForReturnOjective.objectiveTooHigh.includes(returnObjectiveWeight);
  }

  isObjectiveTooLow(returnObjective, unconstrainedRecommendedRiskLevel) {
    const returnObjectiveWeight = returnObjective.weight;
    const riskLevelConflictsForReturnOjective = this.riskLevelConflicts()[unconstrainedRecommendedRiskLevel];

    return riskLevelConflictsForReturnOjective.objectiveTooLow.includes(returnObjectiveWeight);
  }

  maxAcceptableReturnObjectiveForRiskLevel(unconstrainedRecommendedRiskLevel) {
    const returnObjectivesTooHigh = this.riskLevelConflicts()[unconstrainedRecommendedRiskLevel].objectiveTooHigh;
    const nonHighReturnObjectives = [1, 2, 3, 4].filter(returnObjective => !returnObjectivesTooHigh.includes(returnObjective));
    const maxAcceptableReturnObjective = Math.max(...nonHighReturnObjectives);
    const returnObjectiveOptions = this.config.types.AccountReturnObjective;

    return returnObjectiveOptions[maxAcceptableReturnObjective - 1];
  }

  riskLevelConflicts() {
    return {
      1: {
        objectiveTooHigh: [2, 3, 4],
        objectiveTooLow: []
      },
      2: {
        objectiveTooHigh: [2, 3, 4],
        objectiveTooLow: []
      },
      3: {
        objectiveTooHigh: [3, 4],
        objectiveTooLow: [1]
      },
      4: {
        objectiveTooHigh: [3, 4],
        objectiveTooLow: [1]
      },
      5: {
        objectiveTooHigh: [3, 4],
        objectiveTooLow: [1]
      },
      6: {
        objectiveTooHigh: [4],
        objectiveTooLow: [1, 2]
      },
      7: {
        objectiveTooHigh: [4],
        objectiveTooLow: [1, 2]
      },
      8: {
        objectiveTooHigh: [4],
        objectiveTooLow: [1, 2]
      },
      9: {
        objectiveTooHigh: [],
        objectiveTooLow: [1, 2, 3]
      },
      10: {
        objectiveTooHigh: [],
        objectiveTooLow: [1, 2, 3]
      },
    };
  }
}

RiskCalculator.$inject = ['config'];

export default RiskCalculator;
