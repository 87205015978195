'use strict';

function choiceFactory(ram) {
  return new ram.Collection('Choice', {
    belongsTo: {
      question: 'Question'
    },
    resources: {
      default: new ram.resources.Http('/api/choices/:id.json'),
      cookie: new ram.resources.Cookie('choices')
    },
  });
}

angular.module('model.Choice', [
    'ram'
  ])
  .factory('Choice', [
    'ram',
    choiceFactory
  ]);
