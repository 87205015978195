'use strict';

function goalRadioButtonDirective(adaptiveViews, optionHelper) {
  return {
    restrict: 'E',
    scope: {
      name: '@',
      options: '=',
      optionLabel: '@',
      optionValue: '@',
      optionDescription: '@',
      ngModel: '=',
      ngChange: '&',
      help: '@',
      focusHelp: '@',
      title: '@',
      label: '@',
      class: '@',
      labelClass: '@',
      inputClass: '@',
      btnClass: '@',
      helpClass: '@'
    },
    templateUrl: adaptiveViews.getTemplateUrlForSrc('directives/input/goal-radio-button.html', true),
    link: optionHelper.link,
    controller: ['$scope', function($scope) {
      $scope.getGoalIconSrc = function(optionLabel) {
        var fileName = s.underscored(s.replaceAll(optionLabel.toLowerCase(), '\'', ''));
        return 'assets/images/goals/' + fileName + '.svg';
      };
    }]
  };
}

angular.module('directive.input.goal-radio-button', [
    'templates',
    'animations',
    'directive.focus',
    'directive.input.option-helper',
    'service.adaptive-views'
  ])
  .directive('goalRadioButton', [
    'adaptiveViews',
    'optionHelper',
    goalRadioButtonDirective
  ]);
