'use strict';

angular.module('controller.advise.add-client-account', [
    'config',
    'directive.account-goal-detail',
    'model.Account',
    'model.AdvisorCreateNewAccountFlow',
    'model.AccountFlowJointApplicant',
    'model.Goal',
    'service.form-helper',
    'service.securityService'
  ])
  .controller('AdviseAddClientAccountCtrl', [
    '$scope',
    'config',
    'Account',
    'AdvisorCreateNewAccountFlow',
    'AccountFlowJointApplicant',
    'newAccountFlows',
    'client',
    'formHelper',
    'ima',
    'userNotifications',
    'securityServiceFactory',
    adviseClientController
  ]);

function adviseClientController(
  $scope,
  config,
  Account,
  AdvisorCreateNewAccountFlow,
  AccountFlowJointApplicant,
  newAccountFlows,
  client,
  formHelper,
  ima,
  userNotifications,
  securityServiceFactory
) {
  angular.extend($scope, {
    newAccountFlows: newAccountFlows,
    client: client,
    ima: ima,
    submitForm: submitForm
  });

  let currentUserSecurity = securityServiceFactory.create($scope.$root.currentUser);

  resetScope();

  var successMsg = 'The new account application has been sent to the client successfully.',
    errorMsg = 'Something went wrong while sending the new account application to the client. Please try again later.';

  function resetScope() {
    $scope.account = new Account();
    $scope.accountFlowJointApplicant = new AccountFlowJointApplicant();
  }

  function doSave(formController) {
    $scope.isSaving = true;

    var account = $scope.account;

    let portfolioOptionId = null;
    if (currentUserSecurity.canPrefillPortfolioOption()) {
      portfolioOptionId = account.portfolioOptionId();
    } else {
      portfolioOptionId = config.types.AccountPortfolioOption.findByName('core_portfolio').id;
    }

    var accountFlowOptions = {
      accountTypeId: account.typeId(),
      responsible: account.responsible(),
      clientId: client.person().id,
      jurisdictionId: account.jurisdictionId(),
      portfolioOptionId: portfolioOptionId,
      accountNickname: account.nickname()
    };

    var advisorCreateNewAccountFlow = new AdvisorCreateNewAccountFlow(accountFlowOptions);

    return advisorCreateNewAccountFlow.save()
      .then(function(accountFlow) {
        saveAccountFlowJointApplicant(accountFlow);
      }).then(function() {
        formController.$setPristine();
        userNotifications.showSuccess(successMsg);
        resetScope();
      }).catch(function() {
        userNotifications.showError(errorMsg);
      }).finally(function() {
        $scope.isSaving = false;
      });
  }

  function saveAccountFlowJointApplicant(accountFlow) {
    if ($scope.account.type().joint) {
      $scope.accountFlowJointApplicant.advisorCreateNewAccountFlowId(accountFlow.id);
      $scope.accountFlowJointApplicant.save();
    }
  }

  function submitForm(formController) {
    if (formController.$invalid) {
      formHelper.notDone($scope, formController);
    } else {
      doSave(formController);
    }
  }
}
