'use strict';

angular.module('service.group-activities-service', [
    'model.Activity'
  ])
  .factory('groupActivitiesService', [
    'Activity',
    groupActivitiesServiceFactory
  ]);

function groupActivitiesServiceFactory(Activity) {
  var _cloneActivity = function(activity) {
    var clone = new Activity({
      date: activity.date(),
      typeId: activity.typeId(),
      amount: activity.amount(),
      description: activity.description()
    });
    return clone;
  };

  var groupByDateTypeDescription = function(activities) {
    return _.chain(activities)
      .groupBy(function(activity) {
        if (activity.type().hidden !== true) {
          return activity.date() + '#' + activity.typeId() + '#' + activity.description();
        } else return null   
      })
      .map(function(activityGroup) {
        var clone = _cloneActivity(_.first(activityGroup));
        var amount = _.chain(activityGroup)
          .map(function(activity) {
            return parseFloat(activity.amount());
          })
          .reduce(function(acc, val) {
            return acc + val;
          })
          .value();
        clone.amount(_.isNaN(amount) ? null : amount);
        return clone;
      }).value();
  };

  return {
    groupByDateTypeDescription: groupByDateTypeDescription,
  };
}
