'use strict';

function factory(config) {

  function fetch(documents, documentType) {
    var typeId = _.find(config.types.Document, {
      name: documentType
    }).id;

    var groupedDocuments = _.groupBy(documents, function(document) {
      return document.typeId();
    });

    if (groupedDocuments[typeId]) {
      return _.last(groupedDocuments[typeId]);
    } else {
      return null;
    }
  }

  return {
    fetch: fetch
  };

}

angular.module('service.fetch-last-document', [])
  .factory('fetchLastDocument', [
    'config',
    factory
  ]);
