'use strict';

angular.module('controller.advise.overview', [
    'filter.currency',
    'service.promotion-redemption-cookie-creator'
  ])
  .controller('AdviseOverviewCtrl', [
    '$scope',
    '$state',
    '$cookies',
    '$filter',
    'ram',
    'overview',
    'advisorDetails',
    'assetsUnderManagementHistories',
    adviseOverviewController
  ]);

function adviseOverviewController($scope, $state, $cookies, $filter, ram, overview, advisorDetails, assetsUnderManagementHistories) {
  angular.extend($scope, overview.financial);

  $scope.advisorDetails = advisorDetails;
  $scope.eodStatus = overview.eod;

  if ($scope.eodStatus) {
    $scope.eodStatus.csi.className = $scope.eodStatus.csi.ok ? 'fc-green' : 'fc-danger-red';
    $scope.eodStatus.bbs.className = $scope.eodStatus.bbs.ok ? 'fc-green' : 'fc-danger-red';
    $scope.eodStatus.csi.icon = $scope.eodStatus.csi.ok ? 'fa fa-check' : 'fa fa-times';
    $scope.eodStatus.bbs.icon = $scope.eodStatus.bbs.ok ? 'fa fa-check' : 'fa fa-times';
    $scope.displayEodStatus = !!$cookies.get('displayEodStatus') ? true : false;

    $scope.eodStatusSummary = aggregateEodInfo() ? 'fa fa-check fc-green' : 'fa fa-times fc-danger-red';
  }

  $scope.addNewClient = function() {
    if (advisorDetails.multiServiceLevels || advisorDetails.multiRelationshipManagers) {
      $state.go('advise.add-client');
    } else {
      $scope.inviteClientModal(advisorDetails.inviteUrl);
    }
  };

  function aggregateEodInfo() {
    return $scope.eodStatus.status.every(line => line.ok) && $scope.eodStatus.csi.ok && $scope.eodStatus.bbs.ok;
  }

  // ----------------------------------------------
  $scope.start = new ram.Accessor();
  $scope.end = new ram.Accessor();
  $scope.point = {};
  let firstDate;
  let lastDate;

  $scope.cursorText = [{
    value: function(d) {
      return d.date;
    },
    styles: {
      'font-weight': 'bold'
    },
    attrs: {
      'dy': '1em'
    }
  }, {
    value: function(d) {
      return $filter('currency')(d.amount);
    },
    attrs: {
      'dy': '2.5em'
    }
  }];

  $scope.periods = [{
    label: 'All'
  }, {
    label: '1 Year',
    start: moment().subtract(1, 'years').toDate()
  }, {
    label: 'YTD',
    start: moment().startOf('years').toDate()
  }, {
    label: '3 Months',
    start: moment().subtract(3, 'months').toDate()
  }, {
    label: '1 Month',
    start: moment().subtract(1, 'months').toDate()
  }];

  $scope.period = new ram.Accessor($scope.periods[0]);

  $scope.$watch('period()', function(period) {
    $scope.start(period && period.start || firstDate);
    $scope.end(lastDate);

    let aumHistoriesInPeriod = $scope.aumOverTime.filter((aumHistory) => {
      let date = aumHistory.date;
      return $scope.start() <= date && date <= $scope.end();
    });

    $scope.showNoDataText = showNoDataText(aumHistoriesInPeriod);
  });

  $scope.aumOverTime = assetsUnderManagementHistories.map((aumHistory) => {
    // Need to convert the amount to a number or else the d3.extent method will
    // find the max and min based on string values
    return {
      date: moment(aumHistory.date, 'YYYY-MM-DD').toDate(),
      amount: Number(aumHistory.amount) || 0
    };
  });

  if ($scope.aumOverTime.length > 0) {
    let firstAumHistory = $scope.aumOverTime[0];
    let lastAumHistory = $scope.aumOverTime[$scope.aumOverTime.length - 1];

    firstDate = firstAumHistory.date;
    lastDate = lastAumHistory.date;
    $scope.lastUpdatedAtDate = lastAumHistory.date;
    $scope.currentAUM = lastAumHistory.amount;
  } else {
    $scope.lastUpdatedAtDate = new Date();
    $scope.currentAUM = 0;
  }

  function showNoDataText(aumHistories) {
    if (aumHistories.length === 0) {
      return true;
    }

    return aumHistories.every((aumHistory) => {
      return aumHistory.amount === 0.0;
    });
  }
}
// Issue the following in the DevTools console to get this to be sticky
// $.cookie("displayEodStatus", true, { expires : 99999 });
