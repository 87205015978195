'use strict';

angular.module('controller.advise.promotions', [])
  .controller('AdvisePromotionsCtrl', [
    '$scope',
    'promotions',
    advisePromotionsController
  ]);

function advisePromotionsController($scope, promotions) {
  $scope.promotions = promotions;

  $scope.selectPromoReportModal = function() {
    $scope.advisorDownloadPromotionsReportModal();
  };
}
