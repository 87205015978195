'use strict';

angular.module('controller.advise.clients', [
    'component.email-client-flow-button',
    'directive.flex-container',
    'directive.flex-boxes',
    'model.PaginatedClient',
    'model.Paginator',
    'service.modals'
  ])
  .controller('AdviseClientsCtrl', [
    '$scope',
    'PaginatedClient',
    'Paginator',
    'modals',
    adviseClientsController
  ]);

function adviseClientsController($scope, PaginatedClient, Paginator, modals) {
  $scope.clients = [];
  $scope.numColumns = 7;
  $scope.paginator = new Paginator(PaginatedClient, null, _getDataFromPaginator);
  $scope.getPhoneNumber = _getPhoneNumber;

  function _getDataFromPaginator(parameter) {
    return parameter.clients();
  }

  function _getPhoneNumber(client) {
    var phoneNumbers = client.phoneNumbers();
    return _.isEmpty(phoneNumbers) ? null : phoneNumbers[0].full();
  }

  $scope.showDownloadModal = function () {
    modals.downloadTable();
  };
}
