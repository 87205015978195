'use strict';

/**
 * This service manages the USER cookie that is used to control
 * the advertisement that pops up from the bottom of the home page.
 */
function factory($cookies) {

  function getCookie() {
    return $cookies.get('USER');
  }

  function setCookie() {
    if (!getCookie()) {
      $cookies.put('USER', true, {
        expires: 'Fri, 31 Dec 9999 23:59:59 GMT'
      });
    }
  }

  return {
    getCookie: getCookie,
    setCookie: setCookie
  };
}

angular.module('service.manage-user-cookie', [])
  .factory('manageUserCookie', [
    '$cookies',
    factory
  ]);
