'use strict';

class HoldingsPieChartLegendController {
  constructor($scope) {
    this.$scope = $scope;
  }

  $onInit() {
    this.$scope.$ctrl = {
      ...this.$scope.$ctrl,
      showInvestments: {},
      accountsCombined: this.$scope.$ctrl.positions.accountsCombined === 'Combined',
      toogleHideShowRows: (fundId) => {
        this.$scope.$ctrl.showInvestments[fundId] = !this.$scope.$ctrl.showInvestments[fundId];
      }
    };
  }
}

export default HoldingsPieChartLegendController;
