import template from './account-rif-detail.html';
import controller from './account-rif-detail.controller';

const accountRifDetailComponent = {
  template,
  bindings: {
    account: '<',
    rifDetail: '<',
    taxableAccounts: '<',
    bankAccounts: '<'
  },
  controller: ['$scope', '$q', '$http', 'config', 'formModificationTracker', controller],
  controllerAs: '$ctrl'
};

export default accountRifDetailComponent;
