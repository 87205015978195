'use strict';

angular.module('controller.profile.household', [
    'controller.profile.household.create',
    'controller.profile.household.invitations',
    'controller.profile.household.overview'
  ])
  .controller('profileHouseholdCtrl', [
    householdProfileController
  ]);

function householdProfileController() {}
