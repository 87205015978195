'use strict';

function orderFactory(ram, config) {

  /**
   * Constructor for Application models.
   * @class
   */
  var Order = new ram.Collection('Order', {
    enums: {
      status: config.types.OrderStatus
    },
    hasMany: {
      trades: 'Trade',
      tradingEngineResults: 'TradingEngineResult'
    },
    schema: config.schemas.Order,
    resources: {
      default: new ram.resources.Http('/api/orders/:id.json'),
      cookie: new ram.resources.Cookie('orders')
    }
  });

  /**
   * Convert createdAt to a date.
   *
   * @param  {Object} attrs Properties used to initialize this model.
   */
  Order.prototype.initialize = function(attrs) {
    this.createdAt(new Date(attrs.createdAt));
  };

  Order.prototype.tradesFilled = function() {
    return _.all(this.trades(), function(trade) {
      var filled = trade.filled();
      return _.isFinite(filled);
    });
  };

  return Order;
}

angular.module('model.Order', [
    'ram',
    'config',
    'model.Trade',
    'model.TradingEngineResult',
    'model.Allotment'
  ])
  .factory('Order', [
    'ram',
    'config',
    orderFactory
  ]);
