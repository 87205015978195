'use strict';

angular.module('controller.advise.update-record', [
    'model.Issue',
    'model.IssueComment',
    'model.UpdateRecord'
  ])
  .controller('AdviseUpdateRecordCtrl', [
    '$scope',
    '$sce',
    '$window',
    '$http',
    '$stateParams',
    'updateRecord',
    'previousUpdateRecord',
    'newestUpdateRecord',
    'issue',
    'Issue',
    'IssueComment',
    'UpdateRecord',
    adviseUpdateRecordController
  ]);

function adviseUpdateRecordController($scope, $sce, $window, $http, $stateParams, updateRecord, previousUpdateRecord, newestUpdateRecord, issue, Issue, IssueComment, UpdateRecord) {
  $scope.updateRecord = updateRecord;
  $scope.previousUpdateRecord = previousUpdateRecord;
  $scope.newestUpdateRecord = newestUpdateRecord;
  $scope.issue = issue;
  $scope.data = {
    comment: ''
  };

  function getHtmlDiff(previousUpdateRecord, currentUpdateRecord) {
    var previousPayload = previousUpdateRecord && previousUpdateRecord.payload() ? JSON.parse(previousUpdateRecord.payload()) : {};
    var currentPayload = currentUpdateRecord && currentUpdateRecord.payload() ? JSON.parse(currentUpdateRecord.payload()) : {};

    var delta = $window.jsondiffpatch.diff(previousPayload, currentPayload);
    return $sce.trustAsHtml($window.jsondiffpatch.formatters.html.format(delta, previousPayload));
  }

  function getRelatedRecordServer() {
    return $http.get('api/update_records/' + updateRecord.id + '/related.json')
      .then(function(response) {
        if (response.data) {
          $scope.firstRelatedRecord = response.data.first;
          $scope.previousRelatedRecord = response.data.previous;
          $scope.nextRelatedRecord = response.data.next;
          $scope.lastRelatedRecord = response.data.last;
        }
      });
  }

  getRelatedRecordServer();

  function fetchPastUpdates() {
    return $http
      .get('api/update_records/' + updateRecord.id + '/history.json')
      .then(function(response) {
        if (response.data) {
          return _.map(response.data, function(data) {
            return UpdateRecord.new(data);
          });
        }
      });
  }

  $scope.diff = getHtmlDiff(previousUpdateRecord, updateRecord);

  $scope.createComment = function() {
    IssueComment.new({
        issueId: $scope.issue.id,
        body: $scope.data.comment
      })
      .save()
      .then(function() {
        return $scope.issue.issueComments.ready(true);
      });
    $scope.data.comment = '';
  };

  $scope.closeIssue = function() {
    $scope.issue.closedAt(new Date());
    $scope.issue.save();
  };

  $scope.showPastUpdates = false;

  $scope.togglePastUpdates = function() {
    $scope.showPastUpdates = !$scope.showPastUpdates;
    if ($scope.showPastUpdates) {

      $scope.loading = true;
      fetchPastUpdates()
        .then(function(updateRecords) {
          $scope.updateHistory = updateRecords;
        })
        .finally(function() {
          $scope.loading = false;
        });
    }
  };

  $scope.displayDiff = function(records, index) {
    var previous = index > 0 ? records[index - 1] : null;
    var current = records[index];

    return getHtmlDiff(previous, current);
  };
}
