'use strict';

angular.module('routes.wsi-interview', [
    'controller.wsi-interview',
    'service.adaptive-views',
    'service.branding-machine',
    'templates',
    'ui.router'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'adaptiveViewsProvider',
    'brandingMachineProvider',
    configRoutes
  ]);

function configRoutes($stateProvider, $urlRouterProvider, adaptiveViewsProvider, brandingMachineProvider) {
  function routeToGoal($state) {
    $state.transitionTo('wsi-interview.goal');
  }

  function resolveAccountTypeRecommendations($http, storedUser, storedGoal) {
    var request = {
      goalLabel: storedGoal.label(),
      age: storedUser.age()
    };

    return $http.get('api/accounts/types_recommendation.json', {
        params: _.underscoreKeys(request)
      })
      .then(function(recommendationResponse) {
        return recommendationResponse.data;
      });
  }

  function resolveCompletedRiskAppetiteQuizzes(CompletedRiskAppetiteQuiz, currentUser) {
    if (currentUser) {
      return CompletedRiskAppetiteQuiz.where({});
    } else {
      return CompletedRiskAppetiteQuiz.where({}, {
        resource: 'cookie',
        cache: false
      });
    }
  }

  function resolveStoredTrialRiskAppetite(TrialRiskAppetite) {
    return TrialRiskAppetite.findOrCreate({}, {
      resource: 'cookie',
      cache: false
    });
  }

  function riskParams(storedGoal, storedUser) {
    return {
      financialAssets: storedUser.financialAssets(),
      income: storedUser.income(),
      initial: storedGoal.initial(),
      nonFinancialAssets: storedUser.nonFinancialAssets(),
      ongoing: storedGoal.ongoing(),
      riskAppetite: storedUser.riskAppetite(),
      timeframe: storedGoal.timeframe(),
      totalLiabilities: storedUser.totalLiabilities(),
      label: storedGoal.label()
    };
  }

  function resolveUnconstrainedRecommendedRiskLevel($http, storedGoal, storedUser) {
    const params = riskParams(storedGoal, storedUser);

    return $http.get('api/goals/new/wsi_unconstrained_recommended_risk_level.json', {
        params: _.underscoreKeys(params)
      })
      .then(function(riskResponse) {
        return riskResponse.data;
      });
  }

  function resolveRiskLevel($http, storedGoal, storedUser, storedAccount) {
    const params = riskParams(storedGoal, storedUser);
    params.returnObjective = storedAccount.returnObjective() ? storedAccount.returnObjective().weight : null;

    return $http.get('api/goals/new/wsi_risk_level.json', {
      params: _.underscoreKeys(params)
    });
  }

  function resolveRiskAbility($http, storedGoal, storedUser) {
    const params = riskParams(storedGoal, storedUser);

    return $http.get('api/goals/new/wsi_risk_ability.json', {
        params: _.underscoreKeys(params)
      })
      .then(function(riskAbilityResponse) {
        return riskAbilityResponse.data;
      });
  }

  function resolveModelPortfolio(risk, storedAccount, storedGoal, ModelPortfolio) {
    storedGoal.riskRecommendation.set(risk.data, true);
    return ModelPortfolio.find({
        risk: storedGoal.risk(),
        taxable: storedAccount.type() && !storedAccount.type().registered,
        portfolioOptionId: storedAccount.portfolioOptionId()
      })
      .then(function(modelPortfolio) {
        return modelPortfolio && modelPortfolio.projections.ready()
          .then(function() {
            return modelPortfolio;
          });
      });
  }

  function resolveAdvisorGroupUrl() {
    var $cookies = angular.injector(['ngCookies']).get('$cookies');
    var referral = $cookies.getObject('group_url_id');
    return referral && referral.urlId;
  }

  function resolveAllocations(modelPortfolio) {
    return modelPortfolio && modelPortfolio.allocations.ready();
  }

  function resolveGoal(storedGoal) {
    return storedGoal;
  }

  function resolveClientFlow(AdvisorCreateNewClientFlow, currentUser) {
    if (!currentUser) {
      return null;
    }
    return AdvisorCreateNewClientFlow.where({
        clientId: currentUser.person().id
      })
      .then(function(list) {
        if (list.length === 0) {
          return null;
        }
        return _.first(list);
      });
  }

  function resolveAdvisorReferralDetails(currentUser, clientFlow) {
    return brandingMachineProvider.getAdvisorDetails(currentUser, clientFlow && clientFlow.advisorReferralCode());
  }

  function resolveRedirectToInterviewPlatform($location, $q, $state, config) {
    let platform = config.env.platform;

    if (platform && platform.name === 'WSI') {
      return;
    } else {
      // From https://stackoverflow.com/a/30592506 Gustav's comment
      // The last `return $q.reject()` was not used as it seems to work without it.
      return $q.reject()
        .catch(function() {
          $state.transitionTo('interview.goal', $location.search(), {
            location: 'replace'
          });
        });
    }
  }

  $urlRouterProvider
    .when('/wsi-interview', ['$state', routeToGoal]);

  $stateProvider
    .state('wsi-interview', {
      url: '/wsi-interview',
      parent: 'site',
      hasMobileView: true,
      views: {
        'main': {
          controller: 'WsiInterviewCtrl'
        },
        'navbar': {
          templateUrl: 'navbar/interview.html'
        },
        'footer': {
          templateUrl: adaptiveViewsProvider.isMobile() ? 'mobile/partials/footer.html' : 'partials/footer-minimal.html'
        }
      },
      abstract: true,
      resolve: {
        clientFlow: ['AdvisorCreateNewClientFlow', 'currentUser', resolveClientFlow],
        redirectToInterviewPlatform: ['$location', '$q', '$state', 'config', resolveRedirectToInterviewPlatform],
        advisorReferralDetails: ['currentUser', 'clientFlow', resolveAdvisorReferralDetails]
      }
    })
    .state('wsi-interview.goal', {
      url: '/goal',
      controller: 'WsiInterviewGoalCtrl',
      data: {
        progress: 8,
        last: 'wsi-interview.goal',
        next: 'wsi-interview.account-type',
        step: 1,
        title: 'Interview - Goal',
        header: 'Tell us about your investment goal',
        interviewStep: 1
      },
      resolve: {}
    })
    .state('wsi-interview.account-type', {
      url: '/account-type',
      controller: 'WsiInterviewAccountTypeCtrl',
      hasMobileView: true,
      data: {
        progress: 16,
        last: 'wsi-interview.goal',
        next: 'wsi-interview.spousal-rrsp',
        step: 2,
        title: 'Interview - Account Type',
        header: 'Select your account type',
        interviewStep: 1
      },
      resolve: {
        recommendations: ['$http', 'storedUser', 'storedGoal', resolveAccountTypeRecommendations]
      }
    })
    .state('wsi-interview.spousal-rrsp', {
      url: '/spousal-rrsp',
      controller: 'WsiInterviewSpousalRrspCtrl',
      data: {
        progress: 16,
        last: 'wsi-interview.account-type',
        next: 'wsi-interview.financial',
        step: 3,
        title: 'Interview - Spousal RRSP',
        header: 'Tell us about your Spousal RRSP',
        interviewStep: 1
      }
    })
    .state('wsi-interview.financial', {
      url: '/financial',
      controller: 'WsiInterviewFinancialController',
      data: {
        progress: 24,
        last: 'wsi-interview.spousal-rrsp',
        next: 'wsi-interview.risk',
        step: 3,
        title: 'Interview - Financial Situation',
        header: 'Tell us about your financial situation',
        interviewStep: 1
      }
    })
    .state('wsi-interview.risk', {
      url: '/risk-appetite',
      controller: 'WsiInterviewRiskController',
      hasMobileView: true,
      data: {
        progress: 32,
        last: 'wsi-interview.financial',
        next: 'wsi-interview.conflicts-and-recommendations',
        step: 4,
        title: 'Interview - Risk Appetite',
        header: 'Risk Tolerance and Recommendations',
        interviewStep: 1
      },
      resolve: {
        completedRiskAppetiteQuizzes: ['CompletedRiskAppetiteQuiz', 'currentUser', resolveCompletedRiskAppetiteQuizzes],
        storedTrialRiskAppetite: ['TrialRiskAppetite', resolveStoredTrialRiskAppetite],
        unconstrainedRecommendedRiskLevel: ['$http', 'storedGoal', 'storedUser', resolveUnconstrainedRecommendedRiskLevel]
      }
    })
    .state('wsi-interview.conflicts-and-recommendations', {
      url: '/conflicts-and-recommendations',
      controller: 'WsiInterviewConflictsAndRecommendationsController',
      data: {
        progress: 32,
        last: 'wsi-interview.risk',
        next: 'wsi-optimizing',
        step: 5,
        title: 'Interview - Risk Appetite',
        header: 'Risk Tolerance and Recommendations',
        interviewStep: 1
      },
      resolve: {
        unconstrainedRecommendedRiskLevel: ['$http', 'storedGoal', 'storedUser', resolveUnconstrainedRecommendedRiskLevel],
        riskAbility: ['$http', 'storedGoal', 'storedUser', resolveRiskAbility]
      }
    })
    .state('wsi-interview.preview', {
      url: '/preview',
      controller: 'WsiInterviewPreviewCtrl',
      abstract: true,
      hasMobileView: true,
      data: {
        progress: 36,
        last: 'wsi-interview.conflicts-and-recommendations',
        next: 'wsi-interview.account-credentials',
        step: 6,
        title: 'Preview',
        header: adaptiveViewsProvider.isMobile() ? 'Portfolio Overview' : 'Review your plan',
        interviewStep: 1
      },
      resolve: {
        riskLevel: ['$http', 'storedGoal', 'storedUser', 'storedAccount', resolveRiskLevel],
        riskAbility: ['$http', 'storedGoal', 'storedUser', resolveRiskAbility],
        unconstrainedRecommendedRiskLevel: ['$http', 'storedGoal', 'storedUser', resolveUnconstrainedRecommendedRiskLevel],
        modelPortfolio: ['riskLevel', 'storedAccount', 'storedGoal', 'ModelPortfolio', resolveModelPortfolio],
        advisorGroupUrl: [resolveAdvisorGroupUrl]
      }
    })
    .state('wsi-interview.preview.investments', {
      url: '',
      controller: 'PreviewInvestmentsController',
      templateUrl: 'views/preview/investments.html',
      resolve: {
        allocations: ['modelPortfolio', resolveAllocations]
      }
    })
    .state('wsi-interview.preview.projections', {
      controller: 'PreviewProjectionsController',
      templateUrl: 'views/preview/projections.html',
      resolve: {
        goal: ['storedGoal', resolveGoal]
      }
    })
    .state('wsi-interview.preview.performance', {
      controller: 'PreviewPerformanceController',
      templateUrl: 'views/preview/performance.html'
    })
    .state('wsi-interview.preview.fees', {
      controller: 'PreviewFeesController',
      templateUrl: 'views/preview/fees.html'
    })
    .state('wsi-interview.account-credentials', {
      url: '/account',
      controller: 'AccountCredentialsController',
      templateUrl: 'views/interview/account-credentials.html',
      data: {
        progress: 40,
        last: 'wsi-interview.preview.investments',
        step: 7,
        title: 'Interview - Basic Information',
        header: 'Sign up to save your portfolio',
        interviewStep: 2
      }
    });
}
