'use strict';

angular.module('controller.advise.client-application.details', [
    'ram',
    'directive.input.bank-account-verification-method',
    'component.subform.photo-id-upload',
    'service.form-helper',
    'service.spouse-or-partner',
    'service.user-notifications'
  ])
  .controller('AdviseClientApplicationDetailsCtrl', [
    '$scope',
    '$state',
    '$http',
    '$timeout',
    '$q',
    'client',
    'formHelper',
    'institutions',
    'spouse',
    'spouseOrPartner',
    'bankAccounts',
    'bankStatementImageInput',
    'frontImage',
    'backImage',
    'jointFrontImage',
    'jointBackImage',
    'newClientFlow',
    'newAccountFlows',
    adviseClientApplicationDetailsCtrl
  ]);

function adviseClientApplicationDetailsCtrl(
  $scope,
  $state,
  $http,
  $timeout,
  $q,
  client,
  formHelper,
  institutions,
  spouse,
  spouseOrPartner,
  bankAccounts,
  bankStatementImageInput,
  frontImage,
  backImage,
  jointFrontImage,
  jointBackImage,
  newClientFlow,
  newAccountFlows
) {
  $scope.client = client;
  $scope.spouse = spouse;
  $scope.bankAccount = _.first(bankAccounts);
  $scope.institutions = institutions;
  $scope.bankingPhotos = {
    bankStatementImage: bankStatementImageInput
  };
  $scope.frontImage = frontImage;
  $scope.backImage = backImage;
  $scope.jointFrontImage = jointFrontImage;
  $scope.jointBackImage = jointBackImage;

  // The following are not used on this screen, but the component expects a value
  $scope.isResp = false; // Default value
  $scope.isJoint = false; // Default value
  $scope.spouseOrPartner = ''; // Default value, ignored unless joint

  $scope.isEditing = false;
  $scope.oldEmail = $scope.newClientFlow.email();
  $scope.emailAlreadyTakenError = false;
  $scope.emailAlreadyTakenErrorMessage = 'The provided email has already been taken. Please provide a different email address.';

  $scope.emailPromise = null;

  $scope.spouseOrPartner = function() {
    return spouseOrPartner.relationshipName(client);
  };

  function initMaritalStatusWatch($scope, client) {
    $scope.$watch('client.spouseRelation()', function(newValue) {
      if (newValue && client.hasSpouse()) {
        $scope.spouse = newValue.relative();
      } else if (!client.hasSpouse()) {
        $scope.spouse = undefined;
      }
    });
  }

  initMaritalStatusWatch($scope, client);


  function doSave() {
    $scope.client.homeAddress().save()
      .then(function() {
        return $scope.client.mailingAddress().save();
      })
      .then(function() {
        return $scope.client.save();
      })
      .then(function() {
        if ($scope.spouse) {
          return $scope.spouse.save();
        }
      })
      .then(function() {
        return $scope.bankAccount.save();
      })
      .then(function() {
        return newClientFlow.updateName(); // Client name and email can be edited by the above here.  This triggers a general update of the ApplicationRM
      })
      .then(function() {
        if (newAccountFlows.length === 0) {
          $state.go('advise.client-application.account');
        } else {
          $state.go('advise.client-application.review');
        }
      });
  }

  $scope.submit = function(formController) {
    if ($scope.emailPromise) {
      $scope.emailPromise
        .then(submitWorker)
        .catch(function() {
          window.scrollTo(0, 0);
        });
    } else {
      submitWorker();
    }

    function submitWorker() {
      if (formController.$invalid || $scope.emailAlreadyTakenError) {
        formHelper.notDone($scope, formController);
      } else {
        doSave();
      }
    }
  };

  $scope.toggleEdit = function() {
    $scope.isEditing = true;
  };

  $scope.updateEmail = function() {
    var deferred = $q.defer();
    angular.element('input[name="email"]').trigger('blur');
    $scope.emailPromise = deferred.promise;

    $timeout(function() {
      if ($scope.form.email.$invalid) {
        $scope.emailAlreadyTakenError = false;
        return;
      }

      return $http.put('/api/advisor_create_new_client_flows/' + $scope.newClientFlow.id + '/advisor_update_email', {
          email: $scope.newClientFlow.email()
        })
        .then(function() {
          $scope.emailAlreadyTakenError = false;
          deferred.resolve();
        })
        .catch(function(error) {
          if (error.status === 422) {
            $scope.emailAlreadyTakenError = true;
          }
          deferred.reject();
        });
    });
  };
}
