'use strict';

angular.module('query.bbs-upcoming-transfer-requests.deposits-and-withdrawals', [
    'config'
  ])
  .factory('BbsUpcomingTransferRequestsDepositsAndWithdrawalsQuery', [
    '$http',
    'config',
    query
  ]);

function query($http, config) {
  return {
    run: function() {
      var ids = _.chain(config.types.TransferRequest)
        .select(function(type) {
          return type.name === 'deposit' || type.name === 'withdrawal';
        })
        .map('id')
        .value();

      return $http.get('/api/super_advisor/bbs_upcoming_transfer_requests.json', {
          params: {
            'type_id[]': ids
          }
        })
        .then(function(response) {
          return response.data;
        });
    }
  };
}
