'use strict';

function auditFactory(ram, config) {
  var Audit = new ram.Collection('Audit', {
    belongsTo: {},
    schema: config.schemas.Audit,
    resources: {
      default: new ram.resources.Http('/api/audits/:id.json'),
      cookie: new ram.resources.Cookie('audits')
    }
  });

  Audit.prototype.getAttribute = function(attributeName) {
    return this.commentObject[attributeName];
  };

  Audit.prototype.commentObject = null;

  Audit.prototype.initialize = function() {
    // For a given audit, this will never change
    this.commentObject = _.camelizeKeys(JSON.parse(this.comment()));
  };

  return Audit;
}

angular.module('model.Audit', [
    'ram',
    'model.Goal'
  ])
  .factory('Audit', [
    'ram',
    'config',
    auditFactory
  ]);
