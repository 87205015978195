'use strict';

function modalConfirmRiskAppetiteChangeController($scope, $http, config, accounts, oldInvestorType, Goal) {
  var startsWithVowelRe = /^[aeiou]/i;

  $scope.aOrAn = function(word) {
    return startsWithVowelRe.test(word) ? 'an' : 'a';
  };

  $scope.accountRiskRecommendationChanges = [];
  $scope.oldInvestorType = oldInvestorType;
  $scope.updatedInvestorType = $scope.currentUser.investorType().label;

  _.each(accounts, function(account) {
    var goal = account.accountGoal().goal();

    var request = {
      financialAssets: $scope.currentUser.financialAssets(),
      income: $scope.currentUser.income(),
      initial: goal.initial(),
      nonFinancialAssets: $scope.currentUser.nonFinancialAssets(),
      ongoing: goal.ongoing(),
      riskAppetite: $scope.currentUser.riskAppetite(),
      timeframe: goal.timeframe(),
      totalLiabilities: $scope.currentUser.totalLiabilities()
    };

    $http.get('api/goals/new/risk.json', {
        params: _.underscoreKeys(request)
      })
      .then(function(response) {
        var goalWithNewRisk = Goal.new();

        goalWithNewRisk.riskRecommendation(response.data);
        goalWithNewRisk.advisorRiskOffset(goal.advisorRiskOffset());
        goalWithNewRisk.riskOffset(goal.riskOffset());

        if (goal.risk() !== goalWithNewRisk.risk()) {
          $scope.accountRiskRecommendationChanges.push({
            account: account,
            risk: {
              old: goal.risk(),
              new: goalWithNewRisk.risk()
            }
          });
        }
      });
  });
}

angular.module('controller.modal.confirm-risk-appetite-change', [
    'model.Goal'
  ])
  .controller('ModalConfirmRiskAppetiteChangeCtrl', [
    '$scope',
    '$http',
    'config',
    'accounts',
    'oldInvestorType',
    'Goal',
    modalConfirmRiskAppetiteChangeController
  ]);
