'use strict';

angular.module('controller.advise.client.identity-verification', [
    'component.advisor-dashboard.id-verification-section'
  ])
  .controller('AdviseClientIdentityVerificationCtrl', [
    adviseClientIdentityVerificationController
  ]);

function adviseClientIdentityVerificationController() {}
