'use strict';

function paginatedAccountTransferFactory(ram, config) {

  /**
   * Constructor for PaginatedAccountTransfer model.
   * @class
   */
  var PaginatedAccountTransfer = new ram.Collection('PaginatedAccountTransfer', {
    schema: config.schemas.PaginatedAccountTransfer,
    hasOne: {
      paginationMeta: 'PaginationMeta'
    },
    hasMany: {
      accountTransfers: 'AccountTransfer',
    },
    resources: {
      default: new ram.resources.Http('/api/paginated_account_transfers/:id.json'),
      cookie: new ram.resources.Cookie('paginated_account_transfers')
    }
  });

  return PaginatedAccountTransfer;
}

angular.module('model.PaginatedAccountTransfer', [
    'ram',
    'config',
    'model.PaginationMeta',
    'model.AccountTransfer'
  ])
  .factory('PaginatedAccountTransfer', [
    'ram',
    'config',
    paginatedAccountTransferFactory
  ]);
