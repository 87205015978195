'use strict';

angular.module('controller.modal.super-advisor.update-bbs-upcoming-transfer-request-amount', [
    'ram',
    'service.form-helper'
  ])
  .controller('ModalSuperAdvisorUpdateBbsUpcomingTransferRequestAmountCtrl', [
    '$scope',
    'formHelper',
    'ram',
    'transferRequest',
    controller
  ]);

function controller($scope, formHelper, ram, transferRequest) {
  $scope.transferRequest = transferRequest;
  $scope.newAmount = new ram.Accessor();

  $scope.submitForm = function() {
    if ($scope.form.$invalid) {
      formHelper.notDone($scope, $scope.form);
    } else {
      $scope.$close($scope.newAmount());
    }
  };
}
