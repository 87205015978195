'use strict';

angular.module('controller.advise.client.prepare-documents', [
    'model.Document',
    'component.advise.resp-grant-document-subform',
    'component.advise.beneficiary-fillable-form',
    'ram'
  ])
  .controller('AdviseClientPrepareDocumentsCtrl', [
    '$scope',
    'ram',
    'Document',
    'clientAccounts',
    'config',
    adviseClientViewPrepareDocumentsController
  ]);

/**
 * Return an array of objects that specify the current list of fillable PDF forms.
 * This is temporary, until we have more than one form.
 * Once this feature grows beyond 1 form we need to find a better way to sort them and store them.
 *
 * They are not 'documents', so they aren't included in that list.
 *
 * @returns {{label: string, name: string}}
 */
function fillableFormList() {
  return [{
    label: 'Designation of Beneficiary',
    name: 'beneficiary_fillable_form',
    relatedToType: 'Account'
  }];
}

function adviseClientViewPrepareDocumentsController($scope, ram, Document, clientAccounts, config) {
  $scope.clientAccounts = clientAccounts;
  $scope.selectedDocument = null;
  $scope.documentType = new ram.Accessor();

  $scope.reset = function() {
    $scope.documentType(null);
    $scope.selectedDocument = '';
    if ($scope.docTypeForm) {
      $scope.docTypeForm.$setPristine();
    }
  };

  $scope.documentTypes = _.filter(config.types.Document, function(document) {
    return !document.hidden && document.advisorPrefill;
  });
  _.each(fillableFormList(), function(form) {
    $scope.documentTypes.push(form);
  });

  $scope.$watch('documentType()', function(type) {
    if (!type) {
      return;
    }
    $scope.type = type;
    $scope.selectedDocument = type.name;

    if ($scope.selectedDocument === 'resp_government_grant_application') {
      if (type.relatedToType === 'Account') {
        // TODO: Make more dynamic when more document types are added for prefilling
        $scope.possibleRelatedToObjects = _.filter($scope.clientAccounts, function(account) {
          return account.type().resp;
        });
      }
    } else if ($scope.selectedDocument === 'beneficiary_fillable_form') {
      if (type.relatedToType === 'Account') {
        $scope.possibleRelatedToObjects = _.filter($scope.clientAccounts, function(account) {
          return !account.type().resp && account.type().registered;
        });
      }
    }
  });
}
