import template from './holdings-pie-chart-legend.html';
import controller from './holdings-pie-chart-legend.controller';

const holdingsPieChartLegendComponent = {
  template,
  bindings: {
    positions: '<',
    accountBalance: '<'
  },
  controller: ['$scope', controller],
  controllerAs: '$ctrl'
};

export default holdingsPieChartLegendComponent;
