'use strict';

angular.module('controller.modal.grant-fee-credit', [
    'directive.input.date-input',
    'directive.input.number'
  ])
  .controller('grantFeeCreditCtrl', [
    '$scope',
    'ram',
    'client',
    'feeCreditAccount',
    grantFeeCreditCtrl
  ]);

function grantFeeCreditCtrl($scope, ram, client, feeCreditAccount) {

  angular.extend($scope, {
    amount: new ram.Accessor(),
    client: client,
    date: new ram.Accessor(moment().startOf('day')),
    grantFeeCredit: grantFeeCredit,
    notes: undefined
  });

  function grantFeeCredit() {
    if ($scope.validateForm($scope.form)) {
      $scope.isSaving = true;
      var feeCredit = {
        amount: $scope.amount(),
        date: moment($scope.date()).toDate(),
        notes: $scope.notes
      };
      feeCreditAccount.grantCredit(feeCredit).then(function() {
        feeCreditAccount.reload();
        $scope.$dismiss();
        $scope.isSaving = false;
      }, function() {
        $scope.grantFeeCreditFailed = true;
        $scope.isSaving = false;
      });
    }
  }
}
