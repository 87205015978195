'use strict';

function transferFactory(ram, CashFlow, DateAccessor) {

  var Transfer = new ram.Collection('Transfer', {
    belongsTo: {
      account: 'Account'
    },
    resources: {
      default: new ram.resources.Http('/api/transfers/:id.json'),
      cookie: new ram.resources.Cookie('transfers')
    }
  });

  /**
   * Convert the date into a Date object, which is easier to work with.
   * @param  {Object} attrs Balance object attributes
   */
  Transfer.prototype.initialize = function(attrs) {
    this.date = new DateAccessor(attrs.date);
    this.amount(parseFloat(attrs.amount));
  };

  /**
   * Converts the transfer into a cashflow where a negative amount
   * is a contribution and a positive amount is a withdrawl.
   *
   * @return {Object} The transfer as a cash flow
   */
  Transfer.prototype.toCashFlow = function() {
    return new CashFlow(this.date(), -this.amount());
  };

  /**
   * Converts the transfer to something that can be displayed in a graph.
   * @return {Object} The same data as an object
   */
  Transfer.prototype.toDisplayFormat = function() {
    return {
      date: this.date(),
      amount: this.amount() || 0
    };
  };

  return Transfer;
}

angular.module('model.Transfer', [
    'model.Account',
    'model.CashFlow',
    'ram',
    'ram.DateAccessor'
  ])
  .factory('Transfer', [
    'ram',
    'CashFlow',
    'DateAccessor',
    transferFactory
  ]);
