'use strict';

angular.module('controller.advise.transfer-requests.bbs-upcoming-transfer-requests', [
    'controller.modal.super-advisor.update-bbs-upcoming-transfer-request-amount',
    'controller.modal.super-advisor.show-internal-transfers-email-text-and-mark-as-sent',
    'query.bbs-upcoming-transfer-requests.deposits-and-withdrawals',
    'query.bbs-upcoming-transfer-requests.internal-transfers',
    'service.loading-indicator',
    'service.user-notifications'
  ])
  .controller('AdviseTransferRequestsBbsUpcomingTransferRequestsCtrl', [
    '$http',
    '$uibModal',
    '$scope',
    'BbsUpcomingTransferRequestsDepositsAndWithdrawalsQuery',
    'BbsUpcomingTransferRequestsInternalTransfersQuery',
    'loadingIndicator',
    'userNotifications',
    adviseTransferRequestsBbsUpcomingTransferRequestsController
  ]);

function adviseTransferRequestsBbsUpcomingTransferRequestsController(
  $http,
  $modal,
  $scope,
  BbsUpcomingTransferRequestsDepositsAndWithdrawalsQuery,
  BbsUpcomingTransferRequestsInternalTransfersQuery,
  loadingIndicator,
  userNotifications
) {
  $scope.upcomingTransferRequests = [];

  $scope.views = {
    depositsWithdrawals: 'deposits-withdrawals',
    internalTransfers: 'internal-transfers'
  };

  $scope.currentView = $scope.views.depositsWithdrawals;

  $scope.activateDepositAndWithdrawalView = function() {
    loadingIndicator.show(null, 'deposits-withdrawals');

    BbsUpcomingTransferRequestsDepositsAndWithdrawalsQuery.run()
      .then(function(upcomingTransferRequestsResults) {
        $scope.upcomingTransferRequests = upcomingTransferRequestsResults;
        return _resetTransferRequestSelection();
      })
      .then(function() {
        $scope.currentView = $scope.views.depositsWithdrawals;
      })
      .finally(function() {
        loadingIndicator.hide('deposits-withdrawals');
      });
  };

  $scope.activateInternalTransferView = function() {
    loadingIndicator.show();

    BbsUpcomingTransferRequestsInternalTransfersQuery.run()
      .then(function(upcomingTransferRequestsResults) {
        $scope.upcomingTransferRequests = upcomingTransferRequestsResults;
        return _resetTransferRequestSelection();
      })
      .then(function() {
        $scope.currentView = $scope.views.internalTransfers;
      })
      .finally(function() {
        loadingIndicator.hide();
      });
  };

  $scope.sendSelectedTransferRequestsToBbs = function() {
    loadingIndicator.show();
    var selectedTransferRequestIds = _getIdsOfCurrentlySelectedTransferRequestIds();
    var nextBusinessDay;

    _getNextBusinessDay()
      .then(function(nextBusinessDayResult) {
        nextBusinessDay = nextBusinessDayResult;
        loadingIndicator.hide();

        return $modal.open({
            templateUrl: 'modals/super-advisor/confirm-send-bbs-eft-ticket.html',
            controller: ['$scope', function($scope) {
              $scope.numberOfRequests = selectedTransferRequestIds.length;
              $scope.ticketDate = nextBusinessDay;
            }]
          }).result
          .then(function() {
            loadingIndicator.show();
            return _createBbsEftTicket(selectedTransferRequestIds, moment(nextBusinessDay).format('YYYY-MM-DD'));
          })
          .finally(function() {
            loadingIndicator.hide();
          });
      });
  };

  $scope.isWithdrawalAndAvailableCashLessThanAmount = function(transferRequest) {
    return transferRequest.typeLabel === 'Withdrawal' && transferRequest.availableCash < transferRequest.amount;
  };

  $scope.updateTransferRequestAmount = function(transferRequest) {
    return $modal.open({
        templateUrl: 'modals/super-advisor/update-bbs-upcoming-transfer-request-amount.html',
        controller: 'ModalSuperAdvisorUpdateBbsUpcomingTransferRequestAmountCtrl',
        resolve: {
          transferRequest: transferRequest
        }
      }).result
      .then(function(newAmount) {
        loadingIndicator.show();
        return _updateUpcomingTransferRequestAmount(transferRequest.id, newAmount);
      })
      .finally(function() {
        loadingIndicator.hide();
      });
  };

  $scope.generateEmailTextForSelectedTransferRequests = function() {
    var selectedTransferRequestIds = _getIdsOfCurrentlySelectedTransferRequestIds();
    return $modal.open({
        templateUrl: 'modals/super-advisor/show-internal-transfers-email-text-and-mark-as-sent.html',
        controller: 'ModalSuperAdvisorShowInternalTransfersEmailTextAndMarkAsSentCtrl',
        resolve: {
          transferRequests: function() {
            return _.map(selectedTransferRequestIds, function(id) {
              return _.find($scope.upcomingTransferRequests, function(upcomingTransferRequest) {
                return upcomingTransferRequest.id === parseInt(id);
              });
            });
          }
        }
      }).result
      .then(function() {
        loadingIndicator.show();
        return _markInternalTransfersAsSent(selectedTransferRequestIds);
      })
      .finally(function() {
        loadingIndicator.hide();
      });
  };

  function _markInternalTransfersAsSent(selectedTransferRequestIds) {
    userNotifications.clearNotification();

    return $http.put('/api/super_advisor/bbs_upcoming_transfer_requests/mark_as_sent.json', {
        id: selectedTransferRequestIds
      })
      .then(_reloadUpcomingTransferRequests)
      .then(_resetTransferRequestSelection)
      .catch(function() {
        userNotifications.showError('Something went wrong while sending marking the BBS internal transfers as sent. Please try again later.');
      });
  }

  function _resetTransferRequestSelection() {
    return _getNextBusinessDay()
      .then(function(nextBusinessDay) {
        $scope.transferRequestSelection = {};

        $scope.upcomingTransferRequests
          .filter(function(transferRequest) {
            var readyToSendStatuses = ['Upcoming', 'In Progress'];
            return readyToSendStatuses.includes(transferRequest.statusLabel);
          })
          .forEach(function(transferRequest) {
            $scope.transferRequestSelection[transferRequest.id] = new Date(transferRequest.date) <= nextBusinessDay;
          });
      });
  }

  function _getIdsOfCurrentlySelectedTransferRequestIds() {
    var selectedTransferRequestIds = [];

    _.each($scope.transferRequestSelection, function(isSelected, transferRequestId) {
      if (isSelected) {
        selectedTransferRequestIds.push(transferRequestId);
      }
    });

    return selectedTransferRequestIds;
  }

  function _reloadUpcomingTransferRequests() {
    var upcomingTransferRequestsQuery;

    if ($scope.currentView === $scope.views.depositsWithdrawals) {
      upcomingTransferRequestsQuery = BbsUpcomingTransferRequestsDepositsAndWithdrawalsQuery;
    } else {
      upcomingTransferRequestsQuery = BbsUpcomingTransferRequestsInternalTransfersQuery;
    }

    return upcomingTransferRequestsQuery.run()
      .then(function(upcomingTransferRequestsResults) {
        $scope.upcomingTransferRequests = upcomingTransferRequestsResults;
      });
  }

  function _getNextBusinessDay() {
    return $http.get('/api/next_business_day.json', {
        params: {
          date: moment().format('YYYY-MM-DD')
        }
      })
      .then(function(response) {
        return moment(response.data.nextBusinessDay).toDate();
      });
  }

  function _createBbsEftTicket(selectedTransferRequestIds, nextBusinessDay) {
    userNotifications.clearNotification();
    $scope.bbsEftTickets = null;

    return $http.post('/api/super_advisor/bbs_eft_tickets.json', {
        transferRequestIds: selectedTransferRequestIds,
        date: nextBusinessDay
      })
      .then(function(response) {
        $scope.bbsEftTickets = response.data.bbsEftTickets;
        return _reloadUpcomingTransferRequests();
      })
      .then(_resetTransferRequestSelection)
      .catch(function() {
        userNotifications.showError('Something went wrong while sending the BBS EFT ticket. Please try again later.');
      });
  }

  function _updateUpcomingTransferRequestAmount(transferRequestId, newAmount) {
    userNotifications.clearNotification();

    return $http.put('/api/super_advisor/bbs_upcoming_transfer_requests/' + transferRequestId + '.json', {
        amount: newAmount
      })
      .then(_updateDisplayUpcomingTransferRequest)
      .catch(function() {
        userNotifications.showError('Something went wrong while updating the BBS EFT request amount. Please try again later.');
      });
  }

  function _updateDisplayUpcomingTransferRequest(updateUpcomingTransferRequestResponse) {
    var responseTransferRequest = updateUpcomingTransferRequestResponse.data;
    var displayTransferRequestIndex = $scope.upcomingTransferRequests.findIndex((displayTransferRequest) => displayTransferRequest.id === responseTransferRequest.id);
    $scope.upcomingTransferRequests[displayTransferRequestIndex] = responseTransferRequest;
  }
}
