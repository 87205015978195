'use strict';

angular.module('controller.modal.advisor.confirm-cancel-transfer-instruction', [])
  .controller('ModalAdvisorConfirmCancelTransferInstructionCtrl', [
    '$scope',
    'transferInstruction',
    controller
  ]);

function controller($scope, transferInstruction) {
  $scope.transferInstruction = transferInstruction;
  $scope.isRecurringTransferInstruction = !transferInstruction.frequency.is.onlyOnce();
  $scope.upcomingAndInProgressTransferRequests = transferInstruction.transferRequests().filter((transferRequest) => {
    return transferRequest.status.is.upcoming() || transferRequest.status.is.inProgress();
  });
}
