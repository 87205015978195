'use strict';

angular.module('service.account-service', [])
  .factory('accountService', [
    accountServiceFactory
  ]);

function accountServiceFactory() {


  var accountCompletePredicate = function(account) {
    return account.isVisible();
  };

  var accountInProgressPredicate = function(account) {
    return !account.applicationCompleted() && !account.type.is.trial();
  };

  var findTrialAccount = function(accounts) {
    return _.find(accounts, function(account) {
      return account.type() && account.type.is.trial();
    });
  };

  var hasFundedAccount = function(accounts) {
    return _.chain(accounts)
      .reject(function(account) {
        return account.isCombinedAccount();
      })
      .any(function(account) {
        return account.type() && !account.type.is.trial() && account.balance();
      })
      .value();
  };

  var findUnfundedAccount = function(accounts) {
    return _.chain(accounts)
      .reject(function(account) {
        return account.isCombinedAccount() || (account.type && account.type.is.trial());
      })
      .find(function(account) {
        return account.status && account.status.is.open() && account.transfers.length === 0;
      })
      .value();
  };

  var isFirstRealAccount = function(accounts) {
    return accounts.filter(function(account) {
      return !account.type.is.trial() && accountCompletePredicate(account);
    }).length <= 1;
  };

  var hasOpenAccount = function(accounts) {
    return _.chain(accounts)
      .reject(function(account) {
        return account.isCombinedAccount();
      })
      .any(function(account) {
        return account.status && account.status.is.open();
      })
      .value();
  };

  var getNavigationState = function(accounts, currentUser) {
    var hasCompletedAccount = this.hasCompletedAccount(accounts);
    var incompleteAccount = this.findIncompleteAccount(accounts);

    var newState = null;

    if (!hasCompletedAccount && !findTrialAccount(accounts)) {
      newState = 'investment/goal';
      if (incompleteAccount) {
        newState = this.getStateForIncompleteAccount(incompleteAccount);
      } else if (currentUser && currentUser.person().hasCompletedClientFlowWithPendingAccountFlows()) {
        // no completed account and no incomplete account == no accounts
        newState = 'advisor-new-account.view';
      }
    }
    return newState;
  };

  // This list should be kept in sync with the corresponding list of states in onboarding.js route.
  var getStateForIncompleteAccount = function(account) {
    return '/investment/goal';
  };

  // This list should be kept in sync with the corresponding list of states in onboarding.js route.
  var getUrlForIncompleteAccount = function(account) {
    return '/investment/goal';
  };

  var findIncompleteAccount = function(accounts) {
    var incompleteAccounts = _.filter(accounts, accountInProgressPredicate);
    return incompleteAccounts.length > 0 ? incompleteAccounts[0] : null;
  };

  var hasCompletedApplication = function(accounts) {
    return accounts.some(function(account) {
      return account.applicationCompleted();
    });
  };

  var hasCompletedAccount = function(accounts) {
    return _.any(accounts, accountCompletePredicate);
  };

  var hasGroupSavingsPlan = function(accounts) {
    return _.some(accounts, function(account) {
      return !!account.groupSavingsPlanId();
    });
  };

  const hasFccAccount = function(accounts) {
    return accounts.some((account) => {
      if (account.isCombinedAccount()) {
        return undefined;
      }
      return !!account.custodian().isFcc();
    });
  };

  const hasNonTrialCsiAccount = function(accounts) {
    return accounts.some((account) => {
      return account.custodian().isCsi() && account.type() && !account.type.is.trial();
    });
  };

  var getVisibleAccounts = function(accounts) {
    return _.filter(accounts, function(account) {
      return account.isVisible();
    });
  };

  var isJoint = function(account) {
    return account.type && account.type.is.jointWithRightOfSurvivorship();
  };

  var isFamilyResp = function(account) {
    return account.type && (account.type.is.familyResp());
  };

  var isIndividualResp = function(account) {
    return account.type && (account.type.is.individualResp());
  };

  var isResp = function(account) {
    return isIndividualResp(account) || isFamilyResp(account);
  };

  var canHaveBeneficiaries = function(account) {
    return account.type() && account.type.beneficiary();
  };

  var canHaveSpouseAsJoint = function(account) {
    return isResp(account) || isJoint(account);
  };

  var canExistingClientOpenTrialAccount = function(accounts) {
    return !hasOpenAccount(accounts) && !findTrialAccount(accounts);
  };

  var canAddTrialAccountFlow = function(client, newAccountFlows) {
    if (client && !canExistingClientOpenTrialAccount(client.accounts())) {
      return false;
    }
    var hasTrialAccountFlow = _.any(newAccountFlows, function(newAccountFlow) {
      return newAccountFlow.accountType.is.trial();
    });
    return !hasTrialAccountFlow;
  };

  var isRif = function(account) {
    return account.type && account.type.registeredIncomeFund();
  };

  var isSpousal = function(account) {
    return account.type && (account.type.is.spousalRrsp() || account.type.is.spousalRif());
  };

  var noDocumentsToSign = function(currentUser, account) {
    var accounts = currentUser.accounts();
    var custodian = account.custodian();
    var existingCustomer = !isFirstRealAccount(accounts);
    return custodian.isBbs() && existingCustomer && !account.type().registered;
  };

  return {
    canHaveSpouseAsJoint: canHaveSpouseAsJoint,
    canHaveBeneficiaries: canHaveBeneficiaries,
    canExistingClientOpenTrialAccount: canExistingClientOpenTrialAccount,
    canAddTrialAccountFlow: canAddTrialAccountFlow,
    findIncompleteAccount: findIncompleteAccount,
    findTrialAccount: findTrialAccount,
    findUnfundedAccount: findUnfundedAccount,
    getNavigationState: getNavigationState,
    getStateForIncompleteAccount: getStateForIncompleteAccount,
    getUrlForIncompleteAccount: getUrlForIncompleteAccount,
    hasCompletedAccount: hasCompletedAccount,
    hasCompletedApplication: hasCompletedApplication,
    hasFundedAccount: hasFundedAccount,
    hasGroupSavingsPlan: hasGroupSavingsPlan,
    hasFccAccount: hasFccAccount,
    hasNonTrialCsiAccount: hasNonTrialCsiAccount,
    hasOpenAccount: hasOpenAccount,
    isFamilyResp: isFamilyResp,
    isFirstRealAccount: isFirstRealAccount,
    isIndividualResp: isIndividualResp,
    isJoint: isJoint,
    isResp: isResp,
    isRif: isRif,
    isSpousal: isSpousal,
    getVisibleAccounts: getVisibleAccounts,
    noDocumentsToSign: noDocumentsToSign
  };
}
