'use strict';

angular.module('directive.account-processing-message', [
    'service.adaptive-views',
  ])
  .directive('accountProcessingMessage', [
    'adaptiveViews',
    accountProcessingMessageDirective
  ]);

function accountProcessingMessageDirective(adaptiveViews) {
  return {
    restrict: 'E',
    templateUrl: adaptiveViews.getTemplateUrlForSrc('directives/account-processing-message.html', true),
    scope: {
      heading: '@'
    },
    transclude: true
  };
}
