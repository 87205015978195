'use strict';

angular.module('controller.advice-centre.spousal-rrsp', [])
  .controller('AdviceCentreSpousalRRSPCtrl', [
    '$scope',
    '$state',
    'ram',
    'currentUser',
    'spouse',
    adviceCentreSpousalRRSPCtrl
  ]);

function adviceCentreSpousalRRSPCtrl($scope, $state, ram, currentUser, spouse) {

  var spouseBirthDate = moment(spouse.birthDate());

  angular.extend($scope, {
    currentUserAssetsInRRSP: new ram.Accessor(),
    spouseAge: new ram.Accessor(Math.round(moment().diff(spouseBirthDate, 'years', true))),
    spouseIncome: new ram.Accessor(),
    spouseAssetsInRRSP: new ram.Accessor()
  });

  $scope.evaluateForm = function() {
    if ($scope.spouseIncome() <= (0.9 * currentUser.income()) && $scope.spouseAge() < 71) {
      $state.go('advice-centre.spousal-rrsp.schedule-call');
    } else {
      $state.go('advice-centre.spousal-rrsp.end');
    }
  };

}
