'use strict';

function directive() {
  return {
    replace: true,
    restrict: 'E',
    scope: true,
    templateUrl: 'directives/input/email-input.html'
  };
}

angular.module('directive.input.email-input', [])
  .directive('emailInput', [
    directive
  ]);
