'use strict';

angular.module('controller.profile.household.create', [
    'service.user-notifications'
  ])
  .controller('profileHouseholdCreateCtrl', [
    '$scope',
    '$http',
    '$state',
    'userNotifications',
    'isDesktopSafari',
    createHouseholdProfileController
  ]);

function createHouseholdProfileController($scope, $http, $state, userNotifications, isDesktopSafari) {
  $scope.isDesktopSafari = isDesktopSafari;

  $scope.createHousehold = function() {
    $http.post('/api/households.json', {
      household: {
        name: ''
      }, // Need to pass something, but server always overrides.
      personId: $scope.currentUser.person().id
    }).then(function() {
      $state.go('profile.household', {}, {
        reload: true
      });
    }, function() {
      userNotifications.showError(
        'We were unable to create your household. Please try again. Should the problem persist, contact us.'
      );
    });
  };
}
