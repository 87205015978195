'use strict';

angular.module('controller.interview.personal', [
    'controller.interview.personal.contact-info',
    'controller.interview.personal.end'
  ])
  .controller('interviewPersonalCtrl', [
    interviewPersonalCtrl
  ]);

function interviewPersonalCtrl() {}
