'use strict';

angular.module('controller.modal.advisor-modify-bankaccount-approval', [
    'directive.bank-account-formatted-display'
  ])
  .controller('ModalAdvisorModifyBankaccountApproval', [
    '$scope',
    'bankAccount',
    'action',
    controller
  ]);

function controller($scope, bankAccount, action) {
  $scope.bankAccount = bankAccount;
  $scope.action = action;
}
