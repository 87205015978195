'use strict';

function tradingEngineResultFactory(ram, config) {

  /**
   * Constructor for Trade models.
   * @class
   */
  var TradingEngineResult = new ram.Collection('TradingEngineResult', {
    belongsTo: {
      order: 'Order'
    },
    schema: config.schemas.TradingEngineResult,
    resources: {
      default: new ram.resources.Http('/api/trading_engine_results/:id.json')
    }
  });

  return TradingEngineResult;
}

angular.module('model.TradingEngineResult', [
    'ram',
    'config',
    'model.Fund'
  ])
  .factory('TradingEngineResult', [
    'ram',
    'config',
    tradingEngineResultFactory
  ]);
