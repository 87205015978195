'use strict';

angular.module('directive.account-transfer-form', [
    'config',
    'model.AccountTransfer',
    'model.AccountTransferSecurity',
    'ram',
    'service.form-helper',
    'service.resolve-helpers.transfer-institutions',
    'service.entity-list-service',
    'service.securityService',
    'service.user-notifications'
  ])
  .directive('accountTransferForm', [
    '$state',
    '$window',
    '$http',
    '$timeout',
    'AccountTransfer',
    'AdvisorCreateNewTransferFlow',
    'AdvisorCreateNewTransferSecurity',
    'AccountTransferSecurity',
    'config',
    'entityListService',
    'formHelper',
    'ram',
    'securityServiceFactory',
    'userNotifications',
    'transferInstitutionsResolveHelpers',
    accountTransferFormDirective
  ]);

function accountTransferFormDirective(
  $state,
  $window,
  $http,
  $timeout,
  AccountTransfer,
  AdvisorCreateNewTransferFlow,
  AdvisorCreateNewTransferSecurity,
  AccountTransferSecurity,
  config,
  entityListService,
  formHelper,
  ram,
  securityServiceFactory,
  userNotifications,
  transferInstitutionsResolveHelpers
) {

  return {
    controller: ['$scope', accountTransferFormCtrl],
    restrict: 'E',
    templateUrl: 'directives/account-transfer-form.html',
    scope: {
      account: '=',
      transferableAccounts: '=',
      clientId: '=', // The client's user_id
      clientFlow: '='
    }
  };

  function accountTransferFormCtrl($scope) {
    var _filterAccountTypes;
    var userTriggeredStateChange = true;

    $scope.client = securityServiceFactory.create($scope.$root.currentUser).isClient();
    $scope.displaySecurities = false; // Hide the securites for the start
    $scope.transferMyOptions = config.types.AccountTransferCategory;
    $scope.dscOptionsList = config.types.AccountTransferDsc;
    $scope.filteredTransferableAccounts = [];
    $scope.showAutoCompleteMenu = true;
    $scope.institutions = transferInstitutionsResolveHelpers.resolveInsitutions();

    var onAutoCompleteSelect = function(event, ui) {
      if (ui.item) {
        $scope.$apply(function() {
          $scope.from = ui.item.value;
        });
      }
    };
    var codesInitialized = false;

    $scope.initAutocomplete = function(event) {
      if (!codesInitialized) {
        $(event.target).autocomplete({
          appendTo: '#autocomplete-menu',
          delay: 100,
          minLength: 1,
          source: $scope.institutions.$$state.value,
          select: onAutoCompleteSelect
        });
      }
      codesInitialized = true;
    };

    function _reset() {
      $scope.transferMy = new ram.Accessor();
      $scope.accountNumber = new ram.Accessor();
      $scope.transferFrom = new ram.Accessor();
      $scope.dscOption = new ram.Accessor();
      $scope.inKindTransfer = new ram.Accessor();
      $scope.partialTransfer = new ram.Accessor();
      $scope.toAccountId = new ram.Accessor(); // This is the id of the object selected

      if ($scope.clientFlow) {
        $scope.positionsController = entityListService.createAccountTransferFlowPositionsController($scope, []);
      } else {
        $scope.positionsController = entityListService.createAccountTransferPositionsController($scope, []);
      }

      $scope.cashToTransfer = new ram.Accessor();
      $scope.transferSomeButNoPositions = false;
      $scope.transferAmount = new ram.Accessor();
    }

    _reset();

    // for the positions list:
    var setTransferIdCallback = function(entity) {
      entity.accountTransferId($scope.newTransferRecord.id);
    };
    // for the positions list:
    var setAdvisorFlowTransferIdCallback = function(entity) {
      entity.advisorCreateNewTransferFlowId($scope.newTransferRecord.id);
    };
    $scope.saveOnChange = false;

    $scope.howToTransferOptionsList = [{
      id: 1,
      label: 'Transfer as is (in kind)',
      rawLabel: 'Transfer as is (in kind)',
      value: true,
      description: 'We will move your investments as they are to ModernAdvisor. Once we receive your investments we will sell everything except for DSC funds and reinvest them according to your investment plan.',
      recommendedKey: 'in-kind'
    }, {
      id: 2,
      label: 'Transfer in cash',
      rawLabel: 'Transfer in cash',
      value: false,
      description: 'Your institution will sell your investments and will move the money to us. Please note that you may be charged trading or DSC fees.',
      recommendedKey: 'cash'
    }];

    $scope.partialTransferList = [{
      id: 1,
      label: 'Transfer all',
      value: false,
      description: 'We will move all of your investments in your account from your current institution.'
    }, {
      id: 2,
      label: 'Transfer some',
      value: true,
      description: 'You can choose which investments you want to move to ModernAdvisor, and which ones you want to keep at your current institution.'
    }];

    var _transferMyRecord = function() {
      return config.types.AccountTransferCategory.findByName($scope.transferMy());
    };

    var _accountType = function(argument) {
      return config.types.Account.findByName(argument);
    };

    // For the "transfer my" list
    $scope.selectedFriendlyNoun = function() {
      var result = _accountType(_transferMyRecord().suggest);
      return (result && (result.friendlyNoun || result.label)) || '';
    };

    function _setLabels() {
      _.each($scope.howToTransferOptionsList, function(item) {
        item.label = item.rawLabel +
          ' ' +
          (item.recommendedKey === $scope.recommendedTransferMethod() ? '(recommended)' : '');
      });
    }

    $scope.$watch('transferMy()', function(newValue) {
      if (newValue) {
        _filterAccountTypes();
      }
    });

    $scope.$watch('dscOption()', function(newValue, oldValue) {
      if (newValue) {
        _setLabels();
        $scope.inKindTransfer(undefined);

        if (!oldValue) {
          // The first time only ...
          $scope.inKindTransfer($scope.recommendedTransferMethod() === 'cash' ? false : true);
        }
      }
    });

    $scope.$watch('partialTransfer()', function(newValue) {
      if (newValue === true) {
        $scope.displaySecurities = true;
        $scope.transferAmount(undefined);
      } else {
        $scope.displaySecurities = false;
        $scope.resetTransferSomeButNoPositions();
      }
    });

    $scope.$watch('cashToTransfer()', function(newValue) {
      if (newValue && newValue > 0.0) {
        $scope.transferSomeButNoPositions = false;
      }
    });

    $scope.recommendedTransferMethod = function() {
      // Use this business logic to determine recommended method.
      // Used in 'first time' and in setting 'recommended' text.
      var retVal = 'none';
      if ($scope.dscOption()) {
        retVal = $scope.dscOption() === 'no' ? 'cash' : 'in-kind';
      }
      return retVal;
    };

    $scope.downloadPdf = function(form) {
      $scope.$root.showLoading();
      if (!_validateTransferTo() || !$scope.toAccountId()) {
        form.transferMy.$setDirty();
        if (form.toAccountId) {
          form.toAccountId.$setDirty();
        }
        $scope.$root.$broadcast('submit');
        $scope.$root.hideLoading();
        return;
      }
      $http.post('/api/fillable_pdf_forms/save_fillable_pdf_params', {
        typeName: 'AccountTransferFillableForm',
        relatedToId: $scope.toAccountId(),
        relatedToType: 'Account',
        externalAccountNumber: $scope.accountNumber(),
        institutionName: $scope.transferFrom(),
        inKind: $scope.inKindTransfer(),
        partialTransfer: $scope.partialTransfer(),
        dscId: config.types.AccountTransferDsc.findByName($scope.dscOption()).id,
        cashQuantity: $scope.cashToTransfer(),
        otherFunds: _getOtherFunds()
      }).then(function(result) {
        var pdfUrl = '/api/fillable_pdf_forms/create_fillable_pdf?params_id=' + result.data;
        $window.location = pdfUrl;
      }).catch(function() {
        userNotifications.showError('Something went wrong. Please try again.');
        $window.scrollTo(0, 0);
      }).finally(function() {
        $scope.$root.hideLoading();
      });
    };

    $scope.submitTransferRequest = function(form) {
      $scope.$root.showLoading();
      var accountTransferId;

      if (!formHelper.done(form, validateForm)) {
        formHelper.notDone($scope, form);
        $scope.$root.hideLoading();
        return;
      }

      $scope.newTransferRecord = AccountTransfer.new({
        externalAccountNumber: $scope.accountNumber(),
        institutionName: $scope.transferFrom(),
        accountId: $scope.toAccountId(),
        inKind: $scope.inKindTransfer(),
        partialTransfer: $scope.partialTransfer(),
        dscId: config.types.AccountTransferDsc.findByName($scope.dscOption()).id,
        amount: $scope.transferAmount()
      });
      $scope.newTransferRecord.save()
        .then(function(promiseResult) {
          accountTransferId = promiseResult.id;
          if ($scope.cashToTransfer() && $scope.cashToTransfer() > 0.0) {
            var record = AccountTransferSecurity.new({
              accountTransferId: accountTransferId,
              description: 'Cash',
              quantity: $scope.cashToTransfer()
            });
            return record.save();
          } else {
            return promiseResult;
          }
        })
        .then(function() {
          return $scope.positionsController.saveAll(setTransferIdCallback);
        })
        .then(function() {
          return $scope.newTransferRecord.finalize();
        })
        .then(function() {
          if ($scope.account) {
            return $scope.account.accountTransfers.ready(true);
          }
        })
        .then(function() {
          if (securityServiceFactory.create($scope.$root.currentUser).canAccess('transfer_account')) {
            _reset();
            form.$setPristine();
            userNotifications.showSuccess('The account transfer application has been sent to the client successfully.');
          } else {
            userTriggeredStateChange = false;
            $state.go('dashboard.transfer-account-submitted', {
              accountTransferId: accountTransferId
            });
          }
        })
        .catch(function() {
          if (securityServiceFactory.create($scope.$root.currentUser).canAccess('transfer_account')) {
            userNotifications.showError('Something went wrong while sending the account transfer application to the client. Please try again later.');
          } else {
            userNotifications.showError('Something went wrong while processing your account transfer. Please try again later.');
          }
        }).finally(function() {
          $scope.$root.hideLoading();
        });
    };

    $scope.submitAdvisorTransferFlowRequest = function(form) {
      if (!formHelper.done(form, validateForm)) {
        formHelper.notDone($scope, form);
        return;
      }

      doAdvisorTransferFlowRequestSave(form).then(function() {
        $state.go('advise.client-application.review');
      });
    };

    function doAdvisorTransferFlowRequestSave(form) {
      $scope.$root.showLoading();
      var accountTransferId;

      $scope.newTransferRecord = AdvisorCreateNewTransferFlow.new({
        advisorCreateNewClientFlowId: $scope.clientFlow.id,
        clientId: $scope.clientFlow.clientId(),
        externalAccountNumber: $scope.accountNumber(),
        institutionName: $scope.transferFrom(),
        accountFlowId: $scope.toAccountId(),
        inKind: $scope.inKindTransfer(),
        partialTransfer: $scope.partialTransfer(),
        dscId: config.types.AccountTransferDsc.findByName($scope.dscOption()).id,
        amount: $scope.transferAmount()
      });

      return $scope.newTransferRecord.save()
        .then(function(promiseResult) {
          accountTransferId = promiseResult.id;
          if ($scope.cashToTransfer() && $scope.cashToTransfer() > 0.0) {
            var record = AdvisorCreateNewTransferSecurity.new({
              advisorCreateNewTransferFlowId: accountTransferId,
              description: 'Cash',
              quantity: $scope.cashToTransfer()
            });
            return record.save();
          } else {
            return promiseResult;
          }
        })
        .then(function() {
          return $scope.positionsController.saveAll(setAdvisorFlowTransferIdCallback);
        })
        .then(function() {
          _reset();
          form.$setPristine();
        })
        .catch(function() {
          userNotifications.showError('Something went wrong while sending the account transfer application to the client. Please try again later.');
        }).finally(function() {
          $scope.$root.hideLoading();
        });
    }

    $scope.gotoInterviewLocal = function() {
      var typeToSuggest = _transferMyRecord().suggest;
      if (typeToSuggest) {
        $scope.$root.gotoInterview({
          accountTypeName: _accountType(typeToSuggest).name
        });
      }
    };

    $scope.resetTransferSomeButNoPositions = function() {
      $scope.transferSomeButNoPositions = false;
    };

    $scope.backClicked = function() {
      $window.history.back();
    };

    $scope.canOnboardSelectedType = function() {
      return _transferMyRecord().suggest;
    };

    $scope.hasRespAccount = function() {
      return _.any($scope.transferableAccounts, function(account) {
        return account.type().resp;
      });
    };

    $scope.isPartialRespTransfer = function() {
      return $scope.partialTransfer() === true && ($scope.transferMy() === 'individual_resp' || $scope.transferMy() === 'family_resp');
    };

    $scope.disableSubmitButton = function() {
      return ($scope.transferMy() && $scope.filteredTransferableAccounts.length === 0) ||
        $scope.isPartialRespTransfer() ||
        $scope.accountFlowHasTransferFlowAlready();
    };

    $scope.accountFlowHasTransferFlowAlready = function() {
      if (!$scope.clientFlow || !$scope.toAccountId() || !$scope.clientFlow.advisorCreateNewTransferFlows()) {
        return false;
      }

      let transferFlowsForToAccount = $scope.clientFlow.advisorCreateNewTransferFlows().filter((transferFlow) => {
        return transferFlow.accountFlow() && transferFlow.accountFlow().id === $scope.toAccountId();
      });

      return transferFlowsForToAccount.length > 0;
    };

    /////////////////////////////////////////////////////////////////
    // Validation business Logic
    /////////////////////////////////////////////////////////////////

    function _validateTransferSomeButNoPositions() {
      return !$scope.partialTransfer() || ($scope.partialTransfer() || $scope.clientFlow) ||
        ($scope.partialTransfer() && ($scope.cashToTransfer() || $scope.positionsController.getAll().length > 0));
    }

    function _validateTransferTo() {
      return $scope.filteredTransferableAccounts.length !== 0;
    }

    function validateForm(form) {
      $scope.transferSomeButNoPositions = false;

      var validSomeTransfer = _validateTransferSomeButNoPositions();
      var validTransferTo = _validateTransferTo();

      if (!validSomeTransfer) {
        $scope.transferSomeButNoPositions = true;
      }

      form.transferMy.$setValidity('required', $scope.transferMy() && validTransferTo ? true : false);

      return $scope.dscOption() &&
        !_.isUndefined($scope.inKindTransfer()) &&
        !_.isUndefined($scope.partialTransfer()) &&
        validTransferTo &&
        validSomeTransfer;
    }

    /////////////////////////////////////////////////////////////////
    // Utilties
    /////////////////////////////////////////////////////////////////

    $scope.toolTipDataPlacement = ($scope.$root.isMobile ? 'bottom' : 'right');

    _filterAccountTypes = function() {
      $scope.filteredTransferableAccounts = _.filter($scope.transferableAccounts, function(account) {
        return account.type() && $scope.transferMy() && account.type().transferCategory === $scope.transferMy();
      });
      $scope.toAccountId(null);
      if ($scope.filteredTransferableAccounts.length === 1) {
        $scope.toAccountId($scope.filteredTransferableAccounts[0].id);
      }
    };

    $scope.$on('$stateChangeStart', function(event, toState, toParams) {
      if (userTriggeredStateChange && $scope.form.$dirty) {
        event.preventDefault();
        $scope.$root.hideLoading();
        $scope.$root.incompleteAccountTransfer(toState, toParams, {
          call: userWantsToLeaveCallback
        });
      }
    });

    function userWantsToLeaveCallback() {
      userTriggeredStateChange = false;
    }

    function _getOtherFunds() {
      var otherFunds = [];
      _.each($scope.positionsController.getAll(), function(fund) {
        otherFunds.push({
          description: fund.description(),
          quantity: fund.quantity()
        });
      });
      return otherFunds;
    }
  }
}
