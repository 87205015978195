'use strict';

function addressServiceFactory(Address, config) {
  function getAddressByName(name) {
    var type = _.find(config.types.Address, function(type) {
      return type.name === name;
    });
    if (!type) {
      throw new Error('Cannot find address with the type name: [' + name + ']');
    }
    return type.id;
  }

  var addressTypeIds = {
    home: getAddressByName('home'),
    mailing: getAddressByName('mailing'),
    work: getAddressByName('work')
  };

  function resolveAddress(person, typeId) {
    if (!person) {
      return null;
    }
    return Address.findOrCreate({
      typeId: typeId,
      personId: person.id
    });
  }

  function resolveHomeAddress(person) {
    return resolveAddress(person, addressTypeIds.home);
  }

  /**
   * Creates new address for the person.
   * If baseAddress is defined then new address will be set to be the same as that one.
   *
   * @param person
   * @param params Id params for the new address (personId and typeId is required)
   * @param baseAddress
   * @returns {*}
   */
  function createNewAddress(person, params, baseAddress) {
    var newAddress = params;
    if (baseAddress) {
      newAddress = _.extend(newAddress, {
        sameAsId: baseAddress.id
      });
    }

    return Address
      .create(newAddress)
      .then(function() {
        // need to refresh person, so the new address is properly loaded
        return person.fetch(true);
      });
  }

  function resolveMailingAddress(person, homeAddress) {
    if (!person) {
      return null;
    }
    var query = {
      typeId: addressTypeIds.mailing,
      personId: person.id
    };
    return Address
      .find(query)
      .then(function(result) {
        var address = result;
        if (!address) {
          address = createNewAddress(person, query, homeAddress);
        }
        return address;
      });
  }

  return {
    resolveHomeAddress: resolveHomeAddress,
    resolveMailingAddress: resolveMailingAddress
  };
}
angular.module('service.address-service', [])
  .factory('addressService', [
    'Address',
    'config',
    addressServiceFactory
  ]);
