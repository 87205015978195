'use strict';

angular.module('service.portfolio-line-chart-data-service', [])
  .factory('portfolioLineChartDataService', [
    '$q',
    dataService
  ]);

function dataService($q) {

  var sourceDateFormat = 'D-MMM-YY',
    startValue = 1000;

  function fetch(riskLevel, type) {
    var portfolioName = type + riskLevel;

    function getCsv(resolve, reject) {
      var parsedData,
        filename = getFilename(portfolioName);

      d3.csv(filename,
        function(error, data) {
          if (error) {
            reject(error);
          } else {

            parsedData = parseData(data, type);

            parsedData.data.sort(function(x, y) {
              return d3.descending(x.date, y.date);
            });

            resolve(parsedData);
          }
        });
    }

    return $q(getCsv).then(function(parsedData) {
      return {
        data: parsedData.data,
        firstDataPointDate: parsedData.firstDataPointDate
      };
    });
  }

  function getFilename(pf) {
    var ext = '.csv',
      path = 'assets/portfolio-performances/';
    return path + pf + ext + '?date=' + Date.now().toString();
  }

  function parseData(data, type) {
    var previous,
      dateFormat = d3.time.format('%d-%b-%y'),
      firstDataPointDate,
      startOfYear = type === 'core' ? moment('2016-01-01').startOf('year') : moment('2017-01-01').startOf('year');

    data = trimToStartOfyear(data, startOfYear);
    data = setStartIfNecessary(data, startOfYear);
    firstDataPointDate = data[0].date;
    data = prependStartValues(data, startOfYear);

    data.forEach(function(d, i) {
      d.date = dateFormat.parse(d.date);
      d.pctOfPrevious = +d.pctOfPrevious;
      if (i === 0) {
        d.amount = startValue;
      } else {
        d.amount = previous * d.pctOfPrevious;
      }
      previous = d.amount;
    });
    return {
      data: data,
      firstDataPointDate: firstDataPointDate
    };
  }

  function trimToStartOfyear(data, startOfYear) {
    while (firstDate(data).isBefore(startOfYear)) {
      data.shift();
    }
    return data;
  }

  function setStartIfNecessary(data, startOfYear) {
    if (firstDate(data).isSame(startOfYear)) {
      data[0].pctOfPrevious = '1.000';
    }
    return data;
  }

  function prependStartValues(data, startOfYear) {
    while (firstDate(data).isAfter(startOfYear)) {
      data.unshift({
        date: moment(data[0].date, sourceDateFormat).subtract(1, 'days').format(sourceDateFormat),
        pctOfPrevious: '1.000'
      });
    }
    return data;
  }

  function firstDate(data) {
    return moment(data[0].date, sourceDateFormat);
  }

  return {
    fetch: fetch
  };
}
