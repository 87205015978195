'use strict';

angular.module('model.TradeReconciliationMismatch', [
    'ram',
    'config'
  ])
  .factory('TradeReconciliationMismatch', [
    'ram',
    'config',
    factory
  ]);

function factory(ram, config) {

  var TradeReconciliationMismatch = new ram.Collection('TradeReconciliationMismatch', {
    hasOne: {
      tradeReconciliationReport: 'TradeReconciliationReport'
    },
    resources: {
      default: new ram.resources.Http('/api/trade_reconciliation_mismatches/:id.json'),
      cookie: new ram.resources.Cookie('trade_reconciliation_mismatches')
    },
    schema: config.schemas.TradeReconciliationMismatch
  });

  TradeReconciliationMismatch.prototype.sideName = function() {
    return this.side().charAt(0).toUpperCase() + this.side().substring(1).toLowerCase();
  };

  TradeReconciliationMismatch.prototype.line = function() {
    if (this.allocationExternalId()) {
      return this.allocationExternalId().match(/[0-9]+$/)[0];
    }
  };

  TradeReconciliationMismatch.prototype.externalIdTxt = function() {
    if (this.externalId()) {
      return this.externalId();
    } else {
      return '-';
    }
  };

  return TradeReconciliationMismatch;
}
