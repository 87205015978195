'use strict';

angular.module('controller.advise.person.view', [
    'directive.editableContext', //used in directive.profile-form
    'directive.profile-form',
    'directive.editableField',
    'directive.staticField',
    'directive.with',
    'directive.user.date-of-birth-dropdowns',
    'directive.input.email',
    'directive.user.address-field',
    'service.init-unsaved-changes-tracking'
  ])
  .controller('AdvisePersonViewCtrl', [
    '$rootScope',
    '$scope',
    '$state',
    '$window',
    '$stateParams',
    'clientUser',
    'unsavedChangesTracking',
    'personAccounts',
    'securityServiceFactory',
    advisePersonViewController
  ]);

function advisePersonViewController(
  $rootScope,
  $scope,
  $state,
  $window,
  $stateParams,
  clientUser,
  unsavedChangesTracking,
  personAccounts,
  securityServiceFactory
) {
  $scope.personAccounts = personAccounts;
  $scope.isJointApplicant = $stateParams.jointApplicantId ? true : false;

  const security = securityServiceFactory.create($scope.$root.currentUser);
  $scope.allowEdit = security.canAccess('client_personal_info_edit');

  if ($scope.isJointApplicant && clientUser) {
    $scope.clientHomeAddress = clientUser.person().homeAddress();
    $scope.clientMailingAddress = clientUser.person().mailingAddress();
  }

  unsavedChangesTracking.init($rootScope, $scope, $window, $state);

  $scope.onDateOfBirthChange = function(person, date) {
    person.birthDate(date.toDate());
  };

  $scope.canCloseAccount = function(account) {
    return !account.status.is.closed() && (account.type.is.trial() || account.balance() === 0);
  };

  $scope.closeAccount = function(account) {
    /* jshint quotmark:false */
    var modalBody = s.sprintf("Are you sure you want to close %s's %s account %s?", account.clientName(), account.type().label, account.number() || '(number not assigned)?');
    var title = s.sprintf("Closing %s's %s account", account.clientName(), account.type().label);
    /* jshint quotmark:single */
    if (account.balance() > 0) {
      modalBody += ' <br/>The client will lose the trial earnings. ';
    }

    $scope.genericConfirmationModal(title, modalBody)
      .result
      .then(function() {
        return account.close();
      });
  };

  $scope.custodianCode = function(account) {
    return account.custodian().code();
  };
}
