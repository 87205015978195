'use strict';

angular.module('controller.wsi-interview.goal', [
    'config',
    'model.Question',
    'ram'
  ])
  .controller('WsiInterviewGoalCtrl', [
    '$scope',
    '$state',
    'config',
    'questions',
    controller
  ]);

function controller($scope, $state, config, questions) {
  if ($scope.$root.pushStatePending) {
    history.pushState(null, null, $scope.$root.pushStatePending);
    $scope.$root.pushStatePending = undefined;
  }

  $scope.questions = _.indexBy(questions, function(question) {
    return question.accessor();
  });

  // Options for mobile selectors
  $scope.timeframeOptions = makeSelectorOptions('timeframe');
  $scope.retirementAgeOptions = makeSelectorOptions('retirementAge');
  $scope.childsAgeOptions = makeSelectorOptions('childsAge');

  function makeSelectorOptions(timeframeAccessor) {
    var timeframe = $scope.questions[timeframeAccessor],
      values = _.range(timeframe.min(), timeframe.max(), timeframe.step()),
      options = {},
      prefix = timeframe.prefix(),
      suffix = timeframe.suffix(),
      lastSuffix;

    if (timeframeAccessor === 'retirementAge') {
      lastSuffix = ' years or more';
    } else if (timeframeAccessor === 'childsAge') {
      lastSuffix = ' years or older';
    } else {
      lastSuffix = ' years or longer';
    }

    _.each(values, function(value) {
      if (value === 1) {
        options[value] = prefix + value + suffix.replace('years', 'year');
      } else {
        options[value] = prefix + value + suffix;
      }
    });
    options[timeframe.max()] = prefix + timeframe.max() + lastSuffix;

    return options;
  }

  var savedRetirementAge = null;
  if ($scope.goal.preset() === 'Save for Retirement' && $scope.goal.retirementAge()) {
    savedRetirementAge = $scope.goal.retirementAge();
  }

  // initialize to default timeframe if is empty
  if (!$scope.goal.timeframe()) {
    $scope.goal.preset($scope.goal.preset());
  }

  var retirementAgeQuestion = $scope.questions.retirementAge;
  var likelyRetirementAge = Math.min(
    Math.max(
      retirementAgeQuestion.min(),
      65 - $scope.user.age()
    ),
    retirementAgeQuestion.max()
  );

  $scope.$watch('goal.preset()', function(newGoal) {
    if (newGoal === 'Generate Income') {
      $scope.account.returnObjectiveId(null);
      $scope.account.save();
    }

    if (savedRetirementAge !== null) {
      $scope.goal.retirementAge(savedRetirementAge);
      savedRetirementAge = null;
      return;
    }

    if (newGoal === 'Save for Retirement') {
      $scope.goal.retirementAge(likelyRetirementAge);
    }
  });

  $scope.$watch('goal.notimeframe()', function(notimeframe) {
    if (notimeframe) {
      $scope.goal.timeframe(25);
      $scope.goal.save();
    }
  });

  $scope.returnObjectiveOptions = config.types.AccountReturnObjective;

  $scope.hasGoalConflicts = function() {
    const returnObjective = $scope.account.returnObjective;
    const goalLabel = $scope.goal.label();

    if (goalLabel === 'Save for Short-term' || goalLabel === 'Save for a Rainy Day') {
      return returnObjective.is.balancedGrowth() || returnObjective.is.aggressiveGrowth();
    }

    return false;
  };

  $scope.hasTimeframeConflicts = function() {
    const returnObjective = $scope.account.returnObjective;
    const timeframe = $scope.goal.timeframe();

    if (timeframe >= 1 && timeframe <= 3) {
      return returnObjective.is.balancedGrowth() || returnObjective.is.aggressiveGrowth();
    } else if (timeframe >= 3 && timeframe <= 5) {
      return returnObjective.is.aggressiveGrowth();
    }

    return false;
  };

  $scope.hasPreserveCapitalConflict = function() {
    const returnObjective = $scope.account.returnObjective;
    return returnObjective.is.preserveCapital(); // WSI Compliance deems this a hard stop.
  };

  $scope.$watchGroup(['hasGoalConflicts()', 'hasTimeframeConflicts()', 'hasPreserveCapitalConflict()'], function(newValues) {
    const hasGoalConflicts = newValues[0];
    const hasTimeframeConflicts = newValues[1];
    const hasPreserveCapitalConflict = newValues[2];
    let returnObjectiveInputController = $scope.form.returnObjective;

    if (!returnObjectiveInputController) {
      return;
    }

    if (hasGoalConflicts) {
      returnObjectiveInputController.$setDirty();
      returnObjectiveInputController.$setValidity('goalconflict', false);
    } else if (hasTimeframeConflicts) {
      returnObjectiveInputController.$setDirty();
      returnObjectiveInputController.$setValidity('timeframeconflict', false);
    } else if (hasPreserveCapitalConflict) {
      returnObjectiveInputController.$setDirty();
      returnObjectiveInputController.$setValidity('preservecapitalconflict', false);
    } else if (returnObjectiveInputController.$dirty) {
      returnObjectiveInputController.$setValidity('goalconflict', true);
      returnObjectiveInputController.$setValidity('timeframeconflict', true);
      returnObjectiveInputController.$setValidity('preservecapitalconflict', true);
    }
  });

  $scope.$parent.nextSection = function() {
    $state.go($state.current.data.next);
  };
  $scope.$parent.lastSection = function() {
    $state.go($state.current.data.last);
  };
  $scope.$parent.step = $state.current.data.step;
  $scope.$parent.header = $state.current.data.header;
  $scope.$parent.userCanGoBack = false;
}