'use strict';

angular.module('controller.profile.household.overview', [
    'ram',
    'model.Household',
    'service.user-notifications',
    'service.form-helper',
    'directive.input.email',
    'directive.mobile-household-member'
  ])
  .controller('profileHouseholdOverviewCtrl', [
    '$scope',
    '$http',
    '$state',
    'config',
    'ram',
    'Household',
    'userNotifications',
    'formHelper',
    'isDesktopSafari',
    'householdMembership',
    'householdList',
    profileHouseholdOverviewController
  ]);

function profileHouseholdOverviewController($scope, $http, $state, config, ram, Household, userNotifications, formHelper, isDesktopSafari, householdMembership, householdList) {
  $scope.isDesktopSafari = isDesktopSafari;

  var messages = {
    deleteSuccess: '@personFullName@ removed from household.',
    deleteError: 'You cannot remove the last admin user.',
    deleteGeneralError: 'An error occurred when trying to remove @personFullName@ from household. Please try again.',
    nameSuccess: 'Household name changed.',
    nameError: 'There was an error changing the household name.',
    inviteSuccess: '@inviteeEmail@ has been invited.',
    inviteError: 'There was an error inviting @inviteeEmail@.'
  };

  var modalPresets = {
    remove: {
      title: 'Remove User',
      body: 'Are you sure you want to remove <b>@personFullName@</b> from your household?'
    },
    optOut: {
      title: 'Opt Out',
      body: 'Are you sure you want to opt out of the <b>@householdName@</b>?'
    },
    inviteUser: {
      title: 'Invite User',
      body: 'Are you sure you want to invite <b>@inviteeEmail@</b> to join the <b>@householdName@</b>?'
    }
  };

  function _setInviteeEmail(text, inviteeEmail) {
    return text.replace('@inviteeEmail@', inviteeEmail);
  }

  function _setPersonFullName(text, personFullName) {
    return text.replace('@personFullName@', personFullName);
  }

  function _setHouseholdName(text, householdName) {
    return text.replace('@householdName@', householdName);
  }

  $scope.householdMembership = householdMembership;
  $scope.household = householdList[0];
  $scope.relationshipOptions = config.types.HouseholdMembershipRelationship;
  $scope.displayName = new ram.Accessor($scope.household.name());
  $scope.newName = new ram.Accessor($scope.household.name());
  $scope.inviteeEmail = new ram.Accessor();
  $scope.inviteeRelationship = new ram.Accessor();

  $scope.roleEnum = function(householdMembership) {
    return config.types.HouseholdMembershipRole[householdMembership.roleId()];
  };

  function _refresh() {
    Household.where({})
      .then(function(householdList) {
        $scope.household = householdList[0];
      });
  }

  function _resetInvite(form) {
    $scope.inviteeEmail('');
    $scope.inviteeRelationship(null);
    form.$setPristine();
  }

  $scope.deleteClicked = function(householdMembership) {
    var dialogState = $scope.isCurrentUser(householdMembership) ? 'optOut' : 'remove';
    var modalTitle = modalPresets[dialogState].title;
    var modalBody;

    if (dialogState === 'optOut') {
      modalBody = _setHouseholdName(modalPresets[dialogState].body, $scope.household.name());
    } else if (dialogState === 'remove') {
      modalBody = _setPersonFullName(modalPresets[dialogState].body, householdMembership.fullName());
    }

    $scope.genericConfirmationModal(modalTitle, modalBody, 'btn-success').result
      .then(function() {
        householdMembership.revoke()
          .then(function() {
            if ($scope.isCurrentUser(householdMembership)) {
              $state.go('profile.household.create');
            } else {
              _refresh();
            }
            userNotifications.showSuccess(
              _setPersonFullName(messages.deleteSuccess, householdMembership.fullName())
            );
          }, function(error) {
            if (error.data && error.data.code === 'household_membership_revoke_last_admin') {
              userNotifications.showError(messages.deleteError);
            } else {
              userNotifications.showError(
                _setPersonFullName(messages.deleteGeneralError, householdMembership.fullName())
              );
            }
          });
      });
  };

  $scope.canModifyHousehold = function() {
    return householdMembership.roleId() === config.types.HouseholdMembershipRole.findByName('admin').id;
  };

  $scope.modifyHouseholdName = function(form) {
    if (!formHelper.done(form)) {
      formHelper.notDone($scope, form);
      $scope.hideLoading();
      return;
    }

    $scope.state.isModifyingHouseholdName = false;
    if ($scope.newName() === $scope.household.name()) {
      return;
    }

    $scope.household.name($scope.newName());
    $scope.household.save()
      .then(function() {
        userNotifications.showSuccess(messages.nameSuccess);
        $scope.displayName($scope.household.name());
      }, function() {
        userNotifications.showError(messages.nameError);
        $scope.household.name.reset();
      });
  };

  $scope.inviteUser = function(form) {
    if (!formHelper.done(form)) {
      formHelper.notDone($scope, form);
      $scope.hideLoading();
      return;
    }

    var modalTitle = modalPresets.inviteUser.title;
    var modalBody = _setHouseholdName(
      _setInviteeEmail(
        modalPresets.inviteUser.body,
        $scope.inviteeEmail()
      ),
      $scope.household.name()
    );

    $scope.genericConfirmationModal(modalTitle, modalBody, 'btn-success').result
      .then(function() {
        $scope.household.inviteUser($scope.inviteeEmail(), $scope.inviteeRelationship())
          .then(function() {
            userNotifications.showSuccess(
              _setInviteeEmail(messages.inviteSuccess, $scope.inviteeEmail())
            );
            _refresh();
            _resetInvite(form);
          }, function() {
            userNotifications.showError(
              _setInviteeEmail(messages.inviteError, $scope.inviteeEmail())
            );
          });
      });
  };

  $scope.isCurrentUser = function(householdMembershipFromList) {
    return householdMembershipFromList.personId() === $scope.currentUser.person().id;
  };
}
