'use strict';

function directive() {
  return {
    replace: true,
    restrict: 'E',
    scope: true,
    templateUrl: 'directives/input/checkbox-input.html',
    link: function($scope, element) {
      // this is required for having a nice checkbox style
      element.after('<span class="outer"><span class="inner fa fa-check"></span></span>');
    }
  };
}

angular.module('directive.input.checkbox-input', [])
  .directive('checkboxInput', [
    directive
  ]);
