'use strict';

/***********************************************************************************
 * General case controller for this component
 */
class IdVerificationSectionController {
  constructor($http, $rootScope, $state) {
    this.$http = $http;
    this.$rootScope = $rootScope;
    this.$state = $state;
  }

  $onInit() {}

  manuallyVerifyIdentity() {
    this.$rootScope
      .advisorIdVerifyConfirm(this.personId, this.documents)
      .result.then(() => {
        this.isManuallyVerifying = true;
        this.$state.go('advise.client.view');
        return this.afterIdentityCheck({
          personId: this.personId
        });
      })
      .finally(() => {
        this.isManuallyVerifying = false;
      });
  }
}

export default IdVerificationSectionController;
