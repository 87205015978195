'use strict';

angular.module('service.pagination.filter-service', [])
  .factory('paginationFilterService',
    function() {
      function toFilterModel(filterData, initialFilter) {
        return _.chain(filterData)
          .filter(function(data) {
            return _.contains(initialFilter, data.code);
          })
          .map(function(data) {
            return {
              id: data.id
            };
          })
          .value();
      }

      function addEnumFilterData(enumValues, filterData, group, codePrefix) {
        _.each(enumValues, function(value) {
          var entry = {
            id: filterData.length,
            group: group,
            label: value.label,
            code: codePrefix + '.' + value.name
          };
          filterData.push(entry);
        });
      }

      return {
        toFilterModel: toFilterModel,
        addEnumFilterData: addEnumFilterData
      };
    }
  );
