'use strict';

angular.module('routes.iframe-widgets', [
    'controller.iframe-widgets',
    'routes.iframe-widgets.etfs',
    'service.resolve-helpers.model-portfolio',
    'service.resolve-helpers.mutual-funds',
    'templates',
    'ui.router'
  ])
  .config([
    '$stateProvider',
    configRoutes
  ]);

function configRoutes($stateProvider) {
  $stateProvider
    .state('iframe-widgets', {
      url: '/iframe-widgets',
      parent: 'site',
      abstract: true,
      views: {
        'main': {
          controller: 'iframeWidgetsCtrl',
          template: '<div ui-view></div>'
        }
      }
    })
    .state('iframe-widgets.portfolio-performance', {
      url: '/portfolio-performance',
      controller: 'iframeWidgetsPortfolioPerformanceCtrl',
      template: '<div style="background-color: #ffffff;"><portfolio-performance portfolio-selections="portfolioSelections"></portfolio-performance></div>',
      resolve: {
        defaultModelPortfolio: ['modelPortfolioResolveHelpers', function(modelPortfolioResolveHelpers) {
          return modelPortfolioResolveHelpers.resolveDefaultModelPortfolio();
        }],
        portfolioSelections: ['defaultModelPortfolio', 'modelPortfolioResolveHelpers', function(defaultModelPortfolio, modelPortfolioResolveHelpers) {
          return modelPortfolioResolveHelpers.resolvePortfolioSelectionsWithDefaultAndPseudoAccount(defaultModelPortfolio);
        }]
      }
    })
    .state('iframe-widgets.mutual-fund-fees-page', {
      url: '/mutual-fund-fees-page',
      controller: 'iframeWidgetsMutualFundFeesPageCtrl',
      resolve: {
        mutualFunds: ['mutualFundsResolveHelpers', function(mutualFundsResolveHelpers) {
          return mutualFundsResolveHelpers.resolveMutualFunds();
        }]
      }
    });
}
