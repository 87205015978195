'use strict';

angular.module('controller.dashboard.transfer', [
    'service.controller.eft.transfer',
    'service.timeframeDisplayHelper',
    'directive.account-transfers-table',
    'directive.input.date-input',
    'directive.input.number',
    'directive.user.address-field',
    'directive.input.manual-help-block',
    'mobile.directive.transfer-type-dropdown',
    'ram',
  ])
  .controller('DashboardTransferCtrl', [
    'eftTransferService',
    'timeframeDisplayHelper',
    '$state',
    '$scope',
    '$stateParams',
    'accounts',
    'bankAccounts',
    'transferableAccounts',
    'transferRequests',
    'transferInstructions',
    'wfmTransferableAccounts',
    'ram',
    transferController
  ]);

function transferController(
  eftTransferService,
  timeframeDisplayHelper,
  $state,
  $scope,
  $stateParams,
  accounts,
  bankAccounts,
  transferableAccounts,
  transferRequests,
  transferInstructions,
  wfmTransferableAccounts,
  ram
) {
  eftTransferService.controller(
    $scope,
    $stateParams,
    $scope.currentUser,
    accounts,
    $scope.currentUser.bankAccounts(),
    transferableAccounts,
    transferRequests,
    transferInstructions,
    wfmTransferableAccounts
  );

  $scope.transfersToOrFromList = eftTransferService.getTransfersListFromOrTo($scope);
  $scope.transferRequestsByDateList = $scope.transferRequestsByDate;
  $scope.pastTransferRequestsList = $scope.pastTransferRequests;

  $scope.filterValueToOrFrom = new ram.Accessor($scope.transfersToOrFromList[0]);

  $scope.transfersPeriods = timeframeDisplayHelper.getPeriods();
  $scope.filterPeriod = new ram.Accessor($scope.transfersPeriods[2]);

  $scope.accountId = function(id) {
    if (arguments.length) {
      $state.go($state.current, {
        accountId: id
      });
    }
    return $scope.account.id;
  };

  function filterByFromOrTo() {
    if ($scope.filterValueToOrFrom().label === 'All accounts') {
      return;
    }

    $scope.pastTransferRequestsList = $scope.pastTransferRequestsList.filter(transfer => (
      $scope.clientLabel(transfer.transferTo, transfer.transferFrom) === $scope.filterValueToOrFrom().label || $scope.clientLabel(transfer.transferFrom, transfer.transferTo) === $scope.filterValueToOrFrom().label
    ));
  }

  function filterDateGroupByTransfer() {
    if ($scope.filterValueToOrFrom().label === 'All accounts') {
      return;
    }

    $scope.transferRequestsByDateList = $scope.transferRequestsByDateList.map(dateGroup => {
      const dataFiltered = dateGroup.data.filter(transfer => (
        $scope.clientLabel(transfer.transferTo, transfer.transferFrom) === $scope.filterValueToOrFrom().label || $scope.clientLabel(transfer.transferFrom, transfer.transferTo) === $scope.filterValueToOrFrom().label
      ));

      return Object.assign({}, dateGroup, {
        data: dataFiltered
      });

    }).filter(dateGroup => (
      dateGroup.data.length > 0
    ));

  }

  function filterByDate() {
    if (!$scope.filterPeriod().start) {
      return;
    }

    $scope.pastTransferRequestsList = $scope.pastTransferRequestsList.filter(transfer => {
      const transferDate = transfer.dateToBeProcessed();
      if (!transferDate) {
        return (false);
      }

      const parsedTransferDate = new Date(transferDate);
      return parsedTransferDate >= $scope.filterPeriod().start;
    });
  }

  function filterDateGroupByDate() {
    if (!$scope.filterPeriod().start) {
      return;
    }

    $scope.transferRequestsByDateList = $scope.transferRequestsByDateList.filter(transfer => {
      const transferDate = transfer.date;
      if (!transferDate) {
        return (false);
      }

      return transferDate >= $scope.filterPeriod().start;
    });
  }

  $scope.$watchGroup(['filterPeriod()', 'filterValueToOrFrom()'], function() {
    if ($scope.isMobile) {
      $scope.transferRequestsByDateList = $scope.transferRequestsByDate;
      filterDateGroupByDate();
      filterDateGroupByTransfer();
    } else {
      $scope.pastTransferRequestsList = $scope.pastTransferRequests;
      filterByFromOrTo();
      filterByDate();
    }
  });
}
