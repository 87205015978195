'use strict';

angular.module('service.referral-reward-client-greeter', [
    'service.promotion-redemption-cookie-creator'
  ])
  .factory('referralRewardClientGreeter', [
    '$http',
    'promotionRedemptionCookieCreator',
    factory
  ]);

function factory($http, promotionRedemptionCookieCreator) {
  return {
    createCookie: function(referralCode) {
      promotionRedemptionCookieCreator.createReferAFriend(referralCode);
    },
    getReferrerName: function(referralCode) {
      return $http.get('api/users/referrer_name.json', {
          params: {
            referralCode: referralCode
          }
        })
        .then(function(response) {
          return response.data.referrerName;
        });
    }
  };
}
