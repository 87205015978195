'use strict';

angular.module('controller.advise.bulk-id-verification', [
    'controller.advise.bulk-id-verification.upload-list'
  ])
  .controller('AdviseBulkIdVerificationCtrl', [
    adviseBulkIdVerificationController
  ]);

function adviseBulkIdVerificationController() {}
