'use strict';

angular.module('controller.dashboard.holdings', [
    'component.investment-charts.holdings-pie-chart',
    'component.investment-charts.holdings-pie-chart-legend',
    'directive.dashboard-account-processing-messages',
    'service.investment-charts.holdings-data-generator'
  ])
  .controller('DashboardHoldingsCtrl', [
    '$state',
    '$scope',
    'account',
    'accounts',
    'currentUser',
    'identityVerificationStatus',
    'investmentChartsHoldingsDataGenerator',
    'positions',
    holdingsController
  ]);

function holdingsController($state, $scope, account, accounts, currentUser, identityVerificationStatus, investmentChartsHoldingsDataGenerator, positions) {
  $scope.account = account;
  $scope.identityVerificationStatus = identityVerificationStatus;
  $scope.positions = {
    positions: investmentChartsHoldingsDataGenerator.getAggregateData(positions)
  };
  $scope.last = _.last;
  $scope.$parent.$parent.messageBoard.initializeHoldingsPage(currentUser, accounts, account);

  const separatedAccounts = $scope.accounts.filter(account => account.title !== 'Combined')
  const cumulativeBalance = separatedAccounts.reduce((sum, account) => sum += account.balance(), 0);

  $scope.positions = {
    ...$scope.positions,
    totalOfAllAccounts: cumulativeBalance,
    accountsCombined: $scope.account.title
  }

  $scope.accountId = function(id) {
    if (arguments.length) {
      $state.go($state.current, {
        accountId: id
      });
    }
    return $scope.account.id;
  }
}
