'use strict';

angular.module('directive.goal-summary', [
    'directive.goal-progress',
    'directive.simple-allocation-bar'
  ])
  .directive('goalSummary', ['$http', '$state', 'config', goalSummaryDirective]);

function goalSummaryDirective($http, $state, config) {
  return {
    restrict: 'E',
    scope: {
      goal: '=',
      transferInstructions: '=',
      closeAccount: '=',
      view: '@',
    },
    templateUrl: 'directives/goal-summary.html',
    link: function($scope) {
      var goal = $scope.goal;
      var account = goal.accountGoals()[0].account();
      $scope.account = account;
      var modelPortfolio = goal.accountGoals()[0].modelPortfolio();
      $scope.allocationsForBarChart = (modelPortfolio == undefined) ? [] : goal.accountGoals()[0].modelPortfolio().nonZeroAllocations();
      $scope.householdAccount = $scope.view !== 'advisor' && !$scope.account.userIsHolder();
      $scope.clientId = $scope.view === 'advisor' && $scope.account.userId();
      $scope.state = {
        showDetails: false
      };

      $scope.canCloseAccount = function(account) {
        return $scope.view === 'advisor' &&
          !account.status.is.closed() &&
          (account.type.is.trial() || account.balance() === 0);
      };

      $scope.title = account.clientLabel();

      $scope.loadClientNickNameModal = function(account) {
        $scope.$root.accountNicknameModal(account); // Save happens in modal.  No need to handle anything here.
      };

      $scope.deleteAccount = async function (account) {
        const confirmation = confirm(' Are you sure you want to continue?');

        if (confirmation) {
          $http
            .delete('/api/accounts/' + account.id + '.json')
            .then(() => $state.go('advise.new-account-applications'));
        }
      };

      $scope.autoDeposits = function() {
        return _.chain($scope.transferInstructions)
          .filter(function(transferInstruction) {
            return transferInstruction.transferToType() === 'Account' &&
              transferInstruction.transferToId() === account.id &&
              !transferInstruction.frequency.is.onlyOnce() &&
              !transferInstruction.status.is.finished(); // !cancelled is already handled by Ruby
          })
          .value();
      };

      var maxNumberOfAutoDepositsToShow = 2;

      $scope.autoDepositsToShow = function() {
        return _.first($scope.autoDeposits(), maxNumberOfAutoDepositsToShow);
      };

      $scope.autoDepositCancellationDetails = $scope.autoDepositsToShow().map(transferInstruction => {
        let status = '';
        if (transferInstruction.continueUntil()) {
          status = `until ${moment(transferInstruction.continueUntil()).format('YYYY-MM-DD')}`;
        } else if (transferInstruction.manualCancel()) {
          status = `until cancelled`;
        } else if (transferInstruction.maxOccurrence()) {
          const elapsed = transferInstruction.transferRequests().length;
          status = `${elapsed} of ${transferInstruction.maxOccurrence()} complete`;
        }
        return status;
      });

      $scope.numberOfOtherAutoDeposits = function() {
        var numberOfAutoDeposits = $scope.autoDeposits().length;

        if (numberOfAutoDeposits > maxNumberOfAutoDepositsToShow) {
          return numberOfAutoDeposits - maxNumberOfAutoDepositsToShow;
        } else {
          return 0;
        }
      };

      var ongoingAmount = function() {
        return _.reduce($scope.autoDeposits(), function(sum, transferInstruction) {
          return sum + transferInstruction.amountAsMonthlyRate();
        }, 0);
      };
      var projections = (goal.accountGoals()[0].modelPortfolio() == undefined) ? [] : goal.accountGoals()[0].modelPortfolio().projections();
      var simulation = (projections.length == 0) ? false : projections[1].simulate(goal.initial(), ongoingAmount());
      
      $scope.onTrackMonthlyContribution = function() {
        if (simulation == false) {
          return 0
        } else {
          return simulation.findOngoing(goal.targetAmount(), goal.timeframe(), account.balance()) || 0;
        }
      };
      
      $scope.onTrackDeposit = function() {
        if (simulation == false) {
          return 0
        } else {
          return simulation.findInitial(goal.targetAmount(), goal.timeframe(), ongoingAmount()) - account.balance() || 0;
        }
      };
      
      $scope.goalOntrack = function() {
        return ongoingAmount() >= $scope.onTrackMonthlyContribution() || account.balance() >= $scope.onTrackDeposit();
      };
      
      $scope.goToCreateNewDeposit = function() {
        $state.go('dashboard.transfer', {
          defaultTransferType: config.types.TransferInstruction.findByName('deposit').id
        });
      };

      $scope.portfolioOption = function() {
        var portfolioOption = account.portfolioOption()
        if (portfolioOption != undefined) {
          return portfolioOption.label;
        } else {
          return "";
        }
      };

      $scope.contributions = function() {
        return account.contributions();
      };

      $scope.earnings = function() {
        return account.performance();
      };

      $scope.balance = function() {
        return account.balance();
      };

      $scope.firstBalanceDate = function() {
        return account.balances()[0] && account.balances()[0].date();
      };

    }
  };
}
