'use strict';

function createDropdownOption(value, labelLeft, labelRight, callback) {
  return {
    value: value,
    labelLeft: labelLeft,
    labelRight: labelRight,
    callback: callback
  };
}

function transferTypeDropdownDirective() {
  return {
    restrict: 'E',
    scope: {
      transactionTypes: '=',
      selectedValue: '='
    },
    template: '<dropdown options="transferDropdownOptions" value="{{selectedTransTypeId}}" disabled="{{disabled}}"></dropdown>',
    controllerAs: 'ctrl',

    link: function($scope, element, attrs) {
      $scope.disabled = $scope.$eval(attrs.disabled) || false;

      $scope.$watch('selectedValue()', function(value) {
        $scope.selectedTransTypeId = value;
      });

      var _createList = function() {
        var dropdownOptions = [];
        _.each($scope.transactionTypes, function(transactionType) {
          dropdownOptions.push(
            createDropdownOption(
              transactionType.id,
              function() {
                return transactionType.label;
              },
              null,
              function() {
                $scope.selectedValue(transactionType.id);
                $scope.selectedTransTypeId = transactionType.id;
              }
            )
          );
        });
        $scope.transferDropdownOptions = dropdownOptions;
        $scope.selectedTransTypeId = $scope.selectedValue();
      };
      _createList();
    }
  };
}

angular.module('mobile.directive.transfer-type-dropdown', [
    'directive.dropdown'
  ])
  .directive('transferTypeDropdown', [
    transferTypeDropdownDirective
  ]);
