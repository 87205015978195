'use strict';

function ngScopeDirective() {
  return {
    restrict: 'A',
    scope: true,
    link: function(scope, element, attrs) {

      // get the expression from the attribute
      var expression = attrs.ngScope;

      // evaulate the attribute
      var evaluated = scope.$eval(expression);

      // extend scope with evaluated expression
      angular.extend(scope, evaluated);

      // // watch for changes on the expression
      // scope.$watch(expression, function(newValue, oldValue, scope) {
      //   // update extending scope with the new value of the expression
      //   angular.extend(scope, newValue);
      // });

    }
  };
}

angular.module('directive.ng-scope', [])
  .directive('ngScope', [
    ngScopeDirective
  ]);
