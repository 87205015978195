'use strict';

angular.module('controller.modal.invite-client', [
    'service.promotion-redemption-cookie-creator',
    'service.link-copy-handler'
  ])
  .controller('ModalInviteClientCtrl', [
    '$scope',
    '$rootScope',
    '$timeout',
    'config',
    'inviteLink',
    'promotionRedemptionCookieCreator',
    'linkCopyHandler',
    inviteClientCtrl
  ]);

function inviteClientCtrl($scope, $rootScope, $timeout, config, inviteLink, promotionRedemptionCookieCreator, linkCopyHandler) {
  $scope.togglePrefillFeature = config.env.featureToggles.advisorCreateClient;
  $scope.groupUrlAndlink = inviteLink.match(/.*\/(.*)/)[1];
  $scope.mailToLink =
    'subject=Open%20your%20investment%20account&' +
    'body=Please%20use%20this%20link%20to%20open%20your%20account%20' +
    inviteLink + '&';
  $scope.inviteLink = inviteLink;
  $scope.onboard = function() {
    promotionRedemptionCookieCreator.createAdvisorReferral($scope.groupUrlAndlink);
    return $rootScope.logout('/interview');
  };
  $scope.isWfmAdvisor = !!inviteLink.match(/\/adv\/wfm-/);

  $scope.onLinkCopyError = function(e) {
    linkCopyHandler.onLinkCopyError(e);
  };

  $scope.onLinkCopied = function(e) {
    linkCopyHandler.onLinkCopied(e, '<i class="fa fa-check-circle fc-green fs-12px"></i> Copied!');
  };
}
