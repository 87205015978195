'use strict';

angular.module('controller.dashboard', [
    'controller.dashboard.summary',
    'mobile.controller.dashboard.summary',
    'controller.dashboard.account',
    'controller.dashboard.transfer',
    'controller.dashboard.transfer-account',
    'controller.dashboard.transfer-account-submitted',
    'controller.dashboard.activity',
    'controller.dashboard.documents',
    'controller.dashboard.holdings',
    'controller.dashboard.refer-a-friend',
    'controller.dashboard.goal-summary',
    'directive.input',
    'service.account-service',
    'service.adaptive-views',
    'service.dashboard-navbar',
    'service.feature-toggles',
    'service.sticky-account-message-board',
    'service.impersonation-service'
  ])
  .controller('DashboardCtrl', [
    '$scope',
    '$state',
    '$http',
    '$cookies',
    '$window',
    'account',
    'accounts',
    'adaptiveViews',
    'dashboardNavbarService',
    'currentUser',
    'route',
    'trialAccount',
    'incompleteAccount',
    'accountService',
    'kycUpdate',
    'household',
    'pendingHouseholdInvitations',
    'inlineSignatureDocuments',
    'featureToggles',
    'incompleteAccountFlows',
    'clientDashboardStickyAccountMessageBoard',
    'impersonationService',
    dashboardController
  ]);

function accountsNeedingSignature(documents) {
  if (!_.any(documents)) {
    return 0;
  }
  var ima = _.any(documents, function(document) {
    return document.type.is.ima();
  });

  return _.max([1, ima ? documents.length - 1 : documents.length]);
}

function dashboardController(
  $scope,
  $state,
  $http,
  $cookies,
  $window,
  account,
  accounts,
  adaptiveViews,
  dashboardNavbarService,
  currentUser,
  route,
  trialAccount,
  incompleteAccount,
  accountService,
  kycUpdate,
  household,
  pendingHouseholdInvitations,
  inlineSignatureDocuments,
  featureToggles,
  incompleteAccountFlows,
  clientDashboardStickyAccountMessageBoard,
  impersonationService
) {
  if (route) {
    $scope.redirecting = true;
  }

  $scope.isApp = adaptiveViews.isApp();
  $scope.accountIsSelectedInTopNav = dashboardNavbarService.stateIsAccountRelated;
  $scope.account = account;

  // `messageBoard` is in this scope so that the notifications.html file can see it.
  $scope.messageBoard = clientDashboardStickyAccountMessageBoard;

  $scope.accounts = accounts;
  $scope.inlineSignatureDocuments = inlineSignatureDocuments;
  $scope.numIncomplete = accountsNeedingSignature(inlineSignatureDocuments);
  if (incompleteAccount) {
    $scope.numIncomplete++;
  }
  $scope.showApplicationList = false;

  $scope.incompleteAccountFlows = incompleteAccountFlows;
  $scope.incompleteAccountFlow = incompleteAccountFlows && incompleteAccountFlows[0];

  $scope.household = household;
  $scope.pendingHouseholdInvitations = pendingHouseholdInvitations;

  $scope.isGoalSummaryFeatureEnabled = featureToggles.isEnabled('goalSummary');

  $scope.$watchCollection('account.accountTransfers()', function(accountTransfers) {
    $scope.accountTransfers = accountTransfers;
  });

  if (account) {
    $scope.accountTransfers = account.accountTransfers();
  } else {
    // TODO this is required to prevent errors when dashboard controller is initialized, but the user is redirected anyway
    $scope.account = {
      id: null,
      performance: angular.noop,
      contributions: angular.noop,
      userIsHolder: angular.noop
    };

    if (incompleteAccount) {
      $scope.account = incompleteAccount;
    }
    $scope.accountTransfers = [];
  }
  $scope.trialAccount = trialAccount;
  $scope.accountMenu = accounts;

  // Accounts that are either open or trial do _not_ trigger the incomplete
  // message to be displayed.  Incomplete accounts are a) not open and b) not trial.
  $scope.incompleteAccount = incompleteAccount;

  $scope.doDelete = function(account) {
    $scope.deleteApplicationModal(account.id);
  };

  $scope.hasNonTrialAccount = function() {
    return _.any(currentUser.accounts(), function(account) {
      return !account.type.is.trial();
    });
  };

  $scope.addAnotherAccount = function() {
    $state.go('/investment/goal');
  };

  $scope.goToIncomplete = function() {
    if (incompleteAccount) {
      window.location.href = '/investment/goal';
    }
  };

  $scope.impersonatingCookieExist = impersonationService.impersonatingCookieExist;
  $scope.whoIsImpersonating = impersonationService.whoIsImpersonating;
  $scope.revertToOriginalUser = impersonationService.revertToOriginalUser;

  $scope.goToIncompleteAccountFlow = function(accountFlow) {
    if (accountFlow) {
      $state.go('advisor-new-account.view', {
        advisorNewAccountFlowId: accountFlow.id
      });
    }
  };

  $scope.point = {};

  $scope.accountForDocument = function(document) {
    return _.find(accounts, function(account) {
      return account.id === document.relatedToId();
    });
  };

  // ----= KYC Updates =--------------------------
  $scope.kycUpdate = kycUpdate;
  $scope.today = moment().toDate();
  $scope.asDate = function(date) {
    return moment(date).toDate();
  };

  const url = new URL(window.location.href)
  const debuggingDateParam = url.searchParams.get('lowerFeesDate')
  const lowerFeesStartDate = moment(debuggingDateParam || '2023-03-01', 'YYYY-MM-DD')
  const today = moment()
  const userCreatedAt = moment(currentUser.createdAt())

  const lastSignInAt = currentUser.lastSignInAt() ? moment(currentUser.lastSignInAt()) : null
  const notificationSeen = lastSignInAt ? lastSignInAt.isAfter(lowerFeesStartDate) : false

  $scope.showLowerFeesNotification = userCreatedAt.isBefore(lowerFeesStartDate) &&
                                     today.isAfter(lowerFeesStartDate) &&
                                     !notificationSeen
}
