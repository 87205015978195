'use strict';

angular.module('directive.input.number', [
    'templates',
    'animations',
    'directive.focus'
  ])
  .directive('number', [
    numberDirective
  ]);

function numberDirective() {
  return {
    restrict: 'E',
    scope: {
      ngModel: '=',
      ngChange: '&',
      ngMin: '=',
      ngMax: '=',
      form: '=',
      help: '@',
      focusHelp: '@',
      title: '@',
      label: '@',
      name: '@',
      class: '@',
      placeholder: '@',
      inputClass: '@',
      labelClass: '@',
      helpClass: '@',
      prefix: '@',
      suffix: '@'
    },
    templateUrl: 'directives/input/number.html',
    link: function(scope) {

      scope.model = function(val) {
        if (val === undefined) {
          var modelValue = scope.ngModel();
          if (modelValue === undefined) {
            return '';
          }
          return scope.focus ? modelValue : (scope.prefix + modelValue + scope.suffix);
        } else {
          var body = val.replace(scope.prefix, '').replace(scope.suffix, '');
          var num = parseFloat(body.replace(',', '')) || 0;
          scope.ngModel(num);
        }
      };

      if (scope.form !== undefined && scope.name !== undefined) {
        if (_.isNumber(scope.ngMin)) {
          scope.form[scope.name].$validators.min = function() {
            return scope.ngModel() >= scope.ngMin;
          };
        }
        if (_.isNumber(scope.ngMax)) {
          scope.form[scope.name].$validators.max = function() {
            return scope.ngModel() <= scope.ngMax;
          };
        }
      }
    }
  };
}
