'use strict';

angular
  .module('service.modals', [
    'ui.bootstrap',
    'ram',
    'controller.account.number',
    'controller.account.signin',
    'controller.modal.account-nickname',
    'controller.modal.change-new-account-flow-account-nickname',
    'controller.modal.advisor.confirm-account-approval',
    'controller.modal.advisor.add-account-note',
    'controller.modal.advisor.download-table',
    'controller.modal.advisor.confirm-cancel-transfer-instruction',
    'controller.modal.advisor.confirm-delete-last-prefill-for-client',
    'controller.modal.advisor.notification-subscription-modal',
    'controller.modal.advisor-change-client-group',
    'controller.modal.advisor-download-promotions-report',
    'controller.modal.advisor-modify-bankaccount-approval',
    'controller.modal.advisor-modify-document',
    'controller.modal.advisor-modify-risk',
    'controller.modal.advisor-modify-portfolio-options',
    'controller.modal.advisor-create-client-mobile-upload-link',
    'controller.modal.advisor-id-verify-confirm',
    'controller.modal.appetite',
    'controller.modal.change-email',
    'controller.modal.confirm-risk-appetite-change',
    'controller.modal.csrf-error-handler',
    'controller.account.delete',
    'controller.modal.grant-fee-credit',
    'controller.modal.invite-client',
    'controller.modal.mailingList',
    'controller.modal.review',
    'controller.modal.schedule-call',
    'controller.modal.transfer',
    'controller.modal.user-promotion-redemptions',
    'controller.modal.incomplete-account',
    'controller.modal.incomplete-account-transfer',
    'controller.modal.error-modal',
    'controller.modal.transfer-cancel-confirmation-modal',
    'controller.modal.generic-confirmation-modal',
    'controller.modal.activate-two-factor-authentication',
    'controller.modal.deactivate-authenticator-app',
    'controller.modal.two-factor-authentication',
    'controller.modal.wsi.review',
    'model.Question',
    'model.Account',
    'model.BankAccount',
    'model.AdvisorCreateNewAccountFlow',
    'model.ModelPortfolio',
    'model.RiskAppetiteQuiz',
    'service.adaptive-views',
    'service.riskAppetiteFieldNames',
    'service.two-factor-authenticator',
    'controller.modal.advisor.application-status',
    'controller.modal.advisor.resend-forms'
  ])
  .factory('modals', [
    '$uibModal',
    '$q',
    '$rootScope',
    'Question',
    'Account',
    'BankAccount',
    'AdvisorCreateNewAccountFlow',
    'RiskAppetiteQuiz',
    'ModelPortfolio',
    '$analytics',
    'riskAppetiteFieldNames',
    '$http',
    'adaptiveViews',
    'twoFactorAuthenticator',
    modalFactory
  ]);

function modalFactory(
  $modal,
  $q,
  $rootScope,
  Question,
  Account,
  BankAccount,
  AdvisorCreateNewAccountFlow,
  RiskAppetiteQuiz,
  ModelPortfolio,
  $analytics,
  riskAppetiteFieldNames,
  $http,
  adaptiveViews,
  twoFactorAuthenticator) {

  function fnWrap(value) {
    return function() {
      return value;
    };
  }

  function isMobile() {
    return adaptiveViews.isMobile();
  }

  function accountNumberModal(account) {
    return $modal.open({
      controller: 'AccountNumberCtrl',
      templateUrl: 'modals/account-number.html',
      resolve: {
        account: function() {
          return account;
        }
      }
    });
  }

  function deactivateClientTwoFA() {
    return $modal.open({
      templateUrl: 'modals/deactivate-client-two-factor-authentication.html'
    });
  }

  function openAccountTypeHelpModal() {
    $analytics.eventTrack('viewed-account-type-help-modal');
    return $modal.open({
      templateUrl: 'modals/account-type-help.html',
      size: 'lg'
    });
  }

  function appetiteModal(options) {
    $analytics.eventTrack('viewed-appetite-modal');

    var mobileClass = isMobile() ? 'mobile-appetite-modal mobile-fullscreen-modal' : 'appetite-modal';
    return $modal.open({
      templateUrl: adaptiveViews.getTemplateUrlForSrc('modals/appetite.html', true),
      controller: 'AppetiteCtrl',
      backdrop: isMobile() ? false : 'static',
      keyboard: false,
      windowClass: mobileClass,
      resolve: {
        riskAppetiteQuiz: function() {
          return RiskAppetiteQuiz.active();
        },
        options: function() {
          return options;
        }
      }
    });
  }

  function bankModal() {
    $analytics.eventTrack('viewed-bank-modal');
    return $modal.open({
      templateUrl: 'modals/bank.html',
      windowClass: 'single-button-modal ok-modal'
    });
  }

  function csrfErrorModal() {
    return $modal.open({
      templateUrl: 'modals/csrf-error-handler.html',
      controller: 'ModalCsrfErrorCtrl',
      backdrop: 'static',
      keyboard: false
    });
  }

  function otherUserLoggedInModal(fullName) {
    return $modal.open({
      templateUrl: 'modals/other-user-logged-in-error.html',
      backdrop: 'static',
      keyboard: false,
      controller: ['$scope', function($scope) {
        $scope.fullName = fullName;
      }]
    });
  }

  function advisorUserLoggedInModal(fullName) {
    return $modal.open({
      templateUrl: 'modals/advisor-user-logged-in-error.html',
      backdrop: 'static',
      keyboard: false,
      controller: ['$scope', function($scope) {
        $scope.fullName = fullName;
      }]
    });
  }

  function deleteApplicationModal(incompleteAccountId) {
    return $modal.open({
      controller: 'DeleteAccountCtrl',
      templateUrl: 'modals/delete-application.html',
      backdrop: 'static',
      resolve: {
        account: ['Account', function(Account) {
          return Account.find({
            id: incompleteAccountId
          });
        }]
      }
    });
  }

  function disclaimerModal() {
    return $modal.open({
      templateUrl: 'modals/disclaimer.html'
    });
  }

  function goalReviewModal(account, goal, portfolioId, user, questions, toBeStacked, investmentsChartData) {
    $analytics.eventTrack('viewed-goal-modal');
    return $modal.open({
      templateUrl: adaptiveViews.getTemplateUrlForSrc('modals/review.html', true),
      controller: 'modalReviewCtrl',
      windowClass: 'goal-review-modal',
      resolve: {
        account: fnWrap(account),
        goal: fnWrap(goal),
        portfolio: function() {
          return ModelPortfolio.find({
            id: portfolioId
          });
        },
        user: fnWrap(user),
        questions: fnWrap(questions),
        toBeStacked: fnWrap(toBeStacked),
        investmentsChartData: fnWrap(investmentsChartData)
      },
      size: 'lg',
      backdrop: 'static'
    });
  }

  function wsiGoalReviewModal(account, goal, portfolioId, user, questions, toBeStacked, investmentsChartData, riskAbility) {
    $analytics.eventTrack('viewed-goal-modal');
    return $modal.open({
      templateUrl: adaptiveViews.getTemplateUrlForSrc('modals/wsi/review.html', true),
      controller: 'modalWsiReviewCtrl',
      windowClass: 'goal-review-modal',
      resolve: {
        account: fnWrap(account),
        goal: fnWrap(goal),
        portfolio: function() {
          return ModelPortfolio.find({
            id: portfolioId
          });
        },
        user: fnWrap(user),
        questions: fnWrap(questions),
        toBeStacked: fnWrap(toBeStacked),
        investmentsChartData: fnWrap(investmentsChartData),
        riskAbility: fnWrap(riskAbility)
      },
      size: 'lg',
      backdrop: 'static'
    });
  }

  function accountModifyReviewModal(account, balance, goal, portfolio, user, questions, toBeStacked, investmentsChartData) {
    $analytics.eventTrack('viewed-account-modify-review-modal');
    return $modal.open({
      templateUrl: adaptiveViews.getTemplateUrlForSrc('modals/account-modify-review.html', true),
      controller: 'modalReviewCtrl',
      windowClass: 'goal-review-modal',
      resolve: {
        account: fnWrap(account),
        balance: fnWrap(balance),
        goal: fnWrap(goal),
        portfolio: fnWrap(portfolio),
        user: fnWrap(user),
        questions: fnWrap(questions),
        toBeStacked: fnWrap(toBeStacked),
        investmentsChartData: fnWrap(investmentsChartData)
      },
      size: 'lg',
      backdrop: 'static'
    });
  }

  function genericMessageModal(messageTitle, messageBody) {
    return $modal.open({
      templateUrl: 'modals/generic-message.html',
      resolve: {
        messageTitle: function() {
          return messageTitle;
        },
        messageBody: function() {
          return messageBody;
        }
      },
      backdrop: 'static',
      controller: ['$scope', 'messageTitle', 'messageBody', function($scope, messageTitle, messageBody) {
        $scope.messageTitle = messageTitle;
        $scope.messageBody = messageBody;
      }]
    });
  }

  function genericOkModal(messageTitle, messageBody) {
    return $modal.open({
      templateUrl: 'modals/generic-ok.html',
      resolve: {
        messageTitle: function() {
          return messageTitle;
        },
        messageBody: function() {
          return messageBody;
        }
      },
      backdrop: 'static',
      controller: ['$scope', 'messageTitle', 'messageBody', function($scope, messageTitle, messageBody) {
        $scope.messageTitle = messageTitle;
        $scope.messageBody = messageBody;
      }]
    });
  }

  function scheduleCallModal(hubspotUrl) {
    // mob-sched-call-modal necessary to override other modal defaults
    var mobileClass = isMobile() ? 'mobile mob-sched-call-modal' : '';
    return $modal.open({
      backdrop: 'static',
      controller: 'modalScheduleCallCtrl',
      templateUrl: 'modals/schedule-call.html',
      windowClass: 'sched-call-modal ' + mobileClass,
      resolve: {
        hubspotUrl: function() {
          return hubspotUrl;
        }
      }
    });
  }

  function serverErrorMessageModal() {
    return $modal.open({
      templateUrl: 'modals/server-error-modal.html',
      backdrop: 'static'
    });
  }

  function transferConfirmationModal(transferInstruction, transferDisplayData) {
    $analytics.eventTrack('viewed-withdrawal-modal');
    return $modal.open({
      templateUrl: 'modals/transfer.html',
      controller: 'modalTransferCtrl',
      backdrop: 'static',
      resolve: {
        transferInstruction: function() {
          return transferInstruction;
        },
        transferDisplayData: function() {
          return transferDisplayData;
        }
      }
    });
  }

  function transferCancelConfirmationModal(transferInstruction) {
    return $modal.open({
      templateUrl: 'modals/transfer-cancel-confirmation-modal.html',
      controller: 'modalCancelTransferCtrl',
      backdrop: 'static',
      resolve: {
        transferInstruction: function() {
          return transferInstruction;
        }
      },
      windowClass: 'cancel-transfer-modal'
    });
  }

  function transferSendConfirmationModal() {
    return $modal.open({
      templateUrl: 'modals/transfer-send-confirmation-modal.html',
      backdrop: 'static'
    });
  }

  function transferAdvisorTicketSentConfirmationModal() {
    return $modal.open({
      templateUrl: 'modals/transfer-advisor-ticket-sent-confirmation-modal.html',
      backdrop: 'static'
    });
  }

  function genericConfirmationModal(title, body, acceptButtonClass, acceptButtonText, cancelText, otherButtonText) {
    return $modal.open({
      templateUrl: 'modals/generic-confirmation-modal.html',
      controller: 'modalGenericConfirmationCtrl',
      backdrop: 'static',
      resolve: {
        title: function() {
          return title;
        },
        body: function() {
          return body;
        },
        acceptButtonClass: function() {
          return acceptButtonClass;
        },
        acceptButtonText: function() {
          return acceptButtonText;
        },
        cancelText: function() {
          return cancelText;
        },
        otherButtonText: function() {
          return otherButtonText;
        }
      },
      windowClass: 'generic-confirmation-modal'
    });
  }

  function homeVideoModal() {
    $analytics.eventTrack('viewed-home-video');
    return $modal.open({
      templateUrl: 'modals/home-video.html',
      size: 'lg'
    });
  }

  function openTrialRegistrationModal() {
    $analytics.eventTrack('viewed-trial-modal');
    return $modal.open({
      templateUrl: 'modals/trial-registration.html',
      controller: 'accountRegistrationCtrl',
      size: 'lg',
      resolve: {
        mode: function() {
          return {
            accountType: 'trial'
          };
        }
      }
    });
  }

  function openSigninModal() {
    return $modal.open({
      templateUrl: 'modals/signin.html',
      controller: 'AccountSigninCtrl'
    });
  }

  function openChangeEmailModal() {
    return $modal.open({
      templateUrl: 'modals/change-email.html',
      controller: 'ModalChangeEmailCtrl'
    });
  }

  function rejectApplicationModal() {
    return $modal.open({
      templateUrl: 'modals/reject-application.html',
      backdrop: 'static'
    });
  }

  function riskModal() {
    $analytics.eventTrack('viewed-risk-modal');
    return $modal.open({
      templateUrl: 'modals/risk.html'
    });
  }

  function securityModal() {
    $analytics.eventTrack('viewed-security-modal');
    return $modal.open({
      templateUrl: 'modals/security.html'
    });
  }

  function userPromotionRedemptionsModal(userId) {
    return $modal.open({
      templateUrl: 'modals/user-promotion-redemptions.html',
      controller: 'modalUserPromotionRedemptionsCtrl',
      resolve: {
        userId: function() {
          return userId;
        },
      }
    });
  }

  function advisorModifyAccountRiskModal(account) {
    return $modal.open({
      templateUrl: 'modals/advisor-modify-account-risk.html',
      controller: 'ModalAdvisorModifyAccountRiskCtrl',
      resolve: {
        account: function() {
          return account;
        },
        goal: function() {
          return account.accountGoals()[0].goal();
        }
      }
    });
  }

  function advisorModifyRebalancingOptionModal(skipRebalancing) {
    return $modal.open({
      templateUrl: 'modals/advisor-modify-rebalancing-modal.html',
      controller: ['$scope', 'skipRebalancing',
        function($scope, skipRebalancing) {
          $scope.skipRebalancing = skipRebalancing;
        }
      ],
      resolve: {
        skipRebalancing: function() {
          return skipRebalancing;
        }
      }
    });
  }

  function advisorModifyBankApprovalModal(bankAccount, action) {
    return $modal.open({
      templateUrl: 'modals/advisor-modify-bankaccount-approval-modal.html',
      controller: 'ModalAdvisorModifyBankaccountApproval',
      resolve: {
        bankAccount: function() {
          return bankAccount;
        },
        action: function() {
          return action;
        }
      }
    });
  }

  function advisorModifyClientNameOverrideModal(account) {
    return $modal.open({
      templateUrl: 'modals/advisor-set-client-name-override-modal.html',
      controller: ['$scope', 'ram', 'account',
        function($scope, ram, account) {
          $scope.value = new ram.Accessor(account.clientNameOverride());
          $scope.save = function() {
            account.clientNameOverride($scope.value());
          };
        }
      ],
      resolve: {
        account: function() {
          return account;
        }
      }
    });
  }

  function advisorModifyPortfolioOptionsModal(account) {
    return $modal.open({
      templateUrl: 'modals/advisor-modify-portfolio-options.html',
      controller: 'ModalAdvisorModifyPortfolioOptionsCtrl',
      resolve: {
        account: function() {
          return account;
        }
      }
    });
  }

  function advisorNotificationSubscriptionModal(currentUser) {
    return $modal.open({
      templateUrl: 'modals/advisor-notification-subscription.html',
      controller: 'ModalNotificationSubscriptionCtrl',
      resolve: {
        currentUser: function() {
          return currentUser;
        },
        currentUserSubscriptions: ['AdvisorNotificationSubscription', resolveCurrentUserSubscriptions]
      }
    });
  }

  function resolveCurrentUserSubscriptions(AdvisorNotificationSubscription) {
    return AdvisorNotificationSubscription.load().then(result => result.data);
  }

  function advisorModifyDocumentModal(document, documentTypes, attachToOptions) {
    return $modal.open({
      templateUrl: 'modals/advisor-modify-document.html',
      controller: 'ModalAdvisorModifyDocumentCtrl',
      resolve: {
        document: function() {
          return document;
        },
        documentTypes: function() {
          return documentTypes.filter((doc) => !doc.hidden);
        },
        attachToOptions: function() {
          return attachToOptions;
        },
        relatedTo: function() {
          var relatedTo = null;
          if (document.relatedToType() === 'BankAccount') {
            relatedTo = BankAccount.find({
              id: document.relatedToId()
            });
          } else if (document.relatedToType() === 'Account') {
            relatedTo = Account.find({
              id: document.relatedToId()
            });
          }
          return relatedTo;
        }
      }
    });
  }

  function advisorSendClientMobileUploadLink(clientId) {
    return $modal.open({
      templateUrl: 'modals/advisor-create-client-mobile-upload-link.html',
      controller: 'ModalAdvisorCreateClientMobileUploadLink',
      resolve: {
        advisor: function() {
          return $rootScope.currentUser;
        },
        clientId: function() {
          return clientId;
        }
      }
    });
  }

  function advisorIdVerifyConfirm(personId, documents) {
    return $modal.open({
      templateUrl: 'modals/advisor-id-verify-confirm.html',
      controller: 'ModalAdvisorIdVerifyConfirm',
      backdrop: 'static',
      keyboard: false,
      windowClass: 'advisor-id-verify',
      resolve: {
        personId: () => personId,
        documents: () => documents
      }
    });
  }

  function addAccountNote(application) {
    return $modal.open({
      templateUrl: 'modals/advisor/add-account-note.html',
      controller: 'ModalAddAccountNoteCtrl',
      backdrop: 'static',
      resolve: {
        account: application.accountReadModel().account()
      }
    });
  }

  function downloadTable(filterParam) {
    return $modal.open({
      templateUrl: 'modals/advisor/download-table.html',
      controller: 'ModalDownloadTableCtrl',
      backdrop: 'static',
      resolve: {
        filterParam
      }
    });
  }

  function affiliateSignupRewardClientNotifyModal(affiliateName, affiliatePromotionDescription) {
    return $modal.open({
      backdrop: 'static',
      templateUrl: 'modals/affiliate-signup-reward-client-modal.html',
      controller: ['$scope', 'affiliateName', 'affiliatePromotionDescription',
        function(
          $scope, affiliateName, affiliatePromotionDescription
        ) {
          $scope.affiliateName = affiliateName;
          $scope.affiliatePromotionDescription = affiliatePromotionDescription;
        }
      ],
      resolve: {
        affiliateName: function() {
          return affiliateName;
        },
        affiliatePromotionDescription: function() {
          return affiliatePromotionDescription;
        }
      }
    });
  }

  function advisorSignupRewardClientNotifyModal(templateUrl, referralUrl, advisorFirstName, advisorFullName, advisorOffice, promotionDescription) {
    return $modal.open({
      backdrop: 'static',
      templateUrl: templateUrl || 'modals/referral-signup-reward-client-modal.html',
      controller: ['$scope',
        function($scope) {
          $scope.advisorFirstName = advisorFirstName;
          $scope.advisorFullName = advisorFullName;
          $scope.advisorNameAtOffice = _.isEmpty(advisorOffice) ? advisorFullName : advisorFullName + ' at ' + advisorOffice;
          $scope.promotionDescription = promotionDescription;
          $scope.referralArrangementUrl = referralUrl;
        }
      ]
    });
  }

  function referralRewardClientModal(referralCode) {
    return $modal.open({
      backdrop: 'static',
      templateUrl: 'modals/referral-reward-client-modal.html',
      controller: ['$scope', 'referrerName',
        function($scope, referrerName) {
          $scope.referrerName = referrerName;
        }
      ],
      resolve: {
        referrerName: function() {
          return $http.get('api/users/referrer_name.json', {
              params: {
                referralCode: referralCode
              }
            })
            .then(function(response) {
              return response.data.referrerName;
            });
        }
      }
    });
  }

  function unsavedChangesModal(oldFormName) {
    return $modal.open({
      templateUrl: 'modals/unsaved-changes.html',
      keyboard: false,
      backdrop: 'static',
      windowClass: 'unsaved-changes-modal',
      controller: ['$scope', 'oldFormName', function($scope, oldFormName) {
        $scope.oldFormName = oldFormName;
      }],
      resolve: {
        oldFormName: function() {
          return oldFormName;
        }
      }
    });
  }

  function resolveAdvisorNewAccountFlow() {
    var $cookies = angular.injector(['ngCookies']).get('$cookies');
    var id = $cookies.get('currentAdvisorNewAccountFlow');
    if (id) {
      var deferred = $q.defer();
      AdvisorCreateNewAccountFlow.find({
        id: id
      }).then(function(response) {
        deferred.resolve(response);
      }, function() {
        $cookies.remove('currentAdvisorNewAccountFlow');
        deferred.resolve();
      });
      return deferred.promise;
    }
  }

  function resolveStoredAccount(Account) {
    return Account.findOrCreate({
      // Use no fields here.  Just want a basic object with id:1
    }, {
      resource: 'cookie',
      cache: false
    });
  }

  function incompleteAccountConfirmation(account, user) {
    return $modal.open({
      templateUrl: 'modals/incomplete-account-confirmation.html',
      controller: 'ModalIncompleteAccountCtrl',
      resolve: {
        account: fnWrap(account),
        user: fnWrap(user),
        advisorNewAccountFlow: resolveAdvisorNewAccountFlow,
        storedAccount: ['Account', resolveStoredAccount]
      },
      keyboard: false,
      backdrop: 'static',
      size: 'lg',
      windowClass: 'incomplete-account-modal'
    });
  }

  function incompleteAccountTransfer(toState, toParams, userWantsToLeaveCallback) {
    return $modal.open({
      controller: 'ModalIncompleteAccountTransferCtrl',
      resolve: {
        toState: toState,
        toParams: toParams,
        userWantsToLeaveCallback: userWantsToLeaveCallback
      },
      templateUrl: 'modals/incomplete-account-transfer.html'
    });
  }

  function errorModal(title, message) {
    return $modal.open({
      templateUrl: 'modals/error-modal.html',
      controller: 'ErrorModalController',
      resolve: {
        title: fnWrap(title),
        message: fnWrap(message)
      },
      keyboard: false,
      backdrop: 'static',
      windowClass: 'error-modal'
    });
  }

  function confirmAccountApproval() {
    return $modal.open({
      templateUrl: 'modals/advisor/confirm-account-approval.html',
      controller: 'ModalAdvisorConfirmAccountApprovalCtrl'
    });
  }

  function confirmDeleteLastPrefillForClient() {
    return $modal.open({
      templateUrl: 'modals/advisor/confirm-delete-last-prefill-for-client.html',
      controller: 'ConfirmDeleteLastPrefillForClient'
    });
  }

  function advisorConfirmCancelTransferInstruction(transferInstruction) {
    return $modal.open({
      templateUrl: 'modals/advisor/confirm-cancel-transfer-instruction.html',
      controller: 'ModalAdvisorConfirmCancelTransferInstructionCtrl',
      resolve: {
        transferInstruction: function() {
          return transferInstruction;
        }
      }
    });
  }

  function confirmRiskAppetiteChangeModal(currentUser, oldInvestorType) {
    return $modal.open({
      templateUrl: 'modals/confirm-risk-appetite-change.html',
      controller: 'ModalConfirmRiskAppetiteChangeCtrl',
      resolve: {
        accounts: function() {
          var accounts;
          return currentUser.accounts.ready()
            .then(function(_accounts) {
              var acceptableAccountStatusesForRiskLevelChange = ['open', 'approved', 'pending', 'started'];
              var accountsWithAcceptableStatuses = _.filter(_accounts, function(account) {
                return _.contains(acceptableAccountStatusesForRiskLevelChange, account.status().name);
              });
              accounts = accountsWithAcceptableStatuses;
              return $q.all(_.map(accounts, function(account) {
                return account.accountGoal.ready();
              }));
            })
            .then(function(accountGoals) {
              return $q.all(_.map(accountGoals, function(accountGoal) {
                return accountGoal.goal.ready();
              }));
            })
            .then(function() {
              return _.filter(accounts, function(account) {
                return account.accountGoal().goal().advisorRiskOffset() === 0;
              });
            });
        },
        oldInvestorType: function() {
          return oldInvestorType;
        }
      }
    });
  }

  function inviteClientModal(inviteLink) {
    return $modal.open({
      resolve: {
        inviteLink: function() {
          return inviteLink;
        }
      },
      controller: 'ModalInviteClientCtrl',
      templateUrl: 'modals/advisor/external/invite-client.html',
      windowClass: 'invite-client-modal'
    });
  }

  function activateTwoFactorAuthenticationModal() {
    return $modal.open({
      templateUrl: 'modals/activate-two-factor-authentication.html',
      controller: 'ModalActivateTwoFactorAuthenticationController',
      resolve: {
        isMobile: isMobile
      },
      windowClass: 'modal-br-0'
    });
  }

  function deactivateAuthenticatorAppModal() {
    return $modal.open({
      templateUrl: 'modals/deactivate-authenticator-app.html',
      controller: 'ModalDeactivateAuthenticatorAppController',
      resolve: {
        isMobile: isMobile
      },
      windowClass: 'modal-br-0'
    });
  }

  function twoFactorAuthenticationModal(validateCodeAction) {
    return $modal.open({
      templateUrl: 'modals/two-factor-authentication.html',
      controller: 'ModalTwoFactorAuthenticationCtrl',
      resolve: {
        authenticationFactor: function() {
          if ($rootScope.currentUser) {
            return $rootScope.currentUser.authenticationFactor.ready();
          } else {
            return twoFactorAuthenticator.getSanitizedDetails()
              .then(
                function(sanitizedDetails) {
                  return sanitizedDetails;
                });
          }
        },
        isMobile: isMobile,
        validateCodeAction: function() {
          return validateCodeAction;
        }
      },
      windowClass: 'modal-br-0'
    });
  }

  function upgradeAppModal() {
    return $modal.open({
      templateUrl: 'modals/upgrade-app.html',
      windowClass: 'modal-br-0'
    });
  }

  function accountNicknameModal(account) {
    return $modal.open({
      controller: 'accountNicknameCtrl',
      resolve: {
        account: account
      },
      templateUrl: 'modals/account-nickname.html',
      windowClass: 'modal-br-0'
    });
  }

  function changeNewAccountFlowAccountNicknameModal(newAccountFlow) {
    return $modal.open({
      controller: 'changeNewAccountFlowAccountNicknameCtrl',
      resolve: {
        newAccountFlow: newAccountFlow
      },
      templateUrl: 'modals/change-new-account-flow-account-nickname.html',
      windowClass: 'modal-br-0'
    });
  }

  function grantFeeCreditModal(client, feeCreditAccount) {
    return $modal.open({
      controller: 'grantFeeCreditCtrl',
      resolve: {
        client: client,
        feeCreditAccount: feeCreditAccount
      },
      templateUrl: 'modals/grant-fee-credit.html'
    });
  }

  function advisorChangeClientGroupModal(client) {
    let modalInstance = $modal.open({
      controller: 'advisorChangeClientGroupModalController',
      resolve: {
        client: client,
        currentAdvisor: function() {
          return $http.get('/api/people/' + client.person().id + '/get_relationship_manager');
        },
        allAdvisors: function() {
          return $http.get('/api/advisor/relationship_advisor_list');
        },
        pendingAdvisor: function() {
          return $http.get('/api/client_advisor_assignment_flows/' + client.id + '.json');
        },
      },
      templateUrl: 'modals/advisor-change-client-group.html'
    });
    modalInstance.result.finally(function() {
      $http.get('/api/client_advisor_assignment_flows/' + client.id + '.json')
        .then(function(response) {
          $rootScope.pendingAdvisor = response.data;
        });
    });
    return modalInstance;
  }

  function advisorDownloadPromotionsReportModal() {
    return $modal.open({
      controller: 'advisorDownloadPromotionsReportController',
      resolve: {},
      templateUrl: 'modals/advisor-download-promotions-report-modal.html'
    });
  }

  function applicationStatusModal(accountReadModel, applicationReadModel, advisorCreateNewClientFlow) {
    return $modal.open({
      controller: 'ModalAdvisorApplicationsStatusCtrl',
      templateUrl: 'modals/advisor/application-status.html',
      backdrop: 'static',
      resolve: {
        accountReadModel: accountReadModel,
        applicationReadModel: applicationReadModel,
        clientFlow: advisorCreateNewClientFlow
      }
    });
  }


  function resendFormsModal(accountReadModel, applicationReadModel, advisorCreateNewClientFlow) {
    return $modal.open({
      controller: 'ModalAdvisorResendFormsCtrl',
      templateUrl: 'modals/advisor/resend-forms.html',
      backdrop: 'static',
      resolve: {
        accountReadModel: accountReadModel,
        applicationReadModel: applicationReadModel,
        clientFlow: advisorCreateNewClientFlow
      }
    });
  }

  function lowFeesModal() {
    return $modal.open({
      templateUrl: 'modals/low-fees-notification.html',
      backdrop: 'static',
      resolve: {}
    });
  }

  return {
    accountModifyReviewModal: accountModifyReviewModal,
    accountNicknameModal: accountNicknameModal,
    changeNewAccountFlowAccountNicknameModal: changeNewAccountFlowAccountNicknameModal,
    accountNumberModal: accountNumberModal,
    activateTwoFactorAuthenticationModal: activateTwoFactorAuthenticationModal,
    advisorChangeClientGroupModal: advisorChangeClientGroupModal,
    advisorConfirmCancelTransferInstruction: advisorConfirmCancelTransferInstruction,
    advisorDownloadPromotionsReportModal: advisorDownloadPromotionsReportModal,
    advisorIdVerifyConfirm: advisorIdVerifyConfirm,
    addAccountNote,
    downloadTable,
    advisorModifyAccountRiskModal: advisorModifyAccountRiskModal,
    advisorModifyDocumentModal: advisorModifyDocumentModal,
    advisorModifyPortfolioOptionsModal: advisorModifyPortfolioOptionsModal,
    advisorNotificationSubscriptionModal: advisorNotificationSubscriptionModal,
    advisorSendClientMobileUploadLink: advisorSendClientMobileUploadLink,
    advisorSignupRewardClientNotifyModal: advisorSignupRewardClientNotifyModal,
    advisorModifyRebalancingOptionModal: advisorModifyRebalancingOptionModal,
    advisorModifyBankApprovalModal: advisorModifyBankApprovalModal,
    advisorModifyClientNameOverrideModal: advisorModifyClientNameOverrideModal,
    affiliateSignupRewardClientNotifyModal: affiliateSignupRewardClientNotifyModal,
    appetiteModal: appetiteModal,
    bankModal: bankModal,
    confirmAccountApproval: confirmAccountApproval,
    confirmDeleteLastPrefillForClient: confirmDeleteLastPrefillForClient,
    confirmRiskAppetiteChangeModal: confirmRiskAppetiteChangeModal,
    csrfErrorModal: csrfErrorModal,
    deactivateAuthenticatorAppModal: deactivateAuthenticatorAppModal,
    deactivateClientTwoFA: deactivateClientTwoFA,
    deleteApplicationModal: deleteApplicationModal,
    disclaimerModal: disclaimerModal,
    errorModal: errorModal,
    genericMessageModal: genericMessageModal,
    genericOkModal: genericOkModal,
    goalReviewModal: goalReviewModal,
    wsiGoalReviewModal: wsiGoalReviewModal,
    grantFeeCreditModal: grantFeeCreditModal,
    homeVideoModal: homeVideoModal,
    incompleteAccountConfirmation: incompleteAccountConfirmation,
    incompleteAccountTransfer: incompleteAccountTransfer,
    inviteClientModal: inviteClientModal,
    openAccountTypeHelpModal: openAccountTypeHelpModal,
    openChangeEmailModal: openChangeEmailModal,
    openSigninModal: openSigninModal,
    openTrialRegistrationModal: openTrialRegistrationModal,
    otherUserLoggedInModal: otherUserLoggedInModal,
    advisorUserLoggedInModal: advisorUserLoggedInModal,
    referralRewardClientModal: referralRewardClientModal,
    rejectApplicationModal: rejectApplicationModal,
    riskModal: riskModal,
    scheduleCallModal: scheduleCallModal,
    securityModal: securityModal,
    serverErrorMessageModal: serverErrorMessageModal,
    transferAdvisorTicketSentConfirmationModal: transferAdvisorTicketSentConfirmationModal,
    transferCancelConfirmationModal: transferCancelConfirmationModal,
    transferConfirmationModal: transferConfirmationModal,
    transferSendConfirmationModal: transferSendConfirmationModal,
    genericConfirmationModal: genericConfirmationModal,
    twoFactorAuthenticationModal: twoFactorAuthenticationModal,
    unsavedChangesModal: unsavedChangesModal,
    upgradeAppModal: upgradeAppModal,
    userPromotionRedemptionsModal: userPromotionRedemptionsModal,
    applicationStatusModal: applicationStatusModal,
    resendFormsModal:resendFormsModal,
    lowFeesModal: lowFeesModal
  };
}
