'use strict';

angular.module('service.branding-machine', [])
  .provider('brandingMachine', [
    brandingMachineProvider
  ]);

function brandingMachineProvider() {
  /* jshint validthis: true */

  const $cookies = angular.injector(['ngCookies']).get('$cookies');
  const $http = angular.injector(['ng']).get('$http');
  const $q = angular.injector(['ng']).get('$q');

  this.getAdvisorDetails = function(currentUser, advisorReferralCode) {
    const deferred = $q.defer();
    let externalAdvisorLink = '';

    if (advisorReferralCode) {
      externalAdvisorLink = advisorReferralCode;
    } else if (!currentUser && $cookies.getObject('group_url_id')) {
      externalAdvisorLink = $cookies.getObject('group_url_id').urlId;
    } else if (currentUser && currentUser.groupUrlId()) {
      externalAdvisorLink = currentUser.groupUrlId();
    }

    let logoImage = 'assets/images/logo-wb.png';

    if (currentUser && currentUser.brandingLogoUrl()) {
      logoImage = currentUser.brandingLogoUrl();
    }

    const defaultResult = {
      // Same as routes/interview.js used to have {}
      branding: {
        logoImage, // Same as branding-service.js used to have at the bottom.
        logoClass: 'logo',
        cobrandLogoImage: ''
      }
    };

    if (externalAdvisorLink.length === 0) {
      deferred.resolve(defaultResult);
      return deferred.promise;
    }

    $http
      .get('/api/advisor_referral_details/' + externalAdvisorLink + '.json')
      .then(payload => deferred.resolve(_.camelizeKeys(payload.data)))
      .catch(() => deferred.resolve(defaultResult));
    return deferred.promise;
  };

  this.$get = function() {
    return {
      getBranding: this.getBranding
    };
  };
}
