'use strict';

import React from 'react'; // jshint ignore:line
import maReact2Angular from '../maReact2Angular';
import UploadMutualFundTrades from './UploadMutualFundTrades';

angular.module('react.component.advise.upload-mutual-fund-trades', [
    'config',
    'csrf',
    'service.user-notifications',
  ])
  .component('reactUploadMutualFundTrades',
    maReact2Angular(UploadMutualFundTrades, {},
      ['csrfToken', 'userNotifications']));
