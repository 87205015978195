'use strict';

/**
 * Returns an object with the property (`to`) defined. `to` is a function
 * that accepts a redirect state or path as an argument and returns an object
 * with the properties `when` and `always`. `when` is a function that accepts
 * a condition function as its only argument. If the condition evaluates true,
 * the preceding redirect is followed. `always` is a function that follows
 * the associated redirect immediately (and breaks chainability).
 *
 * The idea is to provide a fluent interface for easily specifying redirects.
 *
 * e.g. redirect.to('advise').if(userIsAdvisor).to('dashboard').always();
 *
 * @param  {Object} $state    UI Router $state provider
 * @param  {Object} $window   Angular window provider
 * @return {Object}           Redirector service factory
 */
function redirectorFactory($state, $window, $q) {
  return {
    create: function($currentState) {
      if ($currentState) {
        $state = $currentState;
      }

      function deadChain() {
        return {
          to: function() {
            return {
              when: deadChain,
              always: angular.noop
            };
          }
        };
      }

      function liveChain() {
        return {
          to: function(route) {
            function satisfied() {
              $q.when($state.transition)
                .then(function() {
                  if ($state.get(route)) {
                    $state.go(route);
                  } else {
                    $window.location.href = route;
                  }
                });
              return deadChain();
            }

            return {
              when: function(condition) {
                var value = condition();
                if (value) {
                  return satisfied();
                } else {
                  return liveChain();
                }
              },
              always: function() {
                return satisfied();
              }
            };
          }
        };
      }

      return liveChain();
    }
  };
}

function navigationServiceFactory(redirectorFactory, $state, $cookies, accountService, adaptiveViews, $rootScope, modals, KycUpdate, securityServiceFactory) {
  var isApp = adaptiveViews.isApp();
  var statesBlacklist = [/^dashboard\..*$/, /^profile$/, /^interview\..*$/, /^onboarding\..*$/];
  var redirect = redirectorFactory.create($state);

  var isStateBlacklisted = function(state) {
    if (!state) {
      return false;
    }

    return _.any(statesBlacklist, function(blacklistedState) {
      return state.name && state.name.match(blacklistedState);
    });
  };

  var kycDeadlineUnsubscribe = null;
  var removeStateBlacklisting = function() {
    if (kycDeadlineUnsubscribe) {
      _.each(kycDeadlineUnsubscribe, function(unsubscribe) {
        unsubscribe();
      });
      kycDeadlineUnsubscribe = null;
    }
  };

  var pastDate = function(date) {
    return moment(date).diff(moment()) < 0;
  };

  var KycUpdateNavigator = function(kycUpdate) {
    this.kycUpdate = kycUpdate;
  };
  KycUpdateNavigator.prototype.pastDueDate = function() {
    return this.kycUpdate && pastDate(this.kycUpdate.dueDate());
  };
  KycUpdateNavigator.prototype.pastDeadline = function() {
    return this.kycUpdate && pastDate(this.kycUpdate.deadline());
  };
  KycUpdateNavigator.prototype.initNavigation = function() {
    if (!kycDeadlineUnsubscribe && this.pastDeadline()) {
      if (isStateBlacklisted($state.current)) {
        $state.go('kyc');
      }

      var enforceKyc = function($event, state) {
        if (isStateBlacklisted(state)) {
          $event.preventDefault();
          $rootScope.hideLoading();

          // show modal explaining that user cannot go anywhere else
          modals
            .errorModal('Please confirm your information', 'Since it\'s past the deadline for confirming your information you have to confirm it before continuing to requested page.')
            .result
            .then(function() {
              if ($state.current !== 'kyc') {
                $state.go('kyc');
              }
            });
        }
      };

      kycDeadlineUnsubscribe = [
        $rootScope.$on('$stateChangeStart', enforceKyc),
        $rootScope.$on('$stateChangeSuccess', enforceKyc)
      ];
    }
  };
  KycUpdateNavigator.prototype.perform = function(redirect) {
    if (this.pastDueDate()) {
      return redirect.to('kyc').always();
    }
    return redirect;
  };

  var REDIRECT_COOKIE_KEY = 'redirect_after_signin';
  var redirectUrlNavigator = {
    getRedirectUrl: function() {
      var redirectUrl = $cookies.get(REDIRECT_COOKIE_KEY);
      $cookies.remove(REDIRECT_COOKIE_KEY);

      return redirectUrl;
    },
    perform: function(redirect) {
      var redirectUrl = this.getRedirectUrl();

      return redirect.to(redirectUrl).when(function() {
        return !!redirectUrl;
      });
    }
  };

  var DefaultNavigator = function(currentUser, accounts) {
    this.currentUser = currentUser;
    this.accounts = accounts;
  };

  DefaultNavigator.prototype.perform = function(redirect) {
    var _this = this;
    var newState = accountService.getNavigationState(_this.accounts, _this.currentUser) || 'investment/goal';
    var security = securityServiceFactory.create(_this.currentUser);

    return redirect
      .to('advise.overview').when(function() {
        return security.can('view_advisor_dashboard');
      })
      .to('dashboard.summary').when(function() {
        return accountService.hasCompletedAccount(_this.accounts);
      })
      .to(newState).always();
  };

  return {
    initUserNavigation: function(currentUser) {
      // isClient here is property, not a function, since the currentUser taken from Auth.currentUser() is simple
      // object, not ram entity from User.js
      // Currently we don't support KYC updates on the mobile app, so don't redirect if on mobile app
      if (currentUser && !isApp) {
        KycUpdate
          .find({
            userId: currentUser.id,
            completed: false
          })
          .then(function(kycUpdate) {
            new KycUpdateNavigator(kycUpdate).initNavigation();
          }, angular.noop);
      }
    },
    onKycCompleted: function() {
      removeStateBlacklisting();
    },
    onUserSignedOut: function() {
      removeStateBlacklisting();
    },
    onUserSignedIn: function(currentUser, accounts, kycUpdate) {
      if (!currentUser) {
        return;
      }

      if (currentUser.isItSupport()) {
        window.location.href = '/support';
        return;
      }

      var chain = [];
      if (currentUser.isClient()) {
        chain.push(new KycUpdateNavigator(kycUpdate));
      }
      chain.push(
        redirectUrlNavigator,
        new DefaultNavigator(currentUser, accounts)
      );

      var currentRedirect = redirect;
      _.each(chain, function(navigator) {
        currentRedirect = navigator.perform(currentRedirect);
      });

      return currentRedirect;
    }
  };
}

angular.module('service.navigation', [
    'service.account-service',
    'service.adaptive-views',
    'service.modals',
    'service.securityService',
    'ngCookies',
    'model.KycUpdate'
  ])
  .factory('redirectorFactory', [
    '$state',
    '$window',
    '$q',
    redirectorFactory
  ])
  .factory('navigationService', [
    'redirectorFactory',
    '$state',
    '$cookies',
    'accountService',
    'adaptiveViews',
    '$rootScope',
    'modals',
    'KycUpdate',
    'securityServiceFactory',
    navigationServiceFactory
  ]);
