'use strict';

angular.module('routes.landing', [
    'controller.landing.welcome',
    'templates',
    'ui.router'
  ])
  .config([
    '$stateProvider',
    configRoutes
  ]);

function configRoutes($stateProvider) {
  /**
   * Initialize angular UI router for landing.
   *
   * @param  {Object} $stateProvider API used to declare application states.
   */
  $stateProvider
    .state('landing', {
      parent: 'site',
      abstract: true,
      views: {
        'main': {},
        'navbar': {
          templateUrl: 'navbar/minimal.html'
        },
        'footer': {
          templateUrl: 'partials/footer-minimal.html'
        }
      }
    })
    .state('landing.welcome', {
      url: '/welcome?affiliateName&affiliatePromo&referrerName',
      data: {
        title: 'Welcome'
      },
      controller: 'WelcomeCtrl'
    });
}
