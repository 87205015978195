'use strict';

angular.module('service.recolor-funds', [
    'model.Fund'
  ])
  .factory('recolorFundsService', [
    'Fund',
    'colors',
    recolorFundsFactory
  ]);

function recolorFundsFactory(Fund, colors) {

  var colours = {
    stock: {
      dark: colors.purple[0],
      light: colors.purple[3]
    },
    fixedIncome: {
      dark: colors.green[0],
      light: colors.green[3]
    },
    otherFunds: {
      dark: colors.orange[0],
      light: colors.orange[3]
    }
  };

  function _convertHexColorToRGB(hexColor) {
    var rgb = {};
    rgb.r = parseInt(hexColor.substring(1, 3), 16);
    rgb.g = parseInt(hexColor.substring(3, 5), 16);
    rgb.b = parseInt(hexColor.substring(5), 16);
    return rgb;
  }

  // IE11 does not support the padStart method so we need to define our own
  // helper method.
  function _padHexNumberString(hexNumberString) {
    var paddedHexNumberString = hexNumberString;

    while (paddedHexNumberString.length < 2) {
      paddedHexNumberString = '0' + paddedHexNumberString;
    }

    return paddedHexNumberString;
  }

  function _convertRGBColorToHex(rgbColor) {
    return '#' + _padHexNumberString(rgbColor.r.toString(16)) +
      _padHexNumberString(rgbColor.g.toString(16)) +
      _padHexNumberString(rgbColor.b.toString(16));
  }

  function _recolorFundsEdgeCase(positions) {
    for (var key in colors) {
      var index = _.indexOf(colors[key], positions[0].fund().color);
      if (index === -1) {
        continue;
      } else if (index === 3) {
        positions[0].fund().color = colors[key][2];
        return;
      } else {
        positions[1].fund().color = colors[key][index + 1];
        return;
      }
    }
  }

  function _recolorFundsRegularCase(positions, colorDark, colorLight) {
    var len = positions.length;
    var colorDarkRGB = _convertHexColorToRGB(colorDark);
    var colorLightRGB = _convertHexColorToRGB(colorLight);
    var rDiff = Math.floor((colorLightRGB.r - colorDarkRGB.r) / (len - 1));
    var gDiff = Math.floor((colorLightRGB.g - colorDarkRGB.g) / (len - 1));
    var bDiff = Math.floor((colorLightRGB.b - colorDarkRGB.b) / (len - 1));
    for (var i = 0; i < len; i++) {
      var color = {};
      color.r = colorDarkRGB.r + i * rDiff;
      color.g = colorDarkRGB.g + i * gDiff;
      color.b = colorDarkRGB.b + i * bDiff;
      positions[i].fund().color = _convertRGBColorToHex(color);
    }
  }

  function recolorFundsBasedOnCategory(positions, category) {
    var filteredPositions = _.filter(positions, function(position) {
      return _.contains(Fund.constructor.assetClassIdsByColourCategory[category], position.fund().assetClassId());
    });
    var len = filteredPositions.length;
    if (len < 2) {
      return positions;
    }
    var colorDark = colours[category].dark;
    var colorLight = colours[category].light;
    if (len === 2 && colorDark === colorLight) {
      _recolorFundsEdgeCase(filteredPositions);
    } else {
      _recolorFundsRegularCase(filteredPositions, colorDark, colorLight);
    }
    return positions;
  }

  function recolorFunds(positions) {
    recolorFundsBasedOnCategory(positions, 'stock');
    recolorFundsBasedOnCategory(positions, 'fixedIncome');
    recolorFundsBasedOnCategory(positions, 'otherFunds');
    return positions;
  }

  return {
    recolorFunds: recolorFunds,
    recolorFundsBasedOnCategory: recolorFundsBasedOnCategory
  };
}
