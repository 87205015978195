'use strict';

angular.module('controller.iframe-widgets', [
    'controller.iframe-widgets.etfs',
    'controller.iframe-widgets.mutual-fund-fees-page',
    'controller.iframe-widgets.portfolio-performance',
    'service.intercom'
  ])
  .controller('iframeWidgetsCtrl', [
    '$interval',
    'intercom',
    iframeWidgetsController
  ]);

function iframeWidgetsController($interval, intercom) {
  intercom.shutdown();

  angular.element('body').addClass('ma-iframe');
  // The time inbetween intervals needs to be small enough so that the host
  // website's content can have a smooth transition animation when the iframe
  // changes height.
  $interval(sendScrollHeight, 10);
}

function sendScrollHeight() {
  // This is needed to reset the scrollHeight in cases where the page content
  // has decreased in height. Otherwise the scrollHeight will never decrease.
  document.body.style.height = 0;

  var messageData = {
    messageType: 'iframeWidgetHeight',
    height: document.body.scrollHeight,
  };

  window.parent.postMessage(JSON.stringify(messageData), '*');
}
