'use strict';

angular.module('controller.home.pricing', [
    'ui.bootstrap',
    'directive.input',
    'directive.plus-service-options',
    'ram',
    'service.fee-calculator'
  ])
  .controller('HomePricingCtrl', [
    '$scope',
    '$timeout',
    'feeCalculator',
    'ram',
    pricingController
  ]);

function pricingController($scope, $timeout, feeCalculator, ram) {

  $scope.initialInvestment = new ram.Accessor(10000);

  //calculates savings between our fund & mutual funds
  //based on their initial investment
  var getSavings = function(ourCost) {
    $scope.savings = $scope.mutualFundCost - ourCost;
  };

  $scope.$watch('initialInvestment()', function(val) {
    val = val || 0;
    $scope.mutualFundCost = feeCalculator.getAverageMutualFundCost(val);
    $scope.ourCost = feeCalculator.getFeeCost(val);
    $scope.acctLevel = feeCalculator.getFeeLevel(val);

    getSavings($scope.ourCost);
  });

}
