'use strict';

function accountCreator($rootScope, $state, $http, $q, Account, loadingIndicator) {

  function creatorWrapper($scope, user) {

    function handleError(result) {
      if (result && result.data) {
        $scope.errors = result.data.errors;

        if ($scope.errors && result.data.errors.base) {
          $scope.errorModal('Cannot create an account', _.first(result.data.errors.base));
        }
      }
      return $q.reject(result);
    }

    function redirectAfterSuccessfulCreation(isNewUser) {
      return function(account) {
        if (!account) {
          var title = 'Cannot create an account';
          var body = 'Your user login was created but your investment account couldn\'t be created. Redirecting to account creation page';
          loadingOff();
          return $rootScope.errorModal(title, body).result.finally(function() {
            var errorMsg = 'The user login was created but the investment account could\'t be created. Redirected the user to account creation page.';
            window.Sentry && window.Sentry.withScope(scope => {
              scope.setExtra('email', user.email());
              scope.setExtra('id', user.id);
              window.Sentry.captureMessage(errorMsg);
            });
            $rootScope.toState = 'dashboard.summary';
            return $state.reload('site');
          });
        }

        var toState = account.type.is.trial() ? 'dashboard.summary' : 'onboarding.personal-combined';

        if (isNewUser) {
          $rootScope.toState = toState;
          return $state.reload('site');
        } else {
          return $state.go(toState, {}, {
            reload: 'site'
          });
        }
      };
    }

    function cleanup(account) {
      $scope.errors = null;
      return account;
    }

    function loadingOn() {
      $scope.showLoading = true;
      loadingIndicator.show();
    }

    function loadingOff() {
      $scope.showLoading = false;
      loadingIndicator.hide();
    }

    function createAccount(createCallback, isNewUser) {
      loadingOn();

      return createCallback()
        .catch(handleError)
        .then(cleanup)
        .then(redirectAfterSuccessfulCreation(isNewUser))
        .finally(loadingOff);
    }

    function addAccount() {
      return $http
        .post('api/accounts.json', {
          account: {
            'user_id': user.id
          },
          'use_cookie': true,
          'format': 'json'
        })
        .then(function(response) {
          return Account.new(_.camelizeKeys(response.data));
        });
    }

    function createUserWithAccount() {
      return user.save().then(function() {
        return _.first(user.accounts());
      });
    }

    return {
      addAccount: function() {
        return createAccount(addAccount, false);
      },
      createUserWithAccount: function() {
        return createAccount(createUserWithAccount, true);
      }
    };
  }

  return {
    addAccount: function($scope, user, account) {
      return creatorWrapper($scope, user, account).addAccount();
    },
    createUserWithAccount: function($scope, user, account) {
      return creatorWrapper($scope, user, account).createUserWithAccount();
    }
  };
}
angular.module('service.accountCreator', [
    'model.Account',
    'service.loading-indicator'
  ])
  .factory('accountCreator', [
    '$rootScope',
    '$state',
    '$http',
    '$q',
    'Account',
    'loadingIndicator',
    accountCreator
  ]);
