'use strict';

angular.module('model.AdvisorCreateNewTransferSecurity', [
    'config',
    'ram'
  ])
  .factory('AdvisorCreateNewTransferSecurity', [
    '$http',
    'ram',
    advisorCreateNewTransferSecurityFactory
  ]);

function advisorCreateNewTransferSecurityFactory($http, ram) {
  var AdvisorCreateNewTransferSecurity = new ram.Collection('AdvisorCreateNewTransferSecurity', {
    belongsTo: {
      advisorCreateNewTransferFlow: 'AdvisorCreateNewTransferFlow'
    },
    resources: {
      default: new ram.resources.Http('/api/advisor_create_new_transfer_securities/:id.json'),
      cookie: new ram.resources.Cookie('advisor_create_new_transfer_securities')
    },
    enums: {},
  });

  return AdvisorCreateNewTransferSecurity;
}
