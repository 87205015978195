'use strict';

function saveOnChangeHandlerFactory() {
  return {
    initForScope: function($scope) {
      $scope.onDateOfBirthChange = function(person, date) {
        return person.save({
          birthDate: date.toDate()
        });
      };

      $scope.onPersonChange = function(person) {
        return person.makeConsistentAndSave();
      };

      $scope.onAddressChange = function(address) {
        return address.save();
      };

      $scope.onMaritalStatusChange = function(person) {
        return person.maritalStatusChange();
      };

      $scope.onPhoneNumberChange = function(phoneNumber) {
        phoneNumber.parseFull(); // To remove the extension if no longer needed
        return phoneNumber.save();
      };
    }
  };
}

angular.module('service.save-on-change-handler', [])
  .factory('saveOnChangeHandler', [
    saveOnChangeHandlerFactory
  ]);
