'use strict';

function modalUserPromotionRedemptionsController($scope, User, userId, promotion) {
  $scope.promo = promotion;
  // Setting $scope.user to null tells the view to display a 'Loading ...' message
  $scope.user = null;

  User.find({
      id: userId
    })
    .then(function(user) {
      // Setting an actual user triggers the 'loading ...' message to go away and display
      // the data instead.
      $scope.user = user;
    });

  $scope.removePromoAndReload = function(promotionRedemption) {
    $scope.promo.remove(promotionRedemption).then(function() {
      $scope.user.promotionRedemptions.ready(true);
    });
  };
}

angular.module('controller.modal.user-promotion-redemptions', [
    'model.User',
    'service.promotion'
  ])
  .controller('modalUserPromotionRedemptionsCtrl', [
    '$scope',
    'User',
    'userId',
    'promotion',
    modalUserPromotionRedemptionsController
  ]);
