'use strict';

angular.module('controller.onboarding.personal-combined', [
    'directive.input',
    'directive.luhn',
    'directive.different-sin',
    'directive.invalid-sin-for-client-to-enter',
    'directive.validate-date',
    'directive.user.date-of-birth-dropdowns',
    'directive.beneficiary-regular',
    'directive.beneficiary-resp',
    'filter.string',
    'model.JointApplicant',
    'controller.onboarding.personal.beneficiary',
    'controller.onboarding.personal.spouse',
    'service.account-service',
    'service.save-on-change-handler',
    'service.beneficiary-service'
  ])
  .controller('OnboardingPersonalCombinedController', [
    '$scope',
    '$http',
    '$q',
    'account',
    'person',
    'homeAddress',
    'mailingAddress',
    'spouse',
    'jointApplicants',
    'beneficiaries',
    'successorHolder',
    'identityVerificationStatus',
    'saveOnChangeHandler',
    'accountService',
    'beneficiaryService',
    onboardingPersonalController
  ]);

function onboardingPersonalController($scope, $http, $q, account, person, homeAddress, mailingAddress, spouse, jointApplicants, beneficiaries, successorHolder, identityVerificationStatus, saveOnChangeHandler, accountService, beneficiaryService) {

  function initOnFormSave($scope) {
    saveOnChangeHandler.initForScope($scope);
    $scope.canAddBeneficiary = $scope.isFamilyResp || $scope.isResp && !_.first($scope.beneficiaries);

    var saveSpouse = function() {
      if ($scope.personalSubforms.saveSpouse) {
        return $scope.personalSubforms.saveSpouse();
      }
    };

    var saveBeneficiary = function() {
      if ($scope.personalSubforms.saveBeneficiary) {
        return $scope.personalSubforms.saveBeneficiary();
      }
    };

    $scope.$parent.save = function() {
      return $q
        .all([
          $scope.person.save(),
          $scope.currentUser.save(),
          saveSpouse()
        ])
        .then(function() {
          return $q.all([
            saveBeneficiary(),
            runIdentityVerification()
          ]);
        });
    };
  }

  function runIdentityVerification() {
    return $http.post('/api/external_identity_checks.json');
  }

  $scope.$on('spouseJointSubscriber', function(event, arg) {
    $scope.spouseJointApplicant = arg.spouseJointApplicant;
  });

  function initFormHandlers($scope) {
    $scope.$parent.done = function() {
      $scope.mustLiveInCanada = false;
      if ($scope.person.mustLiveInCanada($scope.spouseJointApplicant)) {
        $scope.mustLiveInCanada = true;
        window.scrollTo(0, 0);
        return false;
      }
      var valid = $scope.form.$valid && $scope.person.residence() !== 'null';
      if (valid && $scope.isResp && $scope.jointApplicants.length > 0) {
        valid = $scope.spouse.residence() !== 'null';
      }
      return valid;
    };

    $scope.$parent.notDone = function() {
      $scope.formNotDone();
    };

    initOnFormSave($scope);
  }

  function initMaritalStatusWatch($scope, person) {
    $scope.$watch('person.spouseRelation()', function(newValue) {
      if (newValue && person.hasSpouse()) {
        $scope.spouse = newValue.relative();
      } else if (!person.hasSpouse()) {
        $scope.spouse = undefined;
      }
    });
  }

  $scope.personalSubforms = {};

  $scope.homeAddress = homeAddress;
  $scope.mailingAddress = mailingAddress;
  $scope.spouse = spouse;
  $scope.jointApplicants = jointApplicants;

  $scope.beneficiaries = beneficiaries;
  $scope.beneficiary = successorHolder || _.first(beneficiaries);
  $scope.client = $scope.currentUser;

  $scope.showEidCheckDisclosure = !identityVerificationStatus.eidCheckExists;

  $scope.hasFccAccount = accountService.hasFccAccount($scope.currentUser.accounts());

  beneficiaryService.setScope($scope);
  beneficiaryService.setBaseScope($scope);
  beneficiaryService.setAccount(account);

  initMaritalStatusWatch($scope, person);
  initFormHandlers($scope);
}
