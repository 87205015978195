'use strict';

angular
  .module('controller.navbar.advise', [])
  .controller('NavbarAdviseCtrl', [
    '$scope',
    '$state',
    '$http',
    '$window',
    'securityServiceFactory',
    navbarAdviseController
  ]);

function navbarAdviseController($scope, $state, $http, $window, securityServiceFactory) {
  $scope.$watchCollection('currentUser.notifications()', function (notifications) {
    $scope.notifications = notifications;

    ////////////////////////////////////////////////////////////////////////////
    //
    // These methods will not be necessary (expect maybe for efficiency) once we
    // move to associations as scoped collections. On that fine day, we should
    // be able to achieve the same thing with:
    //
    // user.notifications.where({relatedToType: 'Account'});
    //
    // or something to that effect.
    //
    ////////////////////////////////////////////////////////////////////////////

    $scope.accountNotifications = _.filter(notifications, function(n) {
      return n.relatedToType() === 'Account';
    });
    $scope.issueNotifications = _.filter(notifications, function(n) {
      return n.relatedToType() === 'Issue';
    });
    $scope.orderNotifications = _.filter(notifications, function(n) {
      return n.relatedToType() === 'Order';
    });
    $scope.transferRequestNotifications = _.filter(notifications, function(n) {
      return n.relatedToType() === 'TransferRequest';
    });
    $scope.transferNotifications = _.filter(notifications, function(n) {
      return n.relatedToType() === 'Transfer';
    });
  });

  $scope.advisorBadgeDisplayText = function(input) {
    var displayText = input;
    if (displayText === 'advisor' && $scope.currentUser.isPersonal()) {
      displayText = 'personalAdvisor';
    }
    return displayText;
  };

  let security = securityServiceFactory.create($scope.$root.currentUser);

  $scope.showMoreMenu = security.canAccess(
    'update_records',
    'promotions',
    'referrals',
    'reports',
    'csv_reports',
    'fees',
    'orders',
    'bulk_id_verification',
    'rrif_payment_details'
  );

  $scope.openNotificationSubscriptionModal = function () {
    $scope.$root.advisorNotificationSubscriptionModal($scope.currentUser);
  };

  $scope.isProducingBm = function () {
    return !!($scope.currentUser && $scope.currentUser.producingBmRole());
  };

  $scope.roleName = function () {
    return $scope.currentUser.roleName();
  };

  $scope.changeUserRole = function () {
    return $http.post('/api/users/change_role_for_producing_bm').then(
      function () {
        $window.location.href = '/dashboard';
      },
      function () {
        window.Sentry &&
          window.Sentry.captureMessage(
            'There was an error getting the data.  Please log in again.'
          );
      }
    );
  };
}
