'use strict';

angular.module('controller.advise.client-application.account', [
    'config',
    'directive.account-goal-detail',
    'model.Account',
    'model.AdvisorCreateNewAccountFlow',
    'model.AccountFlowJointApplicant',
    'service.form-helper',
    'service.securityService'
  ])
  .controller('AdviseClientApplicationAccountCtrl', [
    '$scope',
    '$state',
    'config',
    'Account',
    'AdvisorCreateNewAccountFlow',
    'AccountFlowJointApplicant',
    'formHelper',
    'ima',
    'newAccountFlows',
    'userNotifications',
    'securityServiceFactory',
    adviseClientApplicationAccountCtrl
  ]);

function adviseClientApplicationAccountCtrl(
  $scope,
  $state,
  config,
  Account,
  AdvisorCreateNewAccountFlow,
  AccountFlowJointApplicant,
  formHelper,
  ima,
  newAccountFlows,
  userNotifications,
  securityServiceFactory
) {
  angular.extend($scope, {
    newAccountFlows: newAccountFlows,
    accountCount: newAccountFlows.length,
    cancelAction: cancelAction,
    ima: ima,
    submitForm: submitForm
  });

  let currentUserSecurity = securityServiceFactory.create($scope.$root.currentUser);

  // View initialization
  resetScope();

  // Scoped Functions

  function submitForm(formController) {
    if (formController.$invalid) {
      formHelper.notDone($scope, formController);
    } else {
      doSave(formController).then(goNext);
    }
  }

  function cancelAction() {
    goToReview();
  }

  // Helper Functions

  function resetScope() {
    $scope.account = new Account();
    $scope.accountFlowJointApplicant = new AccountFlowJointApplicant();
    $scope.accountCount++;
  }

  function doSave(formController) {
    $scope.isSaving = true;
    var newAccountFlow;

    return AdvisorCreateNewAccountFlow
      .create(accountFlowOptions($scope.account, $scope.newClientFlow))
      .then(function(accountFlow) {
        newAccountFlow = accountFlow;
        saveAccountFlowJointApplicant(accountFlow);
      }).then(function() {
        formController.$setPristine();
        resetScope();
        return newAccountFlow;
      }).catch(function() {
        userNotifications.showError('Something went wrong while saving the new account. Please try again later.');
      }).finally(function() {
        $scope.isSaving = false;
      });
  }

  function saveAccountFlowJointApplicant(accountFlow) {
    if ($scope.account.type().joint) {
      $scope.accountFlowJointApplicant.advisorCreateNewAccountFlowId(accountFlow.id);
      $scope.accountFlowJointApplicant.save();
    }
  }

  function accountFlowOptions(account, clientFlow) {
    let portfolioOptionId = null;
    if (currentUserSecurity.canPrefillPortfolioOption()) {
      portfolioOptionId = account.portfolioOptionId();
    } else {
      portfolioOptionId = config.types.AccountPortfolioOption.findByName('core_portfolio').id;
    }

    return {
      accountTypeId: account.typeId(),
      responsible: account.responsible(),
      advisorCreateNewClientFlowId: clientFlow.id,
      clientId: clientFlow.clientId(),
      jurisdictionId: account.jurisdictionId(),
      portfolioOptionId: portfolioOptionId,
      accountNickname: account.nickname()
    };
  }

  function goNext(newAccountFlow) {
    if (newAccountFlow.accountType().beneficiary) {
      $state.go('advise.client-application.beneficiary', {
        newAccountFlowId: newAccountFlow.id
      });
    } else {
      goToReview();
    }
  }

  function goToReview() {
    $state.go('advise.client-application.review');
  }
}
