'use strict';

class AllocationOrPositionColourMapperAndSorter {
  constructor(Fund, recolorFundsService) {
    this.Fund = Fund;
    this.recolorFundsService = recolorFundsService;
  }

  getColourMappedAndSortedAllocations(allocations) {
    return this._prepareAllocationsOrPositions(allocations);
  }

  getColourMappedAndSortedPositions(positions) {
    return this._prepareAllocationsOrPositions(positions);
  }

  _prepareAllocationsOrPositions(allocationsOrPositions) {
    var sortedStocks = this._filterAndSortByAssetClassCategory(allocationsOrPositions, 'stock');
    var sortedFixedIncome = this._filterAndSortByAssetClassCategory(allocationsOrPositions, 'fixedIncome');
    var sortedOther = this._filterAndSortByAssetClassCategory(allocationsOrPositions, 'otherFunds');
    var sortedAllocationsOrPostions = sortedStocks.concat(sortedFixedIncome, sortedOther);

    return this.recolorFundsService.recolorFunds(sortedAllocationsOrPostions);
  }

  _compareFundAssetClassIds(allocationOrPostionA, allocationOrPostionB) {
    var fundAssetClassIdA = allocationOrPostionA.fund().assetClassId();
    var fundAssetClassIdB = allocationOrPostionB.fund().assetClassId();
    var cashAssetClassId = 8;

    if (fundAssetClassIdA === cashAssetClassId) {
      // Cash is always sorted last in the fixed income category
      return 1;
    } else if (fundAssetClassIdB === cashAssetClassId) {
      // Cash is always sorted last in the fixed income category
      return -1;
    } else {
      return fundAssetClassIdA - fundAssetClassIdB;
    }
  }


  _filterAndSortByAssetClassCategory(allocationsOrPostions, category) {
    return allocationsOrPostions
      .filter((allocationOrPostion) => this.Fund.constructor.assetClassIdsByColourCategory[category].includes(allocationOrPostion.fund().assetClassId()))
      .sort(this._compareFundAssetClassIds);
  }
}

AllocationOrPositionColourMapperAndSorter.$inject = [
  'Fund',
  'recolorFundsService'
];

export default AllocationOrPositionColourMapperAndSorter;
