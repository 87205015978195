'use strict';

function legalInfoDirective(config, dropdownOptions, entityListService) {

  function saveOnChangeController($scope) {
    $scope.onLegalChange = function(user) {
      return user.save();
    };
  }

  function initInsiderRoles($scope, legalScope) {
    var insiderRolesScope = legalScope.insiderRoles;
    insiderRolesScope.controller = entityListService.createInsiderRolesController($scope, insiderRolesScope.initialData);
  }

  function initBrokerageAccounts($scope, legalScope) {
    var brokerageAccountsScope = legalScope.brokerageAccounts;
    brokerageAccountsScope.controller = entityListService.createBrokerageAccountsController($scope, brokerageAccountsScope.initialData);
  }

  return {
    restrict: 'E',
    scope: {
      // this is used to communicate with the form the legal form is part of
      // (e.g. setting $dirty flag when rows are added/removed)
      parentForm: '=',
      legalScope: '=',
      currentUser: '=',
      // true = entities will be saved on the field change
      // false = parent have to manage saving the data themselves
      saveOnChange: '='
    },
    templateUrl: 'directives/user/legal-info.html',
    compile: function(element, attrs) {
      _.defaults(attrs, {
        saveOnChange: false
      });
    },
    controller: ['$scope', function($scope) {
      $scope.config = config;

      var legalScope = $scope.legalScope;

      $scope.legalInfo = $scope.legalScope.legalInfo;
      $scope.yesOrNo = dropdownOptions.yesOrNo();

      initBrokerageAccounts($scope, legalScope);
      initInsiderRoles($scope, legalScope);

      if ($scope.saveOnChange) {
        saveOnChangeController($scope);
      }
    }]
  };
}

angular.module('directive.user.legal-info', [
    'service.dropdown-options',
    'service.entity-list-service'
  ])
  .directive('legalInfo', [
    'config',
    'dropdownOptions',
    'entityListService',
    legalInfoDirective
  ]);
