'use strict';

angular.module('controller.advise.trade-reconciliation.report-creation-details', [
    'model.Custodian',
  ])
  .controller('AdviseTradeReconciliationReportCreationDetailsCtrl', [
    '$scope',
    'Custodian',
    'tradeReconciliationReport',
    adviseTradeReconciliationReportCreationDetailsController
  ]);

function adviseTradeReconciliationReportCreationDetailsController($scope, Custodian, tradeReconciliationReport) {
  $scope.tradeReconciliationReport = tradeReconciliationReport;
  $scope.resultsSummary = _parseResultsSummary(tradeReconciliationReport.attachedResultsSummary);

  function _parseResultsSummary(data) {
    try {
      return JSON.parse(data);
    } catch (e) {
      return null;
    }
  }
}
