'use strict';

function factory(ram, config) {

  return new ram.Collection('NewAccountApplicationForm', {
    belongsTo: {
      account: 'Account'
    },
    schema: config.schemas.NewAccountApplicationForm,
    resources: {
      default: new ram.resources.Http(config.schemas.NewAccountApplicationForm.resourceApiUrl),
      cookie: new ram.resources.Cookie('new_account_application_form')
    },
  });
}

angular.module('model.NewAccountApplicationForm', [
    'ram',
    'config'
  ])
  .factory('NewAccountApplicationForm', [
    'ram',
    'config',
    factory
  ]);
