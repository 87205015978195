import template from './holdings-pie-chart.html';
import controller from './holdings-pie-chart.controller';

const holdingsPieChartComponent = {
  template,
  bindings: {
    positions: '<'
  },
  controller: ['$scope', controller],
  controllerAs: '$ctrl'
};

export default holdingsPieChartComponent;
