'use strict';

angular.module('controller.advise.client.eft', [
    'service.controller.eft.transfer',
    'directive.account-transfers-table',
    'directive.input.date-input',
    'directive.input.number',
    'directive.user.address-field',
    'directive.input.manual-help-block',
    'mobile.directive.transfer-type-dropdown',
  ])
  .controller('adviseEftCtrl', [
    'eftTransferService',
    '$scope',
    '$stateParams',
    'client',
    'bankAccounts',
    'clientAccounts',
    eftController
  ]);

function eftController(eftTransferService, $scope, $stateParams, client, bankAccounts, clientAccounts) {
  $scope.client = client;

  eftTransferService.controller(
    $scope,
    $stateParams,
    client,
    clientAccounts,
    bankAccounts,
    clientAccounts,
    null,
    null
  );
}
