'use strict';

angular.module('directive.simple-allocation-bar', [
    'filter.percentage',
    'service.investments-bar-prep-data',
    'service.investment-charts.asset-allocation-data-generator'
  ])
  .directive('simpleAllocationBar', [
    'invBarPrepData',
    'investmentChartsAssetAllocationDataGenerator',
    simpleAllocationBarDirective
  ]);

function simpleAllocationBarDirective(invBarPrepData, investmentChartsAssetAllocationDataGenerator) {
  return {
    restrict: 'E',
    scope: {
      account: '=',
      allocations: '='
    },
    templateUrl: 'directives/simple-allocation-bar.html',
    link: function($scope) {
      $scope.bondsPercentage = function() {
        return _.chain($scope.allocations)
          .filter(function(allocation) {
            return allocation.fund().assetType.is.bond() ||
              allocation.fund().assetType.is.cash();
          })
          .reduce(function(memo, allocation) {
            return memo + allocation.weight();
          }, 0)
          .value();
      };

      $scope.stockPercentage = function() {
        return 1 - $scope.bondsPercentage();
      };

      $scope.$watchCollection('allocations', function() {
        var chartData = investmentChartsAssetAllocationDataGenerator.getData($scope.allocations);

        var allocationsForBarChart = _.map(chartData, function(chartDataRow) {
          return chartDataRow.allocation;
        });

        var invChartDataObject = invBarPrepData.getChartDataForAllocations(allocationsForBarChart, $scope.account);

        if (invChartDataObject) {
          $scope.toBeStacked = invChartDataObject.toBeStacked;
          $scope.investmentsChartData = invChartDataObject.investmentsChartData;
        }
      });
    }
  };
}
