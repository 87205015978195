'use strict';

angular.module('controller.advise.person.identity-verification', [
    'component.advisor-dashboard.id-verification-section'
  ])
  .controller('AdvisePersonIdentityVerificationCtrl', [
    advisePersonIdentityVerificationController
  ]);

function advisePersonIdentityVerificationController() {}
