'use strict';

function newtonMethodSolverFactory() {
  /**
   * Finds a root of fx using Newton's Method. If a result
   * cannot be found, an error is thrown.
   *
   * Note for future: Would like to make the fxPrime parameter
   * optional.
   * 
   * @param  {Number}   guess   An initial guess of what the
   *                            root is
   * @param  {Function} fx      Function to find a root of
   * @param  {Function} fxPrime The first derivative of fx
   * @return {Number}           A root of fx
   */
  function newtonMethodSolver(guess, fx, fxPrime) {
    var precision = 0.00000001;
    var numOfIterations = 100;

    if (!_.isFunction(fx) || !_.isFunction(fxPrime)) {
      throw new Error('Both fx and fxPrime must be functions.');
    }

    while (numOfIterations) {
      var y0 = fx(guess);
      var x0 = guess;
      var x1;

      if (y0 < precision && y0 > -precision) {
        return x0;
      }

      x1 = x0 - y0 / fxPrime(guess);
      guess = x1;

      numOfIterations--;
    }

    throw new Error('Unable to calculate a result.');
  }

  return newtonMethodSolver;
}

angular.module('service.newton-method-solver', [])
  .factory('newtonMethodSolver', [
    newtonMethodSolverFactory
  ]);
