'use strict';

function factory(ram, config) {

  var AuthenticationFactor = new ram.Collection('AuthenticationFactor', {
    belongsTo: {
      user: 'User'
    },
    resources: {
      default: new ram.resources.Http('/api/authentication_factors/:id.json'),
      cookie: new ram.resources.Cookie('authentication_factors')
    },
    schema: config.schemas.AuthenticationFactor
  });

  return AuthenticationFactor;
}

angular.module('model.AuthenticationFactor', [
    'model.User'
  ])
  .factory('AuthenticationFactor', [
    'ram',
    'config',
    factory
  ]);
