'use strict';

angular.module('routes.advisor-new-client', [
    'controller.advisor-new-client',
    'controller.minimal-footer',
    'model.AdvisorCreateNewClientFlow',
    'service.adaptive-views',
    'templates',
    'ui.router'
  ])
  .config([
    '$stateProvider',
    'adaptiveViewsProvider',
    configRoutes
  ]);

function configRoutes(
  $stateProvider,
  adaptiveViewsProvider
) {
  function resolveDetails($http, $stateParams) {
    return $http.get('/api/advisor_create_new_client_flows/' + $stateParams.advisorNewClientFlowPassword + '/sanitized_details')
      .then(function(response) {
        return response.data;
      })
      .catch(function() {
        return {
          error: true
        };
      });
  }

  function resolveClientFlow($stateParams, currentUser, AdvisorCreateNewClientFlow) {
    var password = $stateParams.advisorNewClientFlowPassword;
    var params = {
      password: password
    };
    if (!password && currentUser) { // If coming from a re-direct
      params = {
        clientId: currentUser.person().id
      };
    }
    return AdvisorCreateNewClientFlow
      .where(params)
      .then(function(flows) {
        return _.first(flows);
      });
  }

  function resolveAccountFlow(clientFlow) {
    var accountFlowIndex = 0; // Start with the first account

    return clientFlow.accountFlowForNewClient(accountFlowIndex)
      .then(function(response) {
        return response.data;
      });
  }

  function resolveRedirect(details, redirect, currentUser) {
    if (details.error) {
      if (!currentUser || (currentUser && currentUser.isClient())) {
        redirect.to('advisor-new-client.invalid-link').always();
      }
      return; // External advisor is logged in if we get here.  Use existing message in controller.
    }

    if (details.isCompleted) {
      redirect.to('account.signin').always();
    }

    const isModernAdvisorPersonal = details.advisorReferralCode.startsWith('mapersonal');
    if (isModernAdvisorPersonal) {
      redirect.to('advisor-new-client.verify-identity').always();
    }
  }

  $stateProvider
    .state('advisor-new-client', {
      url: '/advisor-new-client/:advisorNewClientFlowPassword',
      abstract: true,
      parent: 'site',
      views: {
        'main': {
          controller: 'AdvisorNewClientController'
        },
        'navbar': {
          templateUrl: 'navbar/create-client-minimal.html'
        },
        'footer': {
          controller: 'minimalFooterCtrl',
          templateUrl: adaptiveViewsProvider.isMobile() ? 'mobile/partials/footer.html' : 'partials/footer-minimal.html'
        }
      },
      data: {
        tracking: {
          pageTitle: 'Welcome to iQ by Worldsource',
          pageId: 'client-registration',
          pageType: 'client-create',
          contents: {
            content: [{
              type: 'form',
            }],
            category: { primary: 'client' }
          },
          forms: [{
            id: "client-signup",
            name: "Sign Up",
            type: "registration",
            steps_count: "2",
            steps_current: "1"
          }]
        }
      },
      resolve: {
        details: ['$http', '$stateParams', resolveDetails]
      }
    })
    .state('advisor-new-client.view', {
      url: '/view',
      controller: 'AdvisorNewClientViewController',
      resolve: {
        resolveRedirect: ['details', 'redirect', 'currentUser', resolveRedirect]
      }
    })
    .state('advisor-new-client.goodbye', {
      url: '/goodbye',
      controller: 'AdvisorNewClientGoodbyeController',
      params: {
        deleted: false
      },
      resolve: {
        resolveRedirect: ['details', 'redirect', resolveRedirect]
      }
    })
    .state('advisor-new-client.verify-identity', {
      url: '/verify-identity',
      controller: 'AdvisorNewClientVerifyIdentityController'
    })
    .state('advisor-new-client.invalid-link', {
      url: '/invalid-link'
    })
    .state('advisor-new-client.add-password', {
      url: '/add-password',
      controller: 'AdvisorNewClientAddPasswordController',
      resolve: {
        clientFlow: ['$stateParams', 'currentUser', 'AdvisorCreateNewClientFlow', resolveClientFlow]
      },
      data: {
        tracking: {
          pageTitle: 'Welcome to iQ by Worldsource',
          pageId: 'client-password-creation',
          pageType: 'client-create',
          contents: {
            content: [{
              type: 'form',
            }],
            category: { primary: 'client' }
          },
          forms: [{
            id: "client-password-creation",
            name: "Create a new password",
            type: "registration",
            steps_count: "2",
            steps_current: "2"
          }]
        }
      },
    });
}
