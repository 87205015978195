'use strict';

angular.module('controller.interview.spousal-rrsp', [
    'ram',
    'directive.input.selector',
  ])
  .controller('InterviewSpousalRrspCtrl', [
    '$scope',
    '$state',
    '$cookies',
    'ram',
    interviewSpousalRrspController
  ]);


function interviewSpousalRrspController($scope, $state, $cookies, ram) {
  angular.extend($scope, {
    peopleOptions: ['Me', 'My Spouse'],
    contributor: new ram.Accessor(),
    withdrawer: new ram.Accessor(),
  });

  if (!!$cookies.get('spousal_rrsp')) {
    var data = $cookies.getObject('spousal_rrsp');
    $scope.contributor(data.contributor);
    $scope.withdrawer(data.withdrawer);
  }

  $scope.$watchGroup(['contributor()', 'withdrawer()'], function(newValues) {
    if (!_.contains(newValues, undefined)) {
      var contributor = newValues[0];
      var withdrawer = newValues[1];
      done(contributor, withdrawer);
    }
  });

  function done(contributor, withdrawer) {
    $scope.suggestionMessage = '';
    if (contributor === withdrawer) {
      if (contributor === 'Me') {
        $scope.suggestionMessage = 'If you are both contributing to and withdrawing from this RRSP, you should open an Individual RRSP.';
      } else {
        $scope.suggestionMessage = 'If your spouse is both contributing to and withdrawing from this RRSP, they should open an Individual RRSP under their ModernAdvisor account';
      }
    } else {
      if (contributor === 'Me') {
        $scope.suggestionMessage = 'To accomplish this, your spouse should open a Spousal RRSP under their ModernAdvisor account and list you as a contributor.';
      } else {
        return true;
      }
    }
    return false;
  }

  function saveCookie() {
    var spousalCookieObj = {
      contributor: $scope.contributor(),
      withdrawer: $scope.withdrawer()
    };
    $cookies.putObject('spousal_rrsp', spousalCookieObj, {
      expires: 'Fri, 31 Dec 9999 23:59:59 GMT'
    });
  }

  $scope.$parent.nextSection = function() {
    saveCookie();
    if (done($scope.contributor(), $scope.withdrawer())) {
      $state.go($state.current.data.next);
    }
  };
  $scope.$parent.lastSection = function() {
    saveCookie();
    if ($scope.isAdvisorCreateNewAccountFlow()) {
      $state.go('/investment/goal');
    } else {
      $state.go($state.current.data.last);
    }
  };
  $scope.$parent.step = $state.current.data.step;
  $scope.$parent.header = $state.current.data.header;
  $scope.$parent.userCanGoBack = true;
}
