'use strict';

angular.module('model.Account', [
    'config',
    'model.AccountGoal',
    'model.Activity',
    'model.AdvisorCreateNewAccountFlow',
    'model.Balance',
    'model.Beneficiary',
    'model.CashFlow',
    'model.Custodian',
    'model.InvestmentPolicyStatement',
    'model.Issue',
    'model.JointApplicant',
    'model.NewAccountApplicationForm',
    'model.Position',
    'model.RifDetail',
    'model.Statement',
    'model.SuccessorHolder',
    'model.Transfer',
    'model.TransferRequest',
    'model.TransferInstruction',
    'model.User',
    'service.combine-dollar-amounts-on-same-day',
    'model.Participant',
    'model.Note',
    'model.BankAccount',
    'ram'
  ])
  .factory('Account', [
    '$filter',
    '$http',
    'ram',
    'config',
    'CashFlow',
    'combineDollarAmountsOnSameDay',
    accountFactory
  ]);

function accountFactory($filter, $http, ram, config, CashFlow, combineDollarAmountsOnSameDay) {
  // Note: Any methods added to Account.prototype should also
  // be added to CombinedAccount.prototype, even if it will be
  // a stub.

  var Account = new ram.Collection('Account', {
    accessors: ['userIsHolder', 'skipRebalancing', 'clientNameOverride', 'canEditBeneficiaries', 'funded', 'performanceSummary'],
    bind: ['label', 'clientLabel', 'descriptiveLabel', 'fee', 'typeShortLabel', 'assignNumber', 'regenerateForms', 'nonZeroPositions', 'isBeingOpened'],
    belongsTo: {
      user: 'User',
      bankAccount: 'BankAccount',
      advisorCreateNewAccountFlow: 'AdvisorCreateNewAccountFlow',
      custodian: 'Custodian'
    },
    enums: {
      type: config.types.Account,
      status: config.types.AccountStatus,
      jurisdiction: config.types.AccountJurisdiction,
      portfolioOption: config.types.AccountPortfolioOption,
      ni54DisclosureOption: config.types.AccountNI54DisclosureOption,
      ni54ReceiveMaterialsOption: config.types.AccountNI54ReceiveMaterialsOption,
      ni54ElectronicDeliveryOption: config.types.AccountNI54ElectronicDeliveryOption,
      returnObjective: config.types.AccountReturnObjective
    },
    hasOne: {
      accountGoal: 'AccountGoal',
      investmentPolicyStatement: 'InvestmentPolicyStatement',
      newAccountApplicationForm: 'NewAccountApplicationForm',
      rifDetail: 'RifDetail',
      successorHolder: 'SuccessorHolder'
    },
    hasMany: {
      accountGoals: 'AccountGoal',
      activities: 'Activity',
      balances: 'Balance',
      beneficiaries: 'Beneficiary',
      investmentPolicyStatements: 'InvestmentPolicyStatement',
      issues: 'Issue',
      jointApplicants: 'JointApplicant',
      participants: 'Participant',
      positions: 'Position',
      transfers: 'Transfer',
      transferRequests: 'TransferRequest',
      transferInstructions: 'TransferInstruction',
      accountTransfers: 'AccountTransfer',
      notes: 'Note'
    },
    resources: {
      default: new ram.resources.Http('/api/accounts/:id.json'),
      cookie: new ram.resources.Cookie('accounts')
    },
    schema: config.schemas.Account
  });

  /**
   * An alias for goal label.
   *
   * @return {String} Label for the account.
   */
  Account.prototype.label = function() {
    if (!this.type()) {
      return 'Pending';
    }

    var number = !this.type.is.trial() && this.number() ? (' - #' + this.number()) : '';

    var status;

    if (this.isTrialEnded() || this.status.is.closed()) {
      status = ' (closed)';
    } else if (!this.number()) {
      status = ' - Pending';
    } else {
      status = '';
    }

    return s.sprintf('%s%s%s', this.type().label, number, status);
  };

  Account.prototype.clientIsHolderLabel = function() {
    if (this.nickname()) {
      return this.nickname();
    }

    return this.label();
  };

  Account.prototype.clientIsViewerLabel = function() {
    return this.clientFirstName() + '\'s ' + this.clientIsHolderLabel();
  };

  /**
   * The label that should be shown to the client
   * @return {String} label to be shown to the client
   */
  Account.prototype.clientLabel = function() {
    if (this.userIsHolder()) {
      return this.clientIsHolderLabel();
    } else {
      return this.clientIsViewerLabel();
    }
  };

  /**
   * A label that includes the current balance.
   * @return {String} label with balance.
   */
  Account.prototype.descriptiveLabel = function() {
    return this.clientLabel() + ' (' + $filter('currency')(this.balance()) + ')';
  };

  /**
   * A compact label that for use in tables
   * @return {String} label
   */
  Account.prototype.shortLabel = function() {
    return this.type.label();
  };

  /**
   * A friendly noun that is used to describe the account
   * @return {String} label
   */
  Account.prototype.friendlyNoun = function() {
    return this.type.friendlyNoun() || this.type.label();
  };

  /**
   * An alias for model portfolio fee.
   *
   * @return {String} Portfolio fee charged to the account.
   */
  Account.prototype.fee = function() {
    var accountGoals = this.accountGoals();
    var modelPortfolio = _.first(accountGoals) && accountGoals[0].modelPortfolio();
    return modelPortfolio && modelPortfolio.fee();
  };

  /**
   * Determines whether the account is a trial account the trial period has already passed.
   *
   * @return {Boolean} true iff the account is a trial account and is past trial end.
   */
  Account.prototype.isTrialEnded = function() {
    return this.type.is.trial() && (this.status.is.deactivated() || this.status.is.closing() || this.status.is.expired() || this.status.is.closed());
  };

  /**
   * Look up the account's balance on the specified date, or current balance if
   * no date is given. Assumes that balances are in chronological order.
   *
   * @param  {Date}        date
   * @return {Number}      The balance of account on date; 0 if the date is
   *                       prior to the first recorded balance or no balances
   *                       are found for this account, current balance if no
   *                       date is found or is in the future.
   */
  Account.prototype.balance = function(date) {

    var balance;
    var balances = this.balances();

    if (!balances || !balances.length) {
      return 0;
    }

    // default to the current balance
    balance = balances[balances.length - 1].amount();

    if (date) {
      _.find(balances, function(element, index, list) {
        if (element.date() > date) {
          // if the first entry is after the specified date, the balance was 0.
          balance = index ? list[index - 1].amount() : 0;
          return true;
        }
      });
    }

    return balance;
  };

  /**
   * Create the balancesHistoryData.  This doesn't do much for Account objects, but needs to
   * be here so Account is compatible with CombinedAccount.  The latter needs this method.
   * @return true to kick off promises.
   */
  Account.prototype.createBalancesHistory = function() {
    // Assuming there is only one balance per day.  If there were more than one we'd need to combine
    // them using combineDollarAmountsOnSameDay, and that doesn't make sense.
    this.balancesHistoryData = _.map(this.balances(), function(balance) {
      return balance.toDisplayFormat();
    });
    return true;
  };

  /**
   * Accessor to get this object's balancesHistoryData
   * @return {Array} This doesn't do much here but is needed for compatibility with CombinedAccount.
   */
  Account.prototype.balancesHistory = function() {
    return this.balancesHistoryData;
  };

  /**
   * Create the transfersHistoryData.  This doesn't do much for Account objects, but needs to
   * be here so Account is compatible with CombinedAccount.  The latter needs this method.
   * @return true to kick off promises.
   */
  Account.prototype.createTransfersHistory = function() {
    this.transfersHistoryData = combineDollarAmountsOnSameDay.process(this.transfers());
    return true;
  };

  /**
   * Accessor to get this object's transfersHistoryData
   * @return {Array} This doesn't do much here but is needed for compatibility with CombinedAccount.
   */
  Account.prototype.transfersHistory = function() {
    return this.transfersHistoryData;
  };

  /**
   * Get contributions made to this account up to the specified date.
   *
   * @param   {Date}    endDate   Sum contributions up to this point (optional)
   * @param   {Date}    startDate Sum contributions after this point (optional)
   * @return  {Number}  Total     Sum of contributions
   */
  Account.prototype.contributions = function(endDate, startDate) {

    var transfers = this.transfers();
    if (!transfers || !transfers.length) {
      return 0;
    }

    return _.reduce(transfers, function(sum, transfer) {
      // if a date is specified and a transfer occurs after, ignore it
      if (endDate && transfer.date() > endDate) {
        return sum;
      } else if (startDate && transfer.date() < startDate) {
        return sum;
      }
      return sum + transfer.amount() * 100;
    }, 0) / 100;
  };

  /**
   * For each date in the balance history of an account, find the cumulative
   * contributions to date.
   *
   * @return {Array} Series of dates and contribution totals.
   */
  Account.prototype.contributionHistory = function() {
    return _.map(this.balances(), function(balance) {
      var date = balance.date();
      return new CashFlow(date, this.contributions(date, date));
    }, this);
  };

  /**
   * Get the difference between balance and contributions on the specified date
   * or the performance to date if undefined.
   *
   * @param  {Date}   date Date to get the account performance on.
   * @return {Number}
   */
  Account.prototype.performance = function(date) {
    return this.balance(date) - this.contributions(date);
  };

  /**
   * For each date in the balance history of an account, find the cumulative
   * earnings to date.
   *
   * @return {Array} Series of dates and earnings totals.
   */
  Account.prototype.earningsHistory = function() {
    return _.map(this.balancesHistory(), function(balance) {
      return new CashFlow(balance.date, this.performance(balance.date));
    }, this);
  };

  /**
   * Converts the balances and transfers in the account into
   * cash flows.
   *
   * It calculates the timeframe from the start of the day on the start date
   * to the end of the day on the end date.
   * (ex. August 1, 2015 to August 2, 2015 is considered to be two days)
   * (ex. calculating just for August 1, 2015 is considered to be one day)
   *
   * Returns undefined if it cannot determine all the cash flows.
   *
   * @param  {Date} startDate   The start of the timeframe
   * @param  {Date} endDate     The end of the timeframe
   * @return {Object}           The cash flows based on the balances
   *                            and transfers in the timeframe
   */
  Account.prototype.cashFlows = function(startDate, endDate) {
    var previousDay = moment(startDate).subtract(1, 'days').toDate();

    var firstCashFlow = new CashFlow(previousDay, -this.balance(previousDay));
    var lastCashFlow = new CashFlow(endDate, this.balance(endDate));

    var transfers = _.chain(this.transfers())
      .filter(function(transfer) {
        return transfer.date() >= startDate && transfer.date() <= endDate;
      })
      .map(function(transfer) {
        return transfer.toCashFlow();
      })
      .value();

    // add transfers that happen on the start date to the first cash flow
    while (transfers.length && _.first(transfers).date.getTime() === startDate.getTime()) {
      firstCashFlow.amount += transfers.shift().amount;
    }

    // add transfers that happen on the end date to the last cash flow
    while (transfers.length && _.last(transfers).date.getTime() === endDate.getTime()) {
      lastCashFlow.amount += transfers.pop().amount;
    }

    return [firstCashFlow].concat(transfers, lastCashFlow);
  };

  Account.prototype.hasBeneficiary = function() {
    return this.type() && this.type().beneficiary;
  };

  Account.prototype.typeShortLabel = function() {
    return this.type() && (this.type().statementName || this.type().label);
  };

  Account.prototype.isCombinedAccount = function() {
    return false;
  };

  Account.prototype.isVisible = function() {
    if (!this.type()) {
      return false;
    }

    // only open accounts from the household should be visible
    if (!this.userIsHolder() && !this.status.is.open()) {
      return false;
    }

    return !this.status.is.closed() &&
      (this.type.is.trial() || this.applicationCompleted());
  };

  Account.prototype.approve = function() {
    return memberAction(this, 'approve');
  };

  Account.prototype.close = function() {
    return memberAction(this, 'close');
  };

  Account.prototype.regenerateForms = function(individualDocs=[]) {
    return memberAction(this, 'regenerate_forms', {individualDocs: individualDocs});
  };

  Account.prototype.changeRebalancing = function() {
    var newVal = !this.skipRebalancing();
    this.skipRebalancing(newVal);
    this.save();
  };

  Account.prototype.assignNumber = function(number) {
    return memberAction(this, 'assign_number', {
      number: number
    });
  };

  Account.prototype.updateBankAccount = function(bankAccountId) {
    this.bankAccountId(bankAccountId); // this will set the ID, so that the value is visible right away.
    return memberAction(this, 'update_bank_account', {
      bankAccountId: bankAccountId
    }).then(function(result) {
      return result.bankAccount.ready(true).then(function() {
        return result;
      });
    });
  };

  function memberAction(account, actionName, payload) {
    return $http.put('/api/accounts/' + account.id + '/' + actionName + '.json', payload)
      .then(function() {
        return account.reload();
      });
  }

  Account.prototype.get_esign_url = function() {
    return memberActionWithResponse(this, 'get_esign_url');
  };

  function memberActionWithResponse(account, actionName, payload) {
    return $http.put('/api/accounts/' + account.id + '/' + actionName + '.json', payload)
      .then(function(res) {
        return res.data;
      });
  }

  Account.prototype.successorHolderOrAnnuitantLabel = function() {
    if (this.type.is.tfsa()) {
      return 'Successor Holder';
    } else if (this.type.is.individualRif() || this.type.is.spousalRif()) {
      return 'Successor Annuitant';
    }
  };

  Account.prototype.nonZeroPositions = function() {
    return _.reject(this.positions(), function(position) {
      return parseFloat(position.shares()) === 0.0;
    });
  };

  Account.prototype.isBeingOpened = function() {
    return ['started', 'pending', 'approved', 'rejected'].includes(this.status().name);
  };

  Account.prototype.canConvertToCorporate = function() {
    return this.type.is.individual() && !this.funded();
  };

  Account.prototype.convertToCorporate = function() {
    return $http.put(`/api/accounts/${this.id}/convert_to_corporate.json`);
  };

  return Account;
}
