'use strict';

angular.module('model.RiskAppetiteQuizChoice', [])
  .factory('RiskAppetiteQuizChoice', [
    'ram',
    riskAppetiteQuizChoiceFactory
  ]);

function riskAppetiteQuizChoiceFactory(ram) {

  /**
   * Constructor for resource-aware RiskAppetiteQuizChoice model.
   * @class
   */
  var RiskAppetiteQuizChoice = new ram.Collection('RiskAppetiteQuizChoice', {
    resources: {
      default: new ram.resources.Http('/api/risk_appetite_quiz_choices/:id.json'),
      cookie: new ram.resources.Cookie('risk_appetite_quiz_choices')
    },
  });

  return RiskAppetiteQuizChoice;
}
