'use strict';

angular.module('controller.profile.personal', [
    'directive.input',
    'directive.profile-form',
    'directive.user.personal-info',
    'model.PersonalRelation',
    'model.Person',
    'service.dropdown-options'
  ])
  .controller('ProfilePersonalEmailCtrl', [
    '$scope',
    '$q',
    'userNotifications',
    profilePersonalEmailController
  ])
  .controller('ProfilePersonalCtrl', [
    '$scope',
    '$q',
    'profilePersonalService',
    'dropdownOptions',
    profilePersonalController
  ])
  .controller('ProfilePersonalEmploymentCtrl', [
    '$scope',
    profilePersonalEmploymentController
  ])
  .controller('ProfilePersonalSpouseCtrl', [
    '$scope',
    '$q',
    'profilePersonalService',
    'dropdownOptions',
    profilePersonalSpouseController
  ])
  .factory('profilePersonalService', [
    '$rootScope',
    'userNotifications',
    profilePersonalServiceFactory
  ]);

// wrapper around save function to enable easier chaining of promises
function saveWrapper(entity) {
  return function() {
    return entity.save();
  };
}

function savePhoneNumbers($q, person) {
  var promises = [];
  angular.forEach(person.phoneNumbers(), function(phoneNumber) {
    promises.push(phoneNumber.save());
  });
  return $q.all(promises);
}

function initFormPersonal($scope, $q, person, profilePersonalService) {
  var hadASpouse = person.hasSpouse();

  $scope.onChange = angular.noop;

  return {
    save: function() {
      var haveASpouse = person.hasSpouse();
      profilePersonalService.spouseWasAdded(!hadASpouse && haveASpouse);
      hadASpouse = haveASpouse;

      return $q
        .all([
          $scope.person.mailingAddress().save(),
          $scope.person.homeAddress().save(),
          savePhoneNumbers($q, $scope.person)
        ])
        // person have to be saved in the end otherwise it will refresh addresses, etc.
        // and all the changes there will be overriden
        .then(saveWrapper($scope.person))
        .then(function() {
          return $scope.person.spouseRelation.ready(true);
        });
    },
    reset: function() {
      return person.fetch(true);
    }
  };
}

function profilePersonalEmailController($scope, $q, userNotifications) {
  $scope.resendConfirmationEmail = function() {
    $scope.isResendingConfirmationEmail = true;
    userNotifications.clearNotification();

    $scope.currentUser.sendConfirmation()
      .then(function() {
        userNotifications.showSuccess('A new confirmation email has been sent to ' + $scope.currentUser.unconfirmedEmail());
      })
      .catch(function() {
        userNotifications.showError('Something went wrong while resending the confirmation email. Please try again later.');
      })
      .finally(function() {
        $scope.isResendingConfirmationEmail = false;
      });
  };

  /* jshint validthis: true */
  this.save = function() {
    userNotifications.clearNotification();

    return $scope.currentUser.save()
      .catch(function(response) {
        if (response.data && response.data.errors && response.data.errors.email) {
          userNotifications.showError('Email ' + response.data.errors.email);
        } else {
          userNotifications.showError('Something went wrong while saving your changes. Please try again later.');
        }

        // The 'cancel' value is so that the 'Error' text doesn't show up beside
        // the Cancel button since we are already giving an error message.
        return $q.reject({
          $value: 'cancel'
        });
      });
  };
  this.reset = function() {
    userNotifications.clearNotification();

    return $scope.currentUser.fetch(true);
  };
}

function profilePersonalController($scope, $q, profilePersonalService, dropdownOptions) {
  var formPersonal = initFormPersonal($scope, $q, $scope.person, profilePersonalService);

  $scope.yesOrNo = dropdownOptions.yesOrNo();
  $scope.residencies = dropdownOptions.residencies();

  /* jshint validthis: true */
  this.save = formPersonal.save;
  this.reset = formPersonal.reset;
}

function profilePersonalEmploymentController($scope) {
  /* jshint validthis: true */
  this.save = function() {
    return $scope.person.makeConsistentAndSave();
  };
  this.reset = function() {
    return $scope.person.fetch(true);
  };
}

function profilePersonalSpouseController($scope, $q, profilePersonalService, dropdownOptions) {
  /* jshint validthis: true */

  $scope.onChange = angular.noop;

  var spouseIsEmpty = function() {
    if ($scope.spouseRelation) {
      var spouse = $scope.spouseRelation.relative();
      return !spouse.firstName() || !spouse.lastName() || !spouse.gender() || !spouse.employmentStatus();
    }
  };

  this.$onInit = function() {
    if (spouseIsEmpty() || profilePersonalService.spouseWasAdded()) {
      $scope.editingEnabled = true;
      $scope.preventCancel = true;
    }
  };

  $scope.yesOrNo = dropdownOptions.yesOrNo();
  $scope.residencies = dropdownOptions.residencies();

  this.save = function() {
    $scope.preventCancel = false;
    if ($scope.spouseRelation) {
      var spouse = $scope.spouseRelation.relative();
      return $q.all([
        spouse.makeConsistentAndSave(),
        spouse.mailingAddress() && spouse.mailingAddress().save(),
        spouse.homeAddress().save(),
        savePhoneNumbers($q, spouse)
      ]);
    }
    return $q.when(null);
  };
  this.reset = function() {
    return $scope.person.spouseRelation.ready(true);
  };
}

function profilePersonalServiceFactory() {
  var spouseWasAdded = false;

  return {
    spouseWasAdded: function(wasAdded) {
      if (arguments.length) {
        spouseWasAdded = wasAdded;
      }
      return spouseWasAdded;
    }
  };
}
