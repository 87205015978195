'use strict';


angular.module('controller.onboarding.bank', [])
  .controller('OnboardingBankController', [
    '$scope',
    'bankAccount',
    'bankStatementImageInput',
    'currentUser',
    'institutions',
    'config',
    onboardingBankController
  ]);

function onboardingBankController($scope, bankAccount, bankStatementImageInput, currentUser, institutions, config) {
  let platform = config.env.platform;

  $scope.isWsi = false;
  if (platform && platform.name === 'WSI') {
    $scope.isWsi = true;
  }

  var shouldSkipBankView = !bankAccount.status.is.draft();
  $scope.photos = {
    bankStatementImage: bankStatementImageInput
  };
  $scope.bankAccount = bankAccount;
  $scope.institutions = institutions;

  function _hasUploadedBankStatement() {
    return $scope.bankAccount.verificationMethod.is.bankStatement() &&
      $scope.photos.bankStatementImage &&
      $scope.photos.bankStatementImage.id;
  }

  $scope.$parent.save = function() {
    if (!shouldSkipBankView) {
      bankAccount.save();
    }
  };

  $scope.$parent.done = function() {
    return shouldSkipBankView ||
      $scope.form.$valid && (_hasUploadedBankStatement() || $scope.bankAccount.verificationMethod.is.cheque());
  };

  $scope.$parent.notDone = function() {
    return $scope.formNotDone();
  };

  if (shouldSkipBankView) {
    $scope.skipStep();
  }
}
