'use strict';

class BeneficiaryFormRegularController {
  constructor(
    $q,
    SuccessorHolder,
    AccountBeneficiary,
    Beneficiary,
    AdvisorBeneficiaryForm,
    spouseOrPartner,
    beneficiaryParamsGenerator
  ) {
    this.$q = $q;
    this.SuccessorHolder = SuccessorHolder;
    this.AccountBeneficiary = AccountBeneficiary;
    this.AdvisorBeneficiaryForm = AdvisorBeneficiaryForm;
    this.Beneficiary = Beneficiary;
    this.spouseOrPartner = spouseOrPartner;
    this.beneficiaryParamsGenerator = beneficiaryParamsGenerator;
  }

  $onInit() {
    this.beneficiary = null;
    this.beneficiaryRadioTypes = {
      estate: 'Estate',
      spouse: 'Spouse',
      other: 'Other'
    };
    this.benficiaryRadioType = this.beneficiaryRadioTypes.estate;
    this.accountType = this.accountFlow.accountType;
    this.subforms.saveBeneficiary = this._saveBeneficiary.bind(this);
    this.beneficiaryRelationTypes = this.Beneficiary.getBeneficiaryRelationTypes('regularBeneficiary');
  }

  onbenficiaryRadioTypeChange() {
    if (this.benficiaryRadioType === this.beneficiaryRadioTypes.estate) {
      this.beneficiary = null;
    } else if (this.benficiaryRadioType === this.beneficiaryRadioTypes.spouse) {
      this.beneficiary = this._createBeneficiary(this._getSpouseRelationId());
    } else if (this.benficiaryRadioType === this.beneficiaryRadioTypes.other) {
      this.beneficiary = this._createBeneficiary();
    }
  }

  getRelationshipName() {
    return this.spouseOrPartner.relationshipName(this.client);
  }

  beneficiaryOrSuccessor() {
    if (this.accountType.canHaveSuccessorHolder()) {
      if (this.accountType.is.tfsa()) {
        return 'successor holder';
      } else {
        return 'successor annuitant';
      }
    } else {
      return 'beneficiary';
    }
  }

  _getSpouseRelationId() {
    const spouseRelation = this.client.spouseRelation();
    return spouseRelation ? spouseRelation.id : null;
  }

  _createBeneficiary(personalRelationId) {
    const params = this.beneficiaryParamsGenerator.getAdvisorBeneficiaryFormParams(
      this.client.user().id,
      this.accountFlow.id,
      personalRelationId
    );
    const beneficiary = this.AdvisorBeneficiaryForm.new(params);
    if (this.accountType.canHaveSuccessorHolder() &&
      this.benficiaryRadioType === this.beneficiaryRadioTypes.spouse) {
      beneficiary.type(this.SuccessorHolder.name);
    } else {
      beneficiary.type(this.AccountBeneficiary.name);
    }
    return beneficiary;
  }

  _saveBeneficiary() {
    if (!this.beneficiary) {
      return this.$q.resolve(); // to keep the return value type consistent
    } else {
      return this.beneficiary.deepSave();
    }
  }
}

export default BeneficiaryFormRegularController;
