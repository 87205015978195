'use strict';

angular.module('model.AdvisorCreateNewTransferInstructionFlow', [
    'model.AdvisorCreateNewAccountFlow',
    'model.BankAccount',
    'config',
    'ram'
  ])
  .factory('AdvisorCreateNewTransferInstructionFlow', [
    'config',
    '$http',
    'ram',
    'AdvisorCreateNewAccountFlow',
    'BankAccount',
    'transferAccountDataProvider',
    advisorCreateNewTransferInstructionFlowFactory
  ]);

function advisorCreateNewTransferInstructionFlowFactory(config, $http, ram, AdvisorCreateNewAccountFlow, BankAccount, transferAccountDataProvider) {
  var AdvisorCreateNewTransferInstructionFlow = new ram.Collection('AdvisorCreateNewTransferInstructionFlow', {
    belongsTo: {
      advisor: 'Person',
      client: 'Person',
      advisorCreateNewClientFlow: 'AdvisorCreateNewClientFlow',
    },
    resources: {
      default: new ram.resources.Http('/api/advisor_create_new_transfer_instruction_flows/:id.json'),
      cookie: new ram.resources.Cookie('advisor_create_new_transfer_instruction_flows')
    },
    hasMany: {},
    enums: {
      type: config.types.TransferInstruction,
      frequency: config.types.TransferInstructionFrequency
    },
  });

  AdvisorCreateNewTransferInstructionFlow.prototype.resetCancelData = function() {
    this.continueUntil(null);
    this.maxOccurrence(null);
    this.manualCancel(null);
  };

  AdvisorCreateNewTransferInstructionFlow.prototype.getFromObject = function() {
    return transferAccountDataProvider.getAccountById(this.transferFromType() === 'AdvisorCreateNewAccountFlow' ? 'Account' : 'BankAccount', this.transferFromId());
  };

  AdvisorCreateNewTransferInstructionFlow.prototype.getToObject = function() {
    return transferAccountDataProvider.getAccountById(this.transferToType() === 'AdvisorCreateNewAccountFlow' ? 'Account' : 'BankAccount', this.transferToId());
  };

  AdvisorCreateNewTransferInstructionFlow.prototype.setFrom = function(typeString, accountId) {
    this.transferFromType(typeString);
    this.transferFromId(accountId);
  };

  AdvisorCreateNewTransferInstructionFlow.prototype.setTo = function(typeString, accountId) {
    this.transferToType(typeString);
    this.transferToId(accountId);
  };
  return AdvisorCreateNewTransferInstructionFlow;
}
