'use strict';

function factory($filter, format, xirr) {

  /**
   * Represents a point along a projection in a simulation.
   *
   * @constructor
   * @param {Number} amount         The projected amount
   * @param {Date} date             The projected date
   * @param {Simulation} simulation The simulation that this point belongs to
   */
  function SimulatedBalance(amount, date, simulation) {
    this.amount = amount;
    this.date = date;
    this.simulation = simulation;
  }

  SimulatedBalance.prototype.balance = function() {
    return Math.max(0, this.amount);
  };

  /**
   * Returns a string indicating the probability that actual balance is amount.
   * Intended to be used by chart popovers.
   *
   * @method probabilityText
   * @return {String}
   */
  SimulatedBalance.prototype.probabilityText = function() {
    var probability = 100 - this.simulation.projection.percentile;
    var amount = Math.max(0, this.amount);
    return s.sprintf('%d%% chance of having at least $%s', probability, $filter('number')(amount, 0));
  };

  /**
   * Returns a string indicating the equivalent return of this projected point.
   * Intended for use in chart popovers.
   *
   * @method returnText
   * @return {String}
   */
  SimulatedBalance.prototype.returnText = function() {
    var cashFlows = this.simulation.toCashFlows(this.date);
    var percent;
    var percentText = '';

    try {
      if (cashFlows.length === 1) {
        percent = 0;
      } else if (cashFlows.length === 2) {
        percent = (cashFlows[1].amount - Math.abs(cashFlows[0].amount)) / Math.abs(cashFlows[0].amount) * 100;
      } else {
        percent = xirr(cashFlows, {
          annualized: false
        }) * 100;
      }

      percentText = percent >= 0 ? Math.round(percent) + '% gain' : Math.abs(Math.round(percent)) + '% loss';

    } catch (e) {
      percentText = '- gain';

    } finally {
      return s.sprintf('Equivalent to a %s', percentText);
    }
  };

  /**
   * Return a string indicating the month and year of the projected point, as
   * well as the amount of time from now that represents.
   *
   * @method timeFrameText
   * @return {String}
   */
  SimulatedBalance.prototype.timeframeText = function() {
    var text = d3.time.format('%B %Y')(this.date);
    var timeFromNow = format.timeFromNow(this.date);
    if (timeFromNow) {
      text += ' (' + timeFromNow + ')';
    }
    return text;
  };

  return SimulatedBalance;
}

angular.module('model.SimulatedBalance', [
    'service.format',
    'service.xirr'
  ])
  .factory('SimulatedBalance', [
    '$filter',
    'format',
    'xirr',
    factory
  ]);
