'use strict';

function promotionRedemptionFactory(ram, config) {

  var PromotionRedemption = new ram.Collection('PromotionRedemption', {
    belongsTo: {
      promotion: 'Promotion',
      user: 'User'
    },
    resources: {
      default: new ram.resources.Http('/api/promotion_redemptions/:id.json'),
      cookie: new ram.resources.Cookie('promotion_redemptions')
    },
    schema: config.schemas.PromotionRedemption
  });

  return PromotionRedemption;
}

angular.module('model.PromotionRedemption', [
    'ram',
    'config',
    'model.Promotion'
  ])
  .factory('PromotionRedemption', [
    'ram',
    'config',
    promotionRedemptionFactory
  ]);
