'use strict';

angular.module('component.advise.beneficiary-fillable-form', [
    'service.form-helper',
    'ram'
  ])
  .component('beneficiaryFillableForm', {
    templateUrl: 'components/beneficiary-fillable-form.html',
    bindings: {
      possibleRelatedToObjects: '=',
      reset: '='
    },
    controller: ['$scope', '$window', '$http', '$timeout', 'ram', 'formHelper', 'userNotifications', beneficiaryFillableFormController],
    controllerAs: '$ctrl'
  });

function beneficiaryFillableFormController($scope, $window, $http, $timeout, ram, formHelper, userNotifications) {
  /* jshint -W040 */
  var $ctrl = this;
  /* jshint +W040 */

  $scope.beneficiaryOptions = [{
    label: 'Client information only',
    value: false
  }, {
    label: 'Client information and existing beneficiary',
    value: true
  }];

  $scope.accountId = new ram.Accessor();
  $scope.printExistingBeneficiary = new ram.Accessor(null);
  /* jshint -W040 */
  $scope.possibleRelatedToObjects = this.possibleRelatedToObjects;
  /* jshint +W040 */

  $scope.reset = function() {
    userNotifications.showSuccess('The document has been downloaded locally.');
    $ctrl.reset();
  };

  $scope.downloadPdf = function(formController) {
    if ($scope.validate(formController)) {
      $http.post('/api/fillable_pdf_forms/save_fillable_pdf_params', {
        typeName: 'BeneficiaryFillableForm',
        relatedToId: $scope.accountId(),
        printExistingBeneficiary: $scope.printExistingBeneficiary()
      }).then(function(result) {
        var pdfUrl = '/api/fillable_pdf_forms/create_fillable_pdf?params_id=' + result.data;
        $window.location = pdfUrl;
        $scope.reset();
      });
    }
  };

  $scope.validate = function(formController) {
    if (formController.$invalid) {
      formHelper.notDone($scope, formController);
      return false;
    } else if ($scope.possibleRelatedToObjects.length === 0) {
      userNotifications.showError('There are no accounts to prepare this document for.');
      return false;
    } else {
      return true;
    }
  };
}
