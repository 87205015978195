'use strict';

/* global s */

function formatPhoneNumber(countryCode, areaCode, exchangeCode, stationCode, extension) {
  var result = countryCode ? s.sprintf('+%s ', countryCode) : '';
  if (areaCode && exchangeCode && stationCode) {
    result += s.sprintf('(%s) %s-%s', areaCode, exchangeCode, stationCode);
  }
  result += extension ? s.sprintf(' ext. %s', extension) : '';

  return result;
}

function createPhoneNumberParser(pattern, ngModelCtrl) {
  return function(formValue) {
    var result = '';

    if (formValue) {
      var match = formValue.match(pattern);
      if (match) {
        result = formatPhoneNumber(match[1], match[2], match[3], match[4], match[5]);
      }
    }

    if (result !== '') {
      // trigger rendering, so the form value is properly reformatted.
      // e.g. if user inputs 2223334444, the value of the input should change to (222) 333-4444.
      ngModelCtrl.$setViewValue(result);
      ngModelCtrl.$render();
    }

    return result;
  };
}

function directive(patterns) {
  return {
    replace: true,
    restrict: 'E',
    require: 'ngModel',
    scope: {},
    template: '<input type="tel" class="form-control" ng-pattern="phoneNumberPattern" placeholder="888-888-8888">',

    link: function($scope, element, attrs, ngModelCtrl) {
      $scope.phoneNumberPattern = patterns.phoneNumber;

      ngModelCtrl.$parsers.push(createPhoneNumberParser($scope.phoneNumberPattern, ngModelCtrl));
    }
  };
}

angular.module('directive.input.phone-number-input', ['service.patterns'])
  .directive('phoneNumberInput', ['patterns', directive]);
