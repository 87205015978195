'use strict';

angular.module('model.TransferInstruction', [
    'config',
    'model.TransferRequest',
    'ram'
  ])
  .factory('TransferInstruction', [
    '$http',
    'config',
    'ram',
    transferInstructionFactory
  ]);

function transferInstructionFactory($http, config, ram) {
  var TransferInstruction = new ram.Collection('TransferInstruction', {
    bind: [
      'workflowClientAction'
    ],
    enums: {
      type: config.types.TransferInstruction,
      frequency: config.types.TransferInstructionFrequency,
      status: config.types.TransferInstructionStatus,
      subStatus: config.types.TransferInstructionSubStatus
    },
    hasMany: {
      transferRequests: 'TransferRequest'
    },
    resources: {
      default: new ram.resources.Http('/api/transfer_instructions/:id.json'),
      cookie: new ram.resources.Cookie('transfer_instructions')
    },
    schema: config.schemas.TransferInstruction
  });

  TransferInstruction.prototype.cancel = function() {
    var self = this;
    return $http.put('/api/transfer_instructions/' + this.id + '/cancel.json')
      .then(function() {
        return self.reload();
      });
  };

  TransferInstruction.prototype.forceCancel = function() {
    var self = this;
    return $http.put('/api/transfer_instructions/' + this.id + '/force_cancel.json')
      .then(function() {
        return self.reload();
      });
  };

  TransferInstruction.prototype.hasInProgressRequests = function() {
    return _.some(this.transferRequests(), function(transferRequest) {
      return transferRequest.status.is.inProgress();
    });
  };

  TransferInstruction.prototype.resetCancelData = function() {
    this.continueUntil(null);
    this.maxOccurrence(null);
    this.manualCancel(null);
  };

  TransferInstruction.prototype.setFrom = function(typeString, accountId) {
    this.transferFromType(typeString);
    this.transferFromId(accountId);
  };

  TransferInstruction.prototype.setTo = function(typeString, accountId) {
    this.transferToType(typeString);
    this.transferToId(accountId);
  };

  TransferInstruction.prototype.userIsHolderOfFromOrTo = function() {
    var fromUserIsHolder = this.transferFrom && this.transferFrom.userIsHolder();
    var toUserIsHolder = this.transferTo && this.transferTo.userIsHolder();

    return fromUserIsHolder || toUserIsHolder;
  };

  TransferInstruction.prototype.amountAsMonthlyRate = function() {
    return (parseFloat(this.amount()) * this.frequency().instancesPerYear) / 12;
  };

  TransferInstruction.prototype.workflowClientAction = function(userId, action) {
    return $http.post('/api/transfer_instructions/workflow_client_actions', {
      userId: userId,
      id: this.id,
      workflowAction: action
    });
  };

  return TransferInstruction;
}
