'use strict';

angular.module('service.controller.eft.transfer', [
    'ram',
    'model.TransferRequest',
    'model.TransferInstruction',
    'service.transfer-account-data-provider',
    'service.group-by-date',
    'service.user-notifications',
    'service.adaptive-views'
  ])
  .factory('eftTransferService', [
    '$http',
    '$filter',
    '$timeout',
    '$analytics',
    'config',
    'ram',
    'TransferRequest',
    'TransferInstruction',
    'transferAccountDataProvider',
    'groupByDateService',
    'userNotifications',
    'adaptiveViews',
    'currencyFilter',
    eftTransferControllerFactory
  ]);

function eftTransferControllerFactory(
  $http,
  $filter,
  $timeout,
  $analytics,
  config,
  ram,
  TransferRequest,
  TransferInstruction,
  transferAccountDataProvider,
  groupByDateService,
  userNotifications,
  adaptiveViews,
  currencyFilter
) {
  var scrollUp = function() {
    window.scrollTo(0, 0);
  };

  function controller(
    $scope,
    $stateParams,
    client,
    accounts,
    bankAccounts,
    transferableAccounts,
    transferRequests,
    transferInstructions,
    wfmTransferableAccounts
  ) {
    var _setFromAndToLists;
    var _reset;
    var _from;
    var _to;
    var _getDefaultItem;
    var _setGuiObjectsAndCancelFlags;
    var _checkDataPassesDateCancel;
    var _checkDataPassesElapsedCancel;
    var _getFormattedDate;
    var _getJsDate;
    var _validateDateCancel;
    var _validateElapsedCancel;
    var _sortTransferInstructionsByDate;
    var _sortTransferRequestsByDate;
    var _insertSpaceForCurrencyError;

    var _isMobile = adaptiveViews.isMobile();

    var _onlyOnceFrequencyId = config.types.TransferInstructionFrequency.findByName('only_once').id;
    var _onlyOnceFrequencyOptions = _.where(config.types.TransferInstructionFrequency, {
      name: 'only_once'
    });

    var defaultTransferType = $stateParams.defaultTransferType;

    $scope.client = client;
    $scope.isClient = $scope.currentUser && $scope.currentUser.isClient();
    $scope.isApp = adaptiveViews.isApp();
    $scope.tab = $stateParams.initialTab;

    $scope.transferableAccounts = transferableAccounts;
    $scope.wfmTransferableAccounts = wfmTransferableAccounts;
    if ($scope.isClient) {
      $scope.pastTransferRequests = _.filter(transferRequests, function(transferRequest) {
        return moment(transferRequest.dateToBeProcessed()).toDate() <= moment().toDate();
      });
      $scope.currentTransferRequests = transferRequests.filter((transferRequest) => {
        // Want to include both ones with date in the past, as above, and the ones for the near future.
        // For CSI, there would be a single TR for a recurring event.  For BBS, there may be up to two,
        // for an every-two-week rotation.  (BBS has a 14-day window)
        return !transferRequest.status.is.completed() && !transferRequest.status.is.failed();
      });
    } else {
      $scope.tab = 'new-transfer';
    }

    // The transfer instructions need to be placed in an object in order for the
    // directives to pick up any changes to the transfer instructions
    $scope.transferInstructionsContainer = {
      transferInstructions: transferInstructions
    };
    $scope.fromAccount = new ram.Accessor(); // This is the id of the object selected
    $scope.toAccount = new ram.Accessor(); // This is the id of the object selected
    $scope.cancelDate = new ram.Accessor();
    $scope.transferableBankAccounts = _.filter(bankAccounts, function(bankAccount) {
      return bankAccount.transferable();
    });

    $scope.showNewTransferInputs = function() {
      return transferableAccounts.length > 1 || $scope.transferableBankAccounts.length > 0;
    };

    $scope.showOnlyTransferTypeInput = function() {
      return ($scope.transferInstruction.type.is.deposit() || $scope.transferInstruction.type.is.withdrawal()) && $scope.transferableBankAccounts.length === 0;
    };

    var _invalidAccountForOperation = function(control) {
      return ($scope.transferInstruction.type.fieldWithBankLabel() === control && $scope.transferableBankAccounts.length === 0);
    };

    $scope.fromToPlaceholderText = function(control) {
      var type = $scope.transferInstruction.typeId();
      if (_.isUndefined(type) || type === null) {
        return 'Please select a transaction type first';
      } else if (_invalidAccountForOperation(control)) {
        return 'No verified bank accounts are set up';
      }
      return 'Please select a \'' + control + '\' account';
    };

    if ($stateParams.showFormEsignedAlert) {
      userNotifications.showInfo(
        'You have successfully signed the account transfer form.' +
        '<br>' +
        'Account transfers could take up to 4 weeks. ' +
        'We will send you an email when we receive your funds.'
      );
    }

    if ($stateParams.showSuccessMessage) {
      userNotifications.showInfo($stateParams.showSuccessMessage);
    }

    transferAccountDataProvider.setInputs(client, accounts, $scope.transferableBankAccounts);

    $scope.messages = {
      number: 'Enter a number greater than zero',
      date: 'Continue until must be after Start Date',
      thisFieldIsRequired: 'This field is required'
    };

    $scope.withdrawingMoreThanBalanceWarningActionVerb = function() {
      return $scope.transferInstruction.type.is.withdrawal() ? 'withdraw' : 'transfer';
    };

    /***************************************************************************
     *
     * Private methods - Setup
     */
    // Formats into MM-DD-YYYY
    _getFormattedDate = function(date) {
      return moment(date)
        .startOf('day')
        .format('MM-DD-YYYY');
    };

    // Formats into Javascript Date
    _getJsDate = function(date) {
      return moment(date)
        .startOf('day')
        .toDate();
    };

    _reset = function() {
      if (_isMobile) {
        defaultTransferType = config.types.TransferInstruction.findByName('deposit').id;
      }
      $scope.transferInstruction = TransferInstruction.new();
      // If the user selected "Add Funds" then defaultTransferType will have a non-null value.  That is our
      // default transfer type.  Set it here.  It is either 'null' or 'not-null-integer' set in the routes.
      $scope.transferInstruction.typeId(defaultTransferType);
      $scope.fromAccounts = [];
      $scope.toAccounts = [];
      $scope.fromAccount(null);
      $scope.toAccount(null);
      _setFromAndToLists();
      $scope.transferDisplayData = null;
      $scope.showTransferInstructionCreationResult = false;

      $scope.transferInstruction.startDate(_getJsDate());

      var tomorrow = moment().add(1, 'd');
      $scope.cancelDate(_getJsDate(tomorrow));
      $scope.cancelDateMinDate = _getFormattedDate(tomorrow);

      $scope.transferInstruction.frequencyId(_onlyOnceFrequencyId);

      $scope.cancelOption = {
        name: 'user-cancel'
      };
      $scope.cancelNumber = {
        value: 1
      };

      $scope.mobileTransferTypeErrorMessage = false;
    };

    _setFromAndToLists = function() {
      if (_.isUndefined($scope.transferInstruction.type())) {
        return;
      }
      if ($scope.transferInstruction.type.is.withdrawal()) {
        _from($scope.transferableAccounts);
        _to($scope.transferableBankAccounts);
      } else if ($scope.transferInstruction.type.is.deposit()) {
        _from($scope.transferableBankAccounts);
        _to($scope.transferableAccounts);
      } else if ($scope.transferInstruction.type.is.transfer()) {
        _from($scope.wfmTransferableAccounts);
        _to($scope.transferableAccounts);
      }
    };

    /**
     * Resets the visual error state of a UX control for the 'from' and 'to'
     * @param  {string}   fieldName  The name of the field to modify, from the view's HTML
     * @param  {function} accessor   The RAM accessor used as the ng-model for the field
     *                               Pass the function.
     */
    var _resetIfValid = function(fieldName, accessor) {
      if (accessor()) {
        // It has a valid value
        if ($scope.myForm) {
          $scope.myForm[fieldName].$invalid = false;
          // Need to 'click' on it to reset the error
          angular.element('select[name=' + fieldName + ']').trigger('focus');
        }
      }
    };

    _from = function(accountList) {
      $scope.fromAccounts = accountList;
      $scope.fromAccount(_getDefaultItem($scope.fromAccounts));
      _resetIfValid('from', $scope.fromAccount);
    };

    _to = function(accountList) {
      $scope.toAccounts = accountList;
      $scope.toAccount(_getDefaultItem($scope.toAccounts));
      _resetIfValid('to', $scope.toAccount);
    };

    _getDefaultItem = function(list) {
      if (list && list[0] && list.length === 1) {
        return list[0].id;
      }
      // If more than one item in list pick nothing.
      return null;
    };

    var _getAccountById = function(accountType, accountId) {
      return transferAccountDataProvider.getAccountById(accountType, accountId);
    };

    $scope.getAccountById = function(accountType, accountId) {
      return _getAccountById(accountType, accountId);
    };

    var _getObjects = function(input) {
      return {
        transferFrom: _getAccountById(input.transferFromType(), input.transferFromId()),
        transferTo: _getAccountById(input.transferToType(), input.transferToId())
      };
    };

    var isTransferInstructionEndDateTodayOrInThePast = function(transferInstruction) {
      var mostRecentTransferRequest;
      var endDate;

      if (transferInstruction.transferRequests().length === 0) {
        return false;
      }

      if (!transferInstruction.hasCreatedAllTransferRequests()) {
        return false;
      }

      mostRecentTransferRequest = _.chain(transferInstruction.transferRequests())
        .sortBy(function(transferRequest) {
          return transferRequest.dateToBeProcessed();
        })
        .last()
        .value();

      endDate = moment(mostRecentTransferRequest.dateToBeProcessed()).toDate();

      return endDate <= moment().toDate();
    };

    _setGuiObjectsAndCancelFlags = function() {
      _.each($scope.transferInstructionsContainer.transferInstructions, function(transferInstruction) {
        var cancelDisplayOptions = {
          isEft: true,
          canCancel: transferInstruction.canCancel(),
          cancelWithMessage: !transferInstruction.frequency.is.onlyOnce()
        };
        _.extend(transferInstruction, {
          cancelDisplayOptions: cancelDisplayOptions
        });
        _.extend(transferInstruction, _getObjects(transferInstruction));
      });
      $scope.transferInstructionsContainer.transferInstructions = _.reject(
        $scope.transferInstructionsContainer.transferInstructions,
        function(transferInstruction) {
          return transferInstruction.status.is.finished() || isTransferInstructionEndDateTodayOrInThePast(transferInstruction);
        });
      _sortTransferInstructionsByDate();
    };

    var _setGuiTransferRequestObjects = function() {
      _.each($scope.pastTransferRequests, function(transferRequest) {
        _.extend(transferRequest, _getObjects(transferRequest));
      });
      _sortTransferRequestsByDate();
    };

    _sortTransferInstructionsByDate = function() {
      $scope.transferInstructionsByDate = groupByDateService.call(
        $scope.transferInstructionsContainer.transferInstructions,
        'startDate'
      );
    };

    _sortTransferRequestsByDate = function() {
      $scope.transferRequestsByDate = groupByDateService.call($scope.pastTransferRequests, 'dateToBeProcessed');
    };

    var _isTfsaAccount = function(accountId) {
      var account = _getAccountById('Account', accountId);

      return account && account.type.is.tfsa();
    };

    const _setFrequencyOptions = function () {
      if ($scope.transferInstruction.type.is.withdrawal()) {
        $scope.transferInstructionFrequencyOptions = _.where(
          config.types.TransferInstructionFrequency.filter((data) => data.name !== 'semi_monthly'),
          {
            allowableForWithdrawals: true
          }
        );
      } else if ($scope.transferInstruction.type.is.transfer()) {
        $scope.transferInstructionFrequencyOptions = _onlyOnceFrequencyOptions;
        $scope.transferInstruction.frequencyId(_onlyOnceFrequencyId);
      } else {
        $scope.transferInstructionFrequencyOptions =
          config.types.TransferInstructionFrequency.filter((data) => data.name !== 'semi_monthly');
      }
    };

    var _getNextBusinessDay = function(gap = null) {
      return $http.get('/api/next_business_day.json', {
          params: {
            date: moment().format('YYYY-MM-DD'),
            gap
          }
        })
        .then(function(response) {
          return moment(response.data.nextBusinessDay).toDate();
        });
    }

    /***************************************************************************
     *
     * Scope methods
     */
    $scope.allDataIncluded = function() {
      // return ($scope.fromAccount() >= 1 && $scope.toAccount() >= 1);
      return true;
    };

    $scope.transferFromToSameAccount = function() {
      return ($scope.transferInstruction.type().name === 'transfer' && $scope.fromAccount() === $scope.toAccount());
    };

    $scope.$watch('transferInstruction.typeId()', function(value) {
      if (_.isUndefined(value) || value === null) {
        return;
      }
      $scope.mobileTransferTypeErrorMessage = false;
      $scope.withdrawingMoreThanBalance = false;
      $scope.withdrawWithOutstandingTransfers = false;
      _setFromAndToLists();
      _setFrequencyOptions();
    });

    /*
     * Catch errors with the cancelNumber and cancelDate as the user is typing.
     */
    $scope.$watch('cancelNumber.value', function(value) {
      if (_.isUndefined(value) || value === null) {
        // The view sets this to null when clicked on.  Null falls into this case and triggers
        // no validation.  Validation will kick in when you press 'Make Transfer' though.
        return;
      }
      _validateDateCancel();
      _validateElapsedCancel();
    });

    $scope.$watch('cancelDate()', function(value) {
      if (_.isUndefined(value) || value === null) {
        return;
      }
      _validateDateCancel();
      _validateElapsedCancel();
    });

    $scope.$watch('transferInstruction.startDate()', function(value) {
      if (_.isUndefined(value) || value === null) {
        return;
      }

      var minDate = moment(value).add(1, 'd');

      if (minDate > $scope.cancelDate()) {
        $scope.cancelDate(_getJsDate(minDate));
      }
      $scope.cancelDateMinDate = _getFormattedDate(minDate);

      _validateDateCancel();
      _validateElapsedCancel();
    });

    /*
     * Reset invalid values when you pick another cancellation option.
     */
    $scope.$watch('cancelOption.name', function() {
      if ($scope.cancellationInstructionsAreUsed()) {
        if ($scope.cancelOption.name !== 'date-cancel' && !_checkDataPassesDateCancel()) {
          $scope.cancelDate(moment().startOf('day').toDate());
        }
        if ($scope.cancelOption.name !== 'elapsed-cancel' && !_checkDataPassesElapsedCancel()) {
          $scope.cancelNumber.value = 1;
        }
      }
    });

    $scope.$watch('transferInstruction.amount()', function(value) {
      if (_.isUndefined(value) || value === null) {
        return;
      } else if (_.isNaN(value)) {
        _insertSpaceForCurrencyError(true);
        return;
      }
      _insertSpaceForCurrencyError(false);
    });

    /*
     * Filter the 'to' accounts list based on the selection in the 'from' (for transfers only)
     */
    const _canFilterTransferAccounts = function (value) {
      return (
        value &&
        $scope.transferInstruction &&
        $scope.transferInstruction.typeId() ===
          config.types.TransferInstruction.findByName('transfer').id
      );
    };

    const _filterTransferrableAccounts = function (accountToExclude) {
      let subType = '';
      let excludeById = false;

      // Check if accountToExclude is an object and has a subType property
      if (typeof accountToExclude === 'object' && accountToExclude.subType) {
        subType = accountToExclude.subType;
      } else if (typeof accountToExclude === 'string' || typeof accountToExclude === 'number') {
        // accountToExclude is an ID, use _getAccountById to find the account
        const account = _getAccountById('Account', accountToExclude);
        if (account && account.type && account.type().label) {
          subType = account.type().label;
        }
        excludeById = true; // Set flag to true to exclude this account by ID
      }

      // Apply filters based on subType and excludeById flag
      return _.filter($scope.transferableAccounts, function (account) {
        // Exclude the account with the same ID if excludeById is true
        if (excludeById && account.id === accountToExclude) {
          return false;
        }
        // Exclude pending accounts
        if (account.label().includes('Pending')) {
          return false;
        }
        // If the subType is 'cash' or not determined, no filtering is done based on subType
        if (!subType || subType.toLowerCase() === 'cash') {
          return true;
        }
        // Filter based on the subtype
        return account.type().label.toLowerCase() === subType.toLowerCase();
      });
    };

    $scope.$watch('fromAccount()', function (accountSelected) {
      if (_canFilterTransferAccounts(accountSelected)) {
        $scope.toAccounts = _filterTransferrableAccounts(accountSelected);
        $scope.toAccount(null);
      }
      if (_.isUndefined(accountSelected) || accountSelected === null) {
        return;
      }

      // Check if the from Account is RESP to remove WITHDRAWAL option.
      if (
        $scope.transferInstruction.type.is.transfer() ||
        $scope.transferInstruction.type.is.withdrawal()
      ) {
        let type;

        if (typeof accountSelected === 'object') {
          type = accountSelected.subType;
        } else {
          const account = _getAccountById('Account', accountSelected);
          type = account.type().label;
        }

        if (type === 'Individual RESP' || type === 'Family RESP') {
          $scope.transactionTypes = _getValidTransactionTypesForResp();
        }
      }

      $scope.withdrawingMoreThanBalance = false;
      $scope.withdrawWithOutstandingTransfers = false;
    });

    $scope.$watch('toAccount()', function() {
      _setFrequencyOptions();
    });

    $scope.setUpAnotherTransfer = function(form) {
      defaultTransferType = null;
      _reset();
      if (form) {
        form.$setPristine();
      }
    };

    $scope.minAmount = 0;
    $scope.$watch('transferInstruction.typeId()', () => {
      const transferType = $scope.transferInstruction.type()
      if (!transferType) return

      if (transferType.name === 'withdrawal') {
        $scope.minAmount = 50;
        $scope.maxDate = $scope.oneYearInFuture;
      } else if (transferType.name === 'deposit') {
        $scope.minAmount = 10;
        $scope.maxDate = $scope.oneYearInFuture;
      } else if (transferType.name === 'transfer') {
        $scope.minAmount = 0;
        $scope.maxDate = moment().toDate();
      }
    });

    function getTransferInstructions() {
      return TransferInstruction.where({})
        .then(function(response) {
          $scope.transferInstructionsContainer.transferInstructions = response;
          _setGuiObjectsAndCancelFlags();
        });
    }

    const createTransferRequest = function (form) {
      $scope.creationStatus = true;

      if (typeof $scope.fromAccount() === 'object') {
        $scope.transferInstruction.internalTransferFromWfmAccount(true);
        $scope.transferInstruction.transferFromId = new ram.Accessor();
        $scope.transferInstruction.transferFromId($scope.fromAccount().accountNumber);
      }
      $scope.transferInstruction
        .save()
        .then(function (transferInstruction) {
          if (!$scope.isClient) {
            userNotifications.showSuccess(
              'An email has been sent to ' + $scope.client.fullName() +
                ' to confirm the ' +
                transferInstruction.type().label +
                ' request for ' +
                currencyFilter(transferInstruction.amount(), '$', 2)
            );
            $scope.setUpAnotherTransfer(form);
          } else {
            // Need to reload the transferInstructions for keeping the
            // upcoming transfers table in the unfunded messages in sync
            if (transferInstruction.transferToType() === 'Account') {
              _getAccountById('Account', transferInstruction.transferToId()).transferInstructions.ready(true);
            }

            // $scope.displayTransferId = transferAccountDataProvider.displayId($scope.transferInstruction);
            $scope.transferDisplayData = transferAccountDataProvider.getConfirmationData($scope.transferInstruction);
            _.extend($scope.transferDisplayData, {
              confirmationMode: 'panel'
            });

            form.$setPristine();
            $scope.showTransferInstructionCreationResult = true;

            getTransferInstructions();

            // For mobile, invoke reset callback
            if (_isMobile) {
              $scope.tab = 'view-transfers'; // Switch to other view
              $scope.setViewToDisplayUpcomingTransfers();
              $scope.setUpAnotherTransfer(form);
              $timeout(function() {
                // Let next page load
                scrollUp();
                userNotifications.showInfo(
                  'Your transfer has been set up successfully.' +
                  '<br>' +
                  (transferInstruction.type.is.deposit() ? '<b>Funds may be held for up to 4 business days before they become available for trading.</b>' : '') +
                  (transferInstruction.type.is.withdrawal() ? '<b>You should receive the funds within 4 business days.</b>' : '')
                );
              });
            }
          }
        })
        .catch(function(response) {
          $scope.creationStatus = false;
          if ($scope.isClient) {
            $scope.showTransferInstructionCreationResult = true;
          } else {
            if (response.data && response.data.error === 'Insufficient funds') {
              userNotifications.showError(`The client cannot ${$scope.withdrawingMoreThanBalanceWarningActionVerb()} more than the available account balance.`);
            } else {
              userNotifications.showError('Something went wrong while sending the EFT request to the client. Please try again later.');
            }
          }
        });
    };

    function _getAccountType(typeId, fromOrTo) {
      switch (typeId) {
        case config.types.TransferInstruction.findByName('withdrawal').id:
          return fromOrTo === 'from' ? 'Account' : 'BankAccount';
        case config.types.TransferInstruction.findByName('deposit').id:
          return fromOrTo === 'to' ? 'Account' : 'BankAccount';
        case config.types.TransferInstruction.findByName('transfer').id:
          return 'Account';
      }
    }

    function _assignTransferInstructions() {
      $scope.transferInstruction.resetCancelData();

      if ($scope.cancellationInstructionsAreUsed()) {
        if ($scope.cancelOption.name === 'user-cancel') {
          $scope.transferInstruction.manualCancel(true);
        } else if ($scope.cancelOption.name === 'date-cancel') {
          $scope.transferInstruction.continueUntil($scope.cancelDate());
        } else if ($scope.cancelOption.name === 'elapsed-cancel') {
          $scope.transferInstruction.maxOccurrence(parseInt($scope.cancelNumber.value));
        }
      }

      if ($scope.transferInstruction.frequencyId() === config.types.TransferInstructionFrequency.findByName('semi_monthly').id) {
        var start = moment($scope.transferInstruction.startDate());
        var day = parseInt(start.format('D'));
        if (2 <= day && day <= 14) {
          $scope.transferInstruction.startDate(start.startOf('month').add(14, 'day').toDate());
        } else if (day >= 16) {
          $scope.transferInstruction.startDate(start.startOf('month').add(1, 'month').toDate());
        }
      }
    }

    _checkDataPassesDateCancel = function() {
      var now = moment().startOf('day').toDate();
      if ($scope.cancelDate() < now) {
        // message handled by validation directive.
        return false;
      }
      if ($scope.cancelDate() < $scope.transferInstruction.startDate()) {
        $scope.dateErrorMessage = true;
        return false;
      }
      return true;
    };

    _validateDateCancel = function() {
      $scope.dateErrorMessage = null;
      if ($scope.cancelOption.name === 'date-cancel') {
        if (!_checkDataPassesDateCancel()) {
          return false;
        }
      }
      return true;
    };

    _checkDataPassesElapsedCancel = function() {
      var value = $scope.cancelNumber.value;

      if (!_.isNumber(value) || value <= 0) {
        $scope.numberErrorMessage = true;
        return false;
      }
      return true;
    };

    _validateElapsedCancel = function() {
      $scope.numberErrorMessage = null;
      if ($scope.cancelOption.name === 'elapsed-cancel') {
        if (!_checkDataPassesElapsedCancel()) {
          return false;
        }
      }
      return true;
    };

    var _validateCancellationInstructions = function() {
      if (_isMobile && (_.isUndefined($scope.transferInstruction.typeId()) || _.isNull($scope.transferInstruction.typeId()))) {
        $scope.mobileTransferTypeErrorMessage = true;
        return false;
      }
      if (!$scope.cancellationInstructionsAreUsed() || ($scope.cancellationInstructionsAreUsed() && _validateDateCancel() && _validateElapsedCancel())) {
        return true;
      }
      return false;
    };

    _insertSpaceForCurrencyError = function(insert) {
      var panel = angular.element('currency + div.panel');
      if (insert) {
        panel.addClass('top-margin-23');
      } else {
        panel.removeClass('top-margin-23');
      }
    };

    const _isWithdrawingMoreThanBalance = function () {
      if (!(typeof $scope.fromAccount() === 'object')) {
        const outstandingTransfers = _sumOutstandingTransfersFromAccount($scope.fromAccount()); // is an id
        $scope.withdrawWithOutstandingTransfers = outstandingTransfers > 0;
        return (
          ($scope.transferInstruction.type.is.withdrawal() ||
            $scope.transferInstruction.type.is.transfer()) &&
          _getAccountById('Account', $scope.fromAccount()).balance() <
            $scope.transferInstruction.amount() + outstandingTransfers
        );
      }
      return $scope.fromAccount().balance < $scope.transferInstruction.amount();
    };

    $scope.done = function(form) {
      var navbarHeight = document.querySelector('nav').clientHeight;
      if (_isWithdrawingMoreThanBalance()) {
        $scope.withdrawingMoreThanBalance = true;
        var alertElement = document.form.querySelector('#withdrawing-more-than-balance-alert');
        if (alertElement) {
          alertElement.scrollIntoView();
          window.scrollBy(0, -(navbarHeight + 10));
        }

        return false;
      } else {
        $scope.withdrawingMoreThanBalance = false;
        $scope.withdrawWithOutstandingTransfers = false;
      }

      $scope.transferInstruction.externalTransferFromNumber($scope.fromAccount().accountNumber);
      var validationResult = _validateCancellationInstructions();
      return form.$valid && validationResult;
    };

    $scope.notDone = function(form) {
      var firstInvalidInput = document.form.querySelector('.ng-invalid');
      var navbarHeight = document.querySelector('nav').clientHeight;

      _.each(form.$error, function(errors) {
        _.each(errors, function(control) {
          control.$setDirty();
        });
      });

      if (firstInvalidInput) {
        firstInvalidInput.focus();
        window.scrollBy(0, -(navbarHeight + 10));
      }

      if (_.isUndefined($scope.transferInstruction.amount())) {
        _insertSpaceForCurrencyError(true);
      }

      this.$root.$broadcast('submit');
    };

    $scope.submitTransferRequest = function(form) {
      if (!$scope.done(form)) {
        $scope.notDone(form);
        return;
      }
      _insertSpaceForCurrencyError(false);

      angular.element('.currency-input > input').trigger('blur'); // In case user presses 'ENTER'

      $scope.transferInstruction.setFrom(
        _getAccountType($scope.transferInstruction.typeId(), 'from'),
        $scope.fromAccount()
      );
      $scope.transferInstruction.setTo(
        _getAccountType($scope.transferInstruction.typeId(), 'to'),
        $scope.toAccount()
      );
      _assignTransferInstructions();

      if (!_validateCancellationInstructions()) {
        // Its an edge case, but semi-monthly may have changed the start date to be beyond the cancel date
        return;
      }

      var transferDisplayData = transferAccountDataProvider.getConfirmationData($scope.transferInstruction);
      _.extend(transferDisplayData, {
        confirmationMode: 'dialog'
      });

      $scope.transferConfirmationModal($scope.transferInstruction, transferDisplayData).result
        .then(function() {
          createTransferRequest(form);
        });
    };

    $scope.cancellationInstructionsAreUsed = function() {
      return !$scope.transferInstruction.frequency.is.onlyOnce();
    };

    $scope.getDateLabel = function() {
      return transferAccountDataProvider.getDateLabel($scope.transferInstruction);
    };

    $scope.getStatusLabel = function(statusId) {
      if (statusId === config.types.TransferRequestStatus.findByName('upcoming').id || statusId === config.types.TransferRequestStatus.findByName('in_progress').id) {
        return 'In progress';
      }
      var status = _.find(config.types.TransferRequestStatus, function(element) {
        return element.id === statusId;
      });
      return status.label;
    };

    $scope.getTransferInstructionTypeLabel = function(typeId) {
      var type = _.find(config.types.TransferInstruction, function(element) {
        return element.id === typeId;
      });
      return type.label;
    };

    $scope.setRadioFocus = function(arg) {
      $scope.cancelOption.name = arg;
    };

    $scope.setForm = function(form) {
      $scope.myForm = form;
    };

    $scope.clientLabel = function(sourceAccount, secondAccount) {
      // Due to policy scopes and access levels, the account passed to the UX may be undefined.
      // In this case, we know it is a bank account.  Polymorphic for Account and BankAccount.
      var firstName = secondAccount ? secondAccount.clientFirstName() : undefined;
      return sourceAccount ? sourceAccount.clientLabel() : firstName + '\'s bank account';
    };

    // don't use a function, it gets called every digest cycle
    $scope.oneYearInFuture = moment().add(1, 'y').toDate();
    $scope.maxDate = $scope.oneYearInFuture;

    /***************************************************************************
     *
     * Private methods for on load
     */
    var _getValidTransactionTypes = function() {
      let types = _.where(config.types.TransferInstruction, {
        system: false,
        hidden: false
      });

      // var list = $scope.transferableAccounts;
      // if (list && list[0] && list.length === 1) {
      //   types = _.filter(types, function(selectedType) {
      //     return selectedType.name !== 'transfer';
      //   });
      // }

      return types;
    };
    $scope.transactionTypes = _getValidTransactionTypes();

    // Do these on load
    _reset();
    if ($scope.isClient) {
      _setGuiObjectsAndCancelFlags();
      _setGuiTransferRequestObjects();
    }

    // ////////////////////////////////////////////////////
    // Mobile integration
    $scope.selectedTabIndex = 0;

    // Mobile Dropdown options
    $scope.transferTypes = [{
      id: 1,
      label: 'Upcoming Transfers',
      name: 'upcoming-transfers'
    }, {
      id: 2,
      label: 'Past Transfers',
      name: 'past-transfers'
    }];

    if ($scope.isMobile && $scope.accountTransfers && $scope.accountTransfers.length > 0) {
      $scope.transferTypes.push({
        id: 3,
        label: 'Account Transfers',
        name: 'account-transfers'
      });
    }

    $scope.transferType = new ram.Accessor();
    $scope.setViewToDisplayUpcomingTransfers = function() {
      $scope.transferType($scope.transferTypes[0]);
    };

    var initialState = _.findWhere($scope.transferTypes, {
      name: $stateParams.initialMobileViewTransfersState
    });

    $scope.transferType(initialState);

    $scope.setNewTransferTab = function($event) {
      $scope.tab = 'new-transfer';

      try {
        $analytics.tabTrack([{
          id: `transfers-${$scope.tab}`,
          name: $event.target.innerText,
          type: 'tab'
        }], {
          forms: [{
            id: $scope.tab,
            name: $event.target.innerText,
            type: $scope.tab
          }]
        })
      } catch(error) {
        console.error(error)
        window.Sentry && window.Sentry.captureException(error);
      }
    };

    $scope.setViewTransfersTab = function($event) {
      $scope.tab = 'view-transfers';

      try {
        $analytics.tabTrack([{
          id: `transfers-${$scope.tab}`,
          name: $event.target.innerText,
          type: 'tab'
        }], {
          lists: [{
            id: $scope.tab,
            name: $event.target.innerText,
            type: $scope.tab
          }]
        })
      } catch(error) {
        console.error(error)
        window.Sentry && window.Sentry.captureException(error);
      }
    };

    // Private functions
    function _sumOutstandingTransfersFromAccount(fromAccountId) {
      if (!$scope.transferInstructionsContainer || !$scope.transferInstructionsContainer.transferInstructions) {
        return 0;
      }

      const totalInstr = $scope.transferInstructionsContainer
        .transferInstructions
        .filter(transferInstruction => isTransferFromAccount(transferInstruction) && !skipTransferInstruction(transferInstruction))
        .reduce(reducer, 0);

      const totalReq = $scope.currentTransferRequests
        .filter(transferRequest => isTransferFromAccount(transferRequest))
        .reduce(reducer, 0);

      return totalInstr + totalReq;

      function reducer(accumulator, transferObject) {
        return accumulator + parseFloat(transferObject.amount());
      }

      function isTransferFromAccount(transferObject) {
        return transferObject.transferFromType() === 'Account' && transferObject.transferFromId() === fromAccountId;
      }

      function skipTransferInstruction(transferInstruction) {
        // Once a TransferRequest is made, it occupies the room in the withdrawal capacity
        return transferInstruction.transferRequests().length !== 0 && allRequestsHaveSameTransferInstructionId(transferInstruction);
      }

      function allRequestsHaveSameTransferInstructionId(transferInstruction) {
        // There is a strange bug when this code reads back a newly created TransferInstruction.  It ends
        // up having TransferRequests, but not ones associated with it.  It should have none.
        // Only allow this TI if all the TRs map back to itself.
        const id = transferInstruction.id;
        return transferInstruction.transferRequests().every(req => req.transferInstructionId() === id);
      }
    }
  }

  function getTransfersListFrom($scope) {
    const listTransfers = _
      .chain($scope.pastTransferRequests)
      .map(transfer => $scope.clientLabel(transfer.transferFrom, transfer.transferTo))
      .uniq()
      .map(transferAccount => ({
        label: transferAccount
      }))
      .value();

    return [{
      label: 'All accounts'
    }].concat(listTransfers);
  }

  function getTransfersListTo($scope) {
    const listTransfers = _
      .chain($scope.pastTransferRequests)
      .map(transfer => $scope.clientLabel(transfer.transferTo, transfer.transferFrom))
      .uniq()
      .map(transferAccount => ({
        label: transferAccount
      }))
      .value();

    return [{
      label: 'All accounts'
    }].concat(listTransfers);
  }

  function getTransfersListFromOrTo($scope) {
    const listTransfersFrom = _
      .chain($scope.pastTransferRequests)
      .map(transfer => $scope.clientLabel(transfer.transferFrom, transfer.transferTo))
      .value();

    const listTransfersTo = _
      .chain($scope.pastTransferRequests)
      .map(transfer => $scope.clientLabel(transfer.transferTo, transfer.transferFrom))
      .value();

    const listTransfersFromOrTo = _
      .chain(listTransfersFrom)
      .concat(listTransfersTo)
      .uniq()
      .map(transferAccount => ({
        label: transferAccount
      }))
      .value();

    return [{
      label: 'All accounts'
    }].concat(listTransfersFromOrTo);
  }

  return {
    controller: controller,
    getTransfersListFrom: getTransfersListFrom,
    getTransfersListTo: getTransfersListTo,
    getTransfersListFromOrTo: getTransfersListFromOrTo
  };
}
