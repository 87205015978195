'use strict';

function dependencyFactory(ram) {

  /**
   * @class A condition on other that must be satisfied for question to display.
   *
   * @method evaluate
   * @method satisfied
   *
   * @param {Object} attrs
   */
  var Dependency = new ram.Collection('Dependency', {
    accessors: ['operator', 'value', 'on', 'question_accessor'],
    resources: {
      default: new ram.resources.Http('/api/dependencies/:id.json'),
      cookie: new ram.resources.Cookie('dependencies')
    },
  });

  /**
   * Evaluate a given value against a dependency condition.
   * @param {String} value The value to test.
   * @return {Boolean}
   */
  Dependency.prototype.evaluate = function(value) {
    value += '';
    switch (this.operator()) {
      case '<':
        return value < this.value();
      case '==':
        return value === this.value();
      case '!=':
        return value !== this.value();
      case '>':
        return value > this.value();
      case '|=':
        return _.contains(this.value().split(','), value);
      default:
        break;
    }
  };

  /**
   * Determine whether a dependency is satisfied or not.
   *
   * @return {Boolean}
   */
  Dependency.prototype.satisfiedBy = function(object) {
    var value = _.deepAccess(object, this.on());
    return this.evaluate(value);
  };

  return Dependency;

}

angular.module('model.Dependency', [
    'ram'
  ])
  .factory('Dependency', [
    'ram',
    dependencyFactory
  ]);
