'use strict';

angular.module('controller.advisor-new-client.view', [
    'ram',
    'service.form-helper',
    'service.user-notifications'
  ])
  .controller('AdvisorNewClientViewController', [
    '$scope',
    '$http',
    '$state',
    '$window',
    '$timeout',
    'details',
    'formHelper',
    'ram',
    'userNotifications',
    advisorNewClientViewController
  ]);

function advisorNewClientViewController(
  $scope,
  $http,
  $state,
  $window,
  $timeout,
  details,
  formHelper,
  ram,
  userNotifications
) {
  if ($scope.currentUser && !$scope.currentUser.isClient() && details.error) {
    // Clients don't see this.  This message is for external advisors
    // Future work will make this more comprehensive for clients
    $scope.genericOkModal('This operation is not permitted while you are logged in.',
      'You cannot create a new client while logged in as an advisor.  Please log out and try again.');
    return;
  }

  $scope.details = details;
  $scope.$root.branding = details.branding;

  $scope.advisorName = details.advisorName;
  $scope.institutionName = details.institutionName;
  $scope.hasServiceFee = details.hasServiceFee;

  $scope.acknowledgedAdvisorReferral = new ram.Accessor(false);
  $scope.acknowledgedAdvisorNotPartnerOfMa = new ram.Accessor(false);
  $scope.acknowledgedAdvisorReceivesServiceFee = new ram.Accessor(false);
  $scope.acknowledgedSharingPersonalInfoWithAdvisor = new ram.Accessor(false);

  $scope.submit = function(formController) {
    if (formController.$invalid) {
      formHelper.notDone($scope, formController);
    } else {
      $state.go('advisor-new-client.verify-identity');
    }
  };

  $scope.deleteAction = function() {
    let modalTitle = 'Delete Your Information?';
    let modalBody = 'Are you sure you want to delete all your personal information from ModernAdvisor?';
    let modalAcceptButtonClass = '';
    let modalAcceptButtonText = 'Delete';
    let modalCancelText = 'Cancel';
    $scope.$root.genericConfirmationModal(modalTitle, modalBody, modalAcceptButtonClass, modalAcceptButtonText, modalCancelText).result
      .then(function() {
        return $scope.deleteClientFlow();
      })
      .then(function() {
        $state.go('advisor-new-client.goodbye', {
          deleted: true
        });
      })
      .catch(function(error) {
        if (error) {
          userNotifications.showError('Unable to delete this application. Please try again. If the problem persists, please contact us.');
        }
      });
  };

  $scope.deleteClientFlow = function() {
    return $http.delete(`/api/advisor_create_new_client_flows/${$state.params.advisorNewClientFlowPassword}/client_delete`);
  };

}
