'use strict';

angular.module('controller.profile.account-settings', [
    'component.investment-charts.model-portfolio-pie-chart',
    'service.autoTimeframeService',
    'service.goal-service',
    'service.investments-bar-prep-data',
    'service.investment-charts.asset-allocation-data-generator',
    'directive.input'
  ])
  .controller('ProfileAccountSettingsCtrl', [
    '$scope',
    '$http',
    'autoTimeframeService',
    'UpdateRecord',
    'Goal',
    'goalService',
    'invBarPrepData',
    'investmentChartsAssetAllocationDataGenerator',
    profileAccountSettingsController
  ]);

function formController($scope, $http, autoTimeframeService, UpdateRecord, Goal, goalService, invBarPrepData, investmentChartsAssetAllocationDataGenerator) {
  var account = $scope.account;
  var goal = $scope.goal;
  var user = $scope.currentUser;

  function updateRiskRecommendation() {
    return goalService.calculateRiskRecommendation(user, goal)
      .then(function(riskRecommendation) {
        goal.riskRecommendation.set(riskRecommendation, true);
      });
  }

  function initForms() {
    if (goal.advisorRiskOffset() || account.skipRebalancing()) {
      $scope.preventEdit = true;
    }

    $scope.autoTimeframe = function(goal) {
      return autoTimeframeService.autoTimeframe(goal, $scope.currentUser, $scope.questions);
    };

    var updateRiskRecommendationThrottled = _.throttle(updateRiskRecommendation, 1000);

    var _prepareGraph = function() {
      var chartData = investmentChartsAssetAllocationDataGenerator.getData($scope.portfolio.nonZeroAllocations());

      $scope.allocationDataForPieChart = _.map(chartData, function(chartDataRow) {
        return {
          color: chartDataRow.color,
          label: chartDataRow.allocation.fund().label(),
          labelAmount: $scope.marketValue(chartDataRow.allocation),
          value: chartDataRow.allocation.weight()
        };
      });

      $scope.allocationDataForLegend = _.map(chartData, function(chartDataRow) {
        return {
          color: chartDataRow.color,
          label: chartDataRow.allocation.fund().label(),
          weight: chartDataRow.allocation.weight(),
          marketValue: $scope.marketValue(chartDataRow.allocation)
        };
      });

      var allocationsForBarChart = _.map(chartData, function(chartDataRow) {
        return chartDataRow.allocation;
      });

      var invChartDataObject = invBarPrepData.getChartDataForAllocations(allocationsForBarChart, $scope.account);
      if (invChartDataObject) {
        $scope.toBeStacked = invChartDataObject.toBeStacked;
        $scope.investmentsChartData = invChartDataObject.investmentsChartData;
      }
    };

    function updateModelPortfolio(current, old) {
      if (current === old) {
        return;
      }

      goalService.fetchModelPortfolio($scope.goal, $scope.account)
        .then(function(modelPortfolio) {
          $scope.portfolio = modelPortfolio;
          $scope.performance = modelPortfolio.performance(10000);
          _prepareGraph();
        });
    }

    $scope.$watch('goal.risk()', updateModelPortfolio);

    updateModelPortfolio(true);

    $scope.marketValue = function(d) {
      return d && d.weight() * $scope.account.balance();
    };

    $scope.onGoalChange = function(goal) {
      updateRiskRecommendationThrottled($http, $scope.currentUser, goal);
    };
  }

  function shouldDecreaseTimeframe(goal) {
    var goalLabel = goal.label();
    if (goalLabel === window.config.types.AccountTypesRecommender.filter((goal) => {return goal.name === "save_for_child_education"})[0].label) {
      return !goal.notimeframe();
    }
    if (goalLabel === window.config.types.AccountTypesRecommender.filter((goal) => {return goal.name === "save_for_retirement"})[0].label) {
      return !goal.notimeframe() && goal.timeframe() !== 25;
    }
  }

  function accountWasConfirmed() {
    return _.contains($scope.kycUpdate.confirmedSteps(), 'Account_' + account.number());
  }

  function initKycTimeframeUpdate() {
    $scope.reducedTimeframe = false;

    if (!shouldDecreaseTimeframe(goal) || accountWasConfirmed()) {
      return false;
    }

    if (moment().diff(moment(account.createdAt()), 'months') > 10) {
      $scope.reducedTimeframe = true;
      goal.timeframe.decrement(1, 0);
    }
  }

  function fetchBaselineRiskLevel() {
    var params = {
      params: _.underscoreKeys({
        accountId: account.id
      })
    };
    return $http.get('/api/update_records/last_goal.json', params)
      .then(function(response) {
        if (response.data) {
          return UpdateRecord.new(response.data);
        }
      });
  }

  function initRiskLevelChange() {
    $scope.riskLevelLoading = true;

    updateRiskRecommendation()
      .then(function() {
        return fetchBaselineRiskLevel();
      })
      .then(function(goalUpdateRecord) {
        if (goalUpdateRecord) {
          $scope.baselineGoal = Goal.new(_.camelizeKeys(goalUpdateRecord.getParsedPayload()));
          if (goal.risk() !== $scope.baselineGoal.risk()) {
            $scope.baselineRiskLevelChange = true;
          }
        }
      })
      .finally(function() {
        $scope.riskLevelLoading = false;
      });

    $scope.$on('$destroy', function() {
      $scope.goal.fetch();
    });

    var saveGoal = function() {
      $scope.goal.save();
    };
    $scope.$on('kycWizardCompleted', saveGoal);
    $scope.$on('kycWizardNextStep', saveGoal);

    $scope.riskChangeTooltip = 'Your recommended risk level might have changed because:<ul class="text-left"><li>Your investor type has changed; and/or</li><li>Your timeframe has changed; and/or</li><li>Your debt-to-income and/or debt-to-assets ratio has changed</li></ul>';
  }

  initForms();
  initKycTimeframeUpdate();
  initRiskLevelChange();

  return {
    initKycTimeframeUpdate: initKycTimeframeUpdate,
    initRiskLevelChange: initRiskLevelChange
  };
}

function profileAccountSettingsController($scope, $q, $http, autoTimeframeService, ModelPortfolio, UpdateRecord, Goal, invBarPrepData) {
  var account = $scope.account;
  var goal = $scope.account.accountGoals()[0].goal();

  $scope.oldRisk = goal.risk();
  $scope.goal = goal;

  var controller = formController($scope, $q, $http, autoTimeframeService, ModelPortfolio, UpdateRecord, Goal, invBarPrepData);

  /* jshint validthis: true */
  this.save = function() {
    return $scope.goal.save()
      .then(function(goal) {
        $scope.oldRisk = goal.risk();

        return account.save()
          .then(function() {
            $scope.reducedTimeframe = false;
            $scope.baselineRiskLevelChange = false;
          });
      });
  };

  this.reset = function() {
    return account.fetch(true)
      .then(function() {
        return controller.initKycTimeframeUpdate() && controller.initRiskLevelChange();
      });
  };

  this.getFormCtrl = function() {
    return $scope['formAccount' + $scope.index];
  };
}
