'use strict';

angular.module('controller.esign', [])
  .controller('EsignCtrl', [
    '$scope',
    '$sce',
    'esignDocument',
    esignController
  ]);

function esignController($scope, $sce, esignDocument) {
  $scope.widgetUrl = $sce.trustAsResourceUrl(esignDocument.widgetUrl());
}
