'use strict';

function adviseUpdateRecordsController($scope, updateRecords) {
  $scope.updateRecords = updateRecords;
  $scope.sortColumn = 'id'; // Set the default sort type
  $scope.sortReverse = true; // Set the default sort order
}

angular.module('controller.advise.update-records', [])
  .controller('AdviseUpdateRecordsCtrl', [
    '$scope',
    'updateRecords',
    adviseUpdateRecordsController
  ]);
