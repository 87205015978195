/* global filepicker */

'use strict';

angular.module('directive.mutual-fund-fees-page', [
    'directive.social-media-icons',
    'model.PhoneNumber',
    'ram'
  ])
  .directive('mutualFundFeesPage', [
    '$uibModal',
    '$http',
    '$analytics',
    '$location',
    'PhoneNumber',
    'ram',
    portfolioPerformanceDirective
  ]);

function portfolioPerformanceDirective($modal, $http, $analytics, $location, PhoneNumber, ram) {
  return {
    restrict: 'E',
    scope: {
      mutualFunds: '='
    },
    templateUrl: 'directives/mutual-fund-fees-page.html',
    link: function($scope) {
      var filepickerInitialized;

      $scope.nameOfPerson = new ram.Accessor('');
      $scope.name = new ram.Accessor(undefined);
      $scope.value = new ram.Accessor(undefined);
      $scope.email = new ram.Accessor(undefined);
      $scope.phoneNumber = new PhoneNumber();

      /**
       * Sum the values of all positions to find the total portfolio value.
       *
       * @param  {Object} portfolio
       * @return {Float}            Total portfolio value
       */
      $scope.$watch('portfolio.length', function() {
        $scope.total = _.reduce($scope.portfolio, function(sum, position) {
          return sum + position.value;
        }, 0);
      });

      $scope.clearPosition = function() {
        this.name.reset();
        this.value.reset();
      };

      $scope.reset = function() {
        this.disabled = false;
        this.blob = undefined;
        this.nameOfPerson.reset();
        this.email.reset();
        this.phoneNumber.full('');
        this.portfolio = [];
        this.clearPosition();
        this.total = 0;

        if (this.form) {
          this.form.$setPristine();
          this.form.$setUntouched();
        }
      };

      /**
       * Reduce portfolio to a CSV string with name and value columns.
       *
       * @param  {Object} portfolio
       * @return {String}           CSV representation of the portfolio
       */
      $scope.buildCSV = function() {
        if (!this.portfolio.length) {
          return;
        }
        return _.reduce(this.portfolio, function(file, position) {
          return file + '"' + position.name + '",' + position.value + '\n';
        }, '');
      };

      function forceMutualFundFormValidation() {
        angular.forEach($scope.positionForm.$error, function(errors) {
          angular.forEach(errors, function(control) {
            control.$setDirty();
          });
        });
        $scope.$broadcast('submit');
      }

      $scope.addPosition = function(name, value) {
        forceMutualFundFormValidation();
        if (!$scope.positionForm.$valid) {
          return;
        }

        var self = this;
        value = parseFloat(value);
        if (name && value) {
          self.portfolio.push({
            name: name,
            value: value
          });
          self.clearPosition();
          self.positionForm.$setPristine();
          self.form.$setPristine();
        }
      };

      $scope.removePosition = function(position) {
        var index = this.portfolio.indexOf(position);
        this.portfolio.splice(index, 1);
      };

      $scope.initAutocomplete = function(event) {
        var self = this;
        if (!filepickerInitialized) {
          $(event.target).autocomplete({
            appendTo: '#autocomplete-menu',
            delay: 100,
            minLength: 3,
            source: $scope.mutualFunds.split('\n'),
            select: function(event, ui) {
              if (ui.item) {
                self.$apply(function() {
                  self.name(ui.item.value);
                });
              }
            }
          });
        }
        filepickerInitialized = true;
      };

      $scope.pickFile = function() {
        var self = this;
        filepicker.pick(function(blob) {
          self.blob = blob;
          self.$apply();
        });
      };

      function forceFormValidation() {
        angular.forEach($scope.form.$error, function(errors) {
          angular.forEach(errors, function(control) {
            control.$setDirty();
          });
        });
        $scope.$broadcast('submit');
      }

      $scope.getReport = function(nameOfPerson, email, phoneNumber) {
        var self = this;
        var file = self.blob || self.buildCSV();

        self.error = '';
        if (!file) {
          self.error = 'Please upload a statement or manually enter your funds';
        }
        forceFormValidation();
        if (!file || !$scope.form.$valid) {
          return;
        }

        self.disabled = true;

        $modal.open({
          templateUrl: 'modals/mutual-fund-fees.html',
          controller: ['$scope', function($scope) {
            $scope.twitterShare = self.twitterShare;
            $scope.emailShare = self.emailShare;
            $scope.fbShare = self.fbShare;
          }]
        });

        filepicker.store(file, {
          filename: nameOfPerson + ' - ' + email
        }, function() {
          self.$apply(function() {
            self.reset();
          });
        });

        //send user email address and phone number to woopra with their action
        // Record analytics for Jelly Marketing campaign
        $analytics.pageTrack('/thank-you-mf-report', $location);
        /* jshint ignore:start */
        fbq('track', 'CompleteRegistration'); // `CompleteRegistration` is one of Facebook's standard events.  We don't know exactly what Jelly wants here yet.
        /* jshint ignore:end */

        // Send all the info in an email to mutualfundfeereportrequests@modernadvisor.ca as well
        $http.post('/api/marketing/record_rrsp_fee_report_request', {
          data: {
            name: nameOfPerson,
            email: email,
            phoneNumber: phoneNumber,
            blob: self.blob,
            csv: (self.blob ? null : file)
          }
        }).then(function() {
          // ignore success
        }, function() {
          // ignore failure
        });
      };

      // Lazy load the filepicker library
      // Note: this is going to be a nightmare to test
      if (typeof(filepicker) === 'undefined') {
        var script = document.createElement('script');
        script.onload = function() {
          filepicker.setKey('AODLXv6OVRIKYZJgWVKDiz');
        };
        script.src = '//api.filepicker.io/v2/filepicker.js';
        document.body.appendChild(script);
      }

      // Initialize
      $scope.reset();


      //========== Social Media Icons ============================================//
      var urlEncode = function(url) {
        return encodeURIComponent(url);
      };

      var shareUrl = 'www.ModernAdvisor.ca/mutual-fund-fees';

      $scope.emailShare = function() {
        var emailSubject = 'Get your free investment fee report and find out how much you can save.',
          emailBody = 'Learn more here: ' + shareUrl;

        return 'mailto:' + '?subject=' + urlEncode(emailSubject) + '&body=' + urlEncode(emailBody);
      };

      $scope.twitterShare = function() {
        var tweetContent = 'Get your free investment fee report and find out how much you can save. https://www.modernadvisor.ca/mutual-fund-fees';
        var url = 'http://twitter.com/share?text=' + urlEncode(tweetContent) + '&url=' + urlEncode(shareUrl);

        window.open(url, 'Share', 'height=400,width=500,left=200,top=200,chrome=yes,centerscreen=yes,dialog=yes,modal=yes,alwaysRaised=yes');
      };

      $scope.fbShare = function() {
        window.FB.ui({
          method: 'feed',
          display: 'popup',
          picture: 'https://www.modernadvisor.ca/assets/images/mutual-fund-fees.png',
          name: 'Get your free investment fee report and find out how much you can save.',
          caption: 'modernadvisor.ca',
          description: 'Learn more here:  ' + shareUrl,
          link: shareUrl
        });
      };
      //========== Social Media Icons End ========================================//
    }
  };
}
