'use strict';

angular.module('controller.advise.client', [
    'controller.advise.client.prepare-documents',
    'controller.advise.client.identity-verification',
    'controller.advise.client.view'
  ])
  .controller('AdviseClientCtrl', [
    '$rootScope',
    '$scope',
    '$http',
    'client',
    'clientRelationshipManagerInfo',
    'identityVerificationStatus',
    'pendingAdvisorInfo',
    adviseClientController
  ])
  .controller('AdvisePersonalInfoChangeController', [
    '$scope',
    advisePersonalInfoChangeController
  ]);

function adviseClientController($rootScope, $scope, $http, client, clientRelationshipManagerInfo, identityVerificationStatus, pendingAdvisorInfo) {
  $scope.client = client;
  $scope.person = client.person();
  $scope.identityVerificationStatus = identityVerificationStatus;
  $rootScope.pendingAdvisor = pendingAdvisorInfo;

  $scope.saveSensitiveInfo = function() {
    return $http.post('/api/people/' + client.person().id + '/advisor_sensitive_info_update', {
      firstName: $scope.person.firstName(),
      middleName: $scope.person.middleName(),
      lastName: $scope.person.lastName(),
      sin: $scope.person.sin(),
      email: $scope.client.email(),
      birthDate: $scope.person.birthDate(),
      employer: $scope.person.employer(),
      occupation: $scope.person.occupation(),
      homeAddress: $scope.person.homeAddress() ? $scope.person.homeAddress().toJSON() : null,
      mailingAddress: $scope.person.mailingAddress() ? $scope.person.mailingAddress().toJSON() : null,
      pro: $scope.person.pro()
    });
  };

  $scope.canCloseProfile = function(client) {
    return !client.deactivatedAt() && _.all(client.accounts(), function(account) {
      return account.status.is.closed();
    });
  };

  $scope.closeProfile = function(client) {
    /* jshint quotmark:false */
    var modalBody = s.sprintf("Are you sure you want to de-activate %s's profile?<br>The client will not be able to log in after this. ", client.fullName());
    var title = s.sprintf("Closing %s's profile", client.fullName());
    /* jshint quotmark:single */

    $scope.genericConfirmationModal(title, modalBody)
      .result
      .then(function() {
        return client.closeProfile();
      });
  };

  $scope.relationshipManager = function() {
    return clientRelationshipManagerInfo.primaryRelationshipManager;
  };

  $scope.institution = function() {
    return clientRelationshipManagerInfo.institution;
  };

  $scope.relationshipManagerUserId = function() {
    return clientRelationshipManagerInfo.primaryRelationshipManagerUserId;
  };

  $scope.reloadIdentityVerificationStatus = function(personId) {
    return $http.get('/api/advisor/people/' + personId + '/identity_verification_status.json')
      .then(function(response) {
        $scope.identityVerificationStatus = response.data;
      });
  };

  $scope.pendingServiceLevel = function() {
    if (!$rootScope.pendingAdvisor || !$rootScope.pendingAdvisor.serviceLevel) {
      return '';
    }

    return `${$rootScope.pendingAdvisor.serviceLevel}`;
  };

}

function advisePersonalInfoChangeController($scope) {
  /* jshint validthis: true */
  function reload() {
    var person = $scope.person || $scope.client.person();
    return person.fetch(true) && $scope.client.fetch(true);
  }

  this.save = function() {
    return $scope.saveSensitiveInfo()
      .then(function() {
        return reload();
      });
  };
  this.reset = function() {
    return reload();
  };
}
