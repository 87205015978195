'use strict';

angular.module('directive.profile-form', [
    'service.form-modification-tracker'
  ])
  .directive('profileForm', [
    '$http',
    '$timeout',
    'formModificationTracker',
    profileFormDirective
  ]);

function initForm($scope, formName) {
  return {
    getFormObject: function() {
      return $scope[formName];
    },

    validate: function() {
      $scope.$root.validateForm(this.getFormObject());
    },

    clearErrorMessages: function() {
      var _this = this;
      angular.forEach(_this.getFormObject().$error, function(errors) {
        angular.forEach(errors, function(control) {
          angular.element('[name=' + control.$name + ']').trigger('blur');
        });
      });
    },

    focusFirstInvalidField: function() {
      var firstInvalidInput = angular.element('form[name="' + formName + '"] .ng-invalid:first');

      if (firstInvalidInput) {
        firstInvalidInput.focus();
      }
    },

    isValid: function() {
      return this.getFormObject().$valid;
    },

    resetFormState: function() {
      var formObject = this.getFormObject();
      if (formObject) {
        formObject.$setPristine();
        this.clearErrorMessages();
      }
    }
  };
}

function profileFormDirective($http, $timeout, formModificationTracker) {
  return {
    restrict: 'E',
    replace: true,
    transclude: true,
    scope: {
      formClass: '@',
      formName: '@',
      formTitle: '@',
      simpleHeading: '@',
      simpleButtons: '@',
      hideButtons: '=',
      hideOnlyButtons: '=',
      formDescription: '@',
      onSave: '&',
      onReset: '&',
      onCheckDirty: '&',
      setForeignScope: '=',
      openWithEdit: '=',
      allEditDisabled: '='
    },
    require: '^editableContext',
    templateUrl: 'directives/profile-form.html',

    link: function($scope, el, attrs, controller) {
      $scope.simpleButtons = $scope.simpleButtons === 'true';

      $scope.validateForm = function() {
        // This blur happens inside `validateForm` too, but when running on Mobile and panning the page
        // after making a change, the blur and the check on $valid were happening too close together.
        angular.element(':focus').trigger('blur');
        var self = this;
        $timeout(function() {
          // Further to the above, this $timeout separates the blur (above) and the check on $valid (in
          // `validateForm`) so they happen in the proper sequence on Mobile.
          if ($scope.$root.validateForm(self[$scope.formName])) {
            $scope.save();
          } else {
            var firstInvalidInput = document.querySelector('form .ng-invalid');
            var navbarHeight = document.querySelector('nav').clientHeight;

            if (firstInvalidInput) {
              firstInvalidInput.focus();
              window.scrollBy(0, -(navbarHeight + 10));
            }
          }
        });
      };

      $scope.getPreventCancel = function() {
        return controller.getPreventCancel();
      };

      /**
       * This is called when the user wants to open editing a section.  If another section is open then
       * this private method is used as part of asking the user if they want to cancel or discard the
       * changes in the other section.
       *
       * @return nothing
       */
      function _toggleEditRisingEdgeHandler() {
        $scope.isSaved = false;
        $scope.isError = false;
        formModificationTracker.setCurrentForm($scope.formName, $scope.formDescription);
        formModificationTracker.activeSectionController(controller);
        formModificationTracker.activeSectionController().toggleEditingEnabled();
      }

      /**
       * Function to call when closing an edit section
       * @return nothing
       */
      function _leaveSectionHandler() {
        $scope.isError = false;
        if ($scope.reset) {
          $scope.reset();
        }
      }

      /**
       * This is called when the user wants to cancel editing a section.  If the section is not dirty
       * it can just be called.  If it is dirty, then we must check with the user first.  The onFormExit
       * method does all the checking for us.
       *
       * @return nothing
       */
      function _toggleEditFallingEdgeHandler() {
        _leaveSectionHandler();
        formModificationTracker.setCurrentForm(null);
        if (formModificationTracker.activeSectionController()) {
          formModificationTracker.activeSectionController().toggleEditingEnabled();
          formModificationTracker.activeSectionController(null);
        }
      }

      $scope.getPreventEdit = function() {
        return controller.getPreventEdit();
      };

      $scope.toggleEditingEnabled = function() {
        var enabled = controller.getEditingEnabled();
        if (!enabled) {
          formModificationTracker.onOpenSectionForEdit(_toggleEditFallingEdgeHandler, _toggleEditRisingEdgeHandler);
        } else {
          formModificationTracker.onFormExit(null, null, _leaveSectionHandler, _leaveSectionHandler);
        }
      };

      if ($scope.openWithEdit) {
        $scope.toggleEditingEnabled();
      }

      $scope.getEditingEnabled = function() {
        return controller.getEditingEnabled();
      };

      $scope.isSaved = false;
      $scope.isSaving = false;
      $scope.isError = false;

      var form = initForm($scope, $scope.formName);

      $scope.isDirty = function() {
        return (form.getFormObject() && form.getFormObject().$dirty) ||
          ($scope.onCheckDirty && $scope.onCheckDirty());
      };

      $scope.save = function() {
        form.validate();

        if (!form.isValid()) {
          form.focusFirstInvalidField();
          return;
        }

        $scope.isSaving = true;

        return $scope.onSave()
          .then(

            function() {
              $scope.$emit('profileUpdated');
              form.resetFormState();

              $scope.isSaving = false;
              $scope.isSaved = true;
              controller.toggleEditingEnabled();
              formModificationTracker.setCurrentForm(null);
              formModificationTracker.activeSectionController(null);
            },
            function(result) {
              // something went wrong
              if (result && result.status === 409) {
                $scope.$emit('maritalStatusChangeFailed', result.data.message);
              }
              $scope.isSaving = false;
              $scope.isError = true;
              if (result && result.$value === 'cancel') {
                $scope.isError = false;
              }
            }
          );
      };

      $scope.reset = function() {
        return $scope
          .onReset()
          .then(function() {
            form.resetFormState();
            $scope.isSaved = false;
          });
      };

      /**
       * The caller may wish to control the isSaving flag.
       */
      if ($scope.setForeignScope) {
        $scope.setForeignScope($scope);
      }

      formModificationTracker.registerForm($scope.formName, $scope);
      $scope.$on('$destroy', function() {
        if ($scope.isDirty()) {
          $scope.reset();
        }
        formModificationTracker.activeSectionController(null);
      });
    }
  };
}
