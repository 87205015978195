'use strict';

angular.module('routes.referral-only', [
    'controller.referral-only',
    'templates',
    'ui.router'
  ])
  .config([
    '$stateProvider',
    configRoutes
  ]);

function configRoutes($stateProvider) {
  function resolveRedirectToInterview($location, $q, $state, currentUser) {
    if (currentUser) {
      // From https://stackoverflow.com/a/30592506 Gustav's comment
      // The last `return $q.reject()` was not used as it seems to work without it.
      return $q.reject()
        .catch(function() {
          $state.transitionTo('interview.goal', $location.search(), {
            location: 'replace'
          });
        });
    }
  }

  $stateProvider
    .state('referral-only', {
      parent: 'site',
      url: '/referral-only?invalidAffiliateCode',
      views: {
        'main': {
          controller: 'ReferralOnlyCtrl'
        },
        'navbar': {
          templateUrl: 'navbar/minimal.html'
        },
        'footer': {
          templateUrl: 'partials/footer-minimal.html'
        }
      },
      data: {
        title: 'Referral Only'
      },
      resolve: {
        resolveRedirectToInterview: ['$location', '$q', '$state', 'currentUser', resolveRedirectToInterview]
      }
    });
}
