'use strict';

angular.module('model.PaginatedBankAccount', [
    'ram',
    'config',
    'model.PaginationMeta',
    'model.BankAccount'
  ])
  .factory('PaginatedBankAccount', [
    'ram',
    'config',
    paginatedBankAccountFactory
  ]);

function paginatedBankAccountFactory(ram, config) {
  /**
   * Constructor for PaginatedBankAccount model.
   * @class
   */
  var PaginatedBankAccount = new ram.Collection('PaginatedBankAccount', {
    schema: config.schemas.PaginatedBankAccount,
    hasOne: {
      paginationMeta: 'PaginationMeta'
    },
    hasMany: {
      bankAccounts: 'BankAccount',
    },
    resources: {
      default: new ram.resources.Http('/api/paginated_bank_accounts/:id.json'),
      cookie: new ram.resources.Cookie('paginated_bank_accounts')
    }
  });

  return PaginatedBankAccount;
}
