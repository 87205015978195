'use strict';

angular.module('routes.eft-verify', [
    'controller.eft-verify',
    'templates',
    'ui.router',
    'model.AdvisorEftDataset',
    'model.TransferInstruction',
    'service.adaptive-views'
  ])
  .config([
    '$stateProvider',
    'adaptiveViewsProvider',
    configRoutes
  ]);

function configRoutes($stateProvider, adaptiveViewsProvider) {

  function _convertJsonToTransferInstruction(TransferInstruction, jsonFragment) {
    return new TransferInstruction(jsonFragment);
  }

  function resolveUserName($stateParams) {
    return $stateParams.user;
  }

  function resolvePassword($stateParams) {
    return $stateParams.password;
  }

  function resolveEftVerifyDetails($http, userName, password) {
    var url = '/api/transfer_instructions/eft_verify_link_details.json';
    var headers = {
      'Authorization': 'Basic ' + btoa(userName + ':' + password)
    };

    return $http.get(url, {
        headers: headers
      })
      .then(function(data) {
        return {
          userId: data.data.userId,
          transferInstructions: data.data.transferInstructions,
          advisorDataArray: data.data.advisorDataArray
        };
      })
      .catch(function(data) {
        if (data.data) {
          return {
            error: data.data
          };
        }
        return null;
      });
  }

  function mapTransferInstructions(TransferInstruction, eftClientData) {
    return _.map(eftClientData.transferInstructions, function(jsonFragment) {
      return _convertJsonToTransferInstruction(TransferInstruction, jsonFragment);
    });
  }

  function resolveAdvisorEftDataset(AdvisorEftDataset, eftClientData) {
    return AdvisorEftDataset.where({
      userId: eftClientData.userId
    });
  }

  function resolveUserId(eftClientdata) {
    return eftClientdata.userId;
  }

  function resolveAdvisorDataArray(eftClientdata) {
    return eftClientdata.advisorDataArray;
  }

  $stateProvider
    .state('eft-verify', {
      url: '/eft-verify?user&password',
      parent: 'site',
      controller: 'EftVerifyCtrl',
      resolve: {
        userName: ['$stateParams', resolveUserName],
        password: ['$stateParams', resolvePassword],
        eftClientData: ['$http', 'userName', 'password', resolveEftVerifyDetails],
        eftDetails: ['TransferInstruction', 'eftClientData', mapTransferInstructions],
        userId: ['eftClientData', resolveUserId],
        advisorDataArray: ['eftClientData', resolveAdvisorDataArray],
        advisorEftDataset: ['AdvisorEftDataset', 'eftClientData', resolveAdvisorEftDataset]
      },
      views: {
        main: {
          controller: 'EftVerifyCtrl'
        },
        navbar: {
          templateUrl: 'navbar/vanilla-navbar.html'
        },
        footer: {
          templateUrl: adaptiveViewsProvider.isMobile() ? 'mobile/partials/footer.html' : 'partials/footer-minimal.html'
        }
      }
    });
}
